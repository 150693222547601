import { Skeleton } from "antd";
import type { IRendererConfig } from "../domain";
import type { IRelatedCollapseConfig } from "./domain";
import WidgetRelatedCollapseEditor from "./editor/WidgetRelatedCollapseEditor";
import RecordRelatedCollapse from "./widget/RecordRelatedCollapse";

export const widgetRelatedCollapseDefinition: IRendererConfig<IRelatedCollapseConfig> =
  {
    parseConfig: (widget) => {
      const defaultValue: IRelatedCollapseConfig = {
        foreignObjectPropertyId: "",
        options: {
          pageSize: 10,
          label: [],
          sortBy: [],
          right: [],
          filters: {
            operator: "and",
            filters: [],
          },
        },
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IRelatedCollapseConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, form, org) => {
      return (
        <WidgetRelatedCollapseEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          form={form}
        />
      );
    },
    renderComponent: (
      widget,
      object,
      record,
      layoutId,
      recordId,
      conf,
      edit
    ) => {
      return (
        <RecordRelatedCollapse
          widget={widget}
          object={object}
          record={record}
          layoutId={layoutId}
          recordId={recordId}
          conf={conf}
          edit={edit}
        />
      );
    },
    renderLoadingComponent: (widget, conf) => (
      <Skeleton.Button
        active
        block
        shape="default"
        size="small"
        style={{ height: 250 }}
      />
    ),
  };
