import { Form } from "antd";
import { ColumnsAddition } from "../../../../../../../components/widget-options/ColumnsAddition";
import ChartOptionLine from "../../../../../../chart-options/components/ChartOptionLine";
import type { AvailableColumn } from "../../../../object/domain";

type ColumnSelectorProps = {
  availableColumns: AvailableColumn[];
  label: string;
  name: Array<string | number>;
};

export const ColumnSelector = ({
  availableColumns,
  label,
  name,
}: ColumnSelectorProps) => {
  return (
    <ChartOptionLine
      items={[
        {
          content: (
            <Form.Item
              label={label}
              required
              style={{ marginBottom: 4 }}
              name={name}
            >
              <ColumnsAddition availableColumns={availableColumns} />
            </Form.Item>
          ),
          flex: 1,
        },
      ]}
    />
  );
};
