import { FileFilled } from "@ant-design/icons";
import type { ReportType } from "../../interfaces/reports";
import { grey } from "../../utils/colorPalette";
import { WlyDashboardIcon } from "./icons/DashboardIcon";
import { WlyQuestionIcon } from "./icons/QuestionIcon";

type ReportIconProps = {
  type: ReportType | undefined;
  disabled?: boolean;
};

export const ReportIcon = ({ type, disabled = false }: ReportIconProps) => {
  switch (type) {
    case "DASHBOARD":
      return (
        <WlyDashboardIcon style={{ color: disabled ? grey[5] : "#BE98C6" }} />
      );
    case "QUESTION":
      return (
        <WlyQuestionIcon style={{ color: disabled ? grey[5] : "#69A2B0" }} />
      );
    default:
      return <FileFilled />;
  }
};
