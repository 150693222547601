import {
  CodeOutlined,
  ColumnWidthOutlined,
  DoubleRightOutlined,
  DownloadOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  WarningTwoTone,
  ZoomInOutlined,
} from "@ant-design/icons";
import type { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Drawer,
  Dropdown,
  Flex,
  Popconfirm,
  Popover,
  Skeleton,
} from "antd";
import numeral from "numeral";
import { useEffect, useMemo, useRef, useState } from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import { handleGQLErrors } from "../../../../helpers/gqlHelpers";
import type { IDestination } from "../../../../interfaces/destinations";
import { IOrgFeatureType } from "../../../../interfaces/org";
import type { IDataset } from "../../../../interfaces/sources";
import { orange } from "../../../../utils/colorPalette";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import DbtQueryModal from "../../../spreadsheet/dbtQueryModal/dbtQueryModal";
import type {
  ColumnInformations,
  TableTabItem,
} from "../../../spreadsheet/domain";
import DrillsModal from "../../../spreadsheet/drills/DrillsModal";
import type { IRelatedObject } from "../../../spreadsheet/history/HistoryDrawer";
import HistoryDrawer from "../../../spreadsheet/history/HistoryDrawer";
import type { ISpreadsheetLoading } from "../../../spreadsheet/SpreadsheetCore";
import type { ViewTabItem } from "../../../spreadsheet/views/ViewSelector";
import { DEFAULT_WORKBENCH_RECORD_NUMBER, type TableData } from "../domain";
import { ModelMigrationModal } from "../migration/MigrationModal";
import type { DatasetSavedData } from "../selector/tables/models/AddModelModal";
import AddModelModal from "../selector/tables/models/AddModelModal";
import { ColumnDetails } from "./ColumnDetails";
import { CustomCellRenderer } from "./custom-cell-renderer/CustomCellRenderer";
import { DatasetViewerHeaderCell } from "./dataset-viewer-header-cell/DatasetViewerHeaderCell";
import "./DatasetViewer.scss";
import { LoadingOverlay } from "./overlays/LoadingOverlay";
import { NoRowsOverlay } from "./overlays/NoRowsOverlay";
import { RowDetails } from "./row-details/RowDetails";

type DatasetViewerProps = {
  loading: ISpreadsheetLoading;
  tableData: TableData;
  currentWarehouse?: IDestination;
  datasets?: IDataset[];
  datasetQueryCursor?: string;
  columnInfos?: ColumnInformations;
  activeTable?: TableTabItem;
  activeView?: ViewTabItem;
  activeTableKey?: string;
  activeViewKey?: string;
  onRefresh?: () => void;
  onDrillUpdate?: (drills: string[]) => Promise<any>;
  onMigrationDone?: (
    toDatasetId: string,
    datasetIds: string[],
    explorationIds: string[]
  ) => Promise<void>;
  onCreateModel?: (dataset: DatasetSavedData) => Promise<any>;
  onExportCsv?: () => void;
  additionalActions?: Array<JSX.Element>;
};

type Props = DatasetViewerProps & InjectedOrgProps;

const Component = ({
  loading,
  tableData,
  currentWarehouse,
  datasets,
  datasetQueryCursor,
  columnInfos,
  activeTable,
  activeView,
  activeTableKey,
  activeViewKey,
  onRefresh,
  onDrillUpdate,
  onMigrationDone,
  onCreateModel,
  onExportCsv,
  org,
  orgFeatures,
  additionalActions,
}: Props) => {
  const gridRef = useRef<AgGridReact>(null);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRowData, setSelectedRowData] = useState<
    Record<string, any> | undefined
  >(undefined);
  const [selectedHeader, setSelectedHeader] = useState<string | undefined>(
    undefined
  );
  const [drillsModalVisible, setDrillsModalVisible] = useState(false);
  const [historyDrawerVisible, setHistoryDrawerVisible] = useState(false);
  const [dbtQueryModalVisible, setDbtQueryModalVisible] = useState(false);
  const [migrationModalVisible, setMigrationModalVisible] = useState(false);
  const [addModelModalVisible, setAddModelModalVisible] = useState(false);

  const components = useMemo(() => {
    return {
      agColumnHeader: DatasetViewerHeaderCell,
    };
  }, []);

  const selectedHeaderSchema = useMemo(
    () =>
      tableData.schema && selectedHeader
        ? tableData.schema[selectedHeader ?? ""]
        : undefined,
    [selectedHeader, tableData.schema]
  );

  const columnDefs = useMemo(
    () =>
      tableData.schema
        ? Object.entries(tableData.schema).map(([field, { domain, type }]) => ({
            field,
            headerName: field,
            sortable: false,
            headerComponentParams: {
              domain,
              type,
              isPrimaryKey: activeTable?.primaryKey?.includes(field),
              onClick: activeViewKey && (() => setSelectedHeader(field)),
            },
            cellRenderer: CustomCellRenderer,
            cellRendererParams: { domain },
          }))
        : undefined,
    [tableData.schema, activeTable?.primaryKey, activeViewKey]
  );

  const hasAnyLoading = useMemo(() => {
    return Object.values(loading).some((value) => !!value);
  }, [loading]);

  useEffect(() => {
    const hasNoData = !tableData.list || tableData.list.length === 0;

    if (gridApi && hasAnyLoading) {
      gridApi.showLoadingOverlay();
    } else if (gridApi && hasNoData) {
      gridApi.showNoRowsOverlay();
    } else if (gridApi) {
      gridApi.hideOverlay();
    }
  }, [gridApi, hasAnyLoading, tableData.list]);

  const handleGridReady = () => {
    setGridApi(gridRef.current?.api);
    gridRef.current?.api.autoSizeAllColumns();
  };

  const fromCellMenu = (targets: EventTarget[] | undefined) => {
    return targets?.some(
      (target) =>
        target instanceof HTMLElement &&
        target.classList.contains("cell-hover-menu")
    );
  };

  const drillsPrimaryKeys = activeTable?.primaryKey ?? [];
  const drills = activeView?.drills;
  const activeTableIsModel = !!activeTable?.isModel;
  const displayDbtQueryModal =
    !!activeTable?.dataset?.id &&
    !!activeTable?.dataset?.isModel &&
    activeTable?.dataset?.managedBy === "WHALY";
  const hasOldWorkbenchFeature = orgFeatures.includes(
    IOrgFeatureType.OLD_WORKBENCH
  );
  const hasExceedRecordLimit =
    !!tableData.list &&
    tableData.list.length >= DEFAULT_WORKBENCH_RECORD_NUMBER;

  const showMigrateButton =
    activeTable && !hasOldWorkbenchFeature && !activeTableIsModel;
  const showUseInFlowButton = showMigrateButton;
  const showRefreshButton = !!onRefresh;
  const showDrillsButton = drills && !!onDrillUpdate;

  const relatedObjects: IRelatedObject[] = [
    ...(activeTableKey
      ? [
          {
            recordId: activeTableKey,
            tableName: "Dataset" as "Dataset",
          },
        ]
      : []),
    ...(activeTable && activeTable.isModel && activeViewKey
      ? [
          {
            recordId: activeViewKey,
            tableName: "View" as "View",
          },
        ]
      : []),
  ];

  const moreMenuItems = [
    ...(activeTableIsModel
      ? [
          {
            key: 0,
            icon: <HistoryOutlined />,
            label: "Show history",
            onClick: () => setHistoryDrawerVisible(true),
          },
        ]
      : []),
    ...(displayDbtQueryModal
      ? [
          {
            key: 2,
            icon: <CodeOutlined />,
            label: "Show dbt query",
            onClick: () => setDbtQueryModalVisible(true),
          },
        ]
      : []),
  ];

  const hasTopMenu =
    showMigrateButton ||
    showUseInFlowButton ||
    showRefreshButton ||
    showDrillsButton ||
    moreMenuItems.length > 0;

  return (
    <>
      <Flex vertical className="dataset-viewer">
        {hasTopMenu && (
          <Flex align="center" gap="middle" className="top-menu">
            <Flex align="center" gap="small" style={{ flexGrow: "1" }}>
              {showMigrateButton && (
                <Button
                  size="small"
                  type="text"
                  onClick={() => setMigrationModalVisible(true)}
                  icon={<DoubleRightOutlined />}
                >
                  Migrate
                </Button>
              )}
              {showUseInFlowButton && (
                <Button
                  size="small"
                  type="text"
                  onClick={() => setAddModelModalVisible(true)}
                  icon={<PlusCircleOutlined />}
                >
                  Use in flow
                </Button>
              )}

              {showRefreshButton && (
                <Button
                  size="small"
                  type="text"
                  disabled={loading.records}
                  onClick={onRefresh}
                  icon={<ReloadOutlined />}
                >
                  Refresh
                </Button>
              )}

              {showDrillsButton && (
                <Button
                  size="small"
                  type="text"
                  onClick={() => setDrillsModalVisible(true)}
                  icon={<ZoomInOutlined />}
                >
                  Drills
                </Button>
              )}
            </Flex>

            {moreMenuItems.length > 0 && (
              <Dropdown menu={{ items: moreMenuItems }} trigger={["click"]}>
                <Button size="small" type="text" icon={<MoreOutlined />} />
              </Dropdown>
            )}
          </Flex>
        )}

        <div className="grid-container">
          <AgGridReact
            key={activeViewKey}
            ref={gridRef}
            className="ag-theme-alpine ag-theme-models"
            columnDefs={columnDefs}
            components={components}
            rowData={tableData.list}
            onGridReady={handleGridReady}
            onFirstDataRendered={() => gridApi?.autoSizeAllColumns()}
            onRowClicked={({ eventPath, data }) => {
              !fromCellMenu(eventPath) && setSelectedRowData(data);
            }}
            rowSelection="single"
            suppressContextMenu
            loadingOverlayComponent={LoadingOverlay}
            noRowsOverlayComponent={NoRowsOverlay}
          />
        </div>

        <Flex justify="flex-end" align="center" className="bottom-menu">
          {gridApi && !hasAnyLoading && (
            <div style={{ flexGrow: "1" }}>
              <Button
                size="small"
                type="text"
                onClick={() => gridApi.autoSizeAllColumns()}
                icon={<ColumnWidthOutlined />}
              >
                Autosize all columns
              </Button>
            </div>
          )}

          {!hasAnyLoading ? (
            <Flex gap="small">
              <span>
                {numeral(tableData.rowCount ?? 0).format("0,0[.]00")} records
              </span>
              {hasExceedRecordLimit && (
                <Popover
                  placement="topLeft"
                  content={`Displaying only the first ${DEFAULT_WORKBENCH_RECORD_NUMBER} results`}
                >
                  <WarningTwoTone
                    twoToneColor={orange[6]}
                    style={{ cursor: "help" }}
                  />
                </Popover>
              )}
            </Flex>
          ) : (
            <Skeleton.Button size="small" active style={{ width: 100 }} />
          )}

          {onExportCsv && tableData.list && (
            <Popconfirm
              title="Export as CSV"
              description={`This will export the ${tableData.list?.length} visible results as CSV`}
              placement="topRight"
              icon={<InfoCircleOutlined />}
              okText="Download"
              cancelText="No"
              onConfirm={onExportCsv}
            >
              <Button type="text" size="small" icon={<DownloadOutlined />} />
            </Popconfirm>
          )}
          {...additionalActions ? additionalActions : []}
        </Flex>
      </Flex>

      {drills && onDrillUpdate && (
        <DrillsModal
          visible={drillsModalVisible}
          onClose={() => setDrillsModalVisible(false)}
          schemaLoading={loading.schema}
          drills={drills}
          schema={tableData.schema ?? {}}
          primaryKeys={drillsPrimaryKeys}
          onUpdate={(drills: string[]) => onDrillUpdate(drills)}
        />
      )}

      <HistoryDrawer
        onClose={() => setHistoryDrawerVisible(false)}
        visible={historyDrawerVisible}
        relatedObjects={relatedObjects}
      />

      {displayDbtQueryModal && (
        <DbtQueryModal
          datasetId={activeTable.dataset?.id}
          isOpen={dbtQueryModalVisible}
          setIsOpen={(open) => setDbtQueryModalVisible(open)}
        />
      )}

      {activeTable?.dataset &&
        onMigrationDone &&
        currentWarehouse &&
        datasets && (
          <ModelMigrationModal
            currentWarehouse={currentWarehouse}
            visible={migrationModalVisible}
            onClose={() => setMigrationModalVisible(false)}
            datasets={datasets}
            dataset={activeTable.dataset}
            onMigrationDone={onMigrationDone}
          />
        )}

      {onCreateModel && currentWarehouse && (
        <AddModelModal
          key={datasetQueryCursor}
          visible={addModelModalVisible}
          currentWarehouse={currentWarehouse}
          initialData={{
            datasetId: datasetQueryCursor,
          }}
          onCancel={() => setAddModelModalVisible(false)}
          onSave={(data) =>
            onCreateModel({ ...data, isModel: true })
              .then(() => setAddModelModalVisible(false))
              .catch(handleGQLErrors())
          }
        />
      )}

      <Drawer
        title="Row details"
        open={!!selectedRowData}
        onClose={() => {
          setSelectedRowData(undefined);
          gridApi?.deselectAll();
        }}
        width={400}
        styles={{ body: { padding: 0 } }}
      >
        {selectedRowData && tableData.schema && (
          <RowDetails
            rowData={selectedRowData}
            schema={tableData.schema}
            primaryKeys={activeTable?.primaryKey}
          />
        )}
      </Drawer>

      <Drawer
        className="dataset-column-details-drawer"
        title={
          <Flex gap="small" align="center">
            <pre>"{selectedHeader}"</pre>
            <span>details</span>
          </Flex>
        }
        open={!!selectedHeader}
        onClose={() => setSelectedHeader(undefined)}
        width={400}
      >
        {selectedHeaderSchema && selectedHeader && activeViewKey && (
          <ColumnDetails
            org={org}
            field={selectedHeader}
            description={selectedHeaderSchema.description}
            domain={selectedHeaderSchema.domain}
            type={selectedHeaderSchema.type}
            tests={columnInfos && columnInfos[selectedHeader]?.tests}
            viewId={activeViewKey}
            recordCount={tableData.rowCount}
          />
        )}
      </Drawer>
    </>
  );
};

export const DatasetViewer = compose<Props, DatasetViewerProps>(WithOrg)(
  Component
);
