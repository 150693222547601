import {
  CloseOutlined,
  DashboardOutlined,
  ExperimentOutlined,
  HomeOutlined,
  MenuOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Drawer,
  Flex,
  Layout,
  Space,
  Typography,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/lib/layout/Sider";
import type { Location } from "history";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import type { RouteComponentProps } from "react-router";
import { Route, Switch, matchPath, withRouter } from "react-router";
import { useEffectOnce } from "react-use";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/error/Error";
import {
  Breakpoints,
  useBreakpoint,
} from "../../../../components/hooks/useBreakpoint";
import { useIsOnline } from "../../../../components/hooks/useIsOnline";
import { WlyCaretDownIcon } from "../../../../components/icons/custom-icons/WlyCaretDownIcon";
import Feednack from "../../../../components/layout/feedback/feedback";
import { LoadingLogo } from "../../../../components/layout/feedback/loadinglogo";
import {
  LeftMenu,
  type ILeftMenuItem,
} from "../../../../components/menu/LeftMenu";
import UserAvatar from "../../../../components/user/avatar/UserAvatar";
import { IOrgFeatureType } from "../../../../interfaces/org";
import { IUserRoleType } from "../../../../interfaces/user";
import type {
  RouteDefinition,
  RouteDescriptorComponent,
} from "../../../../routes/domain";
import routes, { routeDescriptor } from "../../../../routes/routes";
import { routesComponents } from "../../../../routes/routesComponents";
import { track } from "../../../../services/AnalyticsService";
import { isDemoEnvActivated } from "../../../../services/DemoService";
import type { WorkspaceDatatoreProps } from "../../../../store/dataStores/workspace/workspaceDatastore";
import type { WorkspaceUIStoreProps } from "../../../../store/workspaceUIStore";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import AppSwitcherDropdown from "../../../orgs/picker/AppSwitcherDropdown";
import UserMenuDropdown from "../../../orgs/picker/UserMenuDropdown";
import { currentTool } from "../../../orgs/picker/domain";
import HasRoleAccess, {
  hasRoleAccessBoolean,
} from "../../../user-settings/HasRoleAccess";
import ObjectShareForm from "../object/viewer/modals/ObjectShareForm";
import WorkspaceSearchModal from "../workspace/WorkspaceSearchModal";
import { LayoutDNDWrapper } from "./LayoutDNDWrapper";
import { OrgLogoV2 } from "./OrgLogoV2";
import "./V2Layout.scss";
import type { IV2Context } from "./domain";
import { BORDER_COLOR } from "./domain";
import CreateButton from "./menus/CreateButton";
import SettingsMenu from "./menus/SettingsMenu";
import WorkspaceMenu from "./menus/WorkspaceMenu";

interface IV2LayoutProps {
  menu?: "DEFAULT" | "SETTINGS";
}

type Props = IV2LayoutProps &
  InjectedOrgProps &
  RouteComponentProps &
  WorkspaceDatatoreProps &
  WorkspaceUIStoreProps;

const V2Layout = (props: Props) => {
  const {
    user,
    org,
    workspaceDatastore,
    workspaceUIStore,
    history,
    match: { params },
    location,
    orgFeatures,
    role,
  } = props;

  const [internalLocation, setInternalLocation] = useState<Location>();
  const [mobileSiderOpen, setMobileSiderOpen] = useState<boolean>(false);
  const isMobile = useBreakpoint(Breakpoints.SM);
  const isOnline = useIsOnline();

  const currentModalRoute = useMemo(() => {
    return routes
      .filter(({ embedModal }) => !!embedModal)
      .find(({ path }) => !!matchPath(location.pathname, path));
  }, [location.pathname]);

  const toolName = useMemo(() => {
    return currentTool(
      currentModalRoute?.embedModal && internalLocation
        ? internalLocation.pathname
        : location.pathname
    );
  }, [currentModalRoute?.embedModal, internalLocation, location.pathname]);

  useEffectOnce(() => {
    track("Workspace Viewed", {});
  });

  useEffect(() => {
    workspaceDatastore.reset();

    const includeObjects = !!org.featureGrants.find(
      ({ feature }) => feature.apiName === IOrgFeatureType.OBJECTS
    );

    const initWorkspace = async () => {
      await workspaceDatastore.init(
        {
          orgId: org.id,
          userId: user.id,
          notInLabels: user.isAdmin ? [] : ["technical"],
          includeObjects,
        },
        10000
      );
    };

    initWorkspace();

    return workspaceDatastore.reset;
  }, [org.featureGrants, org.id, user.id, user.isAdmin, workspaceDatastore]);

  useEffect(() => {
    toolName === "workbench"
      ? workspaceDatastore.sleep()
      : workspaceDatastore.wakeUp();
  }, [toolName, workspaceDatastore]);

  useEffect(() => {
    if (
      !_.isEqual(location, internalLocation) &&
      !currentModalRoute?.embedModal
    ) {
      setInternalLocation(Object.assign({}, location));
    }
  }, [currentModalRoute?.embedModal, internalLocation, location]);

  const {
    drawerType,
    drawerLabel,
    siderSize: workspaceSiderSize,
    hideRightHeader,
    shareObjectModalOpen,
    globalSearchOpen,
    setShareObjectModalOpen,
    setDrawerType,
    setDrawerTitleActionsDomNode,
    setSelectedFolder,
    setGlobalSearchOpen,
  } = workspaceUIStore;

  const workspaceDataStatus = workspaceDatastore.data.status;

  if (workspaceDataStatus === "initial" || workspaceDataStatus === "loading") {
    return (
      <Feednack>
        <LoadingLogo />
      </Feednack>
    );
  }

  if (workspaceDataStatus === "error") {
    return (
      <Feednack>
        <Error />
      </Feednack>
    );
  }

  const workspaceData = workspaceDatastore.data.data;
  const {
    allExplorationSections,
    allExplorations,
    allReportFolders,
    myFolder,
    allObjects,
    allFileUploadJobs,
  } = workspaceData;
  const context: IV2Context = {
    folders: allReportFolders,
    personalFolder: myFolder?.[0],
    allObjects: allObjects ?? [],
  };

  const workspaceMenuItems: ILeftMenuItem[] = [
    {
      key: "home",
      label: "Home",
      icon: <HomeOutlined />,
      active:
        !!matchPath(location.pathname, routeDescriptor.home.path)?.isExact &&
        !drawerType,
      onClick: () => {
        setSelectedFolder();
        setDrawerType(undefined);
        history.push(routeDescriptor.home.renderRoute(params));
      },
    },
    {
      key: "search",
      label: "Search",
      icon: <SearchOutlined />,
      active: globalSearchOpen,
      disabled: !isOnline,
      onClick: () => setGlobalSearchOpen(true),
    },
    {
      key: "lists",
      label: "Lists",
      icon: <UnorderedListOutlined />,
      active: drawerType === "lists",
      disabled: !isOnline,
      onClick: () =>
        setDrawerType(drawerType === "lists" ? undefined : "lists"),
      hasDivider: true,
    },
    {
      key: "dashboards",
      label: "Dashboards",
      icon: <DashboardOutlined />,
      active: drawerType === "dashboards",
      onClick: () =>
        setDrawerType(drawerType === "dashboards" ? undefined : "dashboards"),
    },
  ];

  const workspaceBottomMenuItems: ILeftMenuItem[] = [];

  if (
    user.isAdmin ||
    (orgFeatures.includes(IOrgFeatureType.DOCUMENTS) &&
      hasRoleAccessBoolean(IUserRoleType.EDITOR, user, org.id))
  ) {
    workspaceBottomMenuItems.push({
      key: "file-indexers",
      label: "File Indexer",
      icon: <ExperimentOutlined />,
      active:
        !!matchPath(location.pathname, routeDescriptor.fileUploads.path)
          ?.isExact && !drawerType,
      onClick: () => {
        setSelectedFolder();
        setDrawerType(undefined);
        history.push(routeDescriptor.fileIndexer.renderRoute(params));
      },
    });
  }

  if (
    allFileUploadJobs.length > 0 &&
    orgFeatures.includes(IOrgFeatureType.FILE_UPLOADS_API_NAME) &&
    (role.hasFileUploadPermission ||
      hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id))
  ) {
    workspaceBottomMenuItems.push({
      key: "file-uploaders",
      label: "File Uploaders",
      icon: <UploadOutlined />,
      active:
        !!matchPath(location.pathname, routeDescriptor.fileUploads.path)
          ?.isExact && !drawerType,
      onClick: () => {
        setSelectedFolder();
        setDrawerType(undefined);
        history.push(routeDescriptor.fileUploads.renderRoute(params));
      },
    });
  }

  const isOfflineForbidden =
    toolName !== "workspace" || location.pathname.includes("/object/");

  const getOrgName = () => {
    if (isDemoEnvActivated) {
      return "Whaly Demo";
    }
    return org.name;
  };

  const getOrgLogo = () => {
    if (isDemoEnvActivated) {
      return undefined;
    } else if (user.type === "PORTAL") {
      return user.portal?.logo ?? org.logo;
    }
    return org.logo;
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3a5c83",
          colorBorderSecondary: BORDER_COLOR,
        },
        components: {
          Dropdown: {
            controlItemBgActive: "#E7ECEF",
            controlItemBgActiveHover: "rgba(0, 0, 0, 0.04)",
          },
        },
      }}
    >
      {shareObjectModalOpen && (
        <ObjectShareForm
          visible={!!shareObjectModalOpen}
          canBeManagedByCurrentUser={
            !!shareObjectModalOpen?.canBeManagedByCurrentUser
          }
          onClose={() => setShareObjectModalOpen(undefined)}
          object={shareObjectModalOpen}
        />
      )}

      <LayoutDNDWrapper>
        {(drop, isDraggingReport) => (
          <Layout className="v2-layout" ref={drop}>
            {toolName !== "workbench" && !hideRightHeader ? (
              <Header className="v2-layout-header">
                <WorkspaceSearchModal
                  allExplorationSections={allExplorationSections}
                  allExplorations={allExplorations}
                  folders={allReportFolders}
                  personalFolder={myFolder}
                  objects={allObjects ?? []}
                />

                <div style={{ flex: 1, height: "100%" }}>
                  <Flex align="center" gap="small" style={{ height: "100%" }}>
                    {toolName === "settings" && (
                      <Button
                        className="display-inline display-none-lg"
                        size="large"
                        type="text"
                        onClick={() => setMobileSiderOpen(!mobileSiderOpen)}
                        icon={
                          mobileSiderOpen ? <MenuOutlined /> : <MenuOutlined />
                        }
                        style={{ verticalAlign: "middle" }}
                      />
                    )}

                    <OrgLogoV2
                      className={
                        toolName === "settings"
                          ? "display-none display-block-lg"
                          : ""
                      }
                      logo={getOrgLogo()}
                    />

                    {user.type === "PORTAL" && (
                      <Typography.Text type="secondary" strong>
                        {_.capitalize(user.portal?.name ?? org.name)}
                      </Typography.Text>
                    )}

                    {user.type === "STANDARD" && (
                      <>
                        <Button
                          type="text"
                          key="org"
                          size="small"
                          onClick={() => {
                            setDrawerType(undefined);
                            history.push(
                              routeDescriptor["org-picker"].renderRoute({
                                ...params,
                              })
                            );
                          }}
                        >
                          <Typography.Text type="secondary" strong>
                            {_.capitalize(getOrgName())}
                          </Typography.Text>
                          <span
                            style={{
                              // transform: "scale(0.8)",
                              marginLeft: 4,
                            }}
                          >
                            <WlyCaretDownIcon style={{ color: "#939393" }} />
                          </span>
                        </Button>

                        {isOnline && (
                          <Typography.Text
                            type="secondary"
                            className="display-none display-inline-lg"
                          >
                            /
                          </Typography.Text>
                        )}

                        {isOnline && (
                          <AppSwitcherDropdown>
                            <Button
                              type="text"
                              key="app"
                              size="small"
                              className="display-none display-inline-lg"
                              onClick={() => setDrawerType(undefined)}
                            >
                              <Typography.Text strong>
                                {_.capitalize(toolName)}
                              </Typography.Text>
                              <span
                                style={{
                                  marginLeft: 4,
                                }}
                              >
                                <WlyCaretDownIcon />
                              </span>
                            </Button>
                          </AppSwitcherDropdown>
                        )}
                      </>
                    )}
                  </Flex>
                </div>

                <div style={{ flex: 0, height: "100%" }}>
                  <Space style={{ height: "100%", display: "flex" }}>
                    {toolName === "workspace" && isOnline && (
                      <HasRoleAccess accessLevel={IUserRoleType.EDITOR}>
                        <div className="display-none display-inline-lg">
                          <CreateButton />
                        </div>
                      </HasRoleAccess>
                    )}

                    <UserMenuDropdown>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: `1px solid ${BORDER_COLOR}`,
                          borderRadius: "50%",
                        }}
                      >
                        <UserAvatar
                          user={user}
                          size={32}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </UserMenuDropdown>
                  </Space>
                </div>
              </Header>
            ) : null}

            <Layout
              hasSider={toolName === "workspace" ? !isMobile : true}
              className={
                toolName === "workspace"
                  ? "v2-sublayout bottom-menu-on-mobile"
                  : "v2-sublayout"
              }
              style={{ position: "relative" }}
            >
              {isOfflineForbidden && !isOnline && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1000,
                  }}
                >
                  <Flex vertical gap="middle">
                    <Typography.Text type="secondary">
                      This page can't be accessed offline.
                    </Typography.Text>
                    <Button
                      type="primary"
                      onClick={() => window.location.assign("/")}
                    >
                      Go back to home page
                    </Button>
                  </Flex>
                </div>
              )}
              {toolName === "settings" && (
                <>
                  <Sider
                    width={workspaceSiderSize}
                    style={{
                      backgroundColor: "#F9F8F8",
                      borderRight: "1px solid #D8D8D8",
                      overflowX: "hidden",
                    }}
                    className="whly-sub-menu display-none display-block-lg"
                  >
                    <SettingsMenu />
                  </Sider>

                  <MobileSider
                    size={workspaceSiderSize}
                    open={mobileSiderOpen}
                    onClose={() => setMobileSiderOpen(false)}
                  >
                    <SettingsMenu />
                  </MobileSider>
                </>
              )}

              {toolName === "workspace" && !hideRightHeader && (
                <LeftMenu
                  items={workspaceMenuItems}
                  bottomItems={workspaceBottomMenuItems}
                  bottomMenuOnMobile
                />
              )}

              <Content className="v2-layout-content">
                <Drawer
                  title={
                    <DrawerTitle
                      title={drawerLabel}
                      onClose={() => setDrawerType(undefined)}
                      setDrawerTitleActionsDomNode={
                        setDrawerTitleActionsDomNode
                      }
                      isOnline={isOnline}
                    />
                  }
                  placement={isMobile ? "bottom" : "left"}
                  open={!!drawerType}
                  closable={false}
                  onClose={() => setDrawerType(undefined)}
                  getContainer={false}
                  width={isMobile ? "100%" : "360px"}
                  height={isMobile ? "100%" : undefined}
                  styles={{
                    body: { padding: "12px", cursor: "pointer" },
                    header: { padding: "8px 12px" },
                  }}
                >
                  <WorkspaceMenu
                    allReportFolders={allReportFolders}
                    personalFolder={myFolder?.[0]}
                    allObjects={allObjects}
                    isDraggingReport={isDraggingReport}
                    allFileUploadJobs={allFileUploadJobs}
                  />
                </Drawer>

                <div style={{ height: "100%", overflowY: "auto" }}>
                  <Switch
                    location={
                      currentModalRoute?.embedModal
                        ? internalLocation
                        : undefined
                    }
                  >
                    {routes
                      .filter((r) => !r.public && (r as any).subComponent)
                      .map((r) => {
                        const Component =
                          routesComponents[(r as any).subComponent];
                        const subProps = (r as any).subProps;
                        const props = (r as any).props;
                        return (
                          <Route
                            key={0} // shared key to reuse layout and avoid remounting components on route change
                            exact={true}
                            path={r.path}
                          >
                            <Component
                              {...(r.removeInheritance ? {} : props)}
                              {...(subProps ? subProps : {})}
                              {...(props ? props : {})}
                              {...{ context: context }}
                            />
                          </Route>
                        );
                      })}
                  </Switch>

                  {currentModalRoute && currentModalRoute.embedModal && (
                    <Modal
                      route={currentModalRoute}
                      modalComponentDescriptor={currentModalRoute.embedModal}
                      onClose={() =>
                        history.push(
                          internalLocation
                            ? internalLocation
                            : routeDescriptor.home.renderRoute({
                                ...params,
                              })
                        )
                      }
                    />
                  )}
                </div>
              </Content>
            </Layout>
          </Layout>
        )}
      </LayoutDNDWrapper>
    </ConfigProvider>
  );
};

type ModalProps = {
  route: RouteDefinition;
  modalComponentDescriptor: RouteDescriptorComponent;
  onClose: () => void;
};
const Modal = ({ route, modalComponentDescriptor, onClose }: ModalProps) => {
  const ModalComponent = routesComponents[modalComponentDescriptor];

  return (
    <ModalComponent
      open={true}
      additionalProps={route.props}
      onClose={onClose}
    />
  );
};

type MobileSiderProps = {
  size: number;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
};
const MobileSider = ({ size, open, onClose, children }: MobileSiderProps) => {
  return (
    <Drawer
      placement="left"
      open={open}
      closable={false}
      onClose={onClose}
      getContainer={false}
      width={size}
      styles={{ body: { padding: "8px", cursor: "pointer" } }}
    >
      {children}
    </Drawer>
  );
};

type DrawerTitleProps = {
  title: string;
  isOnline: boolean;
  onClose: () => void;
  setDrawerTitleActionsDomNode: (domNode: HTMLDivElement) => void;
};
const DrawerTitle = ({
  title,
  isOnline,
  onClose,
  setDrawerTitleActionsDomNode,
}: DrawerTitleProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    ref.current && setDrawerTitleActionsDomNode(ref.current);
  }, [setDrawerTitleActionsDomNode]);

  return (
    <Flex align="center" justify="space-between" gap="small">
      <Typography.Title level={5} style={{ margin: 0, marginLeft: "8px" }}>
        {title}
      </Typography.Title>

      <Flex align="center" gap="small">
        <Flex
          align="center"
          gap="small"
          ref={ref}
          style={!isOnline ? { display: "none" } : undefined}
        >
          {undefined}
        </Flex>
        <Button
          shape="circle"
          type="text"
          onClick={onClose}
          icon={<CloseOutlined />}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </Flex>
    </Flex>
  );
};

export default compose<Props, IV2LayoutProps>(
  WithOrg,
  withRouter,
  inject("workspaceDatastore", "workspaceUIStore"),
  observer
)(V2Layout);
