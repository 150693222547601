import type { BinaryFilter, Filter, UnaryFilter } from "@cubejs-client/core";
import type {
  ColDef,
  ColumnOrderState,
  ColumnPinningState,
  ColumnSizingState,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridOptions,
  IRowNode,
  IServerSideDatasource,
  IsServerSideGroupOpenByDefaultParams,
  MenuItemDef,
  StatusPanelDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Pagination } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import type { AvailableColumn } from "../../../containers/v2-demo/container/object/domain";
import { isAvailableProperty } from "../../../containers/v2-demo/container/object/domain";
import type { AvailableProperty } from "../../../containers/v2-demo/container/object/viewer/domain";
import { getTimePartFromTimeDimension } from "../../../containers/v2-demo/container/object/viewer/domain";
import PropertyRenderer from "../../../containers/v2-demo/container/record/component/widgets/properties/widget/PropertyRenderer";
import { type IRecord } from "../../../containers/v2-demo/container/record/domain";
import type { IObject } from "../../../interfaces/object";
import "../theme/ag-theme-whaly.scss";
import Crosshair from "./Crosshair";
import type {
  ColumnsSettings,
  ISetTableQueryAction,
  ISetTableUIStateAction,
  ObjectTableExpandedNode,
  TableQuery,
  TableUIState,
} from "./domain";
import type { ObjectTableHeaderProps } from "./header/ObjectTableHeader";
import ObjectTableHeader from "./header/ObjectTableHeader";
import CountStatusBarComponent from "./status/CountStatusBarComponent";

interface IObjectTableBaseProps {
  displayType: "list" | "relatedList";
  availableColumns: AvailableColumn[];
  cubeJSDataSource: IServerSideDatasource;
  gridRef: React.RefObject<AgGridReact>;
  tableQuery: TableQuery;
  tableUIState?: TableUIState;
  object: IObject;
  showRowNumbers?: boolean;
  rowCount?: number;
  pagination?: {
    enabled: boolean;
    pageSize: number;
  };
  columnsSettings?: ColumnsSettings;
  setTableQuery: React.Dispatch<ISetTableQueryAction>;
  setTableUIState?: React.Dispatch<ISetTableUIStateAction>;
  selectable?: boolean;
}

export interface IObjectTableContext {
  tableQuery: TableQuery;
  availableColumns: AvailableColumn[];
  rowCount?: number;
}

interface ISelectableObjectTableProps extends IObjectTableBaseProps {
  selectable: true;
  selectedRecords: IRecord[];
  setSelectedRecords: (r: IRecord[]) => void;
}

type Props = IObjectTableBaseProps | ISelectableObjectTableProps;

/**
 * TODO : Update AG-Grid and then add a CustomLoadingComponent that add the capability to handle IDB missing data (see: https://www.ag-grid.com/react-data-grid/component-loading-cell-renderer/#failed-loading)
 */

function ObjectTable(props: Props) {
  const {
    displayType,
    availableColumns,
    cubeJSDataSource,
    tableQuery,
    tableUIState,
    gridRef,
    object,
    rowCount,
    showRowNumbers,
    columnsSettings,
    setTableQuery,
    setTableUIState,
    selectable,
    selectedRecords,
    setSelectedRecords,
  } = props as ISelectableObjectTableProps;

  const [pagination, setPagination] = useState<{
    total: number;
    current: number;
  }>({ total: 0, current: 0 });

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const [columnHeaderFormat, setColumnHeaderFormat] = useState<
    "compact" | "large"
  >("compact");

  const onGridReady = useCallback(() => {
    const api = gridRef?.current?.api;
    if (!api) return;
    const sizingModel = tableQuery.columnSizing?.columnSizingModel?.find(
      (c) => c.colId === `ag-Grid-AutoColumn`
    );
    if (sizingModel?.width && displayType === "list") {
      gridRef?.current?.api.setColumnWidth(
        `ag-Grid-AutoColumn`,
        sizingModel.width
      );
    }
    if (tableQuery.groups.length > 0) {
      api.setRowGroupColumns(
        tableQuery.groups.map((group) => group.groupedField)
      );
    }
    api.setGridOption("serverSideDatasource", cubeJSDataSource);
    setPagination({
      current: api.paginationGetCurrentPage() + 1,
      total: api.paginationGetRowCount(),
    });
  }, [
    cubeJSDataSource,
    displayType,
    gridRef,
    tableQuery.columnSizing?.columnSizingModel,
    tableQuery.groups,
  ]);

  const onPaginationChanged = useCallback(() => {
    if (gridRef?.current?.api) {
      const rowCount = gridRef?.current?.api?.paginationGetRowCount();
      const currentPage = gridRef?.current?.api?.paginationGetCurrentPage() + 1;
      setPagination({ total: rowCount, current: currentPage });
    }
  }, []);

  useEffect(() => {
    // we need to reorder the columns defs in order to make the initalState
    // working well when grouping columns in a different order than the order
    // defined by the availableColumns order
    let useLargeFormat = false;
    const desiredOrder = tableQuery.columnOrder?.orderedColIds;
    const colDefs: ColDef[] = [...availableColumns]
      .sort((a, b) => {
        const indexA = desiredOrder?.indexOf(a.data.key) || 0;
        const indexB = desiredOrder?.indexOf(b.data.key) || 0;

        if (indexA < indexB) return -1;
        if (indexA > indexB) return 1;
        return 0;
      })
      .map((col) => {
        const isPrimaryKey =
          col.type === "property" && col.data.type === "primaryKey";

        const isGroupped = tableQuery.groups.find(
          (g) => g.groupedDimension === col.data.key
        )
          ? true
          : false;

        let initialPinned: "left" | "right" | undefined = undefined;
        const isPinnedLeft = tableQuery.columnPinning?.leftColIds.includes(
          col.data.key
        );
        const isPinnedRight = tableQuery.columnPinning?.rightColIds.includes(
          col.data.key
        );
        if (isPinnedLeft) {
          initialPinned = "left";
        } else if (isPinnedRight) {
          initialPinned = "right";
        }

        const savedWidth = tableQuery?.columnSizing?.columnSizingModel?.find?.(
          (c) => c.colId === col.data.key
        )?.width;

        const savedFlex = tableQuery?.columnSizing?.columnSizingModel?.find?.(
          (c) => c.colId === col.data.key
        )?.flex;

        const grouppedQuery = !!tableQuery?.groups?.length;
        const isUsedInQuery = [
          ...(tableQuery.columnVisibility?.visibleColumnIds ?? []),
        ].includes(col.data.key);

        // we should simplify this
        let hide = isPrimaryKey ? grouppedQuery : isGroupped || !isUsedInQuery;
        if (displayType === "relatedList" && !grouppedQuery && !isUsedInQuery) {
          hide = true;
        }

        const field =
          tableQuery.groups.find((g) => g.groupedDimension === col.data.key)
            ?.groupedField ?? col.data.key;

        const timeAgg = getTimePartFromTimeDimension(field);

        const computeWidth = () => {
          if (savedWidth) {
            return savedWidth;
          }
          if (isPrimaryKey) {
            return 300;
          }
          if (col.data.formatter === "activities") {
            return 500;
          }
        };

        const computeFlex = () => {
          if (savedFlex) {
            return savedFlex;
          }
          return undefined;
        };

        if (col.data?.formatter === "activities" && !hide) {
          useLargeFormat = true;
        }

        const settings =
          columnsSettings && columnsSettings?.[col.data.key]
            ? columnsSettings[col.data.key]
            : {};

        const displayImage = settings.displayImage || false;

        let label: string = col.data.label;
        if (settings.label) {
          label = settings.label;
        }

        const colDef = {
          colId: col.data.key,
          headerName: label,
          lockPinned: true,
          field: field,
          enablePivot: false,
          pinned: isPrimaryKey && displayType === "list" ? "left" : undefined,
          initialPinned: initialPinned,
          hide: hide,
          initialRowGroup: isGroupped,
          initialHide: isGroupped,
          enableRowGroup: col.type === "property" && !isPrimaryKey,
          suppressMenu: displayType === "relatedList",
          enableValue: false,
          pivot: false,
          filter: false,
          checkboxSelection: isPrimaryKey && selectable ? true : false,
          width: computeWidth(),
          flex: computeFlex(),
          suppressMovable: false,
          headerComponentParams:
            col.type === "property"
              ? ({ property: col } as ObjectTableHeaderProps)
              : {},
          cellStyle:
            col.data.formatter === "activities" ? { padding: 0 } : undefined,
          cellRenderer: (params) => {
            return (
              <PropertyRenderer
                object={object}
                value={params.value}
                column={col}
                displayNull={isGroupped}
                displayImage={displayImage}
                extra={
                  isGroupped ? (
                    <span style={{ marginLeft: 6, color: "gray" }}>{`(${
                      params.data[`${object.table.cubeName}.count`]
                    })`}</span>
                  ) : undefined
                }
                timeAgg={timeAgg || undefined}
              />
            );
          },
          // valueGetter is used to transform null values to empty string
          // as ag grid does not play well with grouping null values
          valueGetter: ({ data, colDef: { field } }) => {
            if (!field) return "";
            if (typeof data?.[field] === "string") return data[field];
            if (typeof data?.[field] === "number") return data[field];
            if (typeof data?.[field] === "boolean") return String(data[field]);
            return "";
          },
        } as ColDef;

        return colDef;
      });
    const rowNbCol: ColDef = {
      colId: "_wly_row_number",
      headerName: "",
      cellStyle: {
        color: "gray",
        fontSize: 12,
        paddingRight: 0,
      },
      flex: 0,
      width: 46,
      minWidth: 20,
      suppressMenu: true,
      sortable: false,
      resizable: false,
      valueGetter: (params) => {
        if (typeof params.node?.rowIndex === "number") {
          return params.node?.rowIndex + 1;
        } else {
          return "";
        }
      },
    };
    if (showRowNumbers && !tableQuery.groups.length) {
      colDefs.unshift(rowNbCol);
    }
    setColumnDefs(colDefs);
    setColumnHeaderFormat(useLargeFormat ? "large" : "compact");
  }, [
    JSON.stringify(tableQuery),
    JSON.stringify(availableColumns),
    JSON.stringify(columnsSettings ?? {}),
  ]);

  const defaultColDef: ColDef = useMemo(
    () => ({
      minWidth: 150,
      sortable: false,
      resizable: true,
      suppressMovable: true,
      filter: false,
      menuTabs: ["generalMenuTab"],
      headerComponentParams: {
        onToggleSort: (sort) =>
          setTableQuery({
            action: "toggleOrder",
            order: sort,
          }),
      } as ObjectTableHeaderProps,
    }),
    []
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      headerName: "Label",
      flex: displayType === "relatedList" ? 1 : undefined,
      minWidth: displayType === "relatedList" ? 120 : 350,
      suppressMenu: displayType === "relatedList",
      suppressMovable: true,
      sortable: false,
      filter: false,
      initialPinned: true,
      menuTabs: ["generalMenuTab"],
      field: `${object.table?.cubeName}.label`,
      headerComponentParams: {
        onToggleSort: undefined,
      } as ObjectTableHeaderProps,
      cellRendererSelector: (params) => {
        if (params.node.group) {
          return {
            component: "agGroupCellRenderer",
            cellRendererParams: {
              innerRenderer:
                params.node.rowGroupColumn?.getColDef().cellRenderer,
            },
          };
        } else {
          // when we have no renderer it means we are in the leaf, we display the formatter for the primary key
          return {
            component: "agGroupCellRenderer",
            params: {
              innerRenderer: PropertyRenderer,
              innerRendererParams: {
                object: object,
                value: params.value,
                column: availableColumns.find(
                  (o) => o.data.key === `${object.table.cubeName}.label`
                ),
                displayNull: true,
              },
            },
          };
        }
      },
    }),
    [JSON.stringify(columnDefs)]
  );

  const context: IObjectTableContext = {
    rowCount: rowCount,
    tableQuery: tableQuery,
    availableColumns: availableColumns,
  };

  const getContextMenuItems = useCallback(
    (
      params: GetContextMenuItemsParams<any, IObjectTableContext>
    ): (string | MenuItemDef)[] => {
      const result: (string | MenuItemDef)[] = [];

      if (displayType === "relatedList") {
        result.push("copy");
      } else if (displayType === "list") {
        const colId = params.column?.getColId();
        const field = params.node?.field;
        const isGroupped = colId === "ag-Grid-AutoColumn";
        const isLeaf =
          params.node?.group === false &&
          params.node.parent?.leafGroup === true &&
          colId === "ag-Grid-AutoColumn";

        const property = (params.context.availableColumns ?? [])
          ?.filter(isAvailableProperty)
          .find((ap) => {
            if (isGroupped) {
              return ap.data.key === field;
            } else {
              return ap.data.key === colId;
            }
          })?.data;

        const sanitizeValue = (
          value: string | null,
          property: AvailableProperty
        ) => {
          if (
            property.type === "foreignKey" ||
            property.type === "primaryKey"
          ) {
            const [, name] = (value ?? "")?.split("||");
            return name;
          } else {
            return value;
          }
        };

        const filters: Array<{ field: string; value: string | null }> = [];

        if (isLeaf) {
          const primaryProperty = (params.context.availableColumns ?? [])
            ?.filter(isAvailableProperty)
            .find((p) => p.data.type === "primaryKey");
          if (primaryProperty) {
            const primaryValue = params.node?.data[primaryProperty.data.key];
            filters.push({
              field: primaryProperty.data.sortAndFilterKey,
              value: sanitizeValue(primaryValue, primaryProperty.data),
            });
          }
        } else if (isGroupped) {
          const searchParent = (item: IRowNode) => {
            if (item.field) {
              const matchingProperty = (params.context.availableColumns ?? [])
                ?.filter(isAvailableProperty)
                .find((ap) => ap.data.key === item.field)?.data;
              if (matchingProperty) {
                filters.push({
                  field: matchingProperty.sortAndFilterKey,
                  value: sanitizeValue(item.key, matchingProperty),
                });
              }
            }
            if (item.parent) {
              searchParent(item.parent);
            }
          };

          if (params.node) {
            searchParent(params.node);
          }
        } else if (property && colId) {
          filters.push({
            field: property.sortAndFilterKey,
            value: sanitizeValue(params.value, property),
          });
        }

        const canFiler = filters.length;

        if (canFiler) {
          const cubeFilters: Filter[] = filters.map((f) => {
            if (f.value) {
              return {
                operator: "equals",
                member: f.field,
                values: [f.value],
              } as BinaryFilter;
            } else {
              return {
                operator: "notSet",
                member: f.field,
              } as UnaryFilter;
            }
          });

          result.push({
            name: "Filter on this value",
            icon: `<i class="ag-icon ag-icon-filter" />`,
            action: () => {
              setTableQuery({
                action: "addFilters",
                filters: cubeFilters,
              });
            },
          });
        }
        if (result.length) {
          result.push("separator");
        }
        result.push("copy");
      }

      return result;
    },
    [JSON.stringify(tableQuery)]
  );

  const getMainMenuItems = useCallback(
    (
      params: GetMainMenuItemsParams<any, IObjectTableContext>
    ): (string | MenuItemDef)[] => {
      const colId = params.column.getColId();
      const grouppedColId = params.column.getColDef().showRowGroup;

      const canGroup = params.column.getColDef().enableRowGroup;
      const isGroupped = !!grouppedColId;

      const canSort = !isGroupped;

      const items: (string | MenuItemDef)[] = [];

      const property = (params.context.availableColumns ?? [])
        ?.filter(isAvailableProperty)
        .find((ap) => colId === ap.data.key)?.data;

      if (canSort) {
        if (items.length) {
          items.push("separator");
        }
        let sortKey: string = colId;
        if (property) {
          sortKey = property.sortAndFilterKey;
        }

        items.push(
          {
            name: "Sort ascending",
            icon: `<i class="ag-icon ag-icon-asc" />`,
            action: () => {
              setTableQuery({
                action: "toggleOrder",
                order: [sortKey, "asc"],
              });
            },
          },
          {
            name: "Sort descending",
            icon: `<i class="ag-icon ag-icon-desc" />`,
            action: () => {
              setTableQuery({
                action: "toggleOrder",
                order: [sortKey, "desc"],
              });
            },
          }
        );
      }

      if (canGroup) {
        if (property?.domain === "TIME") {
          if (items.length) {
            items.push("separator");
          }
          items.push({
            name: "Group by this field",
            icon: `<i class="ag-icon ag-icon-group" />`,
            subMenu: [
              {
                name: "By raw value",
                action: () =>
                  setTableQuery({
                    action: "setGroups",
                    groups: [
                      ...tableQuery.groups,
                      {
                        groupedDimension: colId,
                        groupedField: colId,
                      },
                    ],
                  }),
              },
              {
                name: "By day",
                action: () =>
                  setTableQuery({
                    action: "setGroups",
                    groups: [
                      ...tableQuery.groups,
                      {
                        groupedDimension: colId,
                        groupedField: colId + ".day",
                      },
                    ],
                  }),
              },
              {
                name: "By week",
                action: () =>
                  setTableQuery({
                    action: "setGroups",
                    groups: [
                      ...tableQuery.groups,
                      {
                        groupedDimension: colId,
                        groupedField: colId + ".week",
                      },
                    ],
                  }),
              },
              {
                name: "By month",
                action: () =>
                  setTableQuery({
                    action: "setGroups",
                    groups: [
                      ...tableQuery.groups,
                      {
                        groupedDimension: colId,
                        groupedField: colId + ".month",
                      },
                    ],
                  }),
              },
              {
                name: "By year",
                action: () =>
                  setTableQuery({
                    action: "setGroups",
                    groups: [
                      ...tableQuery.groups,
                      {
                        groupedDimension: colId,
                        groupedField: colId + ".year",
                      },
                    ],
                  }),
              },
            ],
          });
        } else {
          if (items.length) {
            items.push("separator");
          }
          items.push({
            name: "Group by this field",
            icon: `<i class="ag-icon ag-icon-group" />`,
            action: () =>
              setTableQuery({
                action: "setGroups",
                groups: [
                  ...tableQuery.groups,
                  {
                    groupedDimension: colId,
                    groupedField: colId,
                  },
                ],
              }),
          });
        }
      } else if (isGroupped) {
        if (items.length) {
          items.push("separator");
        }
        items.push({
          name: "Ungroup",
          icon: `<i class="ag-icon ag-icon-group" />`,
          action: () => {
            setTableQuery({
              action: "setGroups",
              groups: [],
            });
          },
        });
      }

      if (!isGroupped) {
        if (items.length) {
          items.push("separator");
        }
        items.push({
          name: "Show this column only",
          icon: `<i class="ag-icon ag-icon-eye" />`,
          action: () => {
            setTableQuery({
              action: "columnVisibility",
              columnVisibility: {
                visibleColumnIds: [colId],
              },
            });
          },
        });
        if (property?.type !== "primaryKey") {
          items.push({
            name: "Hide this column",
            icon: `<i class="ag-icon ag-icon-eye-slash" />`,
            action: () => {
              setTableQuery({
                action: "columnVisibility",
                columnVisibility: {
                  visibleColumnIds: (
                    tableQuery.columnVisibility?.visibleColumnIds ?? []
                  ).filter((c) => c !== colId),
                },
              });
            },
          });
        }
      }

      if (items.length) {
        items.push("separator");
      }
      items.push("autoSizeThis", "autoSizeAll");
      return items;
    },
    [
      JSON.stringify(tableQuery.groups),
      JSON.stringify(tableQuery.order),
      JSON.stringify(tableQuery.columnVisibility),
    ]
  );

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: CountStatusBarComponent,
          statusPanelParams: {
            count: rowCount,
          },
        },
      ],
    };
  }, [rowCount]);

  const debouncedOnColumnPinned = useDebouncedCallback(
    (state: ColumnPinningState) =>
      setTableQuery({ action: "columnPinning", columnPinning: state }),
    200
  );

  const debouncedOnColumnResized = useDebouncedCallback(
    (state: ColumnSizingState) =>
      setTableQuery({ action: "columnSizing", columnSizing: state }),
    200
  );

  const debouncedOnColumnOrdered = useDebouncedCallback(
    (state: ColumnOrderState) =>
      setTableQuery({ action: "columnOrder", columnOrder: state }),
    200
  );

  const components = useMemo<{
    [p: string]: any;
  }>(() => {
    return {
      agColumnHeader: ObjectTableHeader,
    };
  }, []);

  const isServerSideGroupOpenByDefault = useCallback(
    (params: IsServerSideGroupOpenByDefaultParams) => {
      const rowNode = params.rowNode;
      if (
        (tableUIState?.expandedNodes ?? []).find(
          (node) =>
            node.field === rowNode.field &&
            node.key === rowNode.key &&
            node.parentField === rowNode.parent?.field &&
            node.parentKey === rowNode.parent?.key
        )
      )
        return true;
      return false;
    },
    [tableQuery?.order, tableQuery.filters, tableQuery.columnVisibility]
  );

  const additionalProps: GridOptions & { className?: string } = {};

  if (displayType === "list") {
    additionalProps.className = `ag-theme-objects ${columnHeaderFormat}`;
    additionalProps.serverSideInitialRowCount = 50000;
    additionalProps.statusBar = statusBar;
  }

  if (displayType === "relatedList") {
    additionalProps.className = `ag-theme-relatedlist ${columnHeaderFormat}`;
    additionalProps.domLayout = "autoHeight";
    additionalProps.pagination = true;
    additionalProps.paginationPageSize = props.pagination?.pageSize;
    additionalProps.paginationPageSizeSelector = false;
    additionalProps.paginationAutoPageSize = false;
    additionalProps.paginateChildRows = false;
    additionalProps.suppressPaginationPanel = true;
    additionalProps.onPaginationChanged = onPaginationChanged;
    additionalProps.overlayNoRowsTemplate = `<div>No related ${object.name}</div>`;
  }

  return (
    <>
      <Crosshair>
        <AgGridReact
          {...additionalProps}
          context={context}
          ref={gridRef}
          onGridReady={onGridReady}
          autoGroupColumnDef={autoGroupColumnDef}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          enableRangeSelection={false}
          rowModelType={"serverSide"}
          suppressDragLeaveHidesColumns={true}
          alwaysMultiSort={true}
          rowSelection={selectable ? "multiple" : undefined}
          suppressRowClickSelection={true}
          onSelectionChanged={
            selectable
              ? (e) => {
                  const selectedRows = gridRef.current?.api!.getSelectedRows();
                  if (
                    setSelectedRecords &&
                    selectedRows &&
                    Array.isArray(selectedRows)
                  ) {
                    setSelectedRecords(selectedRows);
                  }
                }
              : undefined
          }
          pivotMode={false}
          sideBar={null}
          suppressFieldDotNotation={true}
          getMainMenuItems={getMainMenuItems}
          components={components}
          suppressMenuHide={true}
          initialState={{
            columnPinning: tableQuery.columnPinning,
            columnSizing: tableQuery.columnSizing,
            columnOrder: tableQuery.columnOrder,
            rowGroup: {
              groupColIds: (tableQuery.groups ?? []).map(
                (g) => g.groupedDimension
              ),
            },
          }}
          onStateUpdated={(event) => {
            const columnOrder = event.api.getState().columnOrder;
            if (columnOrder) {
              debouncedOnColumnOrdered(columnOrder);
            }
          }}
          getContextMenuItems={getContextMenuItems}
          isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
          onRowGroupOpened={(event) => {
            const expandedNodes: ObjectTableExpandedNode[] = [];
            event.api.forEachNode((rowNode, index) => {
              if (rowNode.expanded && rowNode.field) {
                expandedNodes.push({
                  field: rowNode.field,
                  key: rowNode.key,
                  parentField: rowNode.parent?.field,
                  parentKey: rowNode.parent?.key,
                });
              }
            });
            setTableUIState?.({
              action: "setExpandedNodes",
              expandedNodes: expandedNodes,
            });
          }}
          onColumnRowGroupChanged={() => {
            setTableUIState?.({
              action: "setExpandedNodes",
              expandedNodes: [],
            });
          }}
          onColumnPinned={(event) => {
            const columnPinning = event.api.getState().columnPinning;
            if (columnPinning) {
              debouncedOnColumnPinned(columnPinning);
            }
          }}
          onColumnResized={(event) => {
            const columnSizing = event.api.getState().columnSizing;
            if (columnSizing) {
              debouncedOnColumnResized(columnSizing);
            }
          }}
        />
      </Crosshair>
      {props.pagination?.enabled && (
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyItems: "right",
              justifyContent: "right",
              flex: 1,
              paddingRight: 18,
              paddingBottom: 6,
              paddingTop: 6,
              borderTop: "1px solid #F0F0F0",
            }}
          >
            <Pagination
              showLessItems={true}
              showSizeChanger={false}
              size="small"
              defaultPageSize={props.pagination.pageSize}
              defaultCurrent={pagination.current}
              pageSize={props.pagination.pageSize}
              current={pagination.current}
              total={pagination.total}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              onChange={(page) => {
                gridRef?.current?.api?.paginationGoToPage(page - 1);
                setPagination((v) => ({ ...v, current: page - 1 }));
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default ObjectTable;
