import { LockFilled } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Alert, Button, Input } from "antd";
import Feednack from "../../../components/layout/feedback/feedback";

import Logo from "../../../assets/logo/logo.svg";

import { useRef, useState } from "react";
import { useKey } from "react-use";
import "./ReportSharingLinkPasswordPage.scss";

interface IReportSharingLinkPasswordPageProps {
  loading: boolean;
  login: (password: string) => any;
  error: boolean;
}

interface IState {
  password: string;
}

const ReportSharingLinkPasswordPage = ({
  error,
  loading,
  login,
}: IReportSharingLinkPasswordPageProps) => {
  const pwdInputRef = useRef<InputRef>(null);
  const [password, setPassword] = useState("");

  useKey(
    "Enter",
    () => {
      const isPwdInputFocused =
        pwdInputRef.current?.input === document.activeElement;

      isPwdInputFocused && login(password);
    },
    {},
    [password]
  );

  return (
    <div className="studio-layout">
      <div className={`main-content`}>
        <Feednack>
          <div className="report-sharing-link-password-page">
            <p className="report-sharing-link-password-page-text">
              This is a password protected dashboard
            </p>

            {error && (
              <Alert
                style={{ marginBottom: 24 }}
                message="Wrong Password"
                type="error"
              />
            )}

            <Input
              ref={pwdInputRef}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="report-sharing-link-password-page-input"
              placeholder="Password"
              type={"password"}
              prefix={<LockFilled />}
            />

            <Button
              loading={loading}
              onClick={() => login(password)}
              style={{ width: "100%" }}
            >
              OK
            </Button>

            <div className="report-sharing-link-password-page-footer">
              Powered by{" "}
              <a rel="noreferrer" target="_blank" href="https://whaly.io">
                <img src={Logo} style={{ height: 30 }} alt="" />
              </a>
            </div>
          </div>
        </Feednack>
      </div>
    </div>
  );
};

export default ReportSharingLinkPasswordPage;
