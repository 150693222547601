import { Button } from "antd";
import { inject, observer } from "mobx-react";
import { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Logo from "../../../assets/icon.svg";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";
import type { AuthStoreProps } from "../../../store/authStore";

interface INoUserProps {}

type Props = INoUserProps & AuthStoreProps & RouteComponentProps;

function NoUser({ authStore }: Props) {
  const [isReloading, setIsReloading] = useState(false);

  const handleClick = () => {
    setIsReloading(true);

    authStore.logout().then(() => window.location.reload());
  };

  return (
    <Feednack>
      <div>
        <div style={{ paddingBottom: 12 }}>
          <img src={Logo} style={{ width: 120, height: 120 }} alt={"logo"} />
        </div>
        <div>We couldn't properly log you in, please try again.</div>
        <div style={{ paddingTop: 12 }}>
          <Button loading={isReloading} onClick={handleClick}>
            Try again
          </Button>
        </div>
      </div>
    </Feednack>
  );
}

export default compose<Props, INoUserProps>(
  inject("authStore"),
  observer,
  withRouter
)(NoUser);
