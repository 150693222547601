import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import Aligner from "../../../components/layout/aligner/Aligner";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import { track } from "../../../services/AnalyticsService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { AuthStoreProps } from "../../../store/authStore";
import type { UserStoreProps } from "../../../store/userStore";
import "../view/ReportView.scss";
import { IS_PASSWORD_PROTECTED } from "../view/domain";
import ReportSharingLink from "./ReportSharingLink";
import ReportSharingLinkPasswordPage from "./ReportSharingLinkPasswordPage";

interface IReportSharingLinkPageProps {}

interface IState {
  accessType: AsyncData<{ passwordProtected: boolean }>;
  login: AsyncData<{}>;
}

type Props = IReportSharingLinkPageProps &
  RouteComponentProps<{ publicToken: string; sharingToken: string }> &
  UserStoreProps &
  AuthStoreProps;

class ReportSharingLinkPage extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      accessType: {
        status: "initial",
      },
      login: {
        status: "initial",
      },
    };
  }

  componentDidMount() {
    this.fetchPageStatus(
      this.props.match.params.publicToken,
      this.props.match.params.sharingToken
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.match.params.publicToken !==
        prevProps.match.params.publicToken ||
      this.props.match.params.sharingToken !==
        prevProps.match.params.sharingToken
    ) {
      this.fetchPageStatus(
        this.props.match.params.publicToken,
        this.props.match.params.sharingToken
      );
    }
  }

  fetchPageStatus = (publicToken: string, sharingLinkSlug: string) => {
    this.setState({ accessType: { status: "initial" } });

    this.props.authStore
      .saveToken(
        {
          access_token: `shared:report:${publicToken}:${sharingLinkSlug}`,
          expires_at: Date.now() + 10000 * 365 * 24 * 3600 * 1000,
          expires_in: 10000 * 365 * 24 * 3600 * 1000,
          refresh_token: "",
          scope: "",
          token_type: "bearer",
        },
        { isPublicToken: true }
      )
      .then(() =>
        GraphQLService(IS_PASSWORD_PROTECTED, {
          sharingLinkToken: sharingLinkSlug,
        })
      )
      .then(({ reportSharingLinkPasswordAccess }) => {
        if (reportSharingLinkPasswordAccess === true) {
          track("Report Password Page Viewed", {});
          this.setState({
            accessType: {
              status: "success",
              data: { passwordProtected: true },
            },
          });
        } else if (reportSharingLinkPasswordAccess === false) {
          return this.props.userStore.getUser().then(() => {
            this.setState({
              accessType: {
                status: "success",
                data: { passwordProtected: false },
              },
            });
          });
        } else {
          this.setState({
            accessType: {
              status: "error",
              error: new Error("No sharing link"),
            },
          });
        }
      });
  };

  public login = (password: string) => {
    this.setState({ login: { status: "loading" } });
    return GraphQLService(
      `mutation isReportSharingLinkPasswordValid($sharingLinkToken: String!, $password: String!) {
        isReportSharingLinkPasswordValid(sharingLinkToken: $sharingLinkToken, password: $password)
      }`,
      {
        sharingLinkToken: this.props.match.params.sharingToken,
        password,
      }
    )
      .then((r) => {
        if (r.isReportSharingLinkPasswordValid === true) {
          return Promise.resolve(
            this.props.authStore.saveToken(
              {
                access_token: `shared:report:${this.props.match.params.publicToken}:${this.props.match.params.sharingToken}:${password}`,
                expires_at: Date.now() + 10000 * 365 * 24 * 3600 * 1000,
                expires_in: 10000 * 365 * 24 * 3600 * 1000,
                refresh_token: "",
                scope: "",
                token_type: "bearer",
              },
              { isPublicToken: true }
            )
          )
            .then(() => {
              return this.props.userStore.getUser();
            })
            .then(() => {
              return this.setState({ login: { status: "success", data: {} } });
            });
        }
        this.setState({
          login: { status: "error", error: new Error("Error") },
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          login: { status: "error", error: new Error("Error") },
        });
      });
  };

  public renderContent = () => {
    const { accessType, login } = this.state;

    switch (accessType.status) {
      case "initial":
        return (
          <Aligner className="standard-bg">
            <Loading />
          </Aligner>
        );
      case "error":
        return (
          <Aligner className="standard-bg">
            This report either doesn't exists or is not public anymore...
          </Aligner>
        );
      case "loading":
      case "success":
        if (
          accessType.status === "success" &&
          login.status !== "success" &&
          accessType.data.passwordProtected === true
        ) {
          return (
            <ReportSharingLinkPasswordPage
              login={this.login}
              loading={login.status === "loading"}
              error={login.status === "error"}
            />
          );
        }
        return <ReportSharingLink />;
    }
  };

  public render() {
    return this.renderContent();
  }
}

export default compose<Props, IReportSharingLinkPageProps>(withRouter)(
  inject("userStore", "authStore")(observer(ReportSharingLinkPage))
);
