import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import type { ActionType } from "../../../reports/view/domain";
import ReportV2Context from "./ReportV2Context";

interface IReportV2PageProps {
  editing?: boolean;
  actionType?: ActionType;
  embededWorkbench?: boolean;
  homePageConfig?: { reportId: string };
}

type Props = RouteComponentProps<{ reportSlug: string }> & IReportV2PageProps;

function ReportV2Page(props: Props) {
  const {
    match: { params },
    editing,
    embededWorkbench,
    actionType,
    homePageConfig,
  } = props;

  const reportPayload = homePageConfig
    ? {
        type: "id",
        value: homePageConfig.reportId,
      }
    : {
        type: "slug",
        value: params.reportSlug,
      };

  return (
    <ReportV2Context
      isEmbedded={!!homePageConfig ? true : false}
      isBeingPushed={false}
      isSharingLink={false}
      isPublic={false}
      isDisplayedInWorkspace={!!homePageConfig ? false : true}
      hideLayout={!!homePageConfig ? true : false}
      editing={!!editing}
      actionType={actionType}
      embededWorkbench={embededWorkbench}
      reportPayload={reportPayload as any}
      dontHighlightFolder={!!homePageConfig}
    />
  );
}

export default compose<Props, IReportV2PageProps>(withRouter)(ReportV2Page);
