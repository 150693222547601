import { action, makeObservable, observable } from "mobx";
import { refreshPortalJwt, refreshToken } from "../auth/auth";
import type {
  JwtTokenResource,
  RefreshTokenResource,
} from "../interfaces/auth";
import { broadcastService } from "../services/broadcastService";
import { IDBService } from "../services/idbService";
import { SessionStorageService } from "../services/SessionStorageService";

const SAFETY_NET = 10;

type SaveTokenOptions = {
  isPublicToken?: boolean;
};

class AuthStore {
  loading: boolean;
  refreshing: boolean;
  timeout: boolean;

  constructor() {
    makeObservable(this, {
      loading: observable,
      refreshing: observable,
      timeout: observable,
      login: action,
      saveToken: action,
      refreshSession: action,
      logout: action,
    });

    this.loading = false;
    this.refreshing = false;
    this.timeout = false;
  }

  saveToken = async (
    token: RefreshTokenResource | JwtTokenResource,
    options?: SaveTokenOptions
  ) => {
    const expires_at = Date.now() + token.expires_in * 1000 - SAFETY_NET * 1000;

    if (options?.isPublicToken) {
      SessionStorageService.setOverrideToken(token);
    } else {
      return IDBService.setAuthResource({ ...token, expires_at });
    }
  };

  login = async (code: string) => {
    const currentAuthRessource = await IDBService.getAuthResource();
    if (currentAuthRessource) {
      return;
    }

    this.loading = true;

    return refreshToken({ code, grant_type: "authorization_code" })
      .then((token) => this.saveToken(token).then(() => token))
      .then(action((token) => token))
      .finally(() => (this.loading = false));
  };

  refreshSession = async () => {
    const authRessource = await IDBService.getAuthResource();
    const isOnline = window.navigator.onLine;

    if (authRessource && !this.refreshing && isOnline) {
      const { token_type } = authRessource;
      this.refreshing = true;

      if (token_type === "bearer") {
        const { refresh_token } = authRessource;

        return refreshToken({ refresh_token, grant_type: "refresh_token" })
          .then(action((token) => this.saveToken(token)))
          .catch((err) => {
            console.error(err);
            this.logout().then(() => window.location.reload());
          })
          .finally(() => (this.refreshing = false));
      } else if (token_type === "portal-jwt") {
        const { access_token } = authRessource;

        return refreshPortalJwt(access_token)
          .then(({ token, expire_in }) =>
            this.saveToken({
              token_type: "portal-jwt",
              expires_in: expire_in ? parseFloat(expire_in) : 2500,
              expires_at: 0,
              access_token: token,
            }).then(() => ({ token, expire_in }))
          )
          .then(action(({ token, expire_in }) => ({ token, expire_in })))
          .catch((err) => {
            console.error(err);
            this.logout().then(() => window.location.reload());
          })
          .finally(() => (this.refreshing = false));
      }
    }

    return Promise.resolve();
  };

  logout = async () => {
    await IDBService.clearAll();
    SessionStorageService.removeOverrideToken();
    broadcastService.sendLogoutEvent();
  };
}

export interface AuthStoreProps {
  authStore: AuthStore;
}

export default new AuthStore();
