import { ExportOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { type IDocumentPropertyItem } from "./domain";

export interface IDocumentPropertyItemProps {
  documentPropertyItem: IDocumentPropertyItem;
}

export function DocumentPropertyItem(props: IDocumentPropertyItemProps) {
  const { documentPropertyItem } = props;

  const onClick = documentPropertyItem?.buttonLink
    ? () => {
        if (documentPropertyItem?.buttonLink) {
          const link = documentPropertyItem.buttonLink.replaceAll(
            "_wly_url_",
            window.location.href
          );
          window.open(link, "_blank")?.focus();
        }
      }
    : undefined;

  return (
    <div
      style={{
        cursor: onClick ? "pointer" : undefined,
        backgroundColor: "#0000000d",
        padding: 8,
        borderRadius: 4,
      }}
      onClick={onClick}
    >
      {documentPropertyItem?.name && (
        <div>
          <Typography.Text strong ellipsis>
            {documentPropertyItem?.name}
          </Typography.Text>
        </div>
      )}
      {documentPropertyItem?.excerpt && (
        <div>
          <Typography.Text>{documentPropertyItem?.excerpt}</Typography.Text>
        </div>
      )}

      <div style={{ textAlign: "right", paddingTop: 8 }}>
        <Button
          size="small"
          shape="round"
          onClick={onClick}
          icon={<ExportOutlined />}
        >
          {documentPropertyItem?.buttonLabel
            ? documentPropertyItem?.buttonLabel
            : "Open"}
        </Button>
      </div>
    </div>
  );
}
