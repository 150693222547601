import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import { useCss } from "react-use";

type MoveActionsProps = {
  moveUp: () => void;
  moveDown: () => void;
};

export const MoveActions = ({ moveUp, moveDown }: MoveActionsProps) => {
  const btnCn = useCss({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10px",
  });

  return (
    <Flex vertical justify="center">
      <Button
        size="small"
        type="text"
        onClick={moveUp}
        icon={<UpOutlined style={{ fontSize: 8 }} />}
        className={btnCn}
      />
      <Button
        size="small"
        type="text"
        onClick={moveDown}
        icon={<DownOutlined style={{ fontSize: 8 }} />}
        className={btnCn}
      />
    </Flex>
  );
};
