import { Space, Typography } from "antd";
import React, { useEffect } from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import { getObjectColumns } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetPropertiesConfig } from "../domain";
import PropertyRenderer from "./PropertyRenderer";

import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import {
  formatRecordForMustache,
  renderMustache,
} from "../../../common/mustache/MustacheRecordHelper";
import { shouldDisplayWidget } from "../../../common/visibility-filters/domain";
import "./WidgetProperties.scss";

interface IWidgetPropertiesProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IWidgetPropertiesConfig;
  edit?: boolean;
}

type Props = IWidgetPropertiesProps & InjectedOrgProps;

function WidgetProperties(props: Props) {
  const { object, widget, record, layoutId, recordId, conf, edit, user } =
    props;

  const { setAsFinished } = useWidgetCacheActions({
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  });

  useEffect(() => {
    setAsFinished();
  }, [setAsFinished]);

  const columns = getObjectColumns(object);

  const modifyRecord = React.useCallback(formatRecordForMustache, [
    record,
    columns,
    user,
  ]);

  return (
    <Space direction="vertical" size={8} className="widget-properties">
      {(conf.columns || [])
        .filter((c) => {
          const displayFilters = conf.options?.[c]?.displayFilters;
          if (!displayFilters) return true;
          return shouldDisplayWidget(displayFilters, record, object, {});
        })
        .map((k, i) => {
          const foundProperty = columns.find((p) => p.data.key === k);

          if (!foundProperty) {
            return (
              <div key={i}>
                <div>
                  <Typography.Text strong>{k}</Typography.Text>
                </div>
                <div>
                  <Typography.Text ellipsis type="danger">
                    Can't find property
                  </Typography.Text>
                </div>
              </div>
            );
          }

          const renderProperty = (val: string | boolean | number) => {
            // get renderers
            const displayVal = edit && !val ? "value" : val;
            const inline = conf?.inline;

            return (
              <PropertyRenderer
                value={displayVal}
                column={foundProperty}
                object={object}
                inline={inline}
                renderMetricAsTag
              />
            );
          };

          const overrideLabel = conf?.options?.[k]?.label;
          const badge = conf?.options?.[k]?.badge;
          const formattedRecord = modifyRecord(record, columns, user);

          return (
            <div
              className={`widget-properties-item${
                conf?.inline ? " inline" : ""
              } ${edit ? "edit" : ""}`}
              key={i}
            >
              <div className="widget-properties-item-label">
                <div className="widget-properties-item-label-title">
                  <Typography.Text ellipsis>
                    {overrideLabel ? overrideLabel : foundProperty.data.label}
                  </Typography.Text>
                </div>
                {badge && (
                  <div className="widget-properties-item-label-badge">
                    <Typography.Text
                      className="widget-properties-item-label-badge-text"
                      type="secondary"
                      ellipsis
                    >
                      {renderMustache(badge, formattedRecord)}
                    </Typography.Text>
                  </div>
                )}
              </div>
              <div className="widget-properties-item-value">
                {renderProperty(record[foundProperty.data.key])}
              </div>
            </div>
          );
        })}
      {conf?.columns?.length === 0 ? (
        <div>
          <Typography.Text type="secondary">
            Please configure this widget
          </Typography.Text>{" "}
        </div>
      ) : null}
    </Space>
  );
}

export default compose<Props, IWidgetPropertiesProps>(WithOrg)(
  WidgetProperties
);
