import type { ConfigType } from "dayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useInterval } from "react-use";

export const useFromNow = (date: ConfigType) => {
  const [fromNow, setFromNow] = useState<string>(dayjs(date).fromNow());

  useInterval(() => {
    setFromNow(dayjs(date).fromNow());
  }, 5000);

  useEffect(() => {
    setFromNow(dayjs(date).fromNow());
  }, [date]);

  return fromNow;
};
