import { gql } from "@apollo/client";
import { OBJECT_FRAGMENT } from "../../../../../fragments/object";
import { USER_FRAGMENT } from "../../../../../fragments/user";
import { type IObjectLayoutType } from "../../../../../interfaces/object";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import { TABLE_FRAGMENT } from "../../../../exploration/single/domain";
import { type IObjectLayoutFragment } from "../../../single/domain";
import { OBJECT_REGISTERED_ACTION_FRAGMENT } from "./tabs/actions/domain";

export const OBJECT_COMPLETE_FRAGMENT = gql`
  ${USER_FRAGMENT}

  ${TABLE_FRAGMENT}

  ${OBJECT_FRAGMENT}

  ${OBJECT_REGISTERED_ACTION_FRAGMENT}

  fragment ObjectQuery on Object {
    id
    name
    slug
    primaryLabel
    primaryImage
    rowLevelFilters
    foreignKeys(
      where: { isDeleted_not: true, object: { isDeleted_not: true } }
    ) {
      id
      label
      object {
        ...ObjectFragment
        layouts(where: { isDeleted_not: true }) {
          id
          name
          type
        }
      }
    }
    properties(where: { isDeleted_not: true }) {
      id
      label
      cubeName
      description
      columnName
      columnDomain
      formatter
      formatterConfig
      userPropertyMapping
      sortingAndFilteringColumn
      hierarchyPath
      foreignKey {
        id
        cubeName
        table {
          cubeName
          object {
            id
            slug
          }
        }
      }
    }
    model {
      id
    }
    canBeListed
    table {
      ...TableQuery
    }
    registeredActions(where: { isDeleted_not: true }) {
      ...ObjectRegisteredActionFragment
    }
    isDeleted
    createdAt
    createdBy {
      ...UpdatedBy
    }
    org {
      emailTemplateMjml
    }
  }
`;

export const createObjectLayoutService = (
  name: string,
  type: IObjectLayoutType,
  objectId: string,
  orgId: string
) => {
  return GraphQLService<{
    createObjectLayout: IObjectLayoutFragment;
  }>(
    gql`
      mutation createObjectLayout($data: ObjectLayoutCreateInput!) {
        createObjectLayout(data: $data) {
          id
          name
          type
          object {
            id
          }
          createdAt
          updatedAt
        }
      }
    `,
    {
      data: {
        name: name,
        type: type,
        header: JSON.stringify({
          visible: type === "EMAIL" ? false : true,
        }),
        object: {
          connect: {
            id: objectId,
          },
        },
        org: {
          connect: {
            id: orgId,
          },
        },
      },
    }
  );
};
