import { Skeleton } from "antd";
import type { IRendererConfig } from "../domain";
import type { IWidgetTitleConfig } from "./domain";
import { TitleWidgetEditor } from "./editor/TitleWidgetEditor";
import { TitleWidget } from "./widget/TitleWidgetRenderer";

export const widgetTitleDefinition: IRendererConfig<IWidgetTitleConfig> = {
  removeCard: true,
  parseConfig: (widget) => {
    const defaultValue: IWidgetTitleConfig = {
      text: "",
      level: 3,
    };

    if (!widget.config) {
      return defaultValue;
    }

    try {
      const config: IWidgetTitleConfig = JSON.parse(widget.config);
      return config;
    } catch (err) {
      console.error(err);
      return defaultValue;
    }
  },
  renderEditor: (widget, object, record, conf, form, org) => {
    return (
      <TitleWidgetEditor
        widget={widget}
        object={object}
        record={record}
        conf={conf}
        form={form}
      />
    );
  },
  renderComponent: (widget, object, record, layoutId, recordId, conf, edit) => {
    return (
      <TitleWidget
        widget={widget}
        object={object}
        record={record}
        layoutId={layoutId}
        recordId={recordId}
        conf={conf}
        edit={edit}
      />
    );
  },
  renderLoadingComponent: (widget, conf) => (
    <Skeleton.Button
      active
      block
      size="small"
      shape="default"
      style={{ height: 75 }}
    />
  ),
};
