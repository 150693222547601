import { Card } from "antd";
import React, { useEffect } from "react";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../../interfaces/object";
import { useWidgetCacheActions } from "../../../../../../../store/widgetCacheStore";
import type { InjectedOrgProps } from "../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../orgs/WithOrg";
import {
  COLOR_DOWN,
  COLOR_GOLD,
  COLOR_NEUTRAL,
  COLOR_UP,
} from "../../../../layout/domain";
import { getObjectColumns } from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import {
  formatRecordForMustache,
  renderMustache,
} from "../../common/mustache/MustacheRecordHelper";
import type { IWidget } from "../../domain";
import type { IWidgetAlertConfig } from "./domain";

interface IAlertWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IWidgetAlertConfig;
  edit?: boolean;
}

type Props = IAlertWidgetProps & InjectedOrgProps;

function AlertWidget(props: Props) {
  const { widget, record, conf, object, layoutId, recordId, user } = props;

  const { setAsFinished } = useWidgetCacheActions({
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  });

  useEffect(() => {
    setAsFinished();
  }, [setAsFinished]);

  const renderColor = (color?: string) => {
    if (!color) {
      return COLOR_NEUTRAL;
    }
    switch (color) {
      case "danger":
        return COLOR_DOWN;
      case "success":
        return COLOR_UP;
      case "warning":
        return COLOR_GOLD;
      default:
        return COLOR_NEUTRAL;
    }
  };

  const columns = getObjectColumns(object);

  const getColor = React.useCallback(renderColor, [conf?.color]);

  const modifyRecord = React.useCallback(formatRecordForMustache, [
    record,
    columns,
    user,
  ]);

  const formattedRecord = modifyRecord(record, columns, user);
  const template = conf?.text ? conf?.text : "Please add a value";

  return (
    <Card
      style={{
        borderLeft: `6px solid ${getColor(conf?.color)}`,
        width: "100%",
      }}
      bodyStyle={{ display: "flex", alignItems: "center" }}
      size="small"
    >
      <div style={{ flex: 1 }}>{renderMustache(template, formattedRecord)}</div>
    </Card>
  );
}

export default compose<Props, IAlertWidgetProps>(WithOrg)(AlertWidget);
