import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import type { ButtonProps } from "antd/lib";
import * as React from "react";
import {
  generateDownloadlink,
  getFileUploadDownloadLink,
} from "../../../services/FileService";

export interface ICustomFileDownloaderProps extends ButtonProps {
  fileUploaderId: string;
  filePath: string;
}

export function CustomFileDownloader(props: ICustomFileDownloaderProps) {
  const { filePath, fileUploaderId, ...rest } = props;
  const [loading, setLoading] = React.useState<boolean>(false);

  const onClickProps = props.onClick;

  const onClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    try {
      setLoading(true);
      const d = await getFileUploadDownloadLink(fileUploaderId, filePath);
      const token = d.data.token;
      const url = generateDownloadlink(
        "file-upload-jobs",
        fileUploaderId,
        token
      );
      const a = document.createElement("a");
      a.href = url;
      a.target = "blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      await onClickProps?.(e);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      {...rest}
      onClick={onClick}
      icon={loading ? <LoadingOutlined spin /> : rest.icon}
    />
  );
}
