import _ from "lodash";
import { action, makeObservable, observable, runInAction } from "mobx";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import GraphQLService from "../../../services/graphql/GraphQLService";

export abstract class AbsctractDatastore<
  DataType,
  QueryVariable extends object
> {
  data: AsyncData<DataType>;
  fetchInProgress: boolean;
  private interval?: NodeJS.Timeout;
  private query: string;
  private previousVariables?: QueryVariable;
  private isPaused: boolean;

  constructor(query: string) {
    makeObservable(this, {
      data: observable,
      fetchInProgress: observable,
      init: action,
      refresh: action,
      reset: action,
    });

    this.isPaused = false;
    this.fetchInProgress = false;
    this.query = query;
    this.data = { status: "initial" };
  }

  private haveVariableChanged = (currentVariables: QueryVariable) => {
    return !_.isEqual(currentVariables, this.previousVariables);
  };

  // we should init a store for each org then we need to update it from remote chunk by chunk
  // today we don't support folder reodering in live TODO we should fix that
  // main idea is to create local actions that can alter the state & then read a list of update / create / delete
  // from redis and replace local actions with remote actions
  init = async (variables: QueryVariable, every?: number) => {
    try {
      if (
        this.data.status === "initial" ||
        this.haveVariableChanged(variables)
      ) {
        runInAction(() => {
          this.data = { status: "initial" };
        });
        await this.initialFetch(variables);
      } else {
        await this.backgroundUpdate(variables);
      }
    } catch (err) {
      console.error(err);
    } finally {
      runInAction(() => {
        this.previousVariables = variables;
      });
      if (every) {
        runInAction(() => {
          this.interval = setInterval(this.backgroundUpdate(variables), every);
        });
      }
    }
  };

  refresh = async (variables?: QueryVariable | undefined) => {
    const currentVariables = variables ?? this.previousVariables;

    if (currentVariables) {
      return this.backgroundUpdate(currentVariables)();
    }
  };

  private initialFetch = async (variables: QueryVariable) => {
    try {
      runInAction(() => {
        this.data = { status: "loading" };
      });
      const data = await this.fetchData(variables);
      runInAction(() => {
        this.data = { status: "success", data: data };
      });
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.data = { status: "error", error: err };
      });
    }
  };

  private backgroundUpdate = (variables: QueryVariable) => async () => {
    const shouldRun =
      !document.hidden &&
      !this.isPaused &&
      this.data.status !== "loading" &&
      !this.fetchInProgress;
    if (!shouldRun) return;

    try {
      const data = await this.fetchData(variables);
      if (!_.isEqual(data, (this.data as any).data)) {
        runInAction(() => {
          this.data = { status: "success", data: data };
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  private unregister = () =>
    runInAction(() =>
      this.interval ? clearInterval(this.interval) : undefined
    );

  reset = () => {
    this.unregister();
    runInAction(() => {
      this.data = { status: "initial" };
    });
  };

  sleep = () => {
    runInAction(() => {
      this.isPaused = true;
    });
  };

  wakeUp = () => {
    runInAction(() => {
      this.isPaused = false;
    });
  };

  private fetchData = (variables: QueryVariable) => {
    runInAction(() => {
      this.fetchInProgress = true;
    });
    return GraphQLService<DataType>(this.query, variables).finally(() =>
      runInAction(() => {
        this.fetchInProgress = false;
      })
    );
  };
}
