import {
  CheckOutlined,
  CloseOutlined,
  FullscreenOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Flex,
  Modal,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { isEqual } from "lodash";
import { useMemo, useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { CachedRecord } from "../../../../../components/hooks/useCachedRecord";
import { useFromNow } from "../../../../../components/hooks/useFromNow";
import { useIsOnline } from "../../../../../components/hooks/useIsOnline";
import { routeDescriptor } from "../../../../../routes/routes";
import { white } from "../../../../../utils/colorPalette";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import RecordView from "../../record/RecordView";
import { EmbedType } from "../../record/domain";
import "./RecordModal.scss";
import type { IRecordModalContext } from "./RecordModalContext";
import { RecordModalContext } from "./RecordModalContext";

interface IRecordModalProps {
  open: boolean;
  onClose: () => void;
}

type Props = IRecordModalProps &
  InjectedOrgProps &
  RouteComponentProps<{ layoutId?: string }>;

const RecordModal = ({
  history,
  open,
  org,
  onClose,
  match: { params },
}: Props) => {
  const isOnline = useIsOnline();
  const [cachedRecord, setCachedRecord] = useState<CachedRecord>({
    isCached: false,
    updatedAt: undefined,
  });
  const [modalContext, setModalContext] = useState<
    IRecordModalContext | undefined
  >(undefined);
  const fromNow = useFromNow(cachedRecord.updatedAt);

  const context = {
    value: modalContext,
    setValue: (v: IRecordModalContext) =>
      !isEqual(v, modalContext) ? setModalContext(v) : undefined,
  };

  const onFullScreen = () => {
    history.replace(
      routeDescriptor["object-record"].renderRoute({
        organizationSlug: org.slug,
        ...params,
      })
    );
  };

  const layoutId = params.layoutId;

  const showPanel =
    modalContext?.status === "success" && modalContext.data.showPanel;
  const showAdditionalButtons =
    modalContext?.status === "success" && modalContext.data.additionalButtons;

  const isLoading =
    !modalContext ||
    modalContext?.status === "initial" ||
    modalContext.status === "loading";

  const isError = modalContext?.status === "error";

  const renderPanelContent = () => {
    if (modalContext?.status === "success") {
      if (modalContext?.data?.showPanel?.type === "JSX") {
        return <>{modalContext?.data?.showPanel?.elements}</>;
      }
    }

    return null;
  };

  const offlineTooltipContent = useMemo(() => {
    if (cachedRecord.isCached) {
      return (
        <>
          <Typography.Text strong style={{ color: white }}>
            Last cached :{" "}
          </Typography.Text>
          <Typography.Text style={{ color: white }}>{fromNow}</Typography.Text>
        </>
      );
    }
    if (isOnline) {
      return "Caching in progress...";
    } else {
      return "";
    }
  }, [cachedRecord.isCached, fromNow, isOnline]);

  return (
    <RecordModalContext.Provider value={context}>
      <Modal
        maskClosable={false}
        closable={false}
        width={"90%"}
        centered
        className="record-modal"
        destroyOnClose={true}
        styles={{
          body: {
            height: "100%",
            maxHeight: "100%",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        footer={null}
        onCancel={onClose}
        open={open}
      >
        <div className="record-title">
          <div className="record-title-content">
            <div
              style={{ display: "flex", gap: 8, alignItems: "center" }}
              key="1"
            >
              {isLoading && (
                <>
                  <div style={{ flex: `0 24px` }}>
                    <Skeleton.Button
                      shape="circle"
                      size="small"
                      active
                      style={{ width: 22 }}
                    />
                  </div>
                  <div style={{ flex: `1` }}>
                    <Skeleton.Button
                      shape="round"
                      size="small"
                      active
                      style={{ width: 75 }}
                    />
                  </div>
                </>
              )}

              {isError && (
                <div style={{ flex: `1` }}>
                  <Typography.Text type="danger">
                    Error while fetching your record
                  </Typography.Text>
                </div>
              )}

              {modalContext?.status === "success" && modalContext.data && (
                <>
                  {modalContext.data?.image && (
                    <div style={{ flex: `0 24px` }}>
                      <Avatar size={24} src={modalContext.data?.image} />
                    </div>
                  )}
                  <div style={{ flex: `1` }}>
                    <Typography.Text strong>
                      {modalContext.data?.name ?? modalContext.data?.id}
                    </Typography.Text>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="record-title-action">
            <Space>
              {isOnline ? (
                <Tooltip title={offlineTooltipContent}>
                  <Tag color={cachedRecord.isCached ? "green" : "default"}>
                    <Flex gap="4px">
                      {cachedRecord.isCached ? (
                        <CheckOutlined />
                      ) : (
                        <LoadingOutlined />
                      )}
                      Offline
                    </Flex>
                  </Tag>
                </Tooltip>
              ) : (
                <div>{offlineTooltipContent}</div>
              )}
              {showAdditionalButtons && [
                ...(modalContext.data.additionalButtons ?? []),
                <Divider key="div" type="vertical" />,
              ]}
              <Button
                onClick={onFullScreen}
                icon={<FullscreenOutlined style={{ color: "gray" }} />}
                type="text"
                shape="circle"
                size="small"
              />
              <Button
                onClick={onClose}
                icon={<CloseOutlined style={{ color: "gray" }} />}
                type="text"
                shape="circle"
                size="small"
              />
            </Space>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            overflow: "hidden",
            maxHeight: "calc(100% - 44px)",
            height: "100%",
          }}
        >
          <div style={{ flex: 1, minWidth: 0 }} className="record-body">
            <RecordView
              homePageConfig={layoutId ? { layoutId: layoutId } : undefined}
              embedType={EmbedType.recordModal}
              setCachedRecord={setCachedRecord}
            />
          </div>

          <div
            className="record-panel"
            style={{ display: showPanel ? undefined : "none" }}
            id="record-panel-modal-context"
          >
            {renderPanelContent()}
          </div>
        </div>
      </Modal>
    </RecordModalContext.Provider>
  );
};

export default compose<Props, IRecordModalProps>(
  withRouter,
  WithOrg
)(RecordModal);
