import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Empty, Row } from "antd";
import moment from "moment";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../components/compose/WlyCompose";
import ReportCard from "../../../../../../components/report/card/ReportCard";
import { ReportCardSkeleton } from "../../../../../../components/report/card/ReportCardSkeleton";
import { ReportDraggableCard } from "../../../../../../components/report/card/ReportDraggableCard";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IReport } from "../../../../../../interfaces/reports";

import { useIsOnline } from "../../../../../../components/hooks/useIsOnline";
import "./Reports.scss";

interface IReportsProps {
  reports: AsyncData<{ allReports: IReport[] }>;
  onUpdate: () => void;
  hasDNDaccess: boolean;
}

type Props = IReportsProps & RouteComponentProps<{}>;

type Sort = "ASC" | "DESC";
type SortType = "name" | "last_opened_by_me" | "last_modified";

function Reports(props: IReportsProps) {
  const { reports, onUpdate, hasDNDaccess } = props;

  const isOnline = useIsOnline();
  const [sort, setSort] = React.useState<Sort>("DESC");
  const [sortType, setSortType] = React.useState<SortType>("name");

  const renderSortType = (sortType: SortType) => {
    switch (sortType) {
      case "last_modified":
        return "Last modified";
      case "name":
        return "Name";
      case "last_opened_by_me":
        return "Last opened by me";
    }
  };

  const getReports = (): IReport[] => {
    if (reports.status === "success") {
      return reports.data.allReports;
    }
    return [];
  };
  const sortedReports = [...getReports()].sort((a, b) => {
    let diff = 0;

    if (sortType === "last_modified") {
      const dateA = moment(a.updatedAt);
      const dateB = moment(b.updatedAt);

      diff = dateA.diff(dateB);
    } else if (sortType === "name") {
      diff = b.name.localeCompare(a.name);
    } else if ("last_opened_by_me") {
      const dateA = moment(a.updatedAt);
      const dateB = moment(b.updatedAt);
      diff = dateA.diff(dateB);
    }

    if (sort === "ASC") {
      if (diff > 0) {
        return 1;
      } else if (diff < 0) {
        return -1;
      }
      return 0;
    } else {
      if (diff > 0) {
        return -1;
      } else if (diff < 0) {
        return 1;
      }
      return 0;
    }
  });

  const renderInner = () => {
    switch (reports.status) {
      case "initial":
      case "loading":
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((r, i) => {
          return <ReportCardSkeleton key={i} />;
        });
      case "success":
        return sortedReports.length > 0 ? (
          sortedReports.map((report, i) => {
            if (hasDNDaccess && report.canBeEditedByCurrentUser) {
              return (
                <ReportDraggableCard
                  key={i}
                  report={report}
                  onUpdate={() => onUpdate()}
                  isOnline={isOnline}
                />
              );
            }
            return (
              <ReportCard
                key={report.id}
                report={report}
                onUpdate={() => onUpdate()}
                isOnline={isOnline}
              />
            );
          })
        ) : (
          <Empty
            style={{ margin: "32px 0" }}
            description={"There is no reports in this folder... "}
          />
        );
      case "error":
        return (
          <div style={{ margin: "32px 0" }}>
            There was an error fetching your reports...
          </div>
        );
    }
  };

  return (
    <Row className="reports" gutter={[16, 16]}>
      <Col flex={"none"} className="reports-title">
        Reports
      </Col>
      <Col className="reports-actions" flex={"auto"}>
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          menu={{
            items: [
              {
                key: "name",
                label: renderSortType("name"),
                onClick: () => setSortType("name"),
              },
              // todo later on
              // {
              //   key: "last_opened_by_me",
              //   label: renderSortType("last_opened_by_me"),
              //   onClick: () => setSortType("last_opened_by_me"),
              // },
              {
                key: "last_modified",
                label: renderSortType("last_modified"),
                onClick: () => setSortType("last_modified"),
              },
            ],
          }}
        >
          <Button type="text">{renderSortType(sortType)}</Button>
        </Dropdown>
        <Button
          onClick={() => (sort === "ASC" ? setSort("DESC") : setSort("ASC"))}
          shape="circle"
          type="text"
          icon={sort === "DESC" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
        />
      </Col>
      <Col className="reports-cards" span={24}>
        {renderInner()}
      </Col>
    </Row>
  );
}

export default compose<Props, IReportsProps>(withRouter)(Reports);
