import type { ComponentType } from "react";
import { useMeasure } from "react-use";

type size = {
  width: number;
  height: number;
};

export type InjectedHeight = {
  height: number;
};

export function withHeightSizer<T>(WrappedComponent: ComponentType<T>) {
  return function WithHeightSizer(props: T) {
    const [ref, { height }] = useMeasure<HTMLDivElement>();

    return (
      <div ref={ref} style={{ width: "100%", height: "100%" }}>
        <WrappedComponent {...props} height={height} />
      </div>
    );
  };
}
