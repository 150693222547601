import { Empty } from "antd";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IClassifierJob, IIndexedFile } from "../domain";
import FileIndexerFileContentInner from "./FileIndexerFileContentInner";

export interface IFileIndexerFileContentProps {
  data: AsyncData<{ files: IIndexedFile[]; classifierJobs: IClassifierJob[] }>;
  onSave: () => Promise<void>;
  selectedId?: string;
}

export function FileIndexerFileContent(props: IFileIndexerFileContentProps) {
  const { data, selectedId, onSave } = props;

  if (!selectedId) {
    return (
      <Feednack>
        <Empty description="Please select a file" />
      </Feednack>
    );
  }

  if (data.status === "initial" || data.status === "loading") {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (data.status === "error") {
    return <Feednack>There was an error fetching your data</Feednack>;
  }

  const selected = data.data.files.find((d) => d.id === selectedId);

  if (!selected) {
    return (
      <Feednack>
        We can't find this file, please check with your admin.
      </Feednack>
    );
  }

  return (
    <FileIndexerFileContentInner
      indexedFile={selected}
      classifierJobs={data.data.classifierJobs}
      onSave={onSave}
    />
  );
}
