import { isEmpty } from "lodash";
import "whatwg-fetch";
import type { CacheStrategy } from "../utils/cacheStrategy";
import { DEFAULT_CACHE_STRATEGY } from "../utils/cacheStrategy";
import { getAccessToken, getAuthorizationType } from "./authService";

export interface ApiOptions {
  authUrl?: boolean;
  localUrl?: boolean;
  loginUrl?: boolean;
  adminApi?: object;
  customDomain?: string;
  lagoonUrl?: boolean;
  lagoonNextUrl?: boolean;
  overrideUrl?: string;
  fileServiceUrl?: boolean;
  assetsUrl?: boolean;
  withCredentials?: boolean;
  authenticated?: boolean;
  asBlob?: boolean;
}

const WHALY_CONSTANTS: any = window.WHALY_CONSTANTS || {};
const AUTH_URL = `${WHALY_CONSTANTS.AUTH_SERVER_URL}`;
const LOCAL_URL = `${WHALY_CONSTANTS.APP_URL}`;
const API_URL = `${WHALY_CONSTANTS.API_URL}/v1/`;
const LAGOON_URL = `${WHALY_CONSTANTS.LAGOON_URL}`;
const LAGOON_NEXT_URL = `${WHALY_CONSTANTS.LAGOON_NEXT_URL}`;
const LOGIN_URL = `${WHALY_CONSTANTS.LOGIN_APP_URL}`;
const FILESERVICE_URL = `${WHALY_CONSTANTS.FILESERVICE_URL}`;
const ASSETS_URL = `${WHALY_CONSTANTS.ASSETS_URL}`;

type RequestMethod = "get" | "post" | "put" | "delete";

function paramsToQueryString(paramsArg: { [key: string]: any } = {}) {
  if (!paramsArg) return "";
  const paramsToArray: string[] = Object.keys(paramsArg);
  const str: string = paramsToArray
    .filter((key) => paramsArg[key] !== undefined)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(paramsArg[key])}`
    )
    .join("&");
  return str.length ? `?${str}` : "";
}

// const getApiCacheResult = async (key: string) => {
//   return IDBService.getApiResource(key);
// };
// const setApiCacheResult = async (key: string, result: any) => {
//   return IDBService.setApiResource(key, result).catch((err) => {
//     err
//       ? console.error(err)
//       : console.error("Error setting cache for Api | idbKey: ", key);
//   });
// };

async function request(
  method: RequestMethod,
  endpoint: string,
  options: {
    params?: { [key: string]: any };
    body?: any;
    headers?: { [key: string]: any };
    customDomain?: string;
    localUrl?: boolean;
    loginUrl?: boolean;
    authUrl?: boolean;
    lagoonUrl?: boolean;
    lagoonNextUrl?: boolean;
    overrideUrl?: string;
    fileServiceUrl?: boolean;
    adminApi?: object;
    assetsUrl?: boolean;
    withCredentials?: boolean;
    authenticated?: boolean;
    asBlob?: boolean;
  } = {}
) {
  const generateBaseUrl = () => {
    if (options.customDomain) {
      return options.customDomain;
    }
    if (options.authUrl) {
      return AUTH_URL;
    }
    if (options.localUrl) {
      return LOCAL_URL;
    }
    if (options.loginUrl) {
      return LOGIN_URL;
    }
    if (options.lagoonUrl) {
      return LAGOON_URL;
    }
    if (options.lagoonNextUrl) {
      return LAGOON_NEXT_URL;
    }
    if (options.overrideUrl) {
      return options.overrideUrl;
    }
    if (options.fileServiceUrl) {
      return FILESERVICE_URL;
    }
    if (options.assetsUrl) {
      return ASSETS_URL;
    }
    return API_URL;
  };

  const baseUrl = generateBaseUrl();
  const url = `${baseUrl}${endpoint}${paramsToQueryString(options.params)}`;

  const requestHeaders = new Headers(options.headers || {});

  if (options.authenticated !== false) {
    const token = await getAccessToken();
    const type = await getAuthorizationType();
    if (token) {
      requestHeaders.append("Authorization", `${type} ${token}`);
    } else {
      throw new Error(
        `Error. Authenticated without token, endpoint:${endpoint}`
      );
    }
  }

  const config: RequestInit = {
    method,
    headers: requestHeaders,
  };

  if (options.body instanceof FormData || options.body instanceof Blob) {
    config.body = options.body;
  } else if (options.body) {
    if (isEmpty(options.headers)) {
      // default headers: application/json
      requestHeaders.append("Accept", "application/json");
      requestHeaders.append("Content-Type", "application/json");
      config.body = JSON.stringify(options.body);
    } else {
      config.body = options.body;
    }
  }

  if (options.withCredentials) {
    config.credentials = "include";
  }

  const checkAndParse = (response: Response) => {
    const contentType = response.headers.get("Content-Type");

    if (response.status === 401) {
      const event = new Event("unauthorizedEvent");
      document.dispatchEvent(event);
    }

    if (options.asBlob) {
      return response.blob().then((blob) => {
        if (!response.ok) {
          Promise.reject(blob);
        }
        return blob;
      });
    }

    if (
      contentType &&
      (contentType.indexOf("image/png") !== -1 ||
        contentType.indexOf("application/octet-stream") !== -1)
    ) {
      return response.blob().then((blob) => {
        if (!response.ok) {
          Promise.reject(blob);
        }
        return blob;
      });
    } else if (contentType && contentType.indexOf("text/html") !== -1) {
      return response.status < 400
        ? Promise.resolve()
        : Promise.reject(response);
    } else if (contentType && contentType.indexOf("text/plain") !== -1) {
      return response.status < 400 ? response.text() : Promise.reject(response);
    }

    // Considered as a json response by default
    return response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      return json;
    });
  };

  return fetch(url, config).then(checkAndParse);
}

// async function request(
//   method: RequestMethod,
//   endpoint: string,
//   options: {
//     params?: { [key: string]: any };
//     body?: any;
//     headers?: { [key: string]: any };
//     customDomain?: string;
//     localUrl?: boolean;
//     loginUrl?: boolean;
//     authUrl?: boolean;
//     lagoonUrl?: boolean;
//     lagoonNextUrl?: boolean;
//     overrideUrl?: string;
//     fileServiceUrl?: boolean;
//     adminApi?: object;
//     assetsUrl?: boolean;
//     withCredentials?: boolean;
//     authenticated?: boolean;
//     asBlob?: boolean;
//   } = {},
//   strategy: CacheStrategy = DEFAULT_CACHE_STRATEGY
// ) {
//   const generateBaseUrl = () => {
//     if (options.customDomain) {
//       return options.customDomain;
//     }
//     if (options.authUrl) {
//       return AUTH_URL;
//     }
//     if (options.localUrl) {
//       return LOCAL_URL;
//     }
//     if (options.loginUrl) {
//       return LOGIN_URL;
//     }
//     if (options.lagoonUrl) {
//       return LAGOON_URL;
//     }
//     if (options.lagoonNextUrl) {
//       return LAGOON_NEXT_URL;
//     }
//     if (options.overrideUrl) {
//       return options.overrideUrl;
//     }
//     if (options.fileServiceUrl) {
//       return FILESERVICE_URL;
//     }
//     if (options.assetsUrl) {
//       return ASSETS_URL;
//     }
//     return API_URL;
//   };

//   const baseUrl = generateBaseUrl();
//   const url = `${baseUrl}${endpoint}${paramsToQueryString(options.params)}`;
//   const requestHeaders = new Headers(options.headers ?? {});

//   if (options.authenticated !== false) {
//     const token = await getAccessToken();
//     const type = await getAuthorizationType();
//     if (token) {
//       requestHeaders.append("Authorization", `${type} ${token}`);
//     } else {
//       throw new Error(
//         `Error. Authenticated without token, endpoint:${endpoint}`
//       );
//     }
//   }

//   const config: RequestInit = {
//     method,
//     headers: requestHeaders,
//   };

//   if (options.body instanceof FormData || options.body instanceof Blob) {
//     config.body = options.body;
//   } else if (options.body) {
//     if (isEmpty(options.headers)) {
//       requestHeaders.append("Accept", "application/json");
//       requestHeaders.append("Content-Type", "application/json");
//       config.body = JSON.stringify(options.body);
//     } else {
//       config.body = options.body;
//     }
//   }

//   if (options.withCredentials) {
//     config.credentials = "include";
//   }

//   const idbKey = getKeyvalStringFromObject({
//     url,
//     headers: JSON.stringify(requestHeaders.entries),
//   });

//   const checkAndParse = (response: Response) => {
//     const contentType = response.headers.get("Content-Type");

//     if (response.status === 401) {
//       const event = new Event("unauthorizedEvent");
//       document.dispatchEvent(event);
//     }

//     if (options.asBlob) {
//       return response.blob().then((blob) => {
//         if (!response.ok) {
//           Promise.reject(blob);
//         }
//         return blob;
//       });
//     }

//     if (
//       contentType &&
//       (contentType.indexOf("image/png") !== -1 ||
//         contentType.indexOf("application/octet-stream") !== -1)
//     ) {
//       return response.blob().then((blob) => {
//         if (!response.ok) {
//           Promise.reject(blob);
//         }
//         return blob;
//       });
//     } else if (contentType && contentType.indexOf("text/html") !== -1) {
//       return response.status < 400
//         ? Promise.resolve()
//         : Promise.reject(response);
//     } else if (contentType && contentType.indexOf("text/plain") !== -1) {
//       return response.status < 400 ? response.text() : Promise.reject(response);
//     }

//     // Considered as a json response by default
//     return response.json().then((json) => {
//       if (!response.ok) {
//         return Promise.reject(json);
//       }

//       return json;
//     });
//   };

//   const fetchData = async () => {
//     const response = await fetch(url, config);
//     const contentType = response.headers.get("Content-Type");

//     if (response.status === 401) {
//       const event = new Event("unauthorizedEvent");
//       document.dispatchEvent(event);
//     }

//     if (options.asBlob) {
//       const blob = await response.blob();
//       return response.ok ? blob : Promise.reject(blob);
//     }

//     if (
//       contentType?.includes("image/png") ||
//       contentType?.includes("application/octet-stream")
//     ) {
//       const blob = await response.blob();
//       return response.ok ? blob : Promise.reject(blob);
//     }

//     if (contentType?.includes("text/html")) {
//       return response.status < 400
//         ? Promise.resolve()
//         : Promise.reject(response);
//     }

//     if (contentType?.includes("text/plain")) {
//       return response.status < 400 ? response.text() : Promise.reject(response);
//     }

//     const json = await response.json();
//     return response.ok ? json : Promise.reject(json);
//   };

//   const getResult = async () => {
//     return fetch(url, config)
//       .then(checkAndParse)
//       .then(async (result) => {
//         method === "get" && setApiCacheResult(idbKey, result);
//         return result;
//       });
//   };

//   const result = await fetchData();

//   const isOnline = window.navigator.onLine ?? true;

//   let strategyToBeUsed = isOnline ? strategy : CacheStrategy.CACHE_ONLY;
//   strategyToBeUsed =
//     method === "get" ? strategyToBeUsed : CacheStrategy.NETWORK_ONLY;

//   switch (strategyToBeUsed) {
//     case CacheStrategy.CACHE_ONLY:
//       return getApiCacheResult(idbKey);
//     case CacheStrategy.NETWORK_ONLY:
//       return getResult();
//     case CacheStrategy.CACHE_FIRST:
//       return getApiCacheResult(idbKey).catch(() => getResult());
//     case CacheStrategy.NETWORK_FIRST:
//       return getResult().catch(() => getApiCacheResult(idbKey));
//     case CacheStrategy.SWR:
//       console.warn(
//         "SWR cache strategy can't be used in GraphQLService. Falling back to default strategy."
//       );
//       return getResult();
//   }

//   return result;
// }

function getRequest<T>(
  endpoint: string,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: ApiOptions = {},
  strategy: CacheStrategy = DEFAULT_CACHE_STRATEGY
): Promise<T> {
  return request(
    "get",
    endpoint,
    {
      params,
      headers,
      ...options,
      authenticated:
        options.authenticated === undefined ? true : options.authenticated,
    }
    // strategy
  ) as Promise<T>;
}

function postRequest<T>(
  endpoint: string,
  body: any,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: ApiOptions = {}
): Promise<T> {
  return request("post", endpoint, {
    params,
    headers,
    body,
    ...options,
    authenticated:
      options.authenticated === undefined ? true : options.authenticated,
  }) as Promise<T>;
}

function putRequest<T>(
  endpoint: string,
  body: any,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: ApiOptions = {}
): Promise<T> {
  return request("put", endpoint, {
    params,
    headers,
    body,
    ...options,
    authenticated:
      options.authenticated === undefined ? true : options.authenticated,
  }) as Promise<T>;
}

function deleteRequest<T>(
  endpoint: string,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: ApiOptions = {}
): Promise<T> {
  return request("delete", endpoint, {
    params,
    headers,
    ...options,
    authenticated:
      options.authenticated === undefined ? true : options.authenticated,
  }) as Promise<T>;
}

export default {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
};
