import type { AuthResource } from "./idbService";

const INTERNAL_STORAGE_KEY = "whaly_storage";

const getStorage = (): Record<string, any> => {
  // const storage = sessionStorage.getItem(INTERNAL_STORAGE_KEY);
  const storage = window[INTERNAL_STORAGE_KEY];

  if (!storage) {
    return {};
  }

  return JSON.parse(storage);
};
const setStorage = (value: string) => {
  // sessionStorage.setItem(INTERNAL_STORAGE_KEY, value);
  window[INTERNAL_STORAGE_KEY] = value;
};

const get = <T extends any>(key: string): T => {
  const storage = getStorage();

  return storage[key];
};

const set = (key: string, value: unknown): void => {
  const storage = getStorage();

  setStorage(
    JSON.stringify({
      ...storage,
      [key]: value,
    })
  );
};

const remove = (key: string): void => {
  const storage = getStorage();

  storage.hasOwnProperty(key) && delete storage[key];

  setStorage(JSON.stringify(storage));
};

export const SessionStorageService = {
  get,
  set,
  remove,
  getOverrideToken: () => get<AuthResource>("overrideToken"),
  setOverrideToken: (token: AuthResource) => set("overrideToken", token),
  removeOverrideToken: () => remove("overrideToken"),
};
