import { gql } from "@apollo/client";
import type { ResultSet, TableColumn } from "@cubejs-client/core";
import { ExportToCsv, type Options } from "export-to-csv";
import moment from "moment";
import type { ChartType } from "../components/chart/domain";
import {
  ChartDefinition,
  type IChartCSVDownloadOptions,
} from "../components/chart/domain";
import type { AsyncData } from "../helpers/typescriptHelpers";
import GraphQLService from "../services/graphql/GraphQLService";

const logDataExport = async (orgId: string, reportId: string | undefined) => {
  const LOG_DATA_EXPORT_MUTATION_QUERY = gql`
    mutation LogDataExport($orgId: ID!, $reportId: ID) {
      logDataExport(orgId: $orgId, reportId: $reportId)
    }
  `;

  await GraphQLService(LOG_DATA_EXPORT_MUTATION_QUERY, {
    orgId,
    reportId,
  });
};

export const downloadCsv =
  (
    orgId: string,
    reportId: string | undefined,
    download:
      | {
          type: "ResultSet";
          data: AsyncData<ResultSet<any>>;
        }
      | {
          type: "ChartData";
          chartType: ChartType;
          options: IChartCSVDownloadOptions;
        }
      | {
          type: "Raw";
          data: Array<object>;
        },
    fileNameSuffix?: string
  ) =>
  async () => {
    const defaultExportOptions: Options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      filename: `${
        fileNameSuffix ? fileNameSuffix : "export"
      }-${moment().format("YYYY-MM-DD")}`,
    };

    if (download.type === "ChartData") {
      await logDataExport(orgId, reportId);
      const exportSettings = ChartDefinition[download.chartType]?.downloadCSV?.(
        download.options
      );
      if (!exportSettings) return;
      const csvExporter = new ExportToCsv({
        ...defaultExportOptions,
        headers: exportSettings.headers,
      });
      return csvExporter.generateCsv(exportSettings.data);
    } else if (
      download.type === "ResultSet" &&
      download.data.status === "success"
    ) {
      await logDataExport(orgId, reportId);
      const extractChildren = (c: TableColumn[]): string[] => {
        return c.flatMap((e) => {
          if (e.children) {
            return extractChildren(e.children);
          }
          return e.meta ? e.meta.name : e.title;
        });
      };

      const options = {
        ...defaultExportOptions,
        headers: download.data.data.tableColumns().flatMap((c) => {
          if (c.children) {
            return extractChildren(c.children);
          }
          return c.meta?.name ? c.meta.name : c.title;
        }),
      };
      const csvExporter = new ExportToCsv(options);
      return csvExporter.generateCsv(download.data.data.tablePivot());
    } else if (download.type === "Raw" && download.data) {
      const options = {
        ...defaultExportOptions,
        headers: Object.keys(download.data?.[0] ?? {}),
      };
      const csvExporter = new ExportToCsv(options);
      console.log("here");
      return csvExporter.generateCsv(download.data);
    }
  };
