import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { IObject } from "../../../../interfaces/object";
import { compose } from "../../../compose/WlyCompose";
import UserAvatar from "../../../user/avatar/UserAvatar";

interface IUserInfoCellRendererProps {
  value: string;
  object?: IObject;
}

type Props = IUserInfoCellRendererProps &
  RouteComponentProps<{ organizationSlug: string }>;

const UserInfoCellRenderer = (props: Props) => {
  const {
    value,
    object,
    match: {
      params: { organizationSlug },
    },
  } = props;
  const [id, email, firstName, lastName, displayUrl] = (value ?? "")?.split(
    "||"
  );

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <UserAvatar
        user={{
          id: id,
          firstName: firstName,
          lastName: lastName,
          avatarColor: displayUrl?.startsWith("#") ? displayUrl : "",
          gravatar: displayUrl?.startsWith("http") ? displayUrl : "",
          email: email,
        }}
        size={"small"}
        style={{ marginRight: 6 }}
      />{" "}
      {firstName} {lastName}
    </div>
  );
};

export default compose<Props, IUserInfoCellRendererProps>(withRouter)(
  UserInfoCellRenderer
);
