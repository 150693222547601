import { FallOutlined, RiseOutlined, TrophyFilled } from "@ant-design/icons";
import { Card, Space, theme } from "antd";
import { useEffect } from "react";
import type { IObject } from "../../../../../../../../interfaces/object";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetInsightConfig } from "../domain";

const { useToken } = theme;

interface IInsightWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IWidgetInsightConfig;
  edit?: boolean;
}

type Props = IInsightWidgetProps;

export function InsightWidget(props: Props) {
  const { widget, conf, object, layoutId, recordId } = props;

  const { token } = useToken();
  const { setAsFinished } = useWidgetCacheActions({
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  });

  useEffect(() => {
    setAsFinished();
  }, [setAsFinished]);

  const renderIcon = (c: string) => {
    if (c === "rising") {
      return <RiseOutlined />;
    } else if (c === "falling") {
      return <FallOutlined />;
    }
    return <TrophyFilled />;
  };

  const COLORS = {
    rising: {
      background: token["green-2"],
      font: token["green-8"],
    },
    falling: {
      background: token["red-2"],
      font: token["red-8"],
    },
    trophy: {
      background: token["gold-2"],
      font: token["gold-8"],
    },
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {(conf?.insights || []).map((c, i) => (
        <Card key={i} size="small" styles={{ body: { padding: `12px 24px` } }}>
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <div style={{ flex: `0 36px` }}>
              <div
                style={{
                  backgroundColor: COLORS[c.icon].background,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 36,
                  fontSize: 16,
                  color: COLORS[c.icon].font,
                }}
              >
                {renderIcon(c.icon)}
              </div>
            </div>
            <div style={{ flex: 1 }}>{c.description}</div>
          </div>
        </Card>
      ))}
    </Space>
  );
}
