import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Typography } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../components/compose/WlyCompose";
import Feednack from "../../components/layout/feedback/feedback";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import { routeDescriptor } from "../../routes/routes";
import graphQlService from "../../services/graphql/GraphQLService";
import type { WorkspaceDatatoreProps } from "../../store/dataStores/workspace/workspaceDatastore";
import type { ILagoonQuery } from "../exploration/single/domain";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import { BORDER_COLOR } from "../v2-demo/container/layout/domain";
import { type IClassifierJob, type IIndexedFile } from "./domain";
import FileIndexerList from "./list/FileIndexerList";
import { FileIndexerFileContent } from "./viewer/FileIndexerFileContent";

interface IFileIndexerModalProps {
  open: boolean;
  onClose: () => void;
  isEmbed?: boolean;
  initialQuery?: ILagoonQuery;
}

type Props = WorkspaceDatatoreProps &
  IFileIndexerModalProps &
  RouteComponentProps<{ fileId?: string }> &
  InjectedOrgProps;

function FileIndexerModal(props: Props) {
  const {
    open,
    workspaceDatastore: { data },
    onClose,
    match: {
      params: { fileId },
      params,
    },
    org,
    history,
  } = props;

  const [indexedItems, setIndexedItems] = React.useState<
    AsyncData<{ files: IIndexedFile[]; classifierJobs: IClassifierJob[] }>
  >({ status: "initial" });

  const fetch = async (refetch?: boolean) => {
    try {
      if (!refetch) {
        setIndexedItems({ status: "loading" });
      }

      const d = await graphQlService<{
        allDocuments: IIndexedFile[];
        allClassifierJobs: IClassifierJob[];
      }>(
        `
      query getDocuments($orgId: ID!) {
        allDocuments(where: { isDeleted_not: true, org: { id: $orgId } }) {
          id
          fileName
          extension
          externalId
          filePath
          validFrom
          validUntil
          sizeKb
          storage {
            id
          }
          mappings(where: { isDeleted_not: true }) {
            id
            status
            error
            mappings
            mappingsOverride
            classifier {
              id
            }
          }
        }
        allClassifierJobs(where: { isDeleted_not: true, org: { id: $orgId }, type: DOCUMENT }) {
          id
          classifier {
            id
            name
          }
        }
      }  
      `,
        {
          orgId: org.id,
        }
      );
      setIndexedItems({
        status: "success",
        data: { files: d.allDocuments, classifierJobs: d.allClassifierJobs },
      });
    } catch (err) {
      console.error(err);
      setIndexedItems({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    if (open) {
      fetch();
    }
  }, [open, org.id]);

  if (data.status !== "success") {
    return (
      <Feednack>
        <div>Please load datastore first</div>
      </Feednack>
    );
  }

  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      closable={false}
      width={"80%"}
      centered
      className="file-uploader-modal"
      styles={{
        body: {
          height: "100%",
          padding: 0,
          maxHeight: "100%",
          overflow: "hidden",
        },
      }}
      footer={null}
      open={open}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div
          style={{ borderBottom: `1px solid ${BORDER_COLOR}`, flex: `0 56px` }}
          className="file-uploader-title"
        >
          <div className="file-uploader-title-content">
            <Typography.Title
              id={"file-uploader-title"}
              style={{ marginBottom: 0 }}
              level={5}
            >
              Documents
            </Typography.Title>
          </div>
          <div className="file-uploader-title-action">
            <Space>
              <div className="file-uploader-title-action-portal" />
              <Button
                onClick={onClose}
                icon={<CloseOutlined />}
                type="text"
                shape="circle"
              />
            </Space>
          </div>
        </div>
        <div
          style={{
            overflow: "auto",
            flex: 1,
            display: "flex",
          }}
        >
          <div
            style={{
              flex: `0 300px`,
              height: "100%",
              overflow: "auto",
              borderRight: `1px solid ${BORDER_COLOR}`,
            }}
            id={"file-indexer-list"}
          >
            <FileIndexerList
              // onUpload={(data) =>
              //   setIndexedItems((d) => {
              //     if (d.status === "success") {
              //       return {
              //         ...d,
              //         data: [...d.data, data],
              //       };
              //     }
              //     return d;
              //   })
              // }
              data={indexedItems}
              onSelect={(s) =>
                history.push(
                  routeDescriptor.fileIndexer.renderRoute({
                    ...params,
                    fileId: s,
                  })
                )
              }
              selectedId={fileId}
            />
          </div>
          <div style={{ flex: 1, height: "100%", overflow: "hidden" }}>
            <FileIndexerFileContent
              data={indexedItems}
              onSave={() => {
                return fetch(true);
              }}
              selectedId={fileId}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default compose<Props, IFileIndexerModalProps>(
  inject("workspaceDatastore"),
  observer,
  WithOrg,
  withRouter
)(FileIndexerModal);
