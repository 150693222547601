import { EditOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { POLICIES } from "../../../../../auth/authorization";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import Delayed from "../../../../../components/delayed/delayed";
import { useIsOnline } from "../../../../../components/hooks/useIsOnline";
import { SafeText } from "../../../../../components/safe-text/SafeText";
import type { IReport } from "../../../../../interfaces/reports";
import FloatingActionButtons from "../../../../layout/floating-header/FloatingActionButtons";
import { FloatingLayoutBreadcrumb } from "../../../../layout/floating-header/FloatingLayoutBreadcrumb";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { BORDER_COLOR } from "../../../../v2-demo/container/layout/domain";
import { parseOptions } from "../../domain";
import ScheduleListDrawer from "../../schedule/ScheduleListDrawer";
import type { ScheduleOuterType } from "../../schedule/domain";
import ShareDrawer from "../../share/ShareDrawer";

interface IDashboardContentHeaderProps {
  report: IReport;
  editing?: boolean;
  small?: boolean;
  onEditClick?: () => void;
  isDisplayedInWorkspace: boolean;
}

type Props = IDashboardContentHeaderProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  RouteComponentProps;

function DashboardContentHeaderRaw(props: Props) {
  const { report, small, editing, onEditClick, isDisplayedInWorkspace, user } =
    props;

  const isOnline = useIsOnline();
  const [scheduleListDrawerOpened, setScheduleListDrawerOpened] =
    React.useState<{ opened: boolean; type: ScheduleOuterType }>({
      opened: false,
      type: "email",
    });
  const [shareDrawerOpened, setShareDrawerOpened] =
    React.useState<boolean>(false);

  const options = parseOptions(report.reportOptions);

  const renderHeaderColor = () => {
    if (small) {
      return "black";
    }
    if (options?.header?.color === "light") {
      return "white";
    }
    if (options?.header?.color === "dark") {
      return "black";
    }
    return "white";
  };

  if (!editing) {
    return (
      <div
        style={{
          flex: 0,
          borderBottom: `1px solid ${BORDER_COLOR}`,
          padding: 24,
          display: "flex",
        }}
      >
        <div style={{ flex: 1 }}>
          <div>
            <Typography.Title style={{ marginBottom: 0 }} level={4}>
              {report.name}
            </Typography.Title>
          </div>
          {report.description && (
            <div>
              <Typography.Text type="secondary">
                <SafeText>{report.description}</SafeText>
              </Typography.Text>
            </div>
          )}
        </div>
        <div style={{ flex: 0 }}>
          {POLICIES["report:edit"]({
            user,
            orgId: props.org.id,
            report: report,
          }) && (
            <Button
              icon={<EditOutlined />}
              onClick={onEditClick}
              disabled={!isOnline}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
    );
  }

  if (!isDisplayedInWorkspace) {
    return (
      <div
        style={{
          flex: 0,
          borderBottom: `1px solid ${BORDER_COLOR}`,
          padding: 24,
          display: "flex",
        }}
      >
        <div style={{ flex: 1 }}>
          <div>
            <Typography.Title style={{ marginBottom: 0 }} level={4}>
              {report.name}
            </Typography.Title>
          </div>
          {report.description && (
            <div>
              <Typography.Text type="secondary">
                <SafeText>{report.description}</SafeText>
              </Typography.Text>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <Delayed delay={200}>
      <>
        <FloatingLayoutBreadcrumb key={props.location.pathname}>
          <div>
            <Typography.Title
              level={4}
              className="title"
              style={{ color: renderHeaderColor(), marginBottom: 0 }}
            >
              {report.name}
            </Typography.Title>
          </div>
        </FloatingLayoutBreadcrumb>
        <FloatingActionButtons>
          <ScheduleListDrawer
            report={props.report}
            type={scheduleListDrawerOpened.type}
            visible={scheduleListDrawerOpened.opened}
            onClose={() =>
              setScheduleListDrawerOpened({
                opened: false,
                type: scheduleListDrawerOpened.type,
              })
            }
          />
          <ShareDrawer
            reportId={props.report.id}
            visible={shareDrawerOpened}
            setVisible={setShareDrawerOpened}
          />
        </FloatingActionButtons>
      </>
    </Delayed>
  );
}

export const DashboardContentHeader = compose<
  Props,
  IDashboardContentHeaderProps
>(
  WithOrg,
  withAntUtils,
  withRouter
)(DashboardContentHeaderRaw);
