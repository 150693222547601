import type {
  BinaryFilter,
  Filter,
  Query,
  UnaryFilter,
} from "@cubejs-client/core";
import type {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import _ from "lodash";
import moment from "moment";
import type { AvailableColumn } from "../../../containers/v2-demo/container/object/domain";
import {
  getObjectColumns,
  isAvailableProperty,
} from "../../../containers/v2-demo/container/object/domain";
import {
  getDimensionPartFromTimeDimension,
  getTimePartFromTimeDimension,
  isTimeDimension,
} from "../../../containers/v2-demo/container/object/viewer/domain";
import type { IObject } from "../../../interfaces/object";
import type { IOrg } from "../../../interfaces/org";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../services/LagoonService";
import { cleanIncompleteFilters } from "../../measures/filter-item/domain";
import type { IObjectTableContext } from "./ObjectTable";
import type { BuildQueryOptions } from "./domain";

export const useCubeJSDatasource = (options: {
  object: IObject;
  org: IOrg;
  availableColumns: AvailableColumn[];
  buildQuery: (options: BuildQueryOptions) => Query;
  onSuccess?: (result: any, query: any) => void;
  onError?: (error: string | Error) => void;
}) => {
  const cubeJSDataSource: IServerSideDatasource = {
    // called by the grid when more rows are required
    getRows: async (
      params: IServerSideGetRowsParams<any, IObjectTableContext>
    ) => {
      try {
        const currentQuery = params.context.tableQuery;
        const currentGroups = params.context.tableQuery.groups;
        const newQuery = Object.assign(
          {},
          options.buildQuery({
            tableQuery: currentQuery,
            object: options.object,
            availableColumns: options.availableColumns,
          })
        );
        newQuery.offset = params.request.startRow;
        newQuery.limit =
          (params.request.endRow || 0) - (params.request.startRow || 0);
        newQuery.total = true;

        // we have a row group request
        // we adjust filters and dimensions
        // ag grids sends two object for groups:
        // -- rowGroupCols -> columns that are grouped
        // -- groupKeys -> what groups the user is viewing
        if (currentGroups.length > 0) {
          const dimensionsIds = currentGroups.map((rgc) => rgc.groupedField);

          // we have group(s) and the users and unfolded some
          // we query the dimensions that are are not groupped,
          // we filter the dimeniosn that have a groupKey
          const nbOfFilters = params.request.groupKeys.length;
          if (nbOfFilters > 0) {
            const filters: Filter[] = [
              {
                and: params.request.groupKeys.map((groupKey, index) => {
                  const dimension = dimensionsIds[index];

                  if (groupKey === null || groupKey === "") {
                    return {
                      operator: "notSet",
                      member: getDimensionPartFromTimeDimension(dimension),
                    } as UnaryFilter;
                  } else if (isTimeDimension(dimension)) {
                    const originalDimension =
                      getDimensionPartFromTimeDimension(dimension);
                    const unitOfTime = getTimePartFromTimeDimension(dimension);
                    return {
                      operator: "inDateRange",
                      values: [
                        moment(groupKey)
                          .startOf(unitOfTime as any)
                          .toISOString(true),
                        moment(groupKey)
                          .endOf(unitOfTime as any)
                          .toISOString(true),
                      ],
                      member: originalDimension,
                    } as BinaryFilter;
                  } else {
                    return {
                      operator: "equals",
                      values: [groupKey],
                      member: dimension,
                    };
                  }
                }),
              },
            ];
            if (currentQuery.filters) {
              newQuery.filters = [...(newQuery.filters ?? []), ...filters];
            } else {
              newQuery.filters = filters;
            }
          }

          // we query the first dimension that is not filtered
          // + the matching sortAndFilter dimension if needed
          // + all the metrics used in the sort
          const dimensionToQuery = dimensionsIds[nbOfFilters];

          if (
            newQuery.dimensions &&
            newQuery.dimensions.length > 0 &&
            dimensionToQuery
          ) {
            const displayDimensions = [dimensionToQuery].map(
              (d) =>
                currentGroups.find((g) => g.groupedDimension === d)
                  ?.groupedField ?? d
            );

            // I'm temporary removing this as it is source of a bug
            // If a user does not have acccess to the sortAndFilterKey it will fail
            // example : impersonate a RS on tropicana and open this :
            // http://localhost:3000/tropicana/object/magasins?view=dn-globale-vs-atteinte
            // queries will fail when ungrouping as the sortAndFilter key may be on another table

            const sortAndFilterKey = getObjectColumns(options.object)
              .filter(isAvailableProperty)
              .find((p) => p.data.key === dimensionToQuery)
              ?.data.sortAndFilterKey;

            newQuery.dimensions = _.uniq([
              ...displayDimensions,
              // what we should fix
              // ...(sortAndFilterKey ? [sortAndFilterKey] : []),
            ]);

            const metricsToAdd = [
              ...(newQuery.order instanceof Array ? newQuery.order : []),
            ]
              .map((d) => d[0])
              .filter(
                (d) => d.includes(".met") && !newQuery.measures?.includes(d)
              );

            newQuery.measures = [...(newQuery.measures ?? []), ...metricsToAdd];
          }
        }

        newQuery.filters = cleanIncompleteFilters(newQuery.filters ?? []);

        // Sorting on cube only works when measures and dimension are included
        // in the query measures / dimensions for cube so we add those
        // we should only do so if this isn't a groupped query
        if (newQuery.order instanceof Array && currentGroups.length === 0) {
          const dimensionsToAdd = [...newQuery.order]
            .map((d) => d[0])
            .filter(
              (d) => !d.includes(".met") && !newQuery.dimensions?.includes(d)
            );
          newQuery.dimensions = [
            ...(newQuery.dimensions ?? []),
            ...dimensionsToAdd,
          ];

          const metricsToAdd = [...newQuery.order]
            .map((d) => d[0])
            .filter(
              (d) => d.includes(".met") && !newQuery.measures?.includes(d)
            );
          newQuery.measures = [...(newQuery.measures ?? []), ...metricsToAdd];
        }

        // we send the query and update the table
        const resultSet = await lagoonServiceLoad(
          options.org.id,
          newQuery,
          "OBJECT",
          options.object.id,
          options.object.id,
          LagoonCallOrigin.WHALY_APP,
          undefined,
          undefined
        );

        const rowCount = resultSet.totalRows();
        const rowData = resultSet.tablePivot({ fillMissingDates: false });

        const result = {
          rowData: rowData,
          rowCount: rowCount || 0,
        };
        options.onSuccess?.(result, newQuery);

        params.success(result);
      } catch (error) {
        console.warn(error);
        options.onError?.(error);
        params.fail();
      }
    },
  };

  return cubeJSDataSource;
};
