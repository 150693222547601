import Error from "../../../../../../components/error/Error";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import { SQLEditor } from "./SQLEditor";

export interface ISQLExecutedQueryProps {
  sql: AsyncData<string>;
  height: number;
}

export function SQLExecutedQuery(props: ISQLExecutedQueryProps) {
  const { sql, height } = props;
  if (sql.status === "initial") {
    return <Feednack>Please run a query to get the executed SQL</Feednack>;
  }
  if (sql.status === "loading") {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }
  if (sql.status === "error") {
    return (
      <Feednack>
        <Error>{sql.error?.message}</Error>
      </Feednack>
    );
  }
  return (
    <SQLEditor
      readOnly
      value={sql.data}
      overrideValue={undefined}
      height={height - 80}
    />
  );
}
