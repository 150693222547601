import { CloseOutlined, InfoCircleFilled } from "@ant-design/icons";
import { Button, Progress, theme, Tooltip, Typography } from "antd";
import React from "react";
import { BORDER_COLOR } from "../../../../../containers/v2-demo/container/layout/domain";
import { type DisplayContext } from "../domain";
import {
  renderColor,
  renderDetailedScore,
  renderProgress,
  renderScoreLabel,
  type IScoreConfigValue,
} from "./domain";

export interface IScoreDrawerContentRendererProps {
  context: DisplayContext;
  value?: IScoreConfigValue;
  title: string;
  onClose: () => void;
}

const { useToken } = theme;

export function ScoreDrawerContentRenderer(
  props: IScoreDrawerContentRendererProps
) {
  const { context, title, value, onClose } = props;

  const { token } = useToken();

  const getSubScores = () => {
    if (value?.subScores && Array.isArray(value.subScores)) {
      return value.subScores;
    }
    return [];
  };
  const mainProgress = renderProgress(value);
  const mainColor = renderColor(token, value);

  return (
    <div>
      {context === "INNER_MODAL" && (
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <Typography.Title level={5}>{title}</Typography.Title>
          </div>
          <div>
            <Button
              icon={<CloseOutlined />}
              shape="circle"
              type="text"
              onClick={onClose}
            />
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px 0",
        }}
      >
        <Progress
          type="circle"
          percent={mainProgress}
          success={{ strokeColor: mainColor.color }}
          trailColor={BORDER_COLOR}
          strokeColor={mainColor.color}
          format={() => (
            <div style={{ color: "initial" }}>{renderDetailedScore(value)}</div>
          )}
        />
      </div>
      {value?.description ? <div>{value?.description}</div> : undefined}
      {getSubScores().map((subScore, i) => {
        const progressColor = renderColor(token, subScore);
        const progress = renderProgress(subScore);
        return (
          <React.Fragment key={i}>
            <div
              style={{
                margin: context === "INNER_MODAL" ? `12px -8px` : "12px -16px",
                height: 1,
                background: BORDER_COLOR,
              }}
            />
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Typography.Text strong>{subScore?.label}</Typography.Text>{" "}
                  {subScore?.description ? (
                    <Tooltip title={subScore?.description}>
                      <InfoCircleFilled style={{ color: "#8c8c8c" }} />
                    </Tooltip>
                  ) : null}
                </div>
                <div style={{ flex: `0 auto` }}>
                  {subScore?.scoreLabel
                    ? renderScoreLabel(token, subScore)
                    : renderDetailedScore(subScore)}
                </div>
              </div>
              {/* {subScore?.description ? (
                <div>
                  <Typography.Text>{subScore?.description}</Typography.Text>
                </div>
              ) : null} */}
              <div>
                <Progress
                  type="line"
                  success={{ strokeColor: progressColor.color }}
                  strokeColor={progressColor.color}
                  trailColor={BORDER_COLOR}
                  percent={progress + 1}
                  showInfo={false}
                />
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
