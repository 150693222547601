import type { IObject } from "../../../../../../../../interfaces/object";
import type { ITablePayload } from "../../../../../../../../services/ExplorationMutationService";
import {
  mutateObjectFromCode,
  type IObjectPayload,
  type IObjectUpdatePayload,
} from "../../../../../../../../services/ExplorationMutationService";
import graphQlService from "../../../../../../../../services/graphql/GraphQLService";
import { TableType } from "../../../../../exploration/domain";

export class ObjectProcessor {
  orgId: string;
  warehouseId: string;
  objects: IObject[];
  datasetMap: Map<string, string>;
  datasetViewMap: Map<string, string>;
  objectMap: Map<string, string>;

  constructor(
    orgId: string,
    warehouseId: string,
    objects: IObject[],
    datasetMap: Map<string, string>,
    datasetViewMap: Map<string, string>,
    objectMap: Map<string, string>
  ) {
    this.orgId = orgId;
    this.warehouseId = warehouseId;
    this.objects = objects;
    this.datasetMap = datasetMap;
    this.datasetViewMap = datasetViewMap;
    this.objectMap = objectMap;
  }

  private createObject = async (object: IObject) => {
    const d = await graphQlService<{
      createObject: {
        id: string;
      };
    }>(
      `mutation createObject($data: ObjectCreateInput) {
        createObject(data: $data) {
          id
        }
      }`,
      {
        data: {
          name: object.name,
          org: {
            connect: {
              id: this.orgId,
            },
          },
        },
      }
    );
    this.objectMap.set(object.id, d.createObject.id);
  };

  private processSingleObject = async (object: IObject) => {
    console.log("Importing object ", object.name);
    const conf: IObjectPayload = {
      name: object.name,
      icon: object.icon,
      color: object.color,
      description: object.description,
      primaryLabel: object.primaryLabel,
      primaryImage: object.primaryImage,
      canBeListed: object.canBeListed,
      properties: object.properties.map((p) => {
        return {
          tempId: p.id,
          columnName: p.columnName,
          columnDomain: p.columnDomain,
          label: p.label,
          description: p.description,
          formatter: p.formatter,
          formatterConfig: p.formatterConfig,
          foreignKey: p.foreignKey
            ? this.objectMap.get(p.foreignKey.id)
            : undefined,
        };
      }),
    };

    const buildTable = (): ITablePayload => {
      const table = object.table;
      const allMetricsRefs: Array<[string, string]> = table.metrics.map((m) => [
        `${table.cubeName}.${m.cubeName}`,
        `${table.id}.${m.id}`,
      ]);
      const allDimensionsRefs = object.properties.map((m) => [
        `${table.cubeName}.${m.cubeName}`,
        `${table.id}.${m.id}`,
      ]);

      const sanitizeFormula = (formula: string) => {
        const newFormula = allMetricsRefs.reduce((acc, v) => {
          return acc.replaceAll(v[0], v[1]);
        }, formula);
        // allDimensionsRefs.forEach((m) => newFormula.replaceAll(m[0], m[1]));
        console.log(
          "Sanitized formula from ",
          formula,
          " to ",
          newFormula,
          " with ",
          allMetricsRefs
        );
        return newFormula;
      };

      const sanitizeDrill = (drill: string): string => {
        return [...allMetricsRefs, ...allDimensionsRefs].reduce((acc, v) => {
          return acc.replaceAll(v[0], v[1]);
        }, drill);
      };

      return {
        tempId: table.id,
        view: this.datasetViewMap.get(table.view.id)!,
        name: table.name,
        tableType: TableType.REGULAR,
        semanticGroups: [],
        drills: table.drills!,
        primaryKey: table.primaryKey,
        metrics: table.metrics.map((m) => {
          return {
            tempId: m.id,
            columnName: m.columnName
              ? sanitizeFormula(m.columnName)
              : m.columnName,
            overrideName: m.overrideName,
            description: m.description,
            prefix: m.prefix,
            suffix: m.suffix,
            format: m.format as string,
            expression: m.expression,
            filters: m.filters,
            drills: m.drills ? sanitizeDrill(m.drills) : undefined,
          };
        }),
        dimensions: [],
      };
    };

    const objectUpdatePayload: IObjectUpdatePayload = {
      id: this.objectMap.get(object.id),
      name: object.name,
      description: object.description,
      icon: object.icon,
      color: object.color,
      conf: conf,
      table: buildTable(),
    };
    const newModelId = this.datasetMap.get(object.model.id);
    if (!newModelId) {
      console.log(
        "Skipping ",
        object.name,
        "since we don't find its base model in the graph"
      );
      return;
    }
    const d = await mutateObjectFromCode(
      this.orgId,
      newModelId,
      objectUpdatePayload
    );
    console.log("Got response from server ", d);
    if (d) {
      console.log(
        "Successfully saved object without it properties with id ",
        object.id
      );
      this.objectMap.set(object.id, d.objectId);
    } else {
      console.log("There was an error saving your object");
    }
  };

  process = async () => {
    console.group();
    await this.objects.reduce(async (acc, v) => {
      await acc;
      return this.createObject(v);
    }, Promise.resolve());
    await this.objects.reduce(async (acc, v) => {
      await acc;
      return this.processSingleObject(v);
    }, Promise.resolve());
    console.groupEnd();
  };
}
