import {
  authenticatedRequest,
  getAccessToken,
  getAuthorizationType,
} from "../authService";

import type { ApolloError, DocumentNode } from "@apollo/client";
import {
  ApolloClient,
  createHttpLink,
  from,
  gql,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import type { OperationDefinitionNode } from "graphql";
import { Kind, OperationTypeNode, print } from "graphql";
import {
  CacheStrategy,
  DEFAULT_CACHE_STRATEGY,
  getKeyvalStringFromObject,
} from "../../utils/cacheStrategy";
import StorageService from "../StorageService";
import { IDBService } from "../idbService";

const getHttpErrorCode = (error: ApolloError): number | undefined => {
  if (error.networkError && "statusCode" in error.networkError) {
    return error.networkError.statusCode;
  }

  return undefined;
};

const delay = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

const coreUri =
  StorageService.getItem("_wly_override_core_uri") ??
  window.WHALY_CONSTANTS.API_URL;

const httpLink = createHttpLink({
  uri: `${coreUri}/v1/graphql`,
});

const getAuthLink = (token, type) =>
  setContext((_, { headers, body }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `${type} ${token}` : "",
      },
      body: {
        ...body,
        operationName: _.operationName,
      },
    };
  });

export const getClient = async () => {
  const token = await getAccessToken();
  const type = await getAuthorizationType();

  return new ApolloClient({
    link: from([getAuthLink(token, type), httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });
};

type GqlFetchWithRetryParams = {
  operation: OperationTypeNode | undefined;
  query: DocumentNode;
  queryType: string | undefined;
  variables: any;
  retries?: number;
  keepCache?: boolean;
};
const gqlFetchWithRetry = async <T = any>({
  operation,
  query,
  queryType,
  variables,
  retries = 3,
  keepCache = true,
}: GqlFetchWithRetryParams): Promise<T> => {
  try {
    await authenticatedRequest();
    const client = await getClient();

    const { errors, data } =
      operation === OperationTypeNode.QUERY
        ? await client.query({
            query,
            variables,
            fetchPolicy: "network-only",
          })
        : await client.mutate({
            mutation: query,
            variables,
            fetchPolicy: "network-only",
          });

    if (errors) {
      throw new Error(JSON.stringify(errors));
    }
    if (!data) {
      throw new Error("There was an error with your query");
    }

    if (queryType) {
      keepCache && (await setGqlCacheResult(query, variables, data[queryType]));
      return data[queryType] as T;
    } else {
      keepCache && (await setGqlCacheResult(query, variables, data));
      return data as T;
    }
  } catch (error) {
    const httpErrorCode =
      "networkError" in error
        ? getHttpErrorCode(error as ApolloError)
        : undefined;

    if (retries > 0 && (httpErrorCode === 502 || httpErrorCode === 504)) {
      await delay(2000);
      return gqlFetchWithRetry({
        operation,
        query,
        queryType,
        variables,
        retries: retries - 1,
      });
    } else {
      console.error(error);
      throw new Error(error);
    }
  }
};

const getOperation = (query: DocumentNode): OperationTypeNode | undefined => {
  const operationDefinitions = query.definitions.filter(
    (d) => d.kind === Kind.OPERATION_DEFINITION
  ) as OperationDefinitionNode[];

  if (operationDefinitions.length > 1) {
    throw new Error("Please pass only one OperationDefinition");
  }

  return operationDefinitions.at(0)?.operation;
};

const getIdbKey = (query: DocumentNode, variables: any) => {
  const operation = query.definitions.find(
    (d) => d.kind === Kind.OPERATION_DEFINITION
  );
  const operationName =
    operation && "name" in operation ? operation.name?.value : "unknown";
  const queryText = print(query);
  const keyvals = getKeyvalStringFromObject(variables);

  return `${operationName}|${keyvals}|query:${queryText}`;
};

const getGqlCacheResult = async <T = any>(
  query: DocumentNode,
  variables: any
): Promise<T> => {
  const idbKey = getIdbKey(query, variables);

  return IDBService.getGqlResource<T>(idbKey);
};

const setGqlCacheResult = async (
  query: DocumentNode,
  variables: any,
  data: any
) => {
  const idbKey = getIdbKey(query, variables);

  return IDBService.setGqlResource(idbKey, data).catch((err) => {
    err
      ? console.error(err)
      : console.error("Error setting cache for GQL | idbKey: ", idbKey);
  });
};

const addGqlMutationToBatch = async (query: DocumentNode, variables: any) => {
  const idbKey = getIdbKey(query, variables);

  return IDBService.addToMutationBatch(idbKey);
};

const graphQlService = <T = any>(
  query: string | DocumentNode,
  variables: any,
  queryType?: string,
  strategy: CacheStrategy = DEFAULT_CACHE_STRATEGY
): Promise<T> => {
  const gqlQuery = typeof query === "string" ? gql(query) : query;
  const operation = getOperation(gqlQuery);
  const isOnline = window.navigator.onLine ?? true;
  const gqlFetchParams = {
    operation,
    query: gqlQuery,
    queryType,
    variables,
  };

  if (operation !== OperationTypeNode.QUERY) {
    if (isOnline) {
      return gqlFetchWithRetry<T>({ ...gqlFetchParams, keepCache: false });
    } else {
      addGqlMutationToBatch(gqlQuery, variables);
      return gqlFetchWithRetry<T>({
        ...gqlFetchParams,
        retries: 0,
        keepCache: false,
      });
    }
  }

  const strategyToBeUsed = isOnline ? strategy : CacheStrategy.CACHE_ONLY;

  switch (strategyToBeUsed) {
    case CacheStrategy.CACHE_ONLY:
      return getGqlCacheResult<T>(gqlQuery, variables);
    case CacheStrategy.NETWORK_ONLY:
      return gqlFetchWithRetry<T>(gqlFetchParams);
    case CacheStrategy.CACHE_FIRST:
      return getGqlCacheResult<T>(gqlQuery, variables).catch(() =>
        gqlFetchWithRetry<T>(gqlFetchParams)
      );
    case CacheStrategy.NETWORK_FIRST:
      return gqlFetchWithRetry<T>(gqlFetchParams).catch(() =>
        getGqlCacheResult<T>(gqlQuery, variables)
      );
    case CacheStrategy.SWR:
      console.warn(
        "SWR cache strategy can't be used in GraphQLService. Falling back to default strategy."
      );
      return gqlFetchWithRetry<T>(gqlFetchParams);
  }
};

export default graphQlService;
