import { createContext, useEffect, useState } from "react";
import { SECOND } from "../../utils/timeConstants";

export const SlowNetworkContext = createContext<boolean | undefined>(undefined);

const PING_RESOURCE = "/version.txt";
const SLOW_NETWORK_TIMEOUT = 10 * SECOND;
export const HANDLED_TIMEOUT_ERR = "REQUEST_TIMEOUT_ERR";

const withTimeout = (
  promise: Promise<any>,
  time: number = SLOW_NETWORK_TIMEOUT
): Promise<string> => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error(HANDLED_TIMEOUT_ERR));
    }, time);

    promise.then(resolve, reject);
  });
};

export const SlowNetworkProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [slowNetwork, setSlowNetwork] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    const ping = async () => {
      const isOnline = window.navigator.onLine;

      if (!isOnline) {
        setSlowNetwork(true);
        return;
      }

      try {
        withTimeout(fetch(PING_RESOURCE, { method: "GET" })).then(() => {
          setSlowNetwork(false);
        });
      } catch (error) {
        setSlowNetwork(true);
      }
    };

    const interval = setInterval(() => {
      ping();
    }, SLOW_NETWORK_TIMEOUT);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <SlowNetworkContext.Provider value={slowNetwork}>
      {children}
    </SlowNetworkContext.Provider>
  );
};
