import styled from "@emotion/styled";
import Markdoc from "@markdoc/markdoc";
import { Card } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { customFormatter } from "../../../../../../../../components/chart/utils/optionsHelper";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../../../interfaces/object";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import {
  getObjectColumns,
  isAvailableMetric,
} from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import {
  convertKeyToMarkdocVariable,
  getParsedDoc,
} from "../../../common/markdoc/domain";
import type { IWidget } from "../../../domain";
import { additionalComponents } from "../../markdown/widget/markdoc-react-component/config";
import type { IWidgetKPIConfig, kpiTheme } from "../domain";
import { kpiThemes } from "../domain";
import BarSparkline from "./sparkline/bar/BarSparkline";
import ProgressTableSparkline from "./sparkline/progress-table/ProgressTableSparkline";
import { ProgressSparkline } from "./sparkline/progress/ProgressSparkline";
import TableSparkline from "./sparkline/table/TableSparkline";

interface IWidgetKPIProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IWidgetKPIConfig;
  edit?: boolean;
}

type Props = IWidgetKPIProps & InjectedOrgProps;

function WidgetKPI(props: Props) {
  const { object, widget, record, layoutId, recordId, conf, user } = props;
  const widgetCacheProps = {
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  };

  const { setAsFinished } = useWidgetCacheActions(widgetCacheProps);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    conf.type === "progress" && setAsFinished();
  }, [conf.type, setAsFinished]);

  const setSizeSafe = (s: {
    width: number | undefined;
    height: number | undefined;
  }) => setSize({ width: s.width || 0, height: s.height || 0 });

  const onResize = useMemo(() => _.debounce(setSizeSafe, 100), []);
  const { ref } = useResizeObserver({ onResize });

  const columns = getObjectColumns(object);
  const metric = columns
    .filter(isAvailableMetric)
    .find((m) => m.data.key === (conf.columns ?? [])[0]);

  const currentTheme: kpiTheme =
    conf.theme && kpiThemes[conf.theme]
      ? kpiThemes[conf.theme]
      : kpiThemes["gray"];

  if (!metric) {
    setAsFinished(true);
    return (
      <Card style={{ height: 150 }} size="small">
        No metric found
      </Card>
    );
  }

  const label = conf.label ? conf.label : metric.data.label;

  const rawValue = parseFloat(record[metric.data.key] as any);
  const value = customFormatter(rawValue, user.locale, metric.data.formatter);
  const source = conf.secondary ? conf.secondary : "";
  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, {});
  const content = getParsedDoc(source, formattedRecord, user, columns);
  const html = Markdoc.renderers.react(content, React, additionalComponents);

  const Text = styled.div`
    color: ${currentTheme.secondary};
    font-size: 13px;

    & strong {
      color: ${currentTheme.primary};
    }

    & p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: 13px;
      line-height: 17px;
      margin-bottom: 0px;
    }
  `;

  const renderAdditionalContent = () => {
    if (conf.type === "bar") {
      return (
        <div
          style={{
            flexShrink: 0,
            width: 200,
            display: size.width >= 350 ? "initial" : "none",
          }}
        >
          <BarSparkline
            object={object}
            record={record}
            conf={conf}
            theme={currentTheme}
            locale={user.locale}
            widgetCacheProps={widgetCacheProps}
          />
        </div>
      );
    } else if (conf.type === "table") {
      return (
        <div
          style={{
            width: 240,
            display: size.width >= 420 ? "initial" : "none",
          }}
        >
          <TableSparkline
            object={object}
            record={record}
            conf={conf}
            theme={currentTheme}
            locale={user.locale}
            widgetCacheProps={widgetCacheProps}
          />
        </div>
      );
    } else if (conf.type === "progress") {
      const maxSize = 110;
      const progressSize = Math.min(Math.round(size.width / 3), maxSize);
      return (
        <div
          style={{
            flexShrink: 0,
            width: progressSize,
            display: size.width >= 230 ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProgressSparkline
            theme={currentTheme}
            rawValue={rawValue}
            size={progressSize}
          />
        </div>
      );
    } else if (conf.type === "progressTable") {
      return (
        <div
          style={{
            width: 240,
            display: size.width >= 420 ? "initial" : "none",
          }}
        >
          <ProgressTableSparkline
            object={object}
            record={record}
            conf={conf}
            theme={currentTheme}
            locale={user.locale}
            widgetCacheProps={widgetCacheProps}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <Card
      style={{
        background: currentTheme.background,
        border: `1px solid ${currentTheme.background}`,
      }}
    >
      <div
        ref={ref}
        style={{ display: "flex", flexDirection: "row", height: 130 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: currentTheme.primary,
            minWidth: 0,
            flex: 1,
          }}
        >
          <div
            style={{
              fontSize: 16,
              lineHeight: "19px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {label}
          </div>
          <div
            style={{
              fontSize: 30,
              lineHeight: "34px",
              fontWeight: 600,
              marginTop: "auto",
            }}
          >
            {value}
          </div>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              minHeight: 22,
            }}
          >
            <Text>{html}</Text>
          </div>
        </div>
        {renderAdditionalContent()}
      </div>
    </Card>
  );
}

export default compose<Props, IWidgetKPIProps>(WithOrg)(WidgetKPI);
