import type { IClonedWorkbench } from "./domain";
import { DatasetProcessor } from "./processors/DatasetProcessor";
import { ModelFolderProcessor } from "./processors/ModelFolderProcessor";
import { ObjectProcessor } from "./processors/ObjectProcessor";

export const uploadWorkbench = async (
  orgId: string,
  warehouseId: string,
  payload: IClonedWorkbench
): Promise<void> => {
  const datasetMap = new Map<string, string>();
  const objectMap = new Map<string, string>();
  const explorationMap = new Map<string, string>();
  const modelFolderMap = new Map<string, string>();
  const datasetViewMap = new Map<string, string>();

  console.log("starting importing model folders");
  const modelFolderProcessor = new ModelFolderProcessor(
    orgId,
    warehouseId,
    payload.modelFolders,
    modelFolderMap
  );
  await modelFolderProcessor.process();

  console.log("starting importing datasets");
  const datasetProcessor = new DatasetProcessor(
    orgId,
    warehouseId,
    payload.datasets,
    datasetMap,
    datasetViewMap,
    modelFolderMap
  );
  await datasetProcessor.process();

  console.log("Starting importing objects");
  const objectProcessor = new ObjectProcessor(
    orgId,
    warehouseId,
    payload.objects,
    datasetMap,
    datasetViewMap,
    objectMap
  );
  await objectProcessor.process();
  console.log("okokok", payload);
};
