import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../components/ant-utils/withAntUtils";
import { compose } from "../../components/compose/WlyCompose";
import type { IUserRoleType } from "../../interfaces/user";
import type { Layout } from "../../routes/domain";
import type { AuthStoreProps } from "../../store/authStore";
import type { UserStoreProps } from "../../store/userStore";
import { getV2DefaultOrgSlug } from "../login/Login";
import OnboardingWrapper from "../onboarding/OnboardingWrapper";
import NoAccess from "../orgs/no-access/NoAccess";
import NoOrgAccess from "../orgs/no-access/NoOrgAccess";
import NoUser from "../orgs/no-access/NoUser";
import { hasRoleAccessBoolean } from "../user-settings/HasRoleAccess";
import ExternalLayout from "./ExternalLayout";
import NoLayout from "./NoLayout";

interface ILayoutManagerProps {
  layout: Layout;
  component: React.ComponentClass;
  additionalProps?: { [key: string]: any };
  hideOnboarding?: boolean;
  restrictAccess?: IUserRoleType;
}

type Props = ILayoutManagerProps &
  UserStoreProps &
  AuthStoreProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ organizationSlug?: string }>;

const LayoutManager = ({
  layout,
  component,
  additionalProps,
  hideOnboarding,
  restrictAccess,
  antUtils,
  userStore,
  authStore,
  match,
  history,
}: Props) => {
  useEffect(() => {
    if (userStore.user) {
      const org = getV2DefaultOrgSlug(userStore.user);

      if (org && match.path === "/") {
        history.replace(`/${org.org.slug}/`);
      }
    }
  }, [history, match.path, userStore.user]);

  if (!userStore.user) {
    return <NoUser />;
  }

  const organizationSlug = match.params.organizationSlug;
  const role = userStore.user.roles.find(
    ({ org: { slug } }) => slug === organizationSlug
  );

  if (organizationSlug && !role) {
    return <NoOrgAccess />;
  }

  if (
    restrictAccess &&
    role &&
    !hasRoleAccessBoolean(restrictAccess, userStore.user, role.org.id)
  ) {
    return <NoAccess />;
  }

  if (userStore.session.isSudoSession === true) {
    const messageId = `sudoSessionWarn`;
    const logoutUrl =
      userStore.user.type === "PORTAL"
        ? `${
            window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
              ? window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
              : ""
          }/auth/api/logout`
        : `${window.WHALY_CONSTANTS.AUTH_SERVER_URL}/oauth2/sessions/logout`;

    // Remove previous message if there
    antUtils.message.destroy(messageId);
    antUtils.message.warning({
      key: messageId,
      content: (
        <>
          You are impersonnating{" "}
          <b>
            {userStore.user.firstName
              ? `${userStore.user.firstName} ${userStore.user.lastName}`
              : userStore.user.email}
          </b>
          .{" "}
          <a
            href={logoutUrl}
            onClick={(e) => {
              e.preventDefault();
              authStore.logout().then(() => {
                window.location.href = logoutUrl;
              });
            }}
          >
            Click here to logout.
          </a>
        </>
      ),
      duration: 0,
    });
  }

  return hideOnboarding ? (
    <LayoutComp
      layout={layout}
      component={component}
      additionalProps={additionalProps}
    />
  ) : (
    <OnboardingWrapper>
      <LayoutComp
        layout={layout}
        component={component}
        additionalProps={additionalProps}
      />
    </OnboardingWrapper>
  );
};

const LayoutComp = ({
  layout,
  component,
  additionalProps,
}: ILayoutManagerProps) => {
  switch (layout) {
    case "external":
      return (
        <ExternalLayout
          contentComponent={component}
          additionalProps={additionalProps}
        />
      );
    case "none":
      return (
        <NoLayout
          contentComponent={component}
          additionalProps={additionalProps}
        />
      );
    default:
      return <></>;
  }
};

export default compose<Props, ILayoutManagerProps>(
  inject("userStore"),
  inject("authStore"),
  observer,
  withRouter,
  withAntUtils
)(LayoutManager);
