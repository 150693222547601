import { Typography } from "antd";
import { useEffect } from "react";
import type { IObject } from "../../../../../../../../interfaces/object";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetTitleConfig } from "../domain";

interface ISeparatorWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IWidgetTitleConfig;
  edit?: boolean;
}

type Props = ISeparatorWidgetProps;

export function TitleWidget(props: Props) {
  const {
    widget,
    conf: { text, level },
    object,
    layoutId,
    recordId,
  } = props;

  const { setAsFinished } = useWidgetCacheActions({
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  });

  useEffect(() => {
    setAsFinished();
  }, [setAsFinished]);

  return (
    <Typography.Title style={{ marginBottom: 0 }} level={level ?? 1}>
      {text ?? "Title"}
    </Typography.Title>
  );
}
