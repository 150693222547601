import { useContext } from "react";
import {
  type IRecordModalContext,
  RecordModalContext,
} from "../../../../containers/v2-demo/container/modals/records/RecordModalContext";

export type DisplayContext = "INNER_MODAL" | "DRAWER";

export const usePropertyContext = (): [
  DisplayContext,
  ((value: IRecordModalContext) => void) | undefined,
  IRecordModalContext | undefined
] => {
  const { setValue, value } = useContext(RecordModalContext);

  const getSiderContext = () => {
    if (value || setValue) {
      // we have a modal context we may proceed to display in portal
      return "INNER_MODAL";
    } else {
      return "DRAWER";
    }
  };

  const context = getSiderContext();

  return [context, setValue, value];
};
