import type { Query } from "@cubejs-client/core";
import { Skeleton, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import usePrevious from "../../../../../../../../components/hooks/usePrevious";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import type { IObject } from "../../../../../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { IDB_GET_ERROR } from "../../../../../../../../services/idbService";
import type { WidgetCacheProps } from "../../../../../../../../store/widgetCacheStore";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import RecordView from "../../../../RecordView";
import { parseLabel } from "../../../../domain";
import type { IWidgetRepeatableConfig } from "../domain";
import { RepeatableWidgetRenderer } from "./RepeatableWidgetRenderer";

interface IRepeatableWidgetQueryProps {
  object: IObject;
  query: Query;
  conf: IWidgetRepeatableConfig;
  widgetCacheProps: WidgetCacheProps;
}

type Props = IRepeatableWidgetQueryProps & InjectedOrgProps;

function RepeatableWidgetQuery(props: Props) {
  const { object, query, org, conf, widgetCacheProps } = props;

  const { setAsFinished } = useWidgetCacheActions(widgetCacheProps);
  const previousQuery = usePrevious(query);

  const [queryData, setQueryData] = React.useState<AsyncData<Array<any>>>({
    status: "initial",
  });

  React.useEffect(() => {
    const fetch = async () => {
      try {
        setQueryData({ status: "loading" });
        const data = await lagoonServiceLoad(
          org.id,
          query,
          "OBJECT",
          object.id,
          undefined,
          LagoonCallOrigin.WHALY_APP,
          undefined
        );
        const records = data.tablePivot();
        setAsFinished();
        setQueryData({ status: "success", data: records });
      } catch (err) {
        console.error(err);
        setAsFinished(true);
        setQueryData({ status: "error", error: err });
      }
    };
    if (!_.isEqual(query, previousQuery)) {
      fetch();
    }
  }, [query]);

  const renderItem = (): Array<{
    key: string;
    label: React.ReactNode;
    children: React.ReactNode;
  }> => {
    if (queryData.status === "loading" || queryData.status === "initial") {
      const items = new Array(3).fill(null).map((_, i) => {
        const id = String(i + 1);
        return {
          label: (
            <Skeleton.Button
              active
              size="small"
              style={{ width: 50 }}
              shape="round"
            />
          ),
          key: id,
          children: (
            <div style={{ height: 250 }}>
              <Feednack>
                <Loading />
              </Feednack>
            </div>
          ),
        };
      });
      return items;
    }

    if (queryData.status === "error") {
      const items = new Array(1).fill(null).map((_, i) => {
        const id = String(i + 1);
        return {
          label: <Typography.Text type="danger">Error</Typography.Text>,
          key: id,
          children: (
            <div style={{ height: 250 }}>
              {queryData.error.cause === IDB_GET_ERROR ? (
                <Feednack>You need to be online to view this content</Feednack>
              ) : (
                <Feednack>There was an error while fetching data</Feednack>
              )}
            </div>
          ),
        };
      });

      return items;
    }

    if (queryData.data.length > 0) {
      return queryData.data.map<{
        key: string;
        label: React.ReactNode;
        children: React.ReactNode;
      }>((d) => {
        const { id, name } = parseLabel(d[`${object.table.cubeName}.label`]);
        return {
          key: id,
          label: name,
          children: (
            <div className="repeatable-widget">
              <RecordView
                homePageConfig={{
                  recordId: id,
                  objectId: object.id,
                  layoutId: conf.options?.foreignObjectSelectedlayout,
                  hideHeader: true,
                }}
                isSubLayout={true}
              />
            </div>
          ),
        };
      });
    }

    const items = new Array(1).fill(null).map((_, i) => {
      const id = String(i + 1);
      return {
        label: <Typography.Text type="secondary">No results</Typography.Text>,
        key: id,
        children: (
          <div style={{ height: 250 }}>
            <Feednack>No related data</Feednack>
          </div>
        ),
      };
    });

    return items;
  };

  return (
    <RepeatableWidgetRenderer
      type={conf.type ? conf.type : "TAB"}
      titleSize={conf.titleSize ? conf.titleSize : 5}
      object={object}
      items={renderItem()}
    />
  );
}

export default compose<Props, IRepeatableWidgetQueryProps>(WithOrg)(
  RepeatableWidgetQuery
);
