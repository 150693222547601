import type { IPalette } from "../components/palette/utils/paletteData";
import type { IDestination } from "./destinations";
import type { IGroup } from "./group";
import type { IRealm } from "./realm";
import type { SlackCredentials } from "./slack";
import type { ISource } from "./sources";
import type { IUser } from "./user";

export interface IOrg {
  id: string;
  slug: string;
  name: string;
  isDeleted: boolean;
  client_secret: string;
  status: IOrgStatus;
  createdBy: IUser;
  logo?: string;
  destinations: IDestination[];
  slackCredentials?: SlackCredentials;
  freeTrialEndDate?: string;
  paymentLink?: string;
  realm?: IRealm;
  featureGrants: {
    freeTrialStartedAt?: string;
    freeTrialEndAt?: string;
    feature: IOrgFeature;
  }[];
  defaultPaletteCollection?: string;
  paletteCollections: IPaletteCollection[];
  groups: IGroup[];
  userAttributeMetas: IUserAttributeMeta[];
  accountType: IOrgAccountType;
  emailTemplate: string;
  emailTemplateMjml: string;
}

export enum IOrgFeatureType {
  OLD_WORKBENCH = "old-workbench",
  GRANULAR_REPORT_FOLDER_SHARING = "perm-granularReportFolderSharingControl",
  PERSISTENCE_ENGINE_FEATURE_API_NAME = "perm-persistenceEngine",
  MODELS_SYNC_FEATURE_API_NAME = "perm-modelsSync",
  PARTNER_PORTAL_SYNC_FEATURE_API_NAME = "perm-partnerPortal",
  SERVING_LAYER_API_NAME = "perm-servingLayer",
  OBJECTS = "perm-objects",
  RCA_DEMO_DATA = "tmp-rca",
  FILE_UPLOADS_API_NAME = "perm-file-uploads",
  DOCUMENTS = "perm-documents",
}

export const freetrialFeatures = [
  IOrgFeatureType.GRANULAR_REPORT_FOLDER_SHARING,
  IOrgFeatureType.PERSISTENCE_ENGINE_FEATURE_API_NAME,
  IOrgFeatureType.MODELS_SYNC_FEATURE_API_NAME,
  IOrgFeatureType.PARTNER_PORTAL_SYNC_FEATURE_API_NAME,
];

interface IOrgFeature {
  apiName: IOrgFeatureType;
}

export type IOrgStatus =
  | "onboarding"
  | "freetrial"
  | "poc"
  | "full"
  | "defaultpayment";

export type IOrgAccountType = "partner" | "customer" | "internal";

export interface IOrgWithSources extends IOrg {
  sources: ISource[];
}

export interface IPaletteCollection {
  id: string;
  name: string;
  slug: string;
  system?: boolean;
  sequential: IPalette[];
  diverging: IPalette[];
  categorical: IPalette[];
}

export interface IUserAttributeMeta {
  id: string;
  technicalName: string;
  label: string;
  defaultValue?: string;
  options?: string;
  type: IUserAttributeMetaType;
  allowMultipleValues?: boolean;
}

export interface IParsedUserAttributeMeta {
  id: string;
  technicalName: string;
  label: string;
  defaultValue?: string[];
  options?: Array<{ value: string; label: string }>;
  type: IUserAttributeMetaType;
  allowMultipleValues?: boolean;
}

export interface IPartnerPortal {
  id: string;
  name: string;
  primaryColor: string;
  logo: string;
  slug: string;
  defaultGroup: IGroup;
  defaultHomeCover?: string;
  logoutUrl: string;
}

export type IUserAttributeMetaType = "STRING" | "NUMERIC" | "TIME" | "BOOLEAN";
