import ApiService from "./ApiService";

export const getJSONFile = <T>(fileUri: string): Promise<T> => {
  return ApiService.getRequest(
    `/v1/data?uri=${fileUri}`,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const getBlobFile = (fileUri: string): Promise<Blob> => {
  return ApiService.getRequest(
    `/v1/data?uri=${fileUri}`,
    {},
    {},
    { authenticated: true, fileServiceUrl: true, asBlob: true }
  );
};

export const updateFile = (fileUri: string, body: FormData): Promise<any> => {
  return ApiService.putRequest(
    `/v1/data?uri=${fileUri}`,
    body,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const testObjectStorage = (
  objectStorageId: string,
  body: any
): Promise<any> => {
  return ApiService.postRequest(
    `/v1/object-storage/${objectStorageId}/test`,
    body,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const uploadFileJobTrigger = (
  fileUploaderId: string,
  body: FormData
): Promise<any> => {
  return ApiService.postRequest(
    `/v1/file-upload-jobs/${fileUploaderId}/upload`,
    body,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const getFileUploadDownloadLink = (
  fileUploaderId: string,
  filePath: string
): Promise<{
  status: "ok";
  data: {
    token: string;
  };
}> => {
  return ApiService.postRequest(
    `/v1/file-upload-jobs/${fileUploaderId}/download`,
    {
      filePath,
    },
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const uploadFileForIndex = (
  orgId: string,
  body: FormData
): Promise<{
  status: "ok";
  data: {
    originalFileName: string;
    originalFileSize: string;
    orginalFilePath: string;
    fileUri: string;
    results: {
      [key: string]: string;
    };
  };
}> => {
  return ApiService.postRequest(
    `/v1/org/${orgId}/file-indexers/upload`,
    body,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const getFileIndexerDownloadLink = (
  orgId: string,
  filePath: string
): Promise<{
  status: "ok";
  data: {
    token: string;
  };
}> => {
  return ApiService.postRequest(
    `/v1/org/${orgId}/file-indexers/download`,
    {
      filePath,
    },
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const getObjectStorageDownloadLink = (
  objectStorageId: string,
  filePath: string
): Promise<{
  status: "ok";
  data: {
    token: string;
  };
}> => {
  return ApiService.postRequest(
    `/v1/object-storages/${objectStorageId}/download`,
    {
      filePath,
    },
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const getWarehouseDownloadLink = (
  warehouseId: string,
  filePath: string
): Promise<{
  status: "ok";
  data: {
    token: string;
  };
}> => {
  return ApiService.postRequest(
    `/v1/warehouses/${warehouseId}/download`,
    {
      filePath,
    },
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const generateDownloadlink = (
  resource: "warehouses" | "file-upload-jobs" | "object-storages",
  resourceId: string,
  token: string
) => {
  return `${window.WHALY_CONSTANTS.FILESERVICE_URL}/v1/${resource}/${resourceId}/download/${token}`;
};
