import { gql } from "@apollo/client";
import type { IDestination } from "../../../../../../../interfaces/destinations";
import type { IExploration } from "../../../../../../../interfaces/explorations";
import type { IModelFolder } from "../../../../../../../interfaces/modelFolder";
import type { IObject } from "../../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../../interfaces/org";
import type { IDataset } from "../../../../../../../interfaces/sources";
import { makeRecordIterator } from "../../../../../../../services/graphql/PaginatedGQLService";
import {
  DATASET_FRAGMENT,
  EXPLORATION_ITEM_FRAGMENT,
} from "../../../../../../exploration/single/domain";
import { MODEL_FOLDER_FRAGMENT } from "../../../../../single/domain";
import { OBJECT_COMPLETE_FRAGMENT } from "../../../object/domain";
import type { IClonedWorkbench } from "./domain";

export const downloadWorkbench = async (org: IOrg, warehouse: IDestination) => {
  const foldersIterator = await makeRecordIterator<IModelFolder>(
    `
  ${MODEL_FOLDER_FRAGMENT}
  query getModelFolders($orgId: ID!, $warehouseId: ID!, $pageSize: Int, $offset: Int) {
    allModelFolders(where: { org: { id: $orgId }, isDeleted_not: true, warehouse: { id: $warehouseId } }, first: $pageSize, skip: $offset) {
      ...ModelFolderQuery
    }
  }
    `,
    {
      orgId: org.id,
      warehouseId: warehouse.id,
    },
    "allModelFolders"
  );

  const modelFolders: Array<IModelFolder> = [];
  for await (const r of foldersIterator) {
    modelFolders.push(r);
  }

  const datasetIterator = await makeRecordIterator<IDataset>(
    `
  ${DATASET_FRAGMENT}
  query getDatasets($orgId: ID!, $warehouseId: ID!, $pageSize: Int, $offset: Int) {
    allDatasets(where: { org: { id: $orgId }, deleted_not: true, warehouse: { id: $warehouseId } }, first: $pageSize, skip: $offset) {
      ...DatasetQuery
    }
  }
    `,
    {
      orgId: org.id,
      warehouseId: warehouse.id,
    },
    "allDatasets"
  );

  const datasets: Array<IDataset> = [];
  for await (const r of datasetIterator) {
    datasets.push(r);
  }

  const objectIterator = await makeRecordIterator<IObject>(
    gql`
      ${OBJECT_COMPLETE_FRAGMENT}

      query getObjects($orgId: ID!, $pageSize: Int, $offset: Int) {
        allObjects(
          where: { org: { id: $orgId } }
          first: $pageSize
          skip: $offset
        ) {
          ...ObjectQuery
        }
      }
    `,
    {
      orgId: org.id,
      warehouseId: warehouse.id,
    },
    "allObjects"
  );

  const objects: Array<IObject> = [];
  for await (const r of objectIterator) {
    objects.push(r);
  }

  const explorationsIterator = await makeRecordIterator<IExploration>(
    `
  ${EXPLORATION_ITEM_FRAGMENT}
  
  query getExplorations($orgId: ID!, $warehouseId: ID!, $pageSize: Int, $offset: Int) {
    allExplorations(where: { org: { id: $orgId }, warehouse: { id: $warehouseId } }, first: $pageSize, skip: $offset) {
      ...Exploration
    }
  }
    `,
    {
      orgId: org.id,
      warehouseId: warehouse.id,
    },
    "allExplorations"
  );

  const explorations: Array<IExploration> = [];
  for await (const r of explorationsIterator) {
    explorations.push(r);
  }

  const clonedWorkbench: IClonedWorkbench = {
    objects,
    explorations,
    datasets,
    modelFolders,
  };

  const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(clonedWorkbench));
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute(
    "download",
    org.name + " - " + warehouse.name + ".json"
  );
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};
