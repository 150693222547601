import { Button, Dropdown, Input, Space, theme } from "antd";
import React from "react";
import type { AvailableProperty } from "../../containers/v2-demo/container/object/viewer/domain";
import type { AvailableMetric } from "../measures/filter-item/FilterItem";
import { DraggableMeasureItem } from "../measures/measure-item/DraggableMeasureItem";
import { DroppableMeasureZone } from "../measures/measure-picker/DroppableMeasureZone";

interface IColumnsAdditionProps {
  value?: string[];
  onChange?: (v: string[]) => void;
  availableColumns: Array<
    | { type: "metric"; data: AvailableMetric }
    | { type: "property"; data: AvailableProperty }
  >;
  max?: number;
}
const { useToken } = theme;

export function ColumnsAddition(props: IColumnsAdditionProps) {
  const {
    value,
    onChange,
    availableColumns: availableColumnsProps,
    max,
  } = props;

  const [search, setSearch] = React.useState<string>();

  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };

  const availableColumns = availableColumnsProps.map((ac) => ({
    ...ac,
    data: {
      ...ac.data,
      key: ac.data.key,
    },
  }));
  const columns = value || [];

  const disabled = typeof max === "number" && columns?.length >= max;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <DroppableMeasureZone
        accept={["metric", "property"]}
        zone="measure_store"
        availableMeasures={availableColumns.map((c) => ({
          key: c.data.key,
          label: c.data.label,
        }))}
        onAddMeasure={() => ({})}
      >
        {columns.map((c, i) => {
          const found = availableColumns.find((a) => a.data.key === c);

          return (
            <DraggableMeasureItem
              id={c}
              index={i}
              onDrop={(item, position) => {
                const newValue = [...columns.filter((v) => v !== item.id)];
                let dropIndex = newValue.length;
                if (position != null) dropIndex = position;
                newValue.splice(dropIndex, 0, item.id);
                onChange?.(newValue);
              }}
              zone="measure_store"
              key={c}
              type={found?.type ? found.type : "property"}
              name={found?.data?.label ? found.data.label : c}
              error={!found ? ["not found"] : undefined}
              onDelete={() => onChange?.(columns.filter((a) => a !== c))}
            />
          );
        })}
      </DroppableMeasureZone>
      <Dropdown
        disabled={disabled}
        menu={{
          items: availableColumns
            .filter((c) => !columns.includes(c.data.key))
            .map((oc) => ({
              key: oc.data.key,
              label: oc.data.label,
              onClick: () => {
                onChange?.([...columns, oc.data.key]);
              },
            }))
            .filter((oc) => {
              if (
                search &&
                oc.label
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              ) {
                return true;
              }
              if (!search) {
                return true;
              }
              return false;
            }),
        }}
        trigger={["click"]}
        placement={"left" as any}
        arrow={true}
        onOpenChange={() => setSearch("")}
        dropdownRender={(menu) => {
          return (
            <div
              style={{
                ...contentStyle,
                maxWidth: 450,

                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ flex: 0, padding: `4px 12px` }}>
                <Input onChange={(e) => setSearch(e.target.value)} />
              </div>
              <div
                style={{
                  flex: 1,
                  maxHeight: 300,
                  overflowX: "hidden",
                  overflowY: "auto",
                  height: 300,
                  padding: 4,
                }}
              >
                {React.cloneElement(menu as React.ReactElement, {
                  style: menuStyle,
                })}
              </div>
            </div>
          );
        }}
      >
        <Button size="small" block disabled={disabled}>
          Add
        </Button>
      </Dropdown>
    </Space>
  );
}
