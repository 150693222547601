import * as Sentry from "@sentry/react";
import type MobxReactRouter from "mobx-react-router";
import { Component } from "react";
import {
  Redirect,
  Route,
  Router as RouterImport,
  Switch,
} from "react-router-dom";
import error404 from "../../assets/404_text.svg";
import { compose } from "../../components/compose/WlyCompose";
import ErrorFeedback from "../../components/layout/feedback/error";
import Feednack from "../../components/layout/feedback/feedback";
import type { RouteDefinition } from "../../routes/domain";
import routes from "../../routes/routes";
import { routesComponents } from "../../routes/routesComponents";
import { track } from "../../services/AnalyticsService";
import "../../styles/main.scss";
import LayoutManager from "../layout/LayoutManager";
import SharedLogin from "../login/SharedLogin";
import "./App.scss";
import { AuthenticatedRouteRenderer } from "./AuthenticatedRouteRenderer";

interface IAppProps {
  history: MobxReactRouter.SynchronizedHistory;
}

// added that because types are not matching we should update react router to the v6 version but I am lazy right now
const Router = RouterImport as any;

interface IState {
  error?: any;
}

type Props = IAppProps;

class App extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public componentDidCatch(err: Error) {
    this.setState({ error: err });
    Sentry.captureException(err);
  }

  // eslint-disable-next-line react/display-name
  public renderAuthenticatedRoute = (route: RouteDefinition) => () => {
    return (
      <AuthenticatedRouteRenderer route={route} error={this.state.error} />
    );
  };

  // eslint-disable-next-line react/display-name
  public renderPublicRoute = (route: RouteDefinition) => () => {
    const Component = routesComponents[route.component];
    if (this.state.error) {
      return <ErrorFeedback err={this.state.error} />;
    }
    return <Component {...route.props} />;
  };

  // eslint-disable-next-line react/display-name
  public renderSharedRoute = (route: RouteDefinition) => () => {
    if (this.state.error) {
      track("Error", {
        title: route.title,
        path: window.location.pathname,
      });
      return <ErrorFeedback err={this.state.error} />;
    }
    return (
      <SharedLogin>
        <LayoutManager
          component={routesComponents[route.component]}
          layout={route.layout}
          additionalProps={route.props}
          hideOnboarding={true}
        />
      </SharedLogin>
    );
  };

  renderRoute = (route: RouteDefinition) => {
    if (route.public === "PUBLIC" || route.public === "SHARING_LINK") {
      return this.renderPublicRoute(route);
    } else if (route.public === "SHARED") {
      return this.renderSharedRoute(route);
    } else {
      return this.renderAuthenticatedRoute(route);
    }
  };

  public describeRoutes = () => {
    return routes.map((route) => {
      return (
        <Route
          key={0} // shared key to reuse layout and avoid remounting components on route change
          exact={true}
          path={route.path}
          render={this.renderRoute(route)}
        />
      );
    });
  };

  public notFound = () => {
    track("Not Found");
    return (
      <Feednack>
        <img src={error404} alt={"404"} style={{ height: "60%" }} />
        <br />
      </Feednack>
    );
  };

  public render() {
    return (
      <Router history={this.props.history}>
        <Switch>
          {this.describeRoutes()}
          {/* This is a temporary redirect to make sure V2 routes are not leading to a 404 */}
          <Route
            path={"/:organizationSlug/v2/*"}
            render={() => <Redirect to={"/"} />}
          />
          <Route render={this.notFound} />
        </Switch>
      </Router>
    );
  }
}

export default compose<Props, IAppProps>()(App);
