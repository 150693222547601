export enum CacheStrategy {
  CACHE_ONLY = "cache-only",
  NETWORK_ONLY = "network-only",
  CACHE_FIRST = "cache-first",
  NETWORK_FIRST = "network-first",
  SWR = "swr",
}
export const DEFAULT_CACHE_STRATEGY = CacheStrategy.NETWORK_ONLY;

export const CACHE_FILTERED_KEYS = ["rerender"];

export const getKeyvalPairs = (
  obj: Record<PropertyKey, unknown>,
  prefix: string = ""
): [string, any][] => {
  return Object.entries(obj).reduce((acc, [key, value], i) => {
    const newKey = prefix ? `${prefix}:${key}` : key;

    // Handle null and undefined
    if (value === null) return [...acc, [newKey, "null"]];
    if (value === undefined) return [...acc, [newKey, "undefined"]];

    // Handle Date objects
    if (value instanceof Date) {
      return [...acc, [newKey, value.toISOString()]];
    }

    // Handle arrays
    if (Array.isArray(value)) {
      return [...acc, [newKey, JSON.stringify(value)]];
    }

    // Handle objects
    if (typeof value === "object") {
      try {
        return [
          ...acc,
          ...getKeyvalPairs(value as Record<PropertyKey, unknown>, newKey),
        ];
      } catch {
        return [...acc, [newKey, `unhandledObj-${i}`]];
      }
    }

    return [...acc, [newKey, value.toString()]];
  }, []);
};

export const getKeyvalStringFromObject = (
  obj: Record<PropertyKey, unknown>,
  prefix: string = ""
): string =>
  getKeyvalPairs(obj, prefix)
    .filter(([key]) => CACHE_FILTERED_KEYS.includes(key) === false)
    .map(([key, value]) => `${key}:${value}`)
    .join("|");
