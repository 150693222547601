import { useEffect, useMemo, useState } from "react";
import { IDBService } from "../../services/idbService";

export const useIsCached = (search: Record<PropertyKey, string>) => {
  const [isCached, setIsCached] = useState(false);

  const searchStrings = useMemo(
    () => Object.entries(search).map(([key, value]) => `${key}:${value}`),
    [search]
  );

  useEffect(() => {
    let mounted = true;

    (async () => {
      const hasInCache = await IDBService.hasInCache(searchStrings);
      mounted && setIsCached(hasInCache);
    })();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isCached;
};
