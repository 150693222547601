import { useEffect, useMemo, useState } from "react";
import { useNewCacheEvent } from "../../services/broadcastService";
import { IDBService } from "../../services/idbService";

export interface CachedRecord {
  isCached: boolean;
  updatedAt: Date | undefined;
}

export const useCachedRecord = (
  recordId: string,
  objectId: string,
  objectSlug: string,
  layoutId?: string
) => {
  const [cachedRecord, setCachedRecord] = useState<CachedRecord>({
    updatedAt: undefined,
    isCached: false,
  });

  const key = useMemo(
    () =>
      layoutId
        ? `recordId:${recordId}|objectId:${objectId}|objectSlug:${objectSlug}|layoutId:${layoutId}`
        : `recordId:${recordId}|objectId:${objectId}|objectSlug:${objectSlug}`,
    [layoutId, objectId, objectSlug, recordId]
  );

  useEffect(() => {
    let mounted = true;

    (async () => {
      const cachedRecord = await IDBService.getCachedRecord(key);
      mounted && setCachedRecord(cachedRecord);
    })();

    return () => {
      mounted = false;
    };
  }, [key]);

  useNewCacheEvent((idbKey, updatedAt) => {
    idbKey.includes(key) && setCachedRecord({ isCached: true, updatedAt });
  });

  return cachedRecord;
};
