import * as Sentry from "@sentry/react";
import { Button, Flex, Typography } from "antd";
import { useEffect, useState } from "react";
import { useAsync, useSearchParam } from "react-use";
import { useRegisterSW } from "virtual:pwa-register/react";
import type { InjectedAntUtilsProps } from "../components/ant-utils/withAntUtils";
import { withAntUtils } from "../components/ant-utils/withAntUtils";
import { compose } from "../components/compose/WlyCompose";
import { HANDLED_TIMEOUT_ERR } from "../components/contexts/SlowNetworkProvider";
import { useIsOnline } from "../components/hooks/useIsOnline";
import { activeTracking } from "../services/AnalyticsService";
import { getIdbSize } from "../utils/getIdbSize";
import { MINUTE, SECOND } from "../utils/timeConstants";

const PING_RESOURCE = "/version.txt";
const PERIOD = 1 * MINUTE;
const NETWORK_ANT_KEY = "network";
const REFRESH_ANT_KEY = "refresh";

interface IOnlineStatusProviderProps {
  children?: React.ReactNode;
}

type Props = IOnlineStatusProviderProps & InjectedAntUtilsProps;

const IGNORED_ERROR_MESSAGES = [HANDLED_TIMEOUT_ERR];

const bootSentry = (currentVersion: string) => {
  if (activeTracking) {
    Sentry.init({
      dsn: "https://7432521cfb89431da3fb5c4303c38c40@o1143842.ingest.sentry.io/6204897",
      integrations: [new Sentry.BrowserTracing(), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      environment: "production",
      normalizeDepth: 0,
      release: currentVersion,
      ignoreErrors: IGNORED_ERROR_MESSAGES,
      beforeSend: (event) => {
        if (event.message && IGNORED_ERROR_MESSAGES.includes(event.message)) {
          return null;
        } else {
          return event;
        }
      },
    });
  }
};

const OnlineStatusProvider = ({ children, antUtils }: Props) => {
  const isOnline = useIsOnline();
  const swUpdate = useSearchParam("sw-update");
  const [waitingWorkerTimeout, setWaitingWorkerTimeout] = useState(true);

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(_, r) {
      if (r) {
        const { update, installing, waiting } = r;
        const nextSw = installing ?? waiting;

        if (nextSw) {
          setTimeout(() => {
            if (nextSw.state !== "activated" && nextSw.state !== "activating") {
              nextSw.postMessage({ type: "SKIP_WAITING" });
              !swUpdate &&
                window.location.replace(
                  window.location.href + "?sw-update=true"
                );
            } else {
              setWaitingWorkerTimeout(false);
            }
          }, 2 * SECOND);
        } else {
          setWaitingWorkerTimeout(false);
        }

        setInterval(() => {
          if (r) {
            r.update();
          } else {
            Sentry.captureMessage("Lost ServiceWorkerRegistration", {
              level: "warning",
            });
          }
        }, PERIOD);
      } else {
        setWaitingWorkerTimeout(false);
      }
    },
    onRegisterError(error) {
      console.error("SW registration error", error);
    },
  });

  useAsync(async () => {
    const idbSize = await getIdbSize();
    const sizeInMegabytes = idbSize / 1024 / 1024;

    if (sizeInMegabytes > 1000) {
      antUtils.message.warning({
        content: `Your cache has exceeded 1GB`,
        key: "idb-size",
        duration: 0,
      });
    }
  }, []);

  useAsync(async () => {
    const currentVersion = await fetch(PING_RESOURCE, {
      method: "GET",
    }).then((r) => r.text());

    bootSentry(currentVersion);
  }, []);

  useEffect(() => {
    if (needRefresh) {
      antUtils.message.warning({
        content: (
          <Flex gap="small" align="center" style={{ display: "inline-flex" }}>
            <Typography.Text>
              A new version of the app is available
            </Typography.Text>
            <Button
              size="small"
              loading={waitingWorkerTimeout}
              onClick={() => updateServiceWorker(true)}
            >
              Reload
            </Button>
          </Flex>
        ),
        key: REFRESH_ANT_KEY,
        duration: 0,
      });
    }
  }, [
    antUtils.message,
    needRefresh,
    updateServiceWorker,
    waitingWorkerTimeout,
  ]);

  useEffect(() => {
    if (!isOnline) {
      antUtils.message.warning({
        content: "You don't seem connected to the internet...",
        key: NETWORK_ANT_KEY,
        duration: 0,
      });
    } else {
      antUtils.message.destroy(NETWORK_ANT_KEY);
    }
  }, [antUtils.message, isOnline]);

  return <>{children}</>;
};

export default compose<Props, IOnlineStatusProviderProps>(withAntUtils)(
  OnlineStatusProvider
);
