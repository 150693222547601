import { useCallback, useMemo } from "react";
import { useEvent } from "react-use";

// PRIVATE

enum BroadcastType {
  LOGOUT = "LOGOUT",
  NEW_CACHE = "NEW_CACHE",
}

type BroadcastMessage =
  | {
      type: BroadcastType.LOGOUT;
    }
  | {
      type: BroadcastType.NEW_CACHE;
      idbKey: string;
      updatedAt: Date;
    };

const getBroadcastChannel = () => new BroadcastChannel("whaly");

const postMessage = (channel: BroadcastChannel, message: BroadcastMessage) => {
  channel.postMessage({ ...message });
};

// PUBLIC

const sendLogoutEvent = () => {
  const channel = getBroadcastChannel();
  postMessage(channel, { type: BroadcastType.LOGOUT });
};

const sendNewCacheEvent = (idbKey: string, updatedAt: Date) => {
  const channel = getBroadcastChannel();
  postMessage(channel, { type: BroadcastType.NEW_CACHE, idbKey, updatedAt });
};

export const broadcastService = {
  sendLogoutEvent,
  sendNewCacheEvent,
};

// HOOKS

export const useLogoutEvent = (callback: () => void) => {
  const channel = useMemo(() => getBroadcastChannel(), []);

  const internalCallback = useCallback(
    (event: MessageEvent<BroadcastMessage>) => {
      if (event.data.type === BroadcastType.LOGOUT) {
        callback();
      }
    },
    [callback]
  );

  useEvent("message", internalCallback, channel);
};

export const useNewCacheEvent = (
  callback: (idbKey: string, updatedAt: Date) => void
) => {
  const channel = useMemo(() => getBroadcastChannel(), []);

  const internalCallback = useCallback(
    (event: MessageEvent<BroadcastMessage>) => {
      if (event.data.type === BroadcastType.NEW_CACHE) {
        callback(event.data.idbKey, event.data.updatedAt);
      }
    },
    [callback]
  );

  useEvent("message", internalCallback, channel);
};
