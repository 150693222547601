import { useEffect, useMemo } from "react";
import { create } from "zustand";
import { IDBService } from "../services/idbService";

export interface WidgetCacheInfo {
  hasFinished: boolean;
  hasError: boolean;
}

export type WidgetCacheProps = {
  widgetId: string;
  objectId: string;
  layoutId: string;
  recordId: string;
};

interface WidgetCacheStoreState {
  widgetCacheInfos: Record<string, WidgetCacheInfo | undefined>;
  hasInit: boolean;
  init: () => Promise<void>;
  addBaseWidgetCacheInfo: (idbKey: string) => void;
  setAsFinished: (idbKey: string, withError: boolean) => Promise<void>;
}

const DEFAULT_WIDGET_CACHE_INFO: WidgetCacheInfo = {
  hasFinished: false,
  hasError: false,
};

const generateIdbKey = (
  widgetId: string,
  objectId: string,
  layoutId: string,
  recordId: string
) => {
  return `widgetId:${widgetId}|objectId:${objectId}|layoutId:${layoutId}|recordId:${recordId}`;
};

const shouldPersist = (oldInfo: WidgetCacheInfo, newInfo: WidgetCacheInfo) => {
  const willSetAsFinished = newInfo.hasFinished && !oldInfo.hasFinished;
  const willSetErrorAsResolved = oldInfo.hasError && !newInfo.hasError;
  const bothFinished = oldInfo.hasFinished && newInfo.hasFinished;

  return willSetAsFinished || (bothFinished && willSetErrorAsResolved);
};

const useWidgetCacheStore = create<WidgetCacheStoreState>()((set, get) => ({
  widgetCacheInfos: {},
  hasInit: false,
  init: async () => {
    if (!get().hasInit) {
      try {
        const idbWidgetCacheEntries = Object.entries(
          await IDBService.getWidgetCacheInfosFromIDB()
        );

        const widgetCacheInfos = idbWidgetCacheEntries.reduce(
          (acc, [key, widgetCacheInfo]) => {
            acc[key] = widgetCacheInfo;
            return acc;
          },
          {} as Record<string, WidgetCacheInfo | undefined>
        );

        set({ widgetCacheInfos, hasInit: true });
      } catch (error) {
        set({ hasInit: false });
        console.error("Error while initializing widget cache store", error);
      }
    }
  },
  addBaseWidgetCacheInfo: (idbKey) => {
    set((state) => ({
      widgetCacheInfos: {
        ...state.widgetCacheInfos,
        [idbKey]: DEFAULT_WIDGET_CACHE_INFO,
      },
    }));
  },
  setAsFinished: async (idbKey, withError) => {
    let persistInIDB = false;

    const newWidgetCacheInfo = {
      hasFinished: true,
      hasError: withError,
    };

    set((state) => {
      const widgetCacheInfo = state.widgetCacheInfos[idbKey];

      if (!widgetCacheInfo) {
        console.error(`Widget cache info not found for key: ${idbKey}`);
        return state;
      }

      persistInIDB = shouldPersist(widgetCacheInfo, newWidgetCacheInfo);

      return persistInIDB
        ? {
            widgetCacheInfos: {
              ...state.widgetCacheInfos,
              [idbKey]: newWidgetCacheInfo,
            },
          }
        : state;
    });

    persistInIDB &&
      (await IDBService.setCachedWidget(idbKey, newWidgetCacheInfo));
  },
}));

export const useWidgetCache = ({
  widgetId,
  objectId,
  layoutId,
  recordId,
}: WidgetCacheProps) => {
  const idbKey = useMemo(
    () => generateIdbKey(widgetId, objectId, layoutId, recordId),
    [layoutId, objectId, widgetId, recordId]
  );

  const addBaseWidgetCacheInfo = useWidgetCacheStore(
    (state) => state.addBaseWidgetCacheInfo
  );
  const widgetCacheInfo = useWidgetCacheStore(
    (state) => state.widgetCacheInfos[idbKey]
  );

  useEffect(() => {
    if (!widgetCacheInfo) {
      addBaseWidgetCacheInfo(idbKey);
    }
  }, [addBaseWidgetCacheInfo, idbKey, widgetCacheInfo]);

  return widgetCacheInfo;
};

export const useWidgetCacheList = (recordId: string, layoutId: string) => {
  const widgetCacheInfos = useWidgetCacheStore(
    (state) => state.widgetCacheInfos
  );

  return useMemo(
    () =>
      Object.entries(widgetCacheInfos)
        .filter(
          ([key]) =>
            key.includes(`layoutId:${layoutId}`) &&
            key.includes(`recordId:${recordId}`)
        )
        .map(([, widgetCacheInfo]) => widgetCacheInfo)
        .filter((w) => !!w),
    [layoutId, recordId, widgetCacheInfos]
  );
};

export const useWidgetCacheIsInit = () => {
  return useWidgetCacheStore((state) => state.hasInit);
};

export const useWidgetCacheInitAction = () => {
  const init = useWidgetCacheStore((state) => state.init);

  return init;
};

export const useWidgetCacheActions = ({
  widgetId,
  objectId,
  layoutId,
  recordId,
}: WidgetCacheProps) => {
  const idbKey = useMemo(
    () => generateIdbKey(widgetId, objectId, layoutId, recordId),
    [layoutId, objectId, widgetId, recordId]
  );

  const setAsFinished = useWidgetCacheStore((state) => state.setAsFinished);

  const actions = useMemo(
    () => ({
      setAsFinished: (withError?: boolean) =>
        setAsFinished(idbKey, !!withError),
    }),
    [idbKey, setAsFinished]
  );

  return actions;
};
