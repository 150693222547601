import { Badge, Divider, Tooltip, type BadgeProps } from "antd";
import classNames from "classnames";
import type { HTMLAttributes, Key, ReactNode } from "react";
import React from "react";
import { Breakpoints, useBreakpoint } from "../hooks/useBreakpoint";
import "./LeftMenu.scss";

export interface ILeftMenuItem {
  hidden?: boolean;
  key: Key;
  label: string;
  icon: ReactNode;
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
  hasDivider?: boolean;
  badge?: { status?: BadgeProps["status"]; dot?: BadgeProps["dot"] };
}

interface ILeftMenuProps extends HTMLAttributes<HTMLDivElement> {
  items: ILeftMenuItem[];
  bottomItems?: ILeftMenuItem[];
  bottomMenuOnMobile?: boolean;
}

export const LeftMenu = ({
  items,
  bottomItems,
  bottomMenuOnMobile = false,
  ...rest
}: ILeftMenuProps) => {
  const isMobile = useBreakpoint(Breakpoints.SM);
  const tooltipPlacement = isMobile && bottomMenuOnMobile ? "top" : "right";

  const generateItem = ({
    key,
    label,
    icon,
    active,
    disabled,
    onClick,
    hasDivider,
    badge,
  }: ILeftMenuItem) => (
    <React.Fragment key={key}>
      {hasDivider && (
        <Divider
          className={
            bottomMenuOnMobile
              ? "divider display-none display-block-sm"
              : "divider"
          }
        />
      )}
      <Badge status={badge?.status} dot={badge?.dot} offset={[-14, 14]}>
        <TabItem
          active={active}
          onClick={disabled ? () => {} : onClick}
          disabled={disabled}
        >
          <Tooltip placement={tooltipPlacement} title={label}>
            {icon}
          </Tooltip>
        </TabItem>
      </Badge>
    </React.Fragment>
  );

  return (
    <div
      className={
        bottomMenuOnMobile ? "left-menu bottom-menu-on-mobile" : "left-menu"
      }
      {...rest}
    >
      {items.filter(({ hidden }) => !hidden).map(generateItem)}
      {bottomItems && <div style={{ flex: 1 }} />}
      {bottomItems?.filter(({ hidden }) => !hidden).map(generateItem)}
    </div>
  );
};

interface ITabItemProps extends React.HTMLProps<HTMLDivElement> {
  active?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export function TabItem({
  active = false,
  disabled = false,
  ...rest
}: ITabItemProps) {
  return (
    <div
      className={classNames(
        "tab-item",
        active && "active",
        disabled && "disabled"
      )}
      {...rest}
    >
      {rest.children}
    </div>
  );
}
