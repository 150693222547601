import { Skeleton } from "antd";
import type { IRendererConfig } from "../domain";
import type { IWidgetMarkdownConfig } from "./domain";
import { MarkdownWidgetEditor } from "./editor/MarkdownWidgetEditor";
import MarkdownWidget from "./widget/MarkdownWidget";

export const widgetMarkdownDefinition: IRendererConfig<IWidgetMarkdownConfig> =
  {
    removeCard: false,
    allowCardRemoval: true,
    parseConfig: (widget) => {
      const defaultValue: IWidgetMarkdownConfig = {
        text: "",
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IWidgetMarkdownConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, form, org, datasets) => {
      return (
        <MarkdownWidgetEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          form={form}
          datasets={datasets}
        />
      );
    },
    renderComponent: (
      widget,
      object,
      record,
      layoutId,
      recordId,
      conf,
      edit
    ) => {
      return (
        <MarkdownWidget
          widget={widget}
          object={object}
          record={record}
          layoutId={layoutId}
          recordId={recordId}
          conf={conf}
          edit={edit}
        />
      );
    },
    renderLoadingComponent: (widget, conf) => <Skeleton />,
  };
