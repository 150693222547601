import { Skeleton, Space } from "antd";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import WithOrg, { type InjectedOrgProps } from "../../orgs/WithOrg";
import type { IClassifierJob, IIndexedFile } from "../domain";
import { FileIndexerLoadedList } from "./FileIndexerLoadedList";

export interface IFileIndexerListProps {
  data: AsyncData<{ files: IIndexedFile[]; classifierJobs: IClassifierJob[] }>;
  onSelect: (id: string) => void;
  onUpload?: (data: IIndexedFile) => void;
  selectedId?: string;
}

type Props = InjectedOrgProps & IFileIndexerListProps;

function FileIndexerList(props: Props) {
  const { data, onSelect, selectedId, org, onUpload } = props;

  if (data.status === "initial" || data.status === "loading") {
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        {Array(10)
          .fill((_, i) => i)
          .map((_, i) => {
            return (
              <Skeleton.Button
                key={i}
                block
                active
                shape="round"
                size="small"
              />
            );
          })}
      </Space>
    );
  }
  if (data.status === "error") {
    return <Feednack>There was an error fetching your data</Feednack>;
  }

  return (
    <FileIndexerLoadedList
      data={data.data}
      onSelect={onSelect}
      selectedId={selectedId}
    />
  );
}

export default compose<Props, IFileIndexerListProps>(WithOrg)(FileIndexerList);
