import { Form } from "antd";
import ChartOptionLine from "../../../../../../chart-options/components/ChartOptionLine";
import type { AvailableColumn } from "../../../../object/domain";
import PropertySort from "../../../../object/viewer/toolbar/PropertiesSelector/PropertySortSelector";

type SortSelectorProps = {
  availableColumns: AvailableColumn[];
  label: string;
  name: Array<string | number>;
};

export const SortSelector = ({
  availableColumns,
  label,
  name,
}: SortSelectorProps) => {
  return (
    <ChartOptionLine
      items={[
        {
          content: (
            <Form.Item style={{ marginBottom: 4 }} label={label} name={name}>
              <PropertySort columns={availableColumns} />
            </Form.Item>
          ),
          flex: 1,
        },
      ]}
    />
  );
};
