import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer, Flex, Typography } from "antd";
import { inject, observer } from "mobx-react";
import { createPortal } from "react-dom";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import type { WorkbenchUIStoreProps } from "../../../../../../../store/workbenchUIStore";
import { grey } from "../../../../../../../utils/colorPalette";

type AdditionalSiderProps = {
  children: React.ReactNode;
  open: boolean;
  title: string;
  onClose: () => void;
};

type Props = AdditionalSiderProps & WorkbenchUIStoreProps;

const Comp = ({
  children,
  open,
  title,
  onClose,
  workbenchUIStore: { objectEditorContainerDomNode },
}: Props) => {
  return (
    <>
      {objectEditorContainerDomNode &&
        createPortal(
          <Drawer
            open={open}
            onClose={onClose}
            closable={false}
            getContainer={false}
            styles={{
              body: { padding: 0, background: grey[1] },
              mask: { background: "transparent" },
            }}
            width={250}
          >
            <Flex vertical>
              <Flex
                justify="space-between"
                align="center"
                style={{ padding: 12 }}
              >
                <Typography.Text strong>{title}</Typography.Text>
                <Button
                  type="text"
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={onClose}
                />
              </Flex>
              <div style={{ padding: "0px 16px" }}>{children}</div>
            </Flex>
          </Drawer>,
          objectEditorContainerDomNode
        )}
    </>
  );
};

export const AdditionalSider = compose<Props, AdditionalSiderProps>(
  inject("workbenchUIStore"),
  observer
)(Comp);
