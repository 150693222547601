import { Skeleton } from "antd";
import type { IRendererConfig } from "../domain";
import { WidgetMapEditor } from "./WidgetMapEditor";
import WidgetMapRenderer from "./WidgetMapRenderer";
import type { IWidgetMapConfig } from "./domain";

export const widgetMapDefinition: IRendererConfig<IWidgetMapConfig> = {
  parseConfig: (widget) => {
    const defaultValue: IWidgetMapConfig = {
      height: 250,
      options: {
        lat: "",
        long: "",
      },
    };

    if (!widget.config) {
      return defaultValue;
    }

    try {
      const config: IWidgetMapConfig = JSON.parse(widget.config);
      return config;
    } catch (err) {
      console.error(err);
      return defaultValue;
    }
  },
  renderEditor: (widget, object, record, conf, form, org) => {
    return (
      <WidgetMapEditor
        widget={widget}
        object={object}
        record={record}
        conf={conf}
        form={form}
      />
    );
  },
  renderComponent: (widget, object, record, layoutId, recordId, conf, edit) => {
    return (
      <WidgetMapRenderer
        widget={widget}
        object={object}
        record={record}
        layoutId={layoutId}
        recordId={recordId}
        conf={conf}
        edit={edit}
      />
    );
  },
  renderLoadingComponent: (widget, conf) => (
    <Skeleton.Button
      size="small"
      active
      block
      shape="default"
      style={{ height: 250 }}
    />
  ),
};
