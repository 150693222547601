import {
  BarChartOutlined,
  CloseOutlined,
  CodeOutlined,
  CompassOutlined,
  DatabaseOutlined,
  DeploymentUnitOutlined,
  FileMarkdownOutlined,
  InfoCircleOutlined,
  LayoutOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { InputRef } from "antd";
import {
  AutoComplete,
  Button,
  Divider,
  Input,
  Modal,
  Space,
  Typography,
} from "antd";
import Fuse from "fuse.js";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import SettingsLogo from "../../assets/tools/settings.svg";
import WorkbenchLogo from "../../assets/tools/workbench.svg";
import WorkspaceLogo from "../../assets/tools/workspace.svg";
import type { InjectedOrgProps } from "../../containers/orgs/WithOrg";
import WithOrg from "../../containers/orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../containers/user-settings/HasRoleAccess";
import { IUserRoleType } from "../../interfaces/user";
import { routeDescriptor } from "../../routes/routes";
import { track } from "../../services/AnalyticsService";
import { isMacintosh } from "../../utils/isMacinthosh";
import { compose } from "../compose/WlyCompose";
import { Emoji } from "../form/emoji-picker/Emoji";
import { useIsOnline } from "../hooks/useIsOnline";
import { WlyRadarIcon } from "../icons/custom-icons/WlyRadarIcon";
import "./WlyGlobalSearch.scss";

interface IWlyGlobalSearchItem {
  key: string;
  category: string;
  label: string;
  value: string;
  details?: string;
  icon?: React.ReactNode;
  onSelect?: () => void;
}

interface IWlyGlobalSearchProps {
  open: boolean;
  context: "WORKBENCH" | "WORKSPACE";
  items: Array<IWlyGlobalSearchItem>;
  placeholder?: string;
  setOpen: (open: boolean) => void;
}

const getCategoryIcon = (category: string) => {
  switch (category) {
    case "Exploration":
      return <CompassOutlined />;
    case "Model":
      return <FileMarkdownOutlined />;
    case "Source":
      return <DatabaseOutlined />;
    case "Dataset":
      return <DatabaseOutlined />;
    case "Worksheet":
      return <CodeOutlined />;
    case "Report":
      return <BarChartOutlined />;
    case "Radar":
      return <WlyRadarIcon />;
    case "Object":
      return <DeploymentUnitOutlined />;
    case "List":
      return <LayoutOutlined />;
    default:
      return <></>;
  }
};

type Props = IWlyGlobalSearchProps & InjectedOrgProps & RouteComponentProps<{}>;

function WlyGlobalSearch(props: Props) {
  const { placeholder, open, setOpen, context } = props;
  const [searchText, setSearchText] = useState<string>(null);
  const [searchCategory, setSearchCategory] = useState<string>(null);
  const inputRef = useRef<InputRef>(null);
  const isOnline = useIsOnline();

  const toggleVisibity = () => setOpen(!open);

  const shortCuts: IWlyGlobalSearchItem[] = [
    hasRoleAccessBoolean(IUserRoleType.BUILDER, props.user, props.org.id) && {
      key: "Workbench",
      category: "Shortcut",
      label: "Workbench",
      value: "Workbench",
      icon: (
        <img
          src={WorkbenchLogo}
          width={18}
          height={18}
          style={{ borderRadius: 4 }}
          alt="Workbench"
        />
      ),
      onSelect: () => {
        props.history.push(
          routeDescriptor.workbench.renderRoute({
            ...props.match.params,
          })
        );
      },
    },
    {
      key: "Workspace",
      category: "Shortcut",
      label: "Workspace",
      value: "Workspace",
      icon: (
        <img
          src={WorkspaceLogo}
          width={18}
          height={18}
          style={{ borderRadius: 4 }}
          alt="Workspace"
        />
      ),
      onSelect: () =>
        props.history.push(
          routeDescriptor.home.renderRoute({
            ...props.match.params,
          })
        ),
    },
    hasRoleAccessBoolean(IUserRoleType.BUILDER, props.user, props.org.id) && {
      key: "Settings",
      category: "Shortcut",
      label: "Settings",
      value: "Settings",
      icon: (
        <img
          src={SettingsLogo}
          width={18}
          height={18}
          style={{ borderRadius: 4 }}
          alt="Settings"
        />
      ),
      onSelect: () =>
        props.history.push(
          routeDescriptor.settingsBasic.renderRoute({
            ...props.match.params,
          })
        ),
    },
    {
      key: "Change org",
      category: "Shortcut",
      label: "Change org",
      value: "Change org",
      icon: <Emoji emoji={":whale:"} size={18} />,
      onSelect: () =>
        props.history.push(
          routeDescriptor["org-picker"].renderRoute({
            ...props.match.params,
          })
        ),
    },
    hasRoleAccessBoolean(IUserRoleType.BUILDER, props.user, props.org.id) && {
      key: "Manage users",
      category: "Shortcut",
      label: "Manage users",
      value: "Manage users",
      icon: <Emoji emoji={":busts_in_silhouette:"} size={18} />,
      onSelect: () =>
        props.history.push(
          routeDescriptor.settingsAccessManagement.renderRoute({
            ...props.match.params,
          })
        ),
    },
  ].filter((v) => v);

  useEffect(() => {
    if (!isOnline && open) {
      setOpen(false);
    }
  }, [isOnline, open, setOpen]);

  useEffect(() => {
    if (!open) {
      setSearchText(null);
      setSearchCategory(null);
      return;
    }
    // set the focus to the cursor when seach becomes visible
    setTimeout(() => {
      inputRef.current?.focus?.({
        cursor: "end",
      });
      track("Search", {
        context: context,
      });
    }, 200);
  }, [open]);

  useEffect(() => {
    const onKeyPressed = (e: KeyboardEvent) => {
      if (
        (isMacintosh() ? e.metaKey : e.ctrlKey) &&
        e.key?.toLowerCase() === "p"
      ) {
        e.preventDefault();
        if (e.shiftKey) {
          setSearchText(">");
        }
        toggleVisibity();
      }
    };

    window.addEventListener("keydown", onKeyPressed);
    return () => window.removeEventListener("keydown", onKeyPressed);
  }, []);

  let filteredItems: IWlyGlobalSearchItem[] = props.items;
  let isLookingForShortcut: boolean = false;
  if (searchText?.startsWith(">") && !searchCategory) {
    filteredItems = shortCuts;
    isLookingForShortcut = true;
  } else if (searchCategory) {
    filteredItems = props.items.filter((i) => i.category === searchCategory);
  }

  const fuse = new Fuse(filteredItems, {
    keys: ["label", "details"],
    includeMatches: true,
    shouldSort: true,
    includeScore: true,
  });

  let searchedItems: IWlyGlobalSearchItem[] = filteredItems;

  if (isLookingForShortcut && searchText.length > 1) {
    searchedItems = fuse.search(searchText.slice(1)).map((i) => i.item);
  } else if (!isLookingForShortcut && searchText) {
    searchedItems = fuse.search(searchText).map((i) => i.item);
  }

  const autocompleteItems = searchedItems.reduce((arr, property) => {
    if (!arr.find((o) => o.label === property.category)) {
      arr.push({ label: property.category, options: [] });
    }
    arr.find((o) => o.label === property.category).options.push(property);
    return arr;
  }, []);

  if (searchText && !isLookingForShortcut) {
    const bestResults: IWlyGlobalSearchItem[] = fuse
      .search(searchText)
      .slice(0, 4)
      .map((i) => ({
        ...i.item,
        key: `bm-${i.item.key}`,
      }));
    if (bestResults?.length) {
      autocompleteItems.unshift({
        label: "Best results",
        options: bestResults,
      });
    }
  }

  return (
    <Modal
      className="wly-global-search"
      open={open}
      onCancel={() => setOpen(false)}
      closable={false}
      footer={null}
      width={600}
      destroyOnClose
      title={
        <>
          <AutoComplete
            getPopupContainer={() =>
              document.getElementById("wly-global-search")
            }
            size="large"
            dropdownStyle={{ width: 600, maxWidth: "calc(100vw - 32px)" }}
            style={{ width: "100%" }}
            open={open}
            value={searchText}
            notFoundContent={"No matching results"}
            onSelect={(value, option) => {
              if (typeof option.onSelect === "function") {
                option.onSelect();
              }
              setOpen(false);
            }}
            dropdownRender={(menu) => (
              <div style={{}}>
                <div>
                  <div
                    hidden={
                      isLookingForShortcut ||
                      !!searchCategory ||
                      !props.items.length
                    }
                  >
                    <div style={{ flex: 1, padding: "2px 12px 6px 12px" }}>
                      <div
                        style={{
                          color: "rgba(0, 0, 0, 0.45)",
                          fontSize: 12,
                          cursor: "default",
                          paddingBottom: 5,
                        }}
                      >
                        I'm looking for...
                      </div>
                      <div
                        style={{ overflowY: "scroll", scrollbarWidth: "none" }}
                      >
                        <Space>
                          {_.uniq(props.items.map((i) => i.category)).map(
                            (c, index) => (
                              <Button
                                key={index}
                                size="small"
                                shape="round"
                                onClick={() => {
                                  setSearchCategory(c);
                                  setTimeout(() => {
                                    inputRef.current?.focus?.({
                                      cursor: "end",
                                    });
                                  }, 200);
                                }}
                                icon={getCategoryIcon(c)}
                              >
                                {c}
                              </Button>
                            )
                          )}
                        </Space>
                      </div>
                    </div>
                    <Divider style={{ margin: 0 }} />
                  </div>
                  {React.cloneElement(menu as React.ReactElement, {
                    style: {},
                  })}
                  <div
                    hidden={
                      isLookingForShortcut || !!searchText || !!searchCategory
                    }
                  >
                    <div style={{ flex: 1, padding: 0 }}>
                      <Divider style={{ margin: 0 }} />
                      <div
                        style={{
                          color: "rgba(0, 0, 0, 0.45)",
                          fontSize: 12,
                          cursor: "default",
                          paddingTop: 5,
                          paddingBottom: 2,
                          paddingLeft: 12,
                        }}
                      >
                        <InfoCircleOutlined /> Type {">"} to access shortcuts
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setOpen(false);
              }
            }}
            options={autocompleteItems.map((i) => ({
              ...i,
              options: i.options?.map((o) => ({
                ...o,
                label: (
                  <div style={{ display: "flex", gap: 10 }}>
                    <div style={{ flex: 0 }}>{o.icon}</div>
                    <div
                      style={{
                        // flex: 0,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {o.label}
                    </div>
                    <div style={{ flex: 1, justifyContent: "end" }}>
                      <Typography.Text
                        type="secondary"
                        style={{ fontSize: 12 }}
                        ellipsis
                      >
                        {o.details}
                      </Typography.Text>
                    </div>
                    <div style={{ flex: 0 }}>
                      <Typography.Text
                        type="secondary"
                        style={{ fontSize: 12 }}
                      >
                        {i.label === "Best results" && <div>{o.category}</div>}
                      </Typography.Text>
                    </div>
                  </div>
                ),
              })),
            }))}
          >
            <Input
              size="large"
              placeholder={placeholder ?? "Search Whaly..."}
              bordered={false}
              prefix={
                searchCategory ? (
                  <Button
                    onClick={() => setSearchCategory(null)}
                    size="small"
                    shape="round"
                    icon={<CloseOutlined />}
                  >
                    {searchCategory}
                  </Button>
                ) : (
                  <SearchOutlined />
                )
              }
              onKeyDown={(e) => {
                if (e.key === "Backspace" && !searchText && !!searchCategory) {
                  setSearchCategory(null);
                }
              }}
              ref={inputRef}
              allowClear
              onChange={(e) => setSearchText(e.target.value)}
            />
          </AutoComplete>
        </>
      }
    >
      <div id="wly-global-search"></div>
    </Modal>
  );
}

export default compose<Props, IWlyGlobalSearchProps>(
  withRouter,
  WithOrg
)(WlyGlobalSearch);
