import { Button, Space } from "antd";
import { useEffect } from "react";
import { useIsOnline } from "../../../../../../../../components/hooks/useIsOnline";
import type { IObject } from "../../../../../../../../interfaces/object";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetSeparatorConfig } from "../domain";

interface IButtonWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IWidgetSeparatorConfig;
  edit?: boolean;
}

type Props = IButtonWidgetProps;

export function ButtonWidget({ widget, object, layoutId, recordId }: Props) {
  const isOnline = useIsOnline();
  const { setAsFinished } = useWidgetCacheActions({
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  });

  useEffect(() => {
    setAsFinished();
  }, [setAsFinished]);

  return (
    <Space wrap>
      <Button
        href="https://app.hubspot.com/contacts/7126871/record/0-2/19535959603"
        target="_blank"
        disabled={!isOnline}
      >
        Ouvrir dans le CRM
      </Button>
      <Button
        href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
        target="_blank"
        download={"plan de vente.pdf"}
        disabled={!isOnline}
      >
        Télécharger le plan de vente
      </Button>
      <Button disabled={!isOnline}>Planifier une visite</Button>
    </Space>
  );
}
