import { Button, Flex } from "antd";
import { isEqual } from "lodash";
import type { IColumnGroup, IConfiguration } from "../domain";

type RelatedListActionsProps = {
  configurations: IConfiguration[];
  selectedConfiguration: IConfiguration | undefined;
  onConfigurationChange: (configuration: IConfiguration | undefined) => void;
  columnGroups: IColumnGroup[];
  selectedColumnGroups: IColumnGroup[];
  onColumnGroupsChange: (columnGroup: IColumnGroup[]) => void;
  showAllColumnGroupOption?: boolean;
  allColumnGroupOptionName?: string;
};

export const RelatedListActions = ({
  configurations,
  selectedConfiguration,
  onConfigurationChange,
  columnGroups,
  selectedColumnGroups,
  onColumnGroupsChange,
  showAllColumnGroupOption,
  allColumnGroupOptionName,
}: RelatedListActionsProps) => {
  return (
    <Flex justify="space-between" gap="small">
      <Flex wrap="wrap" gap={4}>
        {configurations.map((configuration, index) => {
          return (
            <ActivableButton
              key={`${configuration.name}-${index}`}
              active={isEqual(configuration, selectedConfiguration)}
              label={configuration.name}
              onClick={() => {
                if (isEqual(configuration, selectedConfiguration)) {
                  onConfigurationChange(undefined);
                } else {
                  onConfigurationChange(configuration);
                }
              }}
            />
          );
        })}
      </Flex>

      <Flex wrap="wrap" gap={4}>
        {columnGroups.length > 1 && showAllColumnGroupOption && (
          <ActivableButton
            active={selectedColumnGroups.length === columnGroups.length}
            label={allColumnGroupOptionName ?? "All column groups"}
            onClick={() => {
              if (selectedColumnGroups.length === columnGroups.length) {
                onColumnGroupsChange([]);
              } else {
                onColumnGroupsChange(columnGroups);
              }
            }}
          />
        )}

        {columnGroups.map((columnGroup, index) => {
          return (
            <ActivableButton
              key={`${columnGroup.name}-${index}`}
              active={selectedColumnGroups.some((group) =>
                isEqual(group, columnGroup)
              )}
              label={columnGroup.name}
              onClick={() => {
                if (
                  selectedColumnGroups.some((group) =>
                    isEqual(group, columnGroup)
                  )
                ) {
                  onColumnGroupsChange(
                    selectedColumnGroups.filter(
                      (group) => !isEqual(group, columnGroup)
                    )
                  );
                } else {
                  onColumnGroupsChange([...selectedColumnGroups, columnGroup]);
                }
              }}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

const ActivableButton = ({
  active,
  label,
  onClick,
}: {
  active: boolean;
  label: string;
  onClick: () => void;
}) => {
  return (
    <Button
      type="primary"
      ghost={!active}
      size="small"
      onClick={onClick}
      shape="round"
    >
      {label}
    </Button>
  );
};
