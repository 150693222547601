import { Button } from "antd";
import * as React from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import {
  withAntUtils,
  type InjectedAntUtilsProps,
} from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import {
  generateDownloadlink,
  getObjectStorageDownloadLink,
} from "../../../services/FileService";

export interface IFileIndexerDocViewerProps {
  filePath: string;
  objectStorageId: string;
  extension: string;
  orgId: string;
}

type Props = IFileIndexerDocViewerProps & InjectedAntUtilsProps;

function FileIndexerDocViewer(props: Props) {
  const { filePath, orgId, objectStorageId, extension, antUtils } = props;

  const [fileUri, setFileUri] = React.useState<AsyncData<string>>({
    status: "initial",
  });

  const [downloading, setDownloading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        setFileUri({ status: "loading" });
        const d = await getObjectStorageDownloadLink(objectStorageId, filePath);
        const url = generateDownloadlink(
          "object-storages",
          objectStorageId,
          d.data.token
        );
        setFileUri({ status: "success", data: url });
      } catch (err) {
        setFileUri({ status: "error", error: err });
      }
    };
    fetch();
  }, [filePath]);

  if (fileUri.status === "initial" || fileUri.status === "loading") {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (fileUri.status === "error") {
    return <Feednack>There was an error loading your doc</Feednack>;
  }

  return (
    <Feednack>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
        }}
        onClick={async () => {
          try {
            setDownloading(true);
            const d = await getObjectStorageDownloadLink(
              objectStorageId,
              filePath
            );
            const url = generateDownloadlink(
              "object-storages",
              objectStorageId,
              d.data.token
            );
            window?.open(url, "_blank")?.focus();
          } catch (err) {
            console.error(err);
            antUtils.message.error(
              "There was an error generating your download link... please retry"
            );
          } finally {
            setDownloading(false);
          }
        }}
      >
        <div style={{ maxWidth: 100 }}>
          <FileIcon
            style={{ width: 100 }}
            extension={extension}
            {...defaultStyles[extension]}
          />
        </div>
        <Button type="link">Click here to download the file</Button>
      </div>
    </Feednack>
  );
}

export default compose<Props, IFileIndexerDocViewerProps>(withAntUtils)(
  FileIndexerDocViewer
);
