import { PushpinOutlined } from "@ant-design/icons";
import type { Filter } from "@cubejs-client/core";
import Markdoc from "@markdoc/markdoc";
import { Card, Divider, Skeleton, theme, Typography } from "antd";
import { findLastIndex } from "lodash";
import React, { useState } from "react";
import type { ColumnsSettings } from "../../../../../../../../components/ag-grid/object-table/domain";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import type { AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import type { IObject } from "../../../../../../../../interfaces/object";
import { IDB_GET_ERROR } from "../../../../../../../../services/idbService";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import WithOrg, {
  type InjectedOrgProps,
} from "../../../../../../../orgs/WithOrg";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
} from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import {
  convertKeyToMarkdocVariable,
  getParsedDoc,
} from "../../../common/markdoc/domain";
import "../../../common/markdoc/MarkdocCommonStyles.scss";
import type { IWidget } from "../../../domain";
import { kpiThemes } from "../../kpi/domain";
import { ProgressSparkline } from "../../kpi/renderer/sparkline/progress/ProgressSparkline";
import {
  additionalComponents,
  nodesConfig,
} from "../../markdown/widget/markdoc-react-component/config";
import type {
  IColumnGroup,
  IConfiguration,
  IFilterEditorValue,
} from "../../related-lists/domain";
import { RelatedListActions } from "../../related-lists/widget/RelatedListActions";
import RelatedListQuery from "../../related-lists/widget/RelatedListQuery";
import type { IWidgetInlineObjectiveConfig } from "../domain";

const { useToken } = theme;

interface IInlineObjectivesightWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IWidgetInlineObjectiveConfig;
  edit?: boolean;
}

type Props = IInlineObjectivesightWidgetProps & InjectedOrgProps;

function InlineObjectiveWidget(props: Props) {
  const { widget, record, conf, object, layoutId, recordId, org, user } = props;
  const widgetCacheProps = {
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  };

  const [selectedConfiguration, setSelectedConfiguration] = useState<
    IConfiguration | undefined
  >((conf?.options?.configurations ?? []).find((c) => c.isDefault));
  const [selectedColumnGroups, setSelectedColumnGroups] = useState<
    IColumnGroup[]
  >((conf?.options?.columnGroups ?? []).filter((c) => c.isDefault));
  const { setAsFinished } = useWidgetCacheActions(widgetCacheProps);
  const [total, setTotal] = useState<AsyncData<number>>({ status: "initial" });

  const getRowCount = (foreignObject: IObject) => async (f: Filter[]) => {
    try {
      setTotal({ status: "loading" });
      const query = {
        measures: [`${foreignObject.table.cubeName}.count`],
        filters: f,
      };
      const r = await lagoonServiceLoad(
        org.id,
        query,
        "OBJECT",
        foreignObject.id,
        undefined,
        LagoonCallOrigin.WHALY_APP
      );

      const rowData = r.tablePivot({ fillMissingDates: false });
      const rows = rowData?.[0]?.[`${foreignObject.table.cubeName}.count`];
      if (typeof rows === "string" || typeof rows === "number") {
        setTotal({ status: "success", data: +rows });
      }
    } catch (err) {
      setTotal({ status: "error", error: err });
    }
  };

  const c = conf;
  const source = conf.title ?? "";

  if (!c.foreignObjectPropertyId) {
    setAsFinished(true);
    return (
      <div style={{ height: 250 }}>
        <Feednack>Missing configuration</Feednack>
      </div>
    );
  }

  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === c.foreignObjectPropertyId
  );

  if (!foreignProperty) {
    setAsFinished(true);
    return (
      <div style={{ height: 250 }}>
        <Feednack>Can't find foreign property</Feednack>
      </div>
    );
  }

  if (!c.options?.columns?.length) {
    setAsFinished(true);
    return (
      <div style={{ height: 250 }}>
        <Feednack>Please add at least one column to display</Feednack>
      </div>
    );
  }

  const foreignObject = foreignProperty.object;

  const property = foreignObject.properties.find(
    (p) => p.id === c.foreignObjectPropertyId
  );

  if (!property) {
    setAsFinished(true);
    return (
      <div style={{ height: 250 }}>
        <Feednack>Property must be defined</Feednack>
      </div>
    );
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );
  const cubeName = object.table.cubeName;
  const recordFilters = [
    {
      member: `${foreignAvailable.key}_raw`,
      operator: "equals",
      values: [record[`${cubeName}.id`] as string],
    },
  ];
  const availableColumns = getObjectColumns(foreignObject);

  const columns = [
    ...c.options.columns,
    ...selectedColumnGroups.reduce(
      (acc, { columns }) => [...acc, ...(columns ?? [])],
      []
    ),
  ].filter((value, i, values) => {
    return findLastIndex(values, (v) => v === value) === i;
  });

  const additionalFilters: IFilterEditorValue = c.options.filters as any;

  const sortBy = [
    ...(conf.options?.sortBy ?? []),
    ...(selectedConfiguration?.sortBy ?? []),
  ].filter(([label], index, values) => {
    return findLastIndex(values, (v) => v[0] === label) === index;
  });

  const groupBy = [
    ...(conf.options?.groupBy ?? []),
    ...(selectedConfiguration?.groupBy ?? []),
  ].filter(({ id }, i, values) => {
    return findLastIndex(values, (v) => v.id === id) === i;
  });

  const hidePagination = c.options?.hidePagination;
  const showRowNumber = c.options?.showRowNumber;
  const columnsSettings = (c.options?.columnsSettings ?? {}) as ColumnsSettings;

  const filters = [
    {
      and: [...recordFilters],
    },
    {
      and: [
        additionalFilters
          ? { [additionalFilters.operator]: additionalFilters.filters }
          : { and: [] },
        selectedConfiguration?.filters
          ? {
              [selectedConfiguration.filters.operator]:
                selectedConfiguration.filters.filters,
            }
          : { and: [] },
      ],
    },
  ] as Filter[];

  const objValue = c.objective?.value
    ? parseFloat((record[c.objective?.value] as string) ?? "0")
    : 0;

  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, {});
  const content = getParsedDoc(
    source,
    formattedRecord,
    user,
    availableColumns,
    nodesConfig
  );

  const title = Markdoc.renderers.react(content, React, {
    ...additionalComponents,
  });

  const renderCountLoading = () => {
    if (total.status === "initial" || total.status === "loading") {
      return (
        <Skeleton.Button
          active={true}
          size={"small"}
          shape={"round"}
          style={{ width: 40 }}
        />
      );
    }
    if (total.status === "error") {
      if (total.error.cause === IDB_GET_ERROR) {
        return <Feednack>You need to be online to view this content</Feednack>;
      }

      return (
        <Typography.Text type="danger">Error fetching data</Typography.Text>
      );
    }
    return (
      <Typography.Text type="secondary">
        <PushpinOutlined /> {total.data} {c.count?.label}
      </Typography.Text>
    );
  };

  return (
    <Card size="small" styles={{ body: { padding: 0 } }}>
      <div
        style={{
          padding: `12px 24px`,
          display: "flex",
          alignItems: "center",
          gap: 12,
        }}
      >
        <div style={{ flex: 1 }}>
          {source && <div className="markdoc">{title}</div>}
          {c.count?.show && <div>{renderCountLoading()}</div>}
        </div>
        {c.objective?.show && (
          <>
            <div>
              <Typography.Text style={{ fontSize: "1.2em" }} strong>
                {Math.round((objValue * 1000) / 10)}%
              </Typography.Text>
            </div>
            <div style={{ flex: `0 auto` }}>
              <ProgressSparkline
                size={48}
                rawValue={objValue}
                theme={kpiThemes["gray"]}
              />
            </div>
          </>
        )}
      </div>
      <Divider style={{ margin: 0 }} />
      <div style={{ margin: 12 }}>
        {(conf.options?.configurations || conf.options?.columnGroups) && (
          <div style={{ marginBottom: 24 }}>
            <RelatedListActions
              configurations={conf.options.configurations ?? []}
              selectedConfiguration={selectedConfiguration}
              onConfigurationChange={(s) => setSelectedConfiguration(s)}
              columnGroups={conf.options.columnGroups ?? []}
              selectedColumnGroups={selectedColumnGroups}
              onColumnGroupsChange={(s) => setSelectedColumnGroups(s)}
              showAllColumnGroupOption={
                !!conf.options.allColumnGroupSelectedProps?.show
              }
              allColumnGroupOptionName={
                conf.options.allColumnGroupSelectedProps?.name
              }
            />
          </div>
        )}
        <RelatedListQuery
          object={foreignObject}
          availableColumns={availableColumns}
          pageSize={c.options.pageSize || 10}
          filters={filters}
          sortBy={sortBy}
          groupBy={groupBy}
          columns={columns}
          columnsSettings={columnsSettings}
          hidePagination={hidePagination}
          showRowNumber={showRowNumber}
          getRowCount={getRowCount(foreignObject)}
          widgetCacheProps={{
            widgetId: widget.id,
            objectId: object.id,
            layoutId,
            recordId,
          }}
        />
      </div>
    </Card>
  );
}

export default compose<Props, IInlineObjectivesightWidgetProps>(WithOrg)(
  InlineObjectiveWidget
);
