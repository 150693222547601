import { Button, List } from "antd";
import React from "react";
import Feednack from "../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../../interfaces/destinations";
import { getExport } from "../../../../../../../services/BrizoService";
import "./SQLExport.scss";
import SQLExportItem from "./SQLExportItem";

export interface ISQLExportProps {
  sql: string;
  currentWarehouse: IDestination;
}

export function SQLExport(props: ISQLExportProps) {
  const { sql, currentWarehouse } = props;
  const [state, setState] = React.useState<
    AsyncData<{
      filePath: string;
      results: Array<string>;
    }>
  >({
    status: "initial",
  });

  const fetch = async () => {
    try {
      setState({ status: "loading" });
      const d = await getExport(currentWarehouse.id, sql);
      setState({ status: "success", data: d });
    } catch (err) {
      setState({ status: "error", error: err });
    }
  };

  const renderInner = () => {
    if (state.status === "initial") {
      return (
        <Feednack>
          <div>
            <Button onClick={() => fetch()}>Run export</Button>
          </div>
        </Feednack>
      );
    }
    if (state.status === "loading") {
      return (
        <Feednack>
          <div>
            <Loading />
            <div>Do not change tab we are processing your export</div>
          </div>
        </Feednack>
      );
    }
    if (state.status === "error") {
      return <Feednack>There was an error fetching your export</Feednack>;
    }
    return (
      <div className="worksheet-export">
        <List
          dataSource={state.data.results}
          renderItem={(_, i) => {
            return (
              <List.Item>
                <SQLExportItem
                  filePath={_}
                  index={i}
                  warehouseId={currentWarehouse.id}
                />
              </List.Item>
            );
          }}
        />
      </div>
    );
  };

  return <div className="worksheet-content">{renderInner()}</div>;
}
