import { EditOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import type { PopoverProps } from "antd/lib";
import { useMemo } from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import { getObjectColumns } from "../../../../../object/domain";
import { getAvailableDimensions } from "../../../../../object/viewer/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import { ColumnGroupSelector } from "../../generic-editor-parts/ColumnGroupSelector";
import { ColumnSelector } from "../../generic-editor-parts/ColumnSelector";
import { ConfigurationSelector } from "../../generic-editor-parts/ConfigurationSelector";
import { FilterSelector } from "../../generic-editor-parts/FilterSelector";
import { GroupSelector } from "../../generic-editor-parts/GroupSelector";
import { SortSelector } from "../../generic-editor-parts/SortSelector";
import type { IRelatedListConfig } from "../domain";

interface IWidgetRelatedListEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IRelatedListConfig;
  form: FormInstance;
}

type Props = IWidgetRelatedListEditorProps & InjectedOrgProps;

function WidgetRelatedListEditor(props: Props) {
  const { widget, object, record, conf, form, org } = props;

  const currentPropertyId = useWatch<string | undefined>(
    ["config", "foreignObjectPropertyId"],
    form
  );

  const objectProperty = useMemo(() => {
    return object.foreignKeys.find(({ id }) => id === currentPropertyId);
  }, [currentPropertyId, object.foreignKeys]);

  const availableColumns = useMemo(() => {
    return objectProperty ? getObjectColumns(objectProperty.object) : undefined;
  }, [objectProperty]);

  const availableDimensionsToGroup = useMemo(() => {
    return availableColumns
      ? getAvailableDimensions(
          availableColumns.filter(({ type, data }) => {
            return type !== "property" || data.type !== "primaryKey";
          }),
          {
            type: "display",
          }
        )
      : undefined;
  }, [availableColumns]);

  const availableDimensionsToFilter = useMemo(() => {
    return availableColumns
      ? getAvailableDimensions(availableColumns, { type: "sortAndFilter" })
      : undefined;
  }, [availableColumns]);

  return (
    <>
      <ChartOptionCollapse dark title="Related List Configuration">
        <Space style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item
                    label="Related Object"
                    required
                    style={{ marginBottom: 4 }}
                    name={["config", "foreignObjectPropertyId"]}
                  >
                    <Select
                      optionLabelProp="label"
                      style={{ width: "100%", maxWidth: "100%" }}
                      popupMatchSelectWidth={false}
                    >
                      {object.foreignKeys.map((ok) => {
                        return (
                          <Select.Option
                            label={ok.object.name}
                            key={ok.id}
                            value={ok.id}
                          >
                            <div
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                overflow: "hidden",
                              }}
                            >
                              {ok.object.name}{" "}
                              <Typography.Text type="secondary">
                                on {ok.label}
                              </Typography.Text>
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />

          {availableColumns ? (
            <ColumnSelector
              availableColumns={availableColumns}
              label="Always displayed columns"
              name={["config", "options", "columns"]}
            />
          ) : (
            <Form.Item noStyle hidden name={["config", "options", "columns"]} />
          )}

          {availableColumns &&
          availableDimensionsToGroup &&
          availableDimensionsToFilter ? (
            <ConfigurationSelector
              availableColumns={availableColumns}
              availableDimensionsToGroup={availableDimensionsToGroup}
              availableDimensionsToFilter={availableDimensionsToFilter}
              label="Configurations"
              name={["config", "options", "configurations"]}
              org={org}
              object={object}
            />
          ) : (
            <Form.Item
              noStyle
              hidden
              name={["config", "options", "configurations"]}
            />
          )}

          {availableColumns ? (
            <ColumnGroupSelector
              availableColumns={availableColumns}
              label="Columns groups"
              name={["config", "options", "columnGroups"]}
              formInstance={form}
            />
          ) : (
            <Form.Item
              noStyle
              hidden
              name={["config", "options", "columnGroups"]}
            />
          )}

          {availableColumns ? (
            <SortSelector
              availableColumns={availableColumns}
              label="Base sort"
              name={["config", "options", "sortBy"]}
            />
          ) : (
            <Form.Item noStyle hidden name={["config", "options", "sortBy"]} />
          )}

          {availableDimensionsToGroup ? (
            <GroupSelector
              availableDimensions={availableDimensionsToGroup}
              label="Base group"
              name={["config", "options", "groupBy"]}
            />
          ) : (
            <Form.Item noStyle hidden name={["config", "options", "groupBy"]} />
          )}

          {availableDimensionsToFilter ? (
            <FilterSelector
              availableDimensions={availableDimensionsToFilter}
              label="Base filters"
              name={["config", "options", "filters"]}
              org={org}
              object={object}
            />
          ) : (
            <Form.Item noStyle hidden name={["config", "options", "filters"]} />
          )}

          <ChartOptionLine
            style={{ marginBottom: 6 }}
            items={[
              {
                content: "Hide pagination",
                flex: 1,
              },
              {
                content: (
                  <Form.Item
                    noStyle
                    valuePropName="checked"
                    name={["config", "options", "hidePagination"]}
                  >
                    <Switch size="small" />
                  </Form.Item>
                ),
                flex: 0,
              },
            ]}
          />

          <ChartOptionLine
            style={{ marginBottom: 6 }}
            items={[
              {
                content: "Row number",
                flex: 1,
              },
              {
                content: (
                  <Form.Item
                    noStyle
                    valuePropName="checked"
                    name={["config", "options", "showRowNumber"]}
                  >
                    <Switch size="small" />
                  </Form.Item>
                ),
                flex: 0,
              },
            ]}
          />

          <Form.Item noStyle shouldUpdate>
            {() => {
              const hidePagination = form.getFieldValue([
                "config",
                "options",
                "hidePagination",
              ]);
              return (
                <ChartOptionLine
                  items={[
                    {
                      content: hidePagination ? "Limit" : "Page size",
                      flex: 1,
                    },
                    {
                      content: (
                        <Form.Item
                          noStyle
                          name={["config", "options", "pageSize"]}
                        >
                          <InputNumber size="small" />
                        </Form.Item>
                      ),
                      flex: 0,
                    },
                  ]}
                />
              );
            }}
          </Form.Item>
        </Space>
      </ChartOptionCollapse>

      <Form.Item noStyle shouldUpdate={true}>
        {() => {
          const columns = form.getFieldValue(["config", "options", "columns"]);

          const columnsSettings = (columns ?? []).flatMap((column, index) => {
            const currentPropertyId = form.getFieldValue([
              "config",
              "foreignObjectPropertyId",
            ]);

            const propertyExists = currentPropertyId
              ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
              : undefined;

            if (currentPropertyId && propertyExists) {
              const availableColumn = getObjectColumns(
                propertyExists.object
              ).find((c) => c.data.key === column);
              const columnName = availableColumn
                ? availableColumn.data.label
                : column;
              let canDisplayImage: boolean = false;
              if (
                availableColumn?.type === "property" &&
                availableColumn.data.type === "primaryKey"
              ) {
                canDisplayImage = true;
              }
              if (
                availableColumn?.type === "property" &&
                availableColumn.data.type === "foreignKey"
              ) {
                canDisplayImage = true;
              }

              const columnPopover: PopoverProps = {
                title: columnName,
                content: (
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size={12}
                  >
                    <div>
                      <Typography.Text strong>Column name</Typography.Text>
                      <br />
                      <Form.Item
                        name={[
                          "config",
                          "options",
                          "columnsSettings",
                          column,
                          "label",
                        ]}
                        noStyle
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div>
                      <Typography.Text strong>Display image</Typography.Text>
                      <br />
                      <Form.Item
                        name={[
                          "config",
                          "options",
                          "columnsSettings",
                          column,
                          "displayImage",
                        ]}
                        noStyle
                      >
                        <Switch />
                      </Form.Item>
                    </div>
                    <div>
                      <Typography.Text strong>Initial size</Typography.Text>
                      <br />
                      <Form.Item
                        name={[
                          "config",
                          "options",
                          "columnsSettings",
                          column,
                          "initialSizeType",
                        ]}
                        noStyle
                      >
                        <Select
                          style={{ width: "100%" }}
                          options={[
                            { label: "Fill space", value: "fill" },
                            { label: "Fixed width", value: "fixedWidth" },
                          ]}
                        />
                      </Form.Item>
                      <br />
                      <Form.Item noStyle shouldUpdate>
                        {() => {
                          const displaySize =
                            form.getFieldValue([
                              "config",
                              "options",
                              "columnsSettings",
                              column,
                              "initialSizeType",
                            ]) === "fixedWidth";

                          if (displaySize) {
                            return (
                              <Form.Item
                                name={[
                                  "config",
                                  "options",
                                  "columnsSettings",
                                  column,
                                  "initialSizeWidth",
                                ]}
                                style={{ paddingTop: 8 }}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                  }}
                                  suffix="px"
                                  placeholder="size"
                                />
                              </Form.Item>
                            );
                          }
                        }}
                      </Form.Item>
                    </div>
                  </Space>
                ),
              };

              return (
                <ChartOptionLine
                  key={index}
                  items={[
                    {
                      content: <div>{columnName}</div>,
                      flex: 1,
                      popover: columnPopover,
                    },
                    {
                      content: (
                        <Button
                          size="small"
                          type="text"
                          shape="circle"
                          icon={<EditOutlined />}
                        />
                      ),
                      popover: columnPopover,
                      flex: "0 auto",
                    },
                  ]}
                ></ChartOptionLine>
              );
            } else {
              return [];
            }
          });

          if (!columnsSettings?.length) {
            return null;
          }

          return (
            <ChartOptionCollapse dark title="Columns Settings">
              <Space style={{ width: "100%" }} direction="vertical">
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: "100%" }}
                >
                  {columnsSettings}
                </Space>
              </Space>
            </ChartOptionCollapse>
          );
        }}
      </Form.Item>
    </>
  );
}

export default compose<Props, IWidgetRelatedListEditorProps>(WithOrg)(
  WidgetRelatedListEditor
);
