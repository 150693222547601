import type { SerializedResult, SqlQuery } from "@cubejs-client/core";
import type { UseStore } from "idb-keyval";
import {
  clear,
  createStore,
  entries,
  get,
  keys,
  set,
  update,
} from "idb-keyval";
import type { CachedRecord } from "../components/hooks/useCachedRecord";
import type {
  JwtTokenResource,
  RefreshTokenResource,
} from "../interfaces/auth";
import type { WidgetCacheInfo } from "../store/widgetCacheStore";
import { broadcastService } from "./broadcastService";

export const IDB_GET_ERROR = "NO_IDB_RESOURCE";
export type AuthResource = RefreshTokenResource | JwtTokenResource | undefined;

// PRIVATE

const AUTH_RESOURCE_KEY = "authRessource";

interface LagoonCacheData<T> {
  id: string;
  duration: number;
  cubeData: SerializedResult<T>;
}

let generalStore = createStore("general", "store");
let gqlStore = createStore("gql", "store");
let lagoonSqlStore = createStore("lagoonSql", "store");
let lagoonResultStore = createStore("lagoonResult", "store");
// let apiStore = createStore("api", "store");
let cachedRecordStore = createStore("cachedRecord", "store");
let cachedWidgetStore = createStore("cachedWidget", "store");
let timestampStore = createStore("timestamp", "store");
let stores = [
  generalStore,
  gqlStore,
  lagoonSqlStore,
  lagoonResultStore,
  cachedRecordStore,
  timestampStore,
];

const getResource = async <T extends any>(
  resourceKey: string,
  store: UseStore
): Promise<T> => {
  const resource = await get<T>(resourceKey, store);

  if (resource === undefined) {
    throw new Error(`No resource found for key: ${resourceKey}`, {
      cause: IDB_GET_ERROR,
    });
  } else {
    return resource;
  }
};
const setResource = async (
  resourceKey: string,
  resource: any,
  store: UseStore
) => {
  const currentDate = new Date().toISOString();

  try {
    await set(resourceKey, resource, store);
    set(resourceKey, currentDate, timestampStore);
  } catch (error) {
    console.error(`Error setting resource: ${resourceKey}`, error);
  }
};

// PUBLIC

const getAuthResource = async () => {
  return get<AuthResource>(AUTH_RESOURCE_KEY, generalStore);
};
const setAuthResource = async (token: AuthResource) => {
  return set(AUTH_RESOURCE_KEY, token, generalStore);
};

const getGqlResource = async <T extends any>(resourceKey: string) => {
  return getResource<T>(resourceKey, gqlStore);
};
const setGqlResource = async (resourceKey: string, resource: any) => {
  return setResource(resourceKey, resource, gqlStore);
};

const getLagoonSqlResource = async (resourceKey: string) => {
  return getResource<SqlQuery>(resourceKey, lagoonSqlStore);
};
const setLagoonSqlResource = async (resourceKey: string, resource: any) => {
  return setResource(resourceKey, resource, lagoonSqlStore);
};

const getLagoonResultResource = async <T extends any>(resourceKey: string) => {
  return getResource<LagoonCacheData<T>>(resourceKey, lagoonResultStore);
};
const setLagoonResultResource = async (resourceKey: string, resource: any) => {
  return setResource(resourceKey, resource, lagoonResultStore);
};

// const getApiResource = async <T extends any>(resourceKey: string) => {
//   return getResource<T>(resourceKey, apiStore);
// };
// const setApiResource = async (resourceKey: string, resource: any) => {
//   return setResource(resourceKey, resource, apiStore);
// };

const setCachedRecord = async (key: string) => {
  const cachedRecord = { updatedAt: new Date(), isCached: true };
  await setResource(key, cachedRecord, cachedRecordStore);
  broadcastService.sendNewCacheEvent(key, cachedRecord.updatedAt);
};
const getCachedRecord = async (key: string) => {
  const loadedRecordKeys = await keys<string>(cachedRecordStore);
  const cachedRecordKey = loadedRecordKeys.find((loadedRecordKey) =>
    loadedRecordKey.includes(key)
  );
  const cachedRecord = await get<CachedRecord>(
    cachedRecordKey ?? "",
    cachedRecordStore
  );

  // Old version
  if (typeof cachedRecord === "boolean") {
    return {
      isCached: !!cachedRecord,
      updatedAt: undefined,
    };
  }

  return {
    isCached: !!cachedRecord?.isCached,
    updatedAt: cachedRecord?.updatedAt,
  };
};

const getWidgetCacheInfosFromIDB = async () => {
  const cachedWidgetEntries = await entries<string, WidgetCacheInfo>(
    cachedWidgetStore
  );

  return cachedWidgetEntries.reduce<Record<string, WidgetCacheInfo>>(
    (acc, [key, widgetCacheInfo]) => ({
      ...acc,
      [key]: widgetCacheInfo,
    }),
    {}
  );
};
const setCachedWidget = (key: string, cacheWidget: WidgetCacheInfo) => {
  return set(key, cacheWidget, cachedWidgetStore);
};

const getMutationBatch = async () => {
  return get<string[]>("mutationBatch", generalStore);
};
const addToMutationBatch = async (mutationData: string) => {
  return update(
    "mutationBatch",
    (batch: string[] | undefined) => [...(batch ?? []), mutationData],
    generalStore
  );
};

const clearAll = async () => {
  await Promise.all(stores.map((store) => clear(store)));
};
const clearWithKeepAuth = async () => {
  const authRessource = await getAuthResource();
  await clearAll();
  await setAuthResource(authRessource);
};

const hasInCache = async (searchStrings: string[]) => {
  const keyList = [
    ...(await keys(gqlStore)),
    ...(await keys(lagoonResultStore)),
    // ...(await keys(apiStore)),
  ]
    .filter((key) => typeof key === "string")
    .map((k) => k as string);

  return keyList.some((key) =>
    searchStrings.every((searchString) => key.includes(searchString))
  );
};

export const IDBService = {
  getAuthResource,
  setAuthResource,
  getGqlResource,
  setGqlResource,
  getLagoonSqlResource,
  setLagoonSqlResource,
  getLagoonResultResource,
  setLagoonResultResource,
  // getApiResource,
  // setApiResource,
  getCachedRecord,
  setCachedRecord,
  getWidgetCacheInfosFromIDB,
  setCachedWidget,
  getMutationBatch,
  addToMutationBatch,
  clearAll,
  clearWithKeepAuth,
  hasInCache,
};
