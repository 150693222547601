import { gql } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import type { CachedRecord } from "../../../../components/hooks/useCachedRecord";
import { useCachedRecord } from "../../../../components/hooks/useCachedRecord";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import {
  OBJECT_FRAGMENT,
  OBJECT_LAYOUT_FRAGMENT,
} from "../../../../fragments/object";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IObject, IObjectLayout } from "../../../../interfaces/object";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import { IDB_GET_ERROR } from "../../../../services/idbService";
import RecordPage from "./RecordPage";
import "./RecordView.scss";
import type { EmbedType } from "./domain";

interface IRecordViewProps {
  homePageConfig?: {
    recordId?: string;
    objectId?: string;
    layoutId?: string;
    hideHeader?: boolean;
  };
  embedType?: EmbedType;
  isSubLayout?: boolean;
  setCachedRecord?: (value: CachedRecord) => void;
}

type Props = RouteComponentProps<{
  objectSlug: string;
  recordId: string;
  organizationSlug: string;
  layoutId?: string;
}> &
  IRecordViewProps;

interface IExtendedObject extends IObject {
  fetchedLayout: [IObjectLayout];
}

function RecordView({
  match: {
    params: { recordId, objectSlug, layoutId: routePropsLayoutId },
  },
  homePageConfig,
  isSubLayout,
  embedType,
  setCachedRecord,
}: Props) {
  const layoutId = useMemo(
    () => homePageConfig?.layoutId ?? routePropsLayoutId,
    [homePageConfig?.layoutId, routePropsLayoutId]
  );
  const [recordMetaData, setRecordMetaData] = useState<AsyncData<IObject>>({
    status: "initial",
  });
  const objectId = useMemo(
    () => (recordMetaData.status === "success" ? recordMetaData.data?.id : ""),
    [recordMetaData]
  );
  const cachedRecord = useCachedRecord(recordId, objectId, objectSlug);

  useEffect(() => {
    setCachedRecord?.(cachedRecord);
  }, [cachedRecord, setCachedRecord]);

  const fetchRecordMetadata = useCallback(
    async (d: { type: "slug" | "id"; value: string }) => {
      try {
        setRecordMetaData({ status: "loading" });

        const layoutFragment = () => {
          if (layoutId) {
            return `fetchedLayout: layouts(where: { isDeleted_not: true, id: ${layoutId}}) {
            ...ObjectLayoutFragment
          }`;
          }
          return `defaultLayout {
          ...ObjectLayoutFragment
        }`;
        };

        const renderQuery = () => {
          if (d.type === "id") {
            return gql`
            ${OBJECT_FRAGMENT}
    
            ${OBJECT_LAYOUT_FRAGMENT}
    
            query getRecordMetaData($value: ID!) {
              allObjects(where: { id: $value, isDeleted_not: true }) {
                ...ObjectFragment
                ${layoutFragment()}
                layouts(where: { isDeleted_not: true, type: RECORD }) {
                  id
                  name
                }
                foreignKeys(where: { isDeleted_not: true, object: { isDeleted_not: true } }) {
                  id
                  label
                  object {
                    ...ObjectFragment
                  }
                }
              }
            }`;
          } else {
            return gql`
            ${OBJECT_FRAGMENT}
    
            ${OBJECT_LAYOUT_FRAGMENT}
    
            query getRecordMetaData($value: String!) {
              allObjects(where: { slug: $value, isDeleted_not: true }) {
                ...ObjectFragment
                ${layoutFragment()}
                layouts(where: { isDeleted_not: true, type: RECORD }) {
                  id
                  name
                }
                foreignKeys(where: { isDeleted_not: true, object: { isDeleted_not: true } }) {
                  id
                  label
                  object {
                    ...ObjectFragment
                  }
                }
              }
            }`;
          }
        };

        const data = await GraphQLService<{ allObjects: IExtendedObject[] }>(
          renderQuery(),
          {
            value: d.value,
          }
        );

        let object = data.allObjects[0];
        if (
          layoutId &&
          object &&
          object.fetchedLayout &&
          object.fetchedLayout.length
        ) {
          object = {
            ...object,
            defaultLayout: object.fetchedLayout[0],
          };
        }

        setRecordMetaData({ status: "success", data: object });
      } catch (err) {
        console.error(err);
        setRecordMetaData({ status: "error", error: err });
      }
    },
    [layoutId]
  );

  useEffect(() => {
    if (homePageConfig?.objectId) {
      fetchRecordMetadata({ type: "id", value: homePageConfig?.objectId });
    } else if (objectSlug) {
      fetchRecordMetadata({ type: "slug", value: objectSlug });
    }
  }, [objectSlug, homePageConfig?.objectId, layoutId, fetchRecordMetadata]);

  if (!objectSlug && !homePageConfig?.objectId) {
    return (
      <Feednack>
        <div>
          You don't seem to have access to this object, please contact your
          administrator
        </div>
      </Feednack>
    );
  }

  if (
    recordMetaData.status === "initial" ||
    recordMetaData.status === "loading"
  ) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (recordMetaData.status === "error") {
    return (
      <Feednack>
        <div>
          {recordMetaData.error.cause === IDB_GET_ERROR
            ? "You need to be online to view this content"
            : `There was an error fetching the layout of your object ${recordMetaData.error?.message}`}
        </div>
      </Feednack>
    );
  }

  if (!recordMetaData.data) {
    return (
      <Feednack>
        <div>
          You don't seem to have access to this object, please contact your
          administrator
        </div>
      </Feednack>
    );
  }

  return (
    <div style={{ height: "100%" }}>
      <RecordPage
        recordId={homePageConfig?.recordId ?? recordId}
        object={recordMetaData.data}
        hideHeader={!!homePageConfig?.hideHeader}
        isSubLayout={isSubLayout}
        embedType={embedType}
      />
    </div>
  );
}

export default compose<Props, IRecordViewProps>(withRouter)(RecordView);
