export const documents = {
  render: "Documents",
  children: [],
  attributes: {
    property: {
      type: String,
    },
    size: {
      type: String,
    },
    id: {
      type: String,
    },
  },
};
