import {
  BulbFilled,
  CheckOutlined,
  FallOutlined,
  FlagFilled,
  RiseOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { Tag, type GlobalToken } from "antd";
import colorUtil from "color";

export interface IScoreConfigValue extends IScoreUnitaryValue {
  subScores?: Array<ISubScoreUnitaryValue>;
}

export type ScorePriority = "high" | "medium" | "low";
export type ScoreIcon =
  | "falling"
  | "rising"
  | "insight"
  | "warning"
  | "check"
  | "none";

export interface ISubScoreUnitaryValue extends IScoreUnitaryValue {
  label?: string;
}

export interface IScoreUnitaryValue {
  icon?: ScoreIcon;
  priority?: ScorePriority;
  reasons?: string[]; // deprecated
  score?: string;
  scoreLabel?: boolean;
  scale?: string;
  description?: string;
}

export const renderIcon = (value?: IScoreUnitaryValue) => {
  if (value?.icon === "falling") {
    return <FallOutlined />;
  }
  if (value?.icon === "rising") {
    return <RiseOutlined />;
  }
  if (value?.icon === "insight") {
    return <BulbFilled />;
  }
  if (value?.icon === "warning") {
    return <WarningFilled />;
  }
  if (value?.icon === "check") {
    return <CheckOutlined />;
  }
  if (value?.icon === "none") {
    return null;
  }
  return <FlagFilled />;
};

export const renderColor = (
  token: GlobalToken,
  value?: IScoreUnitaryValue
): {
  background: string;
  color: string;
  border: string;
} => {
  if (value?.priority === "high") {
    return {
      background: "rgba(255, 188, 206, 0.34)", // "#FFBCCE", // token["volcano-2"],
      color: "#FE779D", //token["volcano-8"],
      border: "#FE779D", // token["volcano-4"],
    };
  }
  if (value?.priority === "medium") {
    return {
      background: "rgb(246 220 154 / 18%)", // "#F6DC9A",
      color: "rgb(237 187 56)", // "#FDD15E",
      border: "#FDD15E",
    };
  }
  if (value?.priority === "low") {
    return {
      background: "rgb(173 232 179 / 31%)", // "#ADE8B3",
      color: "#7EE786",
      border: "#7EE786",
    };
  }
  return {
    background: token.colorBgBase,
    color: token.colorTextBase,
    border: token.colorBorder,
  };
};

export const renderProgress = (value?: IScoreUnitaryValue) => {
  if (value && value?.score && value?.scale) {
    const score = parseFloat(value?.score);
    const scale = parseFloat(value?.scale);
    if (!isNaN(score) && !isNaN(scale)) {
      return (score / scale) * 100;
    }
  }
  return 0;
};

export const renderDetailedScore = (value?: IScoreUnitaryValue) => {
  const score = value?.score;
  const scale = value?.scale;

  return `${score}${scale ? `/${scale}` : ""}`;
};

export const renderScoreLabel = (
  token: GlobalToken,
  value?: IScoreUnitaryValue
) => {
  const score = value?.scoreLabel;
  const color = renderColor(token, value);
  const fontColor = colorUtil(color.border).darken(0.6).hex();
  if (score) {
    return (
      <Tag
        bordered={false}
        color={color.background}
        style={{ color: fontColor }}
      >
        {score}
      </Tag>
    );
  }

  return null;
};
