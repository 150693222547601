const toSize = (items: any[]) => {
  let size = 0;
  for (var i = 0; i < items.length; i++) {
    const objectSize = JSON.stringify(items[i]).length;
    size += objectSize;
  }
  return size;
};

const getDatabase = (dbName: string) => {
  return new Promise<IDBDatabase>((resolve) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = (event) => {
      const db = request.result;
      resolve(db);
    };
  });
};

const getStoreSize = (db: IDBDatabase, storeName: string) => {
  return new Promise<number>((resolve) => {
    const transaction = db.transaction(storeName, "readonly");
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.getAll();

    request.onsuccess = () => {
      const items = request.result;
      const size = toSize(items);
      resolve(size);
    };
  });
};

const getDatabaseSize = async (db: IDBDatabase) => {
  const storeNames = db.objectStoreNames;
  let size = 0;

  for (const storeName of storeNames) {
    size += await getStoreSize(db, storeName);
  }

  return size;
};

export const getIdbSize = async () => {
  const databaseNames = (await indexedDB.databases()).map(({ name }) => name);
  let size = 0;

  for (const name of databaseNames) {
    if (name) {
      const db = await getDatabase(name);
      size += await getDatabaseSize(db);
    }
  }

  return size;
};
