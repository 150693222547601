import { useDrag } from "react-dnd";
import type { IReport } from "../../../interfaces/reports";

import { Typography } from "antd";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import { routeDescriptor } from "../../../routes/routes";
import { compose } from "../../compose/WlyCompose";
import { useIsCached } from "../../hooks/useIsCached";
import { ReportIcon } from "../../report/ReportIcon";
import { OfflineTooltip } from "../../tooltips/OfflineTooltip";
import "./FolderItem.scss";

type FolderReportProps = {
  report: IReport;
  isOnline: boolean;
};
type Props = InjectedOrgProps & FolderReportProps;

const Comp = ({ report, isOnline, org }: Props) => {
  const isCached = useIsCached({ slug: report.slug });
  const disabled = useMemo(() => !isOnline && !isCached, [isCached, isOnline]);

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: "REPORT",
      item: report,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.3 : 1,
      }),
      options: {
        dropEffect: "move",
      },
    }),
    []
  );

  return (
    <div
      ref={disabled ? undefined : dragRef}
      className="folder-item v2"
      style={{ opacity }}
    >
      <OfflineTooltip
        isActive={disabled}
        contentLabel="report"
        placement="right"
      >
        <Link
          to={routeDescriptor.report.renderRoute({
            organizationSlug: org.slug,
            reportSlug: report.slug,
          })}
          onClick={
            disabled
              ? (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }
              : undefined
          }
          className="folder-item-name-container"
        >
          <div className="folder-item-name-image">
            <ReportIcon type={report.type} disabled={disabled} />
          </div>

          <div className="folder-item-name-title">
            <Typography.Text disabled={disabled}>{report.name}</Typography.Text>
          </div>
        </Link>
      </OfflineTooltip>
    </div>
  );
};

export const FolderReport = compose<Props, FolderReportProps>(WithOrg)(Comp);
