import type {
  BinaryFilter,
  Filter,
  Query,
  UnaryFilter,
} from "@cubejs-client/core";
import type { ColumnsSettings } from "../../../../../../../components/ag-grid/object-table/domain";
import type { MeasureItemSortValue } from "../../../../../../../components/measures/measure-item/MeasureItem";
import type { AvailableColumn } from "../../../../object/domain";
import { type BaseConfig } from "../domain";

export interface IRelatedListConfig extends BaseConfig {
  foreignObjectPropertyId?: string;
  options?: {
    columns?: string[];
    configurations?: IConfiguration[];
    columnGroups?: IColumnGroup[];
    allColumnGroupSelectedProps?: {
      show: boolean;
      name?: string;
    };
    columnsSettings?: ColumnsSettings;
    sortBy?: Array<[string, MeasureItemSortValue]>;
    groupBy?: Array<{ id: string; agg?: string }>;
    hidePagination?: boolean;
    showRowNumber?: boolean;
    pageSize?: number;
    filters?: IFilterEditorValue;
  };
}

export interface IFilterEditorValue {
  operator: "and" | "or";
  filters: (UnaryFilter | BinaryFilter)[];
}

export interface IConfiguration {
  name: string;
  isDefault?: boolean;
  sortBy?: Array<[string, MeasureItemSortValue]>;
  groupBy?: Array<{ id: string; agg?: string }>;
  filters?: IFilterEditorValue;
}

export interface IColumnGroup {
  name: string;
  isDefault?: boolean;
  columns?: string[];
  sortBy?: Array<[string, MeasureItemSortValue]>;
}

export const buildQueryFromDimensionAndMetrics = (
  dimensions: string[],
  metrics: string[],
  recordFilter?: {
    recordForeignKey: string;
    recordId: string;
  },
  additionalFilters?: IFilterEditorValue,
  sortBy?: Array<[string, MeasureItemSortValue]>,
  limit?: number
): Query => {
  const buildAdditionalFilters = (): Filter[] => {
    if (!additionalFilters || !additionalFilters.filters.length) {
      return [];
    }
    return [
      {
        [additionalFilters.operator]: additionalFilters.filters,
      },
    ] as Filter[];
  };

  const buildRecordFilter = (): Filter[] => {
    if (!recordFilter) {
      return [];
    }
    return [
      {
        member: recordFilter.recordForeignKey,
        operator: "equals",
        values: [recordFilter.recordId],
      },
    ];
  };

  const query: Query = {
    dimensions: dimensions,
    measures: metrics,
    filters: [
      {
        and: [...buildAdditionalFilters(), ...buildRecordFilter()],
      },
    ],
    order: sortBy,
    limit: limit,
  };
  return query;
};

export const buildQueryFromColumn = (
  columns: string[],
  availableColumns: AvailableColumn[],
  recordFilter?: {
    recordForeignKey: string;
    recordId: string;
  },
  additionalFilters?: IFilterEditorValue,
  sortBy?: Array<[string, MeasureItemSortValue]>,
  limit?: number
): Query => {
  // we are doing so because if a metric or dimension is added in the sort
  // field and not in the columns then it is not sorted properly
  const sorts = (sortBy || []).map((s) => s[0]);
  const metricToAdd = availableColumns
    .filter((ac) => ac.type === "metric" && sorts.includes(ac.data.key))
    .map((m) => m.data.key);
  const dimensionsToAdd = availableColumns
    .filter((ac) => ac.type === "property" && sorts.includes(ac.data.key))
    .map((m) => m.data.key);

  const dimensions = availableColumns
    .filter(
      (ac) =>
        ac.type === "property" &&
        (columns.includes(ac.data.key) || dimensionsToAdd.includes(ac.data.key))
    )
    .map((t) => t.data.key);
  const metrics = availableColumns
    .filter(
      (ac) =>
        ac.type === "metric" &&
        (columns.includes(ac.data.key) || metricToAdd.includes(ac.data.key))
    )
    .map((t) => t.data.key);

  return buildQueryFromDimensionAndMetrics(
    dimensions,
    metrics,
    recordFilter,
    additionalFilters,
    sortBy,
    limit
  );
};
