import { Typography } from "antd";
import { Fragment, useMemo } from "react";
import type { Formatter } from "../../../../../../../../../../components/chart/domain";
import { customFormatter } from "../../../../../../../../../../components/chart/utils/optionsHelper";
import type { UserLocale } from "../../../../../../../../../../interfaces/user";
import { parseLabel } from "../../../../../../domain";

import classNames from "classnames";
import "./ProgressTableSparklineRenderer.scss";

export interface IProgressTableSparklineRendererProps {
  config: {
    x: {
      key: string;
    };
    y: { key: string; formatter: Formatter };
    options: {
      highlightLabel?: string;
    };
    secondMetric: {
      key: string;
      formatter: Formatter;
    };
  };
  data: Array<{
    [key: string]: string | number | boolean;
  }>;
  locale: UserLocale;
  theme: { background: string; primary: string; secondary: string };
}

export function ProgressTableSparklineRenderer(
  props: IProgressTableSparklineRendererProps
) {
  const { config, data, theme, locale } = props;

  const totalMetricCount = useMemo(() => {
    return data.reduce((acc, item) => {
      return acc + Number(item[config.y.key]);
    }, 0);
  }, [config.y.key, data]);

  if (!data.length) return <></>;

  return (
    <div className="progress-table-sparkline-renderer">
      {data.map((item, index) => {
        const xLabel = item[config.x.key];
        const yLabel = item[config.y.key];
        const yFormatter = config.y.formatter;
        const secondMetricLabel = item[config.secondMetric.key];
        const secondMetricFormatter = config.secondMetric.formatter;
        const isFirstRow = index === 0;

        const { id, name } = parseLabel(
          typeof xLabel === "string" ? xLabel : ""
        );
        const percentage = Math.round(
          (Number(yLabel) / totalMetricCount) * 100
        );

        return (
          <Fragment key={index}>
            <Typography.Text
              ellipsis
              className={classNames("text", isFirstRow && "is-first-row")}
              style={{ color: theme.primary }}
            >
              {name ?? id}
            </Typography.Text>
            <Typography.Text
              className={classNames(
                "text justify-end",
                isFirstRow && "is-first-row"
              )}
              style={{ color: theme.secondary }}
            >
              {customFormatter(yLabel, locale, yFormatter)}
            </Typography.Text>
            <Typography.Text
              className={classNames(
                "text justify-end",
                isFirstRow && "is-first-row"
              )}
              style={{ color: theme.secondary }}
            >
              {customFormatter(
                secondMetricLabel,
                locale,
                secondMetricFormatter
              )}
            </Typography.Text>
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{
                  background: theme.primary,
                  width: `${percentage}%`,
                }}
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}
