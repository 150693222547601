import { inject, observer } from "mobx-react";
import React, { useMemo } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { compose } from "../../components/compose/WlyCompose";
import type { IDestination } from "../../interfaces/destinations";
import type {
  IOrg,
  IOrgFeatureType,
  IUserAttributeMeta,
} from "../../interfaces/org";
import { freetrialFeatures } from "../../interfaces/org";
import type { IUser, IUserFeatureType, IUserRole } from "../../interfaces/user";
import StorageService from "../../services/StorageService";
import type { UserStoreProps } from "../../store/userStore";
import { parseAttributeValue } from "../settings/user-attributes/domain";

export interface InjectedOrgProps {
  org: IOrg;
  orgFeatures: IOrgFeatureType[];
  userFeatures: IUserFeatureType[];
  user: IUser;
  userAttributes: ICompiledUserAttributes;
  role: IUserRole;
  warehouses: IDestination[];
}

export interface ICompiledUserAttributes {
  [key: string]: {
    id?: string;
    meta: IUserAttributeMeta;
    value: string[];
  };
}

export const compileUserAttributes = (
  user: IUser,
  userAttributeMetas: IUserAttributeMeta[]
): ICompiledUserAttributes => {
  return userAttributeMetas.reduce<ICompiledUserAttributes>((acc, v) => {
    const userAttribute = user.attributes.find(
      (a) => a.userAttributeMeta.id === v.id
    );

    return {
      ...acc,
      [v.technicalName]: {
        id: userAttribute?.id,
        meta: v,
        value: parseAttributeValue(
          userAttribute?.value ? userAttribute?.value : v.defaultValue
        ),
      },
    };
  }, {});
};

export const orgStorageKey = (user: IUser | undefined) =>
  `_wly_${user?.id ?? "unknown"}_org`;

export const getCurrentWarehouse = (
  org: IOrg,
  warehouseSlug?: string
): IDestination | undefined => {
  if (warehouseSlug && org.destinations.length > 0) {
    return org.destinations.find((w) => w.slug === warehouseSlug);
  }
};

const withOrg = <T extends InjectedOrgProps = InjectedOrgProps>(Component) => {
  return function WithOrg(
    props: RouteComponentProps<{
      organizationSlug: string;
      warehouseSlug?: string;
    }> &
      UserStoreProps &
      T
  ) {
    const { userStore, ...rest } = props;
    const {
      params: { organizationSlug, warehouseSlug },
    } = rest.match;
    const user = userStore.user;
    if (!organizationSlug || !user) {
      throw new Error("You need to specify at least a organization and a user");
    }
    const roles = user.roles;

    const foundOrganization = useMemo(
      () => roles.find((o) => o.org.slug === organizationSlug),
      [organizationSlug, roles]
    );

    if (!foundOrganization) {
      throw new Error("ORG_NOT_FOUND");
    }

    React.useEffect(() => {
      const key = orgStorageKey(user);
      const prevOrgId = StorageService.getItem(key);
      if (prevOrgId !== foundOrganization.org.id) {
        StorageService.setItem({ [key]: foundOrganization.org.id });
      }
    }, [foundOrganization.org.id]);

    const userAttributes = useMemo(
      () =>
        compileUserAttributes(user, foundOrganization.org.userAttributeMetas),
      [foundOrganization?.org?.id, user.id]
    );

    const orgFeatures: IOrgFeatureType[] = useMemo(
      () =>
        foundOrganization.org.featureGrants.map(
          (featGrant) => featGrant.feature.apiName as IOrgFeatureType
        ),
      [foundOrganization?.id]
    );

    if (
      foundOrganization.org.accountType === "partner" ||
      (foundOrganization.org.accountType === "customer" &&
        (foundOrganization.org.status === "freetrial" ||
          foundOrganization.org.status === "onboarding" ||
          foundOrganization.org.status === "poc"))
    ) {
      freetrialFeatures.map((f) => orgFeatures.push(f));
    }
    const userFeatures: IUserFeatureType[] = useMemo(
      () =>
        user.featureGrants.map(
          (featGrant) => featGrant.feature.apiName as IUserFeatureType
        ),
      [user?.id]
    );

    return (
      <Component
        {...props}
        org={foundOrganization.org}
        orgFeatures={orgFeatures}
        userFeatures={userFeatures}
        user={user}
        role={foundOrganization}
        userAttributes={userAttributes}
        warehouses={foundOrganization.org.destinations}
        {...rest}
      />
    );
  };
};

export default compose<any, InjectedOrgProps>(
  withRouter,
  inject("userStore"),
  observer,
  withOrg
);
