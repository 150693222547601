import type {
  CubejsApi,
  LoadMethodOptions,
  Query,
  SqlQuery,
} from "@cubejs-client/core";
import cubejs, { ResultSet } from "@cubejs-client/core";
import cubejsNext from "@cubejs-client/core-next";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import {
  CacheStrategy,
  DEFAULT_CACHE_STRATEGY,
  getKeyvalStringFromObject,
} from "../utils/cacheStrategy";
import { getBrowserTimezone } from "../utils/cubejsUtils";
import { retryPromise } from "../utils/promiseUtils";
import type { ApiOptions } from "./ApiService";
import ApiService from "./ApiService";
import {
  authenticatedRequest,
  getAccessToken,
  getAuthorizationType,
} from "./authService";
import { IDBService } from "./idbService";
import HttpTransport from "./LagoonTransport";

export interface WlyResultSet<T> extends ResultSet<T> {
  id: string;
  duration: number;
}

export type LagoonObjectType = "VIEW" | "EXPLORATION" | "OBJECT";
export enum LagoonCallOrigin {
  WHALY_APP = "WHALY_APP",
  PUSH = "PUSH",
}

const getIdbKey = (
  orgId: string,
  query: Query | Query[],
  objectType: LagoonObjectType,
  objectId: string,
  explorationId: string | undefined,
  origin: LagoonCallOrigin,
  reportId?: string,
  useLagoonNext?: boolean
) => {
  return getKeyvalStringFromObject({
    [objectType]: objectId,
    ...(objectType === "EXPLORATION" ? { explorationId } : {}),
    ...(objectType === "VIEW" ? { reportId } : {}),
    ...(objectType === "OBJECT" ? { reportId } : {}),
    orgId,
    origin,
    useLagoonNext,
    query,
  });
};

const catchError = (err: any, key: string) => {
  console.error(err);
  console.error("Error setting cache for Lagoon | idbKey: ", key);
};

const getLagoonLoadCacheResult = async (
  key: string
): Promise<WlyResultSet<any>> => {
  const cacheData = await IDBService.getLagoonResultResource<any>(key);

  const resultSet = ResultSet.deserialize(cacheData.cubeData);
  (resultSet as any).id = cacheData.id;
  (resultSet as any).duration = cacheData.duration;

  return resultSet as WlyResultSet<any>;
};
const setLagoonLoadCacheResult = async (
  key: string,
  set: WlyResultSet<any>
) => {
  const data = {
    id: set.id,
    duration: set.duration,
    cubeData: set.serialize(),
  };

  return IDBService.setLagoonResultResource(key, data).catch((err) =>
    catchError(err, key)
  );
};
const getLagoonSqlCacheResult = async (key: string) => {
  return IDBService.getLagoonSqlResource(key);
};
const setLagoonSqlCacheResult = async (key: string, data: SqlQuery) => {
  return IDBService.setLagoonSqlResource(key, data).catch((err) =>
    catchError(err, key)
  );
};

export const lagoonServiceLoad = async (
  orgId: string,
  query: Query | Query[],
  objectType: LagoonObjectType,
  objectId: string,
  explorationId: string | undefined,
  origin: LagoonCallOrigin,
  reportId?: string,
  options?: LoadMethodOptions | undefined,
  useLagoonNext?: boolean,
  timezone?: string,
  strategy: CacheStrategy = DEFAULT_CACHE_STRATEGY
): Promise<WlyResultSet<any>> => {
  await authenticatedRequest();

  const idbKey = getIdbKey(
    orgId,
    query,
    objectType,
    objectId,
    explorationId,
    origin,
    reportId,
    useLagoonNext
  );

  const getResultSet = () =>
    load(
      orgId,
      query,
      objectType,
      objectId,
      explorationId,
      origin,
      idbKey,
      reportId,
      options,
      useLagoonNext,
      timezone
    );

  const isOnline = window.navigator.onLine ?? true;
  const strategyToBeUsed = isOnline ? strategy : CacheStrategy.CACHE_ONLY;

  switch (strategyToBeUsed) {
    case CacheStrategy.CACHE_ONLY:
      return getLagoonLoadCacheResult(idbKey);
    case CacheStrategy.NETWORK_ONLY:
      return getResultSet();
    case CacheStrategy.CACHE_FIRST:
      return getLagoonLoadCacheResult(idbKey).catch(() => getResultSet());
    case CacheStrategy.NETWORK_FIRST:
      return getResultSet().catch(() => getLagoonLoadCacheResult(idbKey));
    case CacheStrategy.SWR:
      console.warn(
        "SWR cache strategy can't be used in LagoonService. Falling back to default strategy."
      );
      return getResultSet();
  }
};

export const lagoonServiceSQL = async (
  orgId: string,
  query: Query | Query[],
  objectType: LagoonObjectType,
  objectId: string,
  explorationId: string | undefined,
  origin: LagoonCallOrigin,
  reportId?: string,
  options?: LoadMethodOptions | undefined,
  useLagoonNext?: boolean,
  timezone?: string,
  strategy: CacheStrategy = DEFAULT_CACHE_STRATEGY
): Promise<SqlQuery> => {
  await authenticatedRequest();

  const idbKey = getIdbKey(
    orgId,
    query,
    objectType,
    objectId,
    explorationId,
    origin,
    reportId,
    useLagoonNext
  );

  const getSqlQuery = () =>
    sql(
      orgId,
      query,
      objectType,
      objectId,
      explorationId,
      origin,
      idbKey,
      reportId,
      options,
      useLagoonNext,
      timezone
    );

  const isOnline = window.navigator.onLine ?? true;
  const strategyToBeUsed = isOnline ? strategy : CacheStrategy.CACHE_ONLY;

  switch (strategyToBeUsed) {
    case CacheStrategy.CACHE_ONLY:
      return getLagoonSqlCacheResult(idbKey);
    case CacheStrategy.NETWORK_ONLY:
      return getSqlQuery();
    case CacheStrategy.CACHE_FIRST:
      return getLagoonSqlCacheResult(idbKey).catch(() => getSqlQuery());
    case CacheStrategy.NETWORK_FIRST:
      return getSqlQuery().catch(() => getLagoonSqlCacheResult(idbKey));
    case CacheStrategy.SWR:
      console.warn(
        "SWR cache strategy can't be used in LagoonService. Falling back to default strategy."
      );
      return getSqlQuery();
  }
};

const getCubeClient = async (
  useLagoonNext: boolean,
  orgId: string,
  objectType: string,
  objectId: string,
  baseRequestId: string,
  origin: LagoonCallOrigin,
  explorationId?: string,
  reportId?: string
) => {
  const headers = {
    "x-whaly-org-id": orgId,
    "x-whaly-object-type": objectType,
    "x-whaly-object-id": objectId,
    "x-whaly-origin": origin,
    ...(explorationId ? { "x-whaly-exploration-id": explorationId } : {}),
    ...(reportId ? { "x-whaly-report-id": reportId } : {}),
  };

  const token = await getAccessToken();
  const type = await getAuthorizationType();

  const authorization = `${type} ${token}`;

  if (useLagoonNext) {
    const url = `${window.WHALY_CONSTANTS.LAGOON_NEXT_URL}/cubejs-api/v1`;
    return cubejsNext(authorization, {
      apiUrl: url,
      transport: new HttpTransport({
        apiUrl: url,
        headers,
        authorization,
        baseRequestId,
      } as any),
      // We consider that the API is the same than the "current" version
    }) as unknown as CubejsApi;
  } else {
    const url = `${window.WHALY_CONSTANTS.LAGOON_URL}/cubejs-api/v1`;
    return cubejs(authorization, {
      apiUrl: url,
      transport: new HttpTransport({
        apiUrl: url,
        headers,
        authorization,
        baseRequestId,
      } as any),
    });
  }
};

const load = async (
  orgId: string,
  query: Query | Query[],
  objectType: LagoonObjectType,
  objectId: string,
  explorationId: string | undefined,
  origin: LagoonCallOrigin,
  idbKey: string,
  reportId?: string,
  options?: LoadMethodOptions | undefined,
  useLagoonNext?: boolean,
  timezone?: string
): Promise<WlyResultSet<any>> => {
  const startTs = Date.now();
  const baseRequestId = uuidv4();
  const cubeClient = await getCubeClient(
    !!useLagoonNext,
    orgId,
    objectType,
    objectId,
    baseRequestId,
    origin,
    explorationId,
    reportId
  );

  return retryPromise(
    () =>
      cubeClient.load(
        Array.isArray(query)
          ? query.map((q) => ({
              ...q,
              timezone: timezone ?? getBrowserTimezone(),
            }))
          : {
              ...query,
              timezone: timezone ?? getBrowserTimezone(),
            },
        {
          ...options,
        } as any
      ),
    { retries: 3, retryIntervalMs: 200 }
  )
    .then(async (r) => {
      const endTs = Date.now();
      const duration = endTs - startTs;
      // enhance resultSet with data we need for introspection
      (r as any).id = baseRequestId;
      (r as any).duration = duration;

      await setLagoonLoadCacheResult(idbKey, r as WlyResultSet<any>);

      return r as WlyResultSet<any>;
    })
    .catch((err) => {
      console.error(err);
      Sentry.captureException(err, (scope) => {
        scope.setTag("service", "lagoon");
        scope.setTag("requestId", baseRequestId);
        return scope;
      });
      let errMessage = err;
      if (typeof err === "string") {
        errMessage = errMessage + " requestId: " + baseRequestId;
      } else if (err instanceof Error) {
        errMessage.message =
          errMessage.message + " requestId: " + baseRequestId;
      }
      throw new Error(errMessage);
    });
};

const sql = async (
  orgId: string,
  query: Query | Query[],
  objectType: LagoonObjectType,
  objectId: string,
  explorationId: string | undefined,
  origin: LagoonCallOrigin,
  idbKey: string,
  reportId?: string,
  options?: LoadMethodOptions | undefined,
  useLagoonNext?: boolean,
  timezone?: string
): Promise<SqlQuery> => {
  const baseRequestId = uuidv4();
  const cubeClient = await getCubeClient(
    !!useLagoonNext,
    orgId,
    objectType,
    objectId,
    baseRequestId,
    origin,
    explorationId,
    reportId
  );

  return cubeClient
    .sql(
      {
        ...query,
        timezone: timezone ?? getBrowserTimezone(),
      },
      {
        ...options,
      }
    )
    .then(async (sqlQuery) => {
      await setLagoonSqlCacheResult(idbKey, sqlQuery);
      return sqlQuery;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};

export interface IQueryTraceResult<T = any> {
  isFromServingLayer: boolean;
  isFromCache: boolean;
  events: IQueryTraceData<T>[];
}

export interface IQueryTraceData<T = any> {
  evtName: string;
  offset: number;
  requestId: string;
  span: string;
  ts: number;
  data: T;
}

export const traceQuery = (
  queryId: string,
  useLagoonNext: boolean
): Promise<IQueryTraceResult> => {
  const opts: ApiOptions = {};
  if (useLagoonNext) {
    opts.lagoonNextUrl = true;
  } else {
    opts.lagoonUrl = true;
  }
  return ApiService.getRequest(`/trace/${queryId}`, undefined, undefined, {
    authenticated: false,
    ...opts,
  }).then((r) => {
    const { data, isFromCache, isFromServingLayer } = r as {
      status: "ok";
      isFromServingLayer: boolean;
      isFromCache: boolean;
      data: IQueryTraceData[];
    };

    return {
      isFromCache,
      isFromServingLayer,
      events: data,
    };
  });
};
