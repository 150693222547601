import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../../../../../interfaces/sources";
import graphQlService from "../../../../../../../../services/graphql/GraphQLService";

// get all unprocessed datasets
// get those who satisfies all requirement
// modify the payload
// save
// write key to map
// repeat until there is none
// save relationships
export class DatasetProcessor {
  alreadySavedDatasetIds: string[];
  allDatasets: IDataset[];
  datasetMap: Map<string, string>;
  datasetViewMap: Map<string, string>;
  modelFolderMap: Map<string, string>;
  orgId: string;
  warehouseId: string;

  constructor(
    orgId: string,
    warehouseId: string,
    datasets: IDataset[],
    datasetMap: Map<string, string>,
    datasetViewMap: Map<string, string>,
    modelFolderMap: Map<string, string>
  ) {
    this.orgId = orgId;
    this.warehouseId = warehouseId;
    this.alreadySavedDatasetIds = [];
    this.allDatasets = datasets;
    this.datasetMap = datasetMap;
    this.datasetViewMap = datasetViewMap;
    this.modelFolderMap = modelFolderMap;
  }

  private getUnprocessedDatasets = (): IDataset[] => {
    // we only process models. Sources help us find bottom models but we do not replicate them
    return this.allDatasets.filter(
      (d) => !this.alreadySavedDatasetIds.includes(d.id) && !d.source
    );
  };

  private getRelationships = () => {
    return this.allDatasets
      .filter((d) => this.alreadySavedDatasetIds.includes(d.id))
      .flatMap((d) => d.incomingRelationships);
  };

  private getDatasetThatSatisfiesRequirement = (
    unprocessedDatasets: IDataset[]
  ) => {
    return unprocessedDatasets.filter((unprocessed) => {
      const unprocessedDependsOnKeys = unprocessed.dependsOn
        .map((dp) => dp.child?.id)
        .filter((k) => !!k);
      const allSourceIds = this.allDatasets
        .filter((d) => d.source?.id)
        .map((d) => d.id);
      return unprocessedDependsOnKeys.reduce((acc, k) => {
        const isIncludedInProcessedDataset =
          this.alreadySavedDatasetIds.includes(k);
        const isSourceDataset = allSourceIds.includes(k);
        if (acc) {
          if (isIncludedInProcessedDataset || isSourceDataset) {
            return true;
          }
          return false;
        } else {
          return false;
        }
      }, true);
    });
  };

  private getDatasetPayload = (unprocessedDataset: IDataset) => {
    const getFormattedSql = () => {
      if (!unprocessedDataset.sql) {
        return unprocessedDataset.sql;
      }
      return this.alreadySavedDatasetIds.reduce((acc, v) => {
        return acc
          .replaceAll(`\${RAW["${v}"]}`, `\${RAW["${this.datasetMap.get(v)}"]}`)
          .replaceAll(
            `\${DATASETS["${v}"]}`,
            `\${DATASETS["${this.datasetMap.get(v)}"]}`
          );
      }, unprocessedDataset.sql);
    };

    return {
      name: unprocessedDataset.name,
      description: unprocessedDataset.description,
      primaryKey: unprocessedDataset.primaryKey,
      head: unprocessedDataset.head,
      type: unprocessedDataset.type,
      sql: getFormattedSql(),
      isModel: unprocessedDataset.isModel,
      folder: unprocessedDataset.folder?.id
        ? {
            connect: {
              id: this.modelFolderMap.get(unprocessedDataset.folder.id),
            },
          }
        : undefined,
      managedBy: unprocessedDataset.managedBy,
      warehouseDatabaseId: unprocessedDataset.warehouseDatabaseId,
      warehouseTableId: unprocessedDataset.warehouseTableId,
      warehouseSchemaId: unprocessedDataset.warehouseSchemaId,
      isPersistedAs: unprocessedDataset.isPersistedAs,
      warehouseViewDatabaseId: unprocessedDataset.warehouseViewDatabaseId,
      warehouseViewTableId: unprocessedDataset.warehouseViewTableId,
      warehouseViewSchemaId: unprocessedDataset.warehouseViewSchemaId,
      columnTests: unprocessedDataset.columnTests,
      cacheStrategy: unprocessedDataset.cacheStrategy,
      hideFromInterface: unprocessedDataset.hideFromInterface,
      source: undefined,
      org: {
        connect: {
          id: this.orgId,
        },
      },
      warehouse: {
        connect: {
          id: this.warehouseId,
        },
      },
    };
  };

  processSingleDataset = async (dataset: IDataset) => {
    console.log("Creating ", dataset.name);
    const payload = this.getDatasetPayload(dataset);
    const d = await graphQlService<{
      createDataset: { id: string; views: Array<{ id: string }> };
    }>(
      `
    mutation createDataset($data: DatasetCreateInput) {
      createDataset(data: $data) {
        id
        views {
          id
        }
      }
    }  
    `,
      {
        data: payload,
      }
    );
    const newDatasetId = d.createDataset.id;
    const prevViewId = dataset.views[0]?.id;
    const viewId = d.createDataset.views?.[0]?.id;
    if (prevViewId && viewId) {
      this.datasetViewMap.set(prevViewId, viewId);
    }

    this.datasetMap.set(dataset.id, newDatasetId);
    this.alreadySavedDatasetIds.push(dataset.id);
  };

  private processWave = async (waveNumber = 0) => {
    console.group("Starting dataset creation wave number ", waveNumber);
    const unprocessedDatasets = this.getUnprocessedDatasets();
    console.log(
      "There are ",
      unprocessedDatasets.length,
      " datasets remainings"
    );
    if (!unprocessedDatasets.length) {
      console.groupEnd();
      console.log("Ending creating dataset");
      return;
    }
    const satisfyRequirements =
      this.getDatasetThatSatisfiesRequirement(unprocessedDatasets);
    console.log("Only ", satisfyRequirements.length, " satisfy requirements");

    if (!satisfyRequirements.length) {
      console.groupEnd();
      console.log("Ending creating dataset");
      return;
    }

    console.log(satisfyRequirements);
    await satisfyRequirements.reduce(async (acc, v) => {
      await acc;
      return this.processSingleDataset(v);
    }, Promise.resolve());
    console.groupEnd();
    return this.processWave(waveNumber + 1);
  };

  private persistRelationship = async (rel: IDatasetRelationship) => {
    const leftDatasetNewId = this.datasetMap.get(rel.left.id);
    const rightDatasetNewId = this.datasetMap.get(rel.right.id);
    if (leftDatasetNewId && rightDatasetNewId) {
      const payload = {
        from: rel.from,
        to: rel.to,
        type: rel.type,
        editable: rel.editable,
        left: {
          connect: {
            id: leftDatasetNewId,
          },
        },
        right: {
          connect: {
            id: rightDatasetNewId,
          },
        },
        org: {
          connect: {
            id: this.orgId,
          },
        },
      };
      const d = await graphQlService<{
        createDataset: { id: string; views: Array<{ id: string }> };
      }>(
        `
      mutation createDatasetRelationship($data: DatasetRelationshipCreateInput) {
        createDatasetRelationship(data: $data) {
          id
        }
      }  
      `,
        {
          data: payload,
        }
      );
    }
  };

  private processDatasetRelationships = async () => {
    const relationships = this.getRelationships();
    await relationships.reduce(async (acc, v) => {
      await acc;
      return this.persistRelationship(v);
    }, Promise.resolve());
  };

  process = async () => {
    await this.processWave();
    await this.processDatasetRelationships();
  };
}
