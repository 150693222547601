import type { IModelFolder } from "../../../../../../../../interfaces/modelFolder";
import graphQlService from "../../../../../../../../services/graphql/GraphQLService";

export class ModelFolderProcessor {
  allModelFolders: IModelFolder[];
  modelFolderMap: Map<string, string>;
  orgId: string;
  warehouseId: string;

  constructor(
    orgId: string,
    warehouseId: string,
    modelFolders: IModelFolder[],
    modelFolderMap: Map<string, string>
  ) {
    this.orgId = orgId;
    this.warehouseId = warehouseId;
    this.allModelFolders = modelFolders;
    this.modelFolderMap = modelFolderMap;
  }

  private getPayload = (m: IModelFolder) => {
    return {
      name: m.name,
      description: m.description,
      image: m.image,
      order: m.order,
      org: {
        connect: {
          id: this.orgId,
        },
      },
      warehouse: {
        connect: {
          id: this.warehouseId,
        },
      },
    };
  };

  private singleCreateProcess = async (mf: IModelFolder): Promise<void> => {
    const paylaod = this.getPayload(mf);
    const d = await graphQlService<{ createModelFolder: { id: string } }>(
      `
    mutation createModelFolder($data: ModelFolderCreateInput) {
      createModelFolder(data: $data) {
        id
      }
    }  
    `,
      {
        data: paylaod,
      }
    );
    this.modelFolderMap.set(mf.id, d.createModelFolder.id);
  };

  private singleUpdateProcess = async (
    id: string,
    data: any
  ): Promise<void> => {
    await graphQlService<{ updateModelFolder: { id: string } }>(
      `
    mutation updateModelFolder($id: ID!, $data: ModelFolderUpdateInput) {
      updateModelFolder(id: $id, data: $data) {
        id
      }
    }  
    `,
      {
        id,
        data,
      }
    );
  };

  private markParents = () => {
    const allModelFolderWithParents = this.allModelFolders.filter(
      (mf) => mf.parent?.id
    );
    allModelFolderWithParents.reduce(async (acc, v) => {
      await acc;
      const currentKey = this.modelFolderMap.get(v.id);
      const parentKey = this.modelFolderMap.get(v.parent?.id!);
      return this.singleUpdateProcess(currentKey!, {
        parent: { connect: { id: parentKey } },
      });
    }, Promise.resolve());
  };

  process = async () => {
    console.group("Importing model folders");
    console.group("Creating model folder");
    await this.allModelFolders.reduce(async (acc, mf) => {
      await acc;
      return this.singleCreateProcess(mf);
    }, Promise.resolve());
    console.groupEnd();
    console.group("Marking model folder parent");
    await this.markParents();
    console.groupEnd();
    console.groupEnd();
  };
}