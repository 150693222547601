import { Tooltip } from "antd";
import { useMemo } from "react";
import type { RouteComponentProps } from "react-router";
import { matchPath, withRouter } from "react-router";
import { Link } from "react-router-dom";
import type { IObject } from "../../../../interfaces/object";
import { type RouteDefinition } from "../../../../routes/domain";
import { routeDescriptor } from "../../../../routes/routes";
import { grey } from "../../../../utils/colorPalette";
import { compose } from "../../../compose/WlyCompose";
import { useCachedRecord } from "../../../hooks/useCachedRecord";
import { useIsOnline } from "../../../hooks/useIsOnline";

interface ILabelCellRendererProps {
  value: string;
  object: IObject | undefined;
  displayImage?: boolean;
}

export const getCurrentPath = (
  pathName: string
): RouteDefinition | undefined => {
  const allPaths = Object.keys(routeDescriptor).map<RouteDefinition>(
    (k) => routeDescriptor[k]
  );
  const path = allPaths.find((p) => {
    const t = matchPath(pathName, p.path);
    if (t && t.isExact) {
      return true;
    }
    return false;
  });

  return path;
};

type Props = ILabelCellRendererProps &
  RouteComponentProps<{ organizationSlug: string }>;

const LabelCellRenderer = (props: Props) => {
  const {
    value,
    object,
    displayImage,
    match: {
      params: { organizationSlug },
    },
    location: { pathname },
  } = props;
  const [id, name, image] = (value ?? "")?.split("||");

  const isOnline = useIsOnline();
  const { isCached, updatedAt } = useCachedRecord(
    id,
    object?.id ?? "",
    object?.slug ?? ""
  );

  const isDisabled = useMemo(() => {
    return !isOnline && !isCached;
  }, [isOnline, isCached]);

  const renderRoute = () => {
    const path = getCurrentPath(pathname);
    if (path) {
      if (path.path === routeDescriptor["object-record-embed"].path) {
        return routeDescriptor["object-record-embed"].renderRoute({
          organizationSlug,
          objectSlug: object?.slug,
          recordId: id,
        });
      }
      if (path.path === routeDescriptor["object-record-public-shared"].path) {
        return routeDescriptor["object-record-public-shared"].renderRoute({
          organizationSlug,
          objectSlug: object?.slug,
          recordId: id,
        });
      }
    }
    return routeDescriptor["object-record-centered"].renderRoute({
      organizationSlug,
      objectSlug: object?.slug,
      recordId: id,
    });
  };

  if (id && object?.slug && name) {
    return (
      <Tooltip title={isDisabled ? "Not available offline" : ""}>
        <Link
          to={renderRoute()}
          onClick={(e) => isDisabled && e.preventDefault()}
          style={isDisabled ? { color: grey[5], cursor: "not-allowed" } : {}}
        >
          {displayImage && image && (
            <>
              <img
                width={20}
                height={20}
                style={{
                  borderRadius: 12,
                  marginTop: -4,
                  marginRight: 8,
                  filter: isDisabled ? "grayscale(100%)" : "none",
                }}
                src={image}
                alt={name}
              />
            </>
          )}
          {name}
        </Link>
      </Tooltip>
    );
  } else if (name) {
    return <>{name}</>;
  } else {
    return null;
  }
};

export default compose<Props, ILabelCellRendererProps>(withRouter)(
  LabelCellRenderer
);
