import { Tooltip, type TooltipProps } from "antd";

type OfflineTooltipProps = {
  isActive?: boolean;
  contentLabel?: string;
} & TooltipProps;

export const OfflineTooltip = ({
  isActive = false,
  contentLabel = "content",
  ...rest
}: OfflineTooltipProps) => {
  return (
    <Tooltip
      title={
        isActive ? `You need to be online to view this ${contentLabel}` : ""
      }
      {...rest}
    />
  );
};
