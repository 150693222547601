import type { Query } from "@cubejs-client/core";
import { Form } from "antd";
import type { AvailableDimension } from "../../../../../../../components/measures/filter-item/FilterItem";
import type { IObject } from "../../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../../interfaces/org";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../services/LagoonService";
import ChartOptionLine from "../../../../../../chart-options/components/ChartOptionLine";
import { FilterEditor } from "../related-lists/editor/FilterEditor";

type FilterSelectorProps = {
  availableDimensions: AvailableDimension[];
  label: string;
  name: Array<string | number>;
  org: IOrg;
  object: IObject;
  required?: boolean;
};

export const FilterSelector = ({
  availableDimensions,
  label,
  name,
  org,
  object,
  required = false,
}: FilterSelectorProps) => {
  return (
    <ChartOptionLine
      items={[
        {
          content: (
            <Form.Item required={required} name={name} label={label}>
              <FilterEditor
                autocomplete={async (dimension, operator, value) => {
                  try {
                    const dim = availableDimensions.find(
                      (ac) => dimension === ac.key
                    );

                    if (!dim) {
                      throw new Error("Dim not found");
                    }

                    let query: Query = {
                      dimensions: [dim.key],
                      limit: 50,
                      filters: [
                        {
                          member: dim.key,
                          operator: "set",
                        },
                      ],
                    };

                    if (!!value && value !== "" && operator) {
                      query = {
                        ...query,
                        filters: [
                          {
                            member: dim.key,
                            operator,
                            values: [value],
                          },
                        ],
                      };
                    }

                    const data = await lagoonServiceLoad(
                      org.id,
                      query,
                      "OBJECT",
                      object.id,
                      undefined,
                      LagoonCallOrigin.WHALY_APP
                    );

                    const results = data.tablePivot();

                    return results.map((r) => r[dim.key]?.toString?.());
                  } catch (err) {
                    console.error(err);
                    throw new Error("Can't fetch recommendations");
                  }
                }}
                availableDimensions={availableDimensions}
              />
            </Form.Item>
          ),
          flex: 1,
        },
      ]}
    />
  );
};
