import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer, Space, Typography } from "antd";
import cuid from "cuid";
import React, { useState } from "react";
import { usePropertyContext } from "../domain";
import { DocumentPropertyItem } from "./DocumentPropertyItem";
import { type IDocumentProperty } from "./domain";

export interface IDocumentsCellRendererProps {
  property?: string;
  size?: "small" | "large" | "middle";
}

export function DocumentsCellRenderer(props: IDocumentsCellRendererProps) {
  const { property, size } = props;

  const [context, setValue, value] = usePropertyContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const parsedProperty = React.useMemo(() => {
    try {
      const t = JSON.parse(property || '{ "items": [] }');
      return t as IDocumentProperty;
    } catch (err) {
      console.error(err);
      return { items: [] };
    }
  }, [property]);

  const getButtonName = () => {
    return `${
      parsedProperty?.items?.length > 0 ? parsedProperty?.items?.length : "0"
    } ${parsedProperty?.label ? parsedProperty?.label : "Documents"}`;
  };

  const buttonName = getButtonName();

  const renderInner = () => {
    return (
      <div>
        {context === "INNER_MODAL" && (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Typography.Title level={5}>{buttonName}</Typography.Title>
            </div>
            <div>
              <Button
                icon={<CloseOutlined />}
                shape="circle"
                type="text"
                onClick={() => {
                  setIsDrawerOpen(false);
                  if (value && value.status === "success") {
                    const { showPanel, ...rest } = value.data;
                    setValue?.({
                      ...value,
                      data: {
                        ...rest,
                        key: cuid(),
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
        )}
        <div style={{ paddingTop: 12 }}>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            {parsedProperty?.items.map((_, i) => {
              return (
                <div style={{ width: "100%" }} key={i}>
                  <DocumentPropertyItem documentPropertyItem={_} />
                </div>
              );
            })}
          </Space>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Button
        onClick={() => {
          setIsDrawerOpen((v) => !v);
          if (value && value.status === "success") {
            setValue?.({
              ...value,
              data: {
                ...value.data,
                key: cuid(),
                showPanel: {
                  type: "JSX",
                  elements: [renderInner()],
                },
              },
            });
          }
        }}
        size={size}
        shape="round"
      >
        {buttonName}
      </Button>
      {context === "DRAWER" && (
        <Drawer
          styles={{ body: { padding: 8 }, header: { padding: 8 } }}
          open={isDrawerOpen}
          title={buttonName}
          onClose={() => {
            setIsDrawerOpen(false);
            if (value && value.status === "success") {
              setValue?.({
                ...value,
                data: {
                  ...value.data,
                  showPanel: null as any,
                  key: cuid(),
                },
              });
            }
          }}
        >
          {renderInner()}
        </Drawer>
      )}
    </div>
  );
}
