import { Col, Dropdown, Row, Typography } from "antd";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Aligner from "../../../../../../components/layout/aligner/Aligner";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import ImportWorkbench from "./ImportWorkbench";
import { deleteWorkbench } from "./services/deleteWorkbenchService";
import { downloadWorkbench } from "./services/downloadWorkbenchService";

const { Title } = Typography;

interface IExportWorkbenchPageProps {
  currentWarehouse: IDestination;
}

type Props = IExportWorkbenchPageProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function ExportWorkbenchPage(props: Props) {
  const { currentWarehouse, org, antUtils } = props;

  const [isExporting, setIsExporting] = React.useState<boolean>(false);
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

  const exportWb = async () => {
    const l = antUtils.message.loading("Exporting");
    setIsExporting(true);
    try {
      await downloadWorkbench(org, currentWarehouse);
      l();
      antUtils.message.success("Sucessfully exporting your workbench");
    } catch (err) {
      console.error(err);
      l();
      antUtils.message.error("Error while exporting");
    } finally {
      setIsExporting(false);
    }
  };

  const delWb = async () => {
    const l = antUtils.message.loading("Loading", 0);
    setIsDeleting(true);
    try {
      await deleteWorkbench(org.id, currentWarehouse.id);
      l();
      antUtils.message.success("Successfully deleted workbench");
      window.location.reload();
    } catch (err) {
      console.error(err);
      antUtils.message.error("Error deleting workbench");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Aligner>
      <Row justify="center" className="access-management">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <div style={{ paddingTop: 16, display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Title style={{ marginBottom: 0 }} level={3}>
                Export Workbench
              </Title>
            </div>
            <div>
              <Dropdown.Button
                menu={{
                  items: [
                    {
                      key: "delete",
                      label: "Delete",
                      danger: true,
                      onClick: () => {
                        return new Promise((resolve, reject) => {
                          antUtils.modal.warning({
                            content: `You are about to delete all objects, models and folders from the org ${org.name}. This cannot be undone, this is very serious, proceed with caution. Are you sure ?`,
                            onOk: () => delWb(),
                            onCancel: () => resolve({}),
                          });
                        });
                      },
                    },
                  ],
                }}
                htmlType="submit"
                trigger={["click"]}
                loading={isExporting}
                onClick={() => exportWb()}
              >
                Export
              </Dropdown.Button>
            </div>
          </div>
          <div style={{ marginTop: 24 }}>
            <ImportWorkbench currentWarehouse={currentWarehouse} />
          </div>
        </Col>
      </Row>
    </Aligner>
  );
}

export default compose<Props, IExportWorkbenchPageProps>(
  WithOrg,
  withAntUtils
)(ExportWorkbenchPage);
