import { Skeleton } from "antd";
import type { IRendererConfig } from "../domain";
import type { IWidgetRepeatableConfig } from "./domain";
import RepeatableWidgetEditor from "./editor/RepeatableWidgetEditor";
import { RepeatableWidget } from "./widget/RepeatableWidget";

export const widgetRepeatableDefinition: IRendererConfig<IWidgetRepeatableConfig> =
  {
    removeCard: true,
    parseConfig: (widget) => {
      const defaultValue: IWidgetRepeatableConfig = {
        type: "TAB",
        options: {
          limit: 10,
        },
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IWidgetRepeatableConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, form, org) => {
      return (
        <RepeatableWidgetEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          form={form}
        />
      );
    },
    renderComponent: (
      widget,
      object,
      record,
      layoutId,
      recordId,
      conf,
      edit
    ) => {
      return (
        <RepeatableWidget
          widget={widget}
          object={object}
          record={record}
          layoutId={layoutId}
          recordId={recordId}
          conf={conf}
          edit={edit}
        />
      );
    },
    renderLoadingComponent: (widget, conf) => (
      <Skeleton.Button
        active
        block
        size="small"
        shape="default"
        style={{ height: 250 }}
      />
    ),
  };
