import { CaretDownOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, Space, Typography, theme } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import { useIsOnline } from "../../../../components/hooks/useIsOnline";
import Feednack from "../../../../components/layout/feedback/feedback";
import { routeDescriptor } from "../../../../routes/routes";
import type { WorkspaceDatatoreProps } from "../../../../store/dataStores/workspace/workspaceDatastore";
import ExplorationMenu from "../../../exploration/menu/ExplorationMenu";
import ExplorationEditor from "../../../exploration/single/ExplorationEditor";
import type { ILagoonQuery } from "../../../exploration/single/domain";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { BORDER_COLOR } from "../layout/domain";
import "./ExplorationModal.scss";

const { useToken } = theme;

interface IExplorationModalProps {
  open: boolean;
  onClose: () => void;
  isEmbed?: boolean;
  initialQuery?: ILagoonQuery;
}

type Props = WorkspaceDatatoreProps &
  IExplorationModalProps &
  RouteComponentProps<{ exploreSlug: string }> &
  InjectedOrgProps;

function ExplorationModal(props: Props) {
  const {
    open,
    workspaceDatastore: { data },
    onClose,
    history,
    match: {
      params,
      params: { exploreSlug },
    },
  } = props;

  const isOnline = useIsOnline();
  const { token } = useToken();

  useEffect(() => {
    if (open && !isOnline) {
      onClose();
    }
  }, [isOnline, onClose, open]);

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: 400,
    maxHeight: 500,
    overflow: "scroll",
    padding: 6,
    fontWeight: "initial",
  };

  if (data.status !== "success") {
    return (
      <Feednack>
        <div>Please load datastore first</div>
      </Feednack>
    );
  }

  const setInitialExploration = (s: string) => {
    history.push(
      routeDescriptor["explore"].renderRoute({ ...params, exploreSlug: s })
    );
  };

  const currentExploration = data.data.allExplorations.find(
    (ex) => ex.slug === exploreSlug
  );

  const renderInner = () => {
    if (exploreSlug) {
      const renderTitle = () => {
        if (currentExploration) {
          return <span>{currentExploration.name}</span>;
        }
        return <Typography.Text type="danger">Not Found</Typography.Text>;
      };
      return (
        <>
          <div
            style={{ borderBottom: `1px solid ${BORDER_COLOR}` }}
            className="exploration-title"
          >
            <div className="exploration-title-content">
              <Typography.Title
                id={"exploration-title"}
                style={{ marginBottom: 0 }}
                level={5}
              >
                From{" "}
                <Dropdown
                  trigger={["click"]}
                  placement="bottomLeft"
                  arrow
                  menu={{
                    items: [],
                  }}
                  dropdownRender={() => {
                    return (
                      <div style={contentStyle}>
                        <ExplorationMenu
                          hideTitle={true}
                          explorations={data.data.allExplorations}
                          sections={data.data.allExplorationSections}
                          isDragging={false}
                          onClick={(s) => setInitialExploration(s)}
                        />
                      </div>
                    );
                  }}
                  getPopupContainer={() => {
                    const d = document.getElementById("exploration-title");
                    if (d) {
                      return d;
                    }
                    return document.body;
                  }}
                >
                  <span className="underlined">
                    {renderTitle()}{" "}
                    <span style={{ fontSize: "0.7em" }}>
                      <CaretDownOutlined />
                    </span>
                  </span>
                </Dropdown>
              </Typography.Title>
            </div>
            <div className="exploration-title-action">
              <Space>
                <div className="exploration-title-action-portal" />
                <Button
                  onClick={onClose}
                  icon={<CloseOutlined />}
                  type="text"
                  shape="circle"
                />
              </Space>
            </div>
          </div>
          <div
            style={{
              maxHeight: "Calc(100% - 56px)",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <ExplorationEditor
              initialQuery={undefined}
              injectedExplorationSlug={exploreSlug}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{ borderBottom: `1px solid ${BORDER_COLOR}` }}
            className="exploration-title"
          >
            <div className="exploration-title-content">
              <Typography.Title
                id={"exploration-title"}
                style={{ marginBottom: 0 }}
                level={5}
              >
                Create a chart
              </Typography.Title>
            </div>
            <div className="exploration-title-action">
              <Button
                onClick={onClose}
                icon={<CloseOutlined />}
                type="text"
                shape="circle"
              />
            </div>
          </div>
          <div
            style={{
              maxHeight: "Calc(100% - 56px)",
              overflowX: "hidden",
              overflowY: "auto",
              padding: `16px 0`,
            }}
          >
            <ExplorationMenu
              hideTitle={true}
              explorations={data.data.allExplorations}
              sections={data.data.allExplorationSections}
              isDragging={false}
              onClick={(s) => setInitialExploration(s)}
            />
          </div>
        </>
      );
    }
  };

  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      closable={false}
      width={exploreSlug ? "90%" : "50%"}
      centered
      className="exploration-modal"
      styles={{
        body: {
          height: "100%",
          padding: 0,
          maxHeight: "100%",
          overflow: "hidden",
        },
      }}
      footer={null}
      open={open}
    >
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, IExplorationModalProps>(
  inject("workspaceDatastore"),
  observer,
  WithOrg,
  withRouter
)(ExplorationModal);
