import Markdoc from "@markdoc/markdoc";
import { Button, Modal, Skeleton, Typography } from "antd";
import React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { type AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import { type IObject } from "../../../../../../../../interfaces/object";
import { IUserRoleType } from "../../../../../../../../interfaces/user";
import { getCompletions } from "../../../../../../../../services/BrizoService";
import WithOrg, {
  type InjectedOrgProps,
} from "../../../../../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../../../../../user-settings/HasRoleAccess";
import { getObjectColumns } from "../../../../../object/domain";
import { type IRecord } from "../../../../domain";
import { type IWidget } from "../../../domain";

import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import { IDB_GET_ERROR } from "../../../../../../../../services/idbService";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import { fetchDatasheetConcurrently } from "../../../common/markdoc/datasheet/api";
import {
  convertDataSheetNameToMarkdocVariable,
  convertKeyToMarkdocVariable,
  getParsedDoc,
} from "../../../common/markdoc/domain";
import {
  additionalComponents,
  nodesConfig,
} from "../../markdown/widget/markdoc-react-component/config";
import { type IGeneratedTextConfig } from "../domain";
import renderTreeNodeAsText from "../renderAsText";
import "./GeneratedTextRenderer.scss";

export interface IGeneratedTextRendererProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IGeneratedTextConfig;
  edit?: boolean;
}

type Props = IGeneratedTextRendererProps & InjectedOrgProps;

function GeneratedTextRenderer(props: Props) {
  const { widget, conf, record, object, layoutId, recordId, user, org, edit } =
    props;

  const [opened, setOpened] = React.useState<boolean>(false);
  const [data, setData] = React.useState<
    AsyncData<{ answer: string; originalPrompt: string }>
  >({
    status: "initial",
  });
  const { setAsFinished } = useWidgetCacheActions({
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  });

  const source = conf?.prompt ? conf.prompt : "";
  const model = conf?.model;
  const dataSheets = conf?.dataSheets || [];

  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, {});
  const columns = getObjectColumns(object);

  const fetchData = async () => {
    try {
      setData({ status: "loading" });
      // fetch all datasheet & materialize them
      const dataSheetsResults = await fetchDatasheetConcurrently(
        dataSheets,
        org,
        record,
        object
      );

      const formattedDatasheetResults = Object.keys(dataSheetsResults).reduce(
        (acc, v) => {
          return {
            ...acc,
            [convertDataSheetNameToMarkdocVariable(v)]: dataSheetsResults[v],
          };
        },
        {}
      );

      const enhancedFormattedRecord = {
        ...formattedRecord,
        ...formattedDatasheetResults,
      };

      const content = getParsedDoc(
        source,
        enhancedFormattedRecord,
        user,
        columns,
        nodesConfig
      );

      const string = renderTreeNodeAsText(content);

      const d = await getCompletions(org.id, {
        model: conf.model,
        prompt: string,
      });

      setAsFinished();
      setData({
        status: "success",
        data: { answer: d.data, originalPrompt: string },
      });
    } catch (err) {
      console.error(err);
      setAsFinished(true);
      setData({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    if (!edit) {
      fetchData();
    }
  }, [conf.prompt, conf.model]);

  if (!source || !model) {
    return (
      <Typography.Text type="secondary">
        Please configure this widget
      </Typography.Text>
    );
  }

  if (edit) {
    return <div style={{ whiteSpace: "pre-wrap" }}>{source}</div>;
  }

  if (data.status === "initial" || data.status === "loading") {
    return (
      <div>
        <Skeleton active />
      </div>
    );
  }

  if (data.status === "error") {
    return (
      <div style={{ height: 250 }}>
        {data.error.cause === IDB_GET_ERROR ? (
          <Feednack>You need to be online to view this content</Feednack>
        ) : (
          data.error.message
        )}
      </div>
    );
  }

  const responseContent = getParsedDoc(
    data.data.answer,
    formattedRecord,
    user,
    columns,
    nodesConfig
  );

  const html = Markdoc.renderers.react(responseContent, React, {
    ...additionalComponents,
  });

  return (
    <>
      <div className="generated-text-renderer" style={{ position: "relative" }}>
        {html}
        {hasRoleAccessBoolean(IUserRoleType.ADMIN_BUILDER, user, org.id) && (
          <Button
            size="small"
            style={{ position: "absolute" }}
            className="generated-text-renderer-debug"
            onClick={() => setOpened(true)}
          >
            View original prompt
          </Button>
        )}
      </div>
      <Modal
        open={opened}
        title={"Original Prompt"}
        onCancel={() => setOpened(false)}
        okButtonProps={{ style: { display: "none" } }}
      >
        <div style={{ whiteSpace: "pre-wrap" }}>{data.data.originalPrompt}</div>
      </Modal>
    </>
  );
}

export default compose<Props, IGeneratedTextRendererProps>(WithOrg)(
  GeneratedTextRenderer
);
