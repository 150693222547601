import { Divider } from "antd";
import { useEffect } from "react";
import type { IObject } from "../../../../../../../../interfaces/object";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetSeparatorConfig } from "../domain";

interface ISeparatorWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IWidgetSeparatorConfig;
  edit?: boolean;
}

type Props = ISeparatorWidgetProps;

export function SeparatorWidget(props: Props) {
  const { widget, conf, object, layoutId, recordId } = props;

  const { setAsFinished } = useWidgetCacheActions({
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  });

  useEffect(() => {
    setAsFinished();
  }, [setAsFinished]);

  const align = conf.align ? conf.align : "left";
  const text = conf.text;

  return (
    <Divider style={{ margin: 0 }} orientationMargin={0} orientation={align}>
      {text}
    </Divider>
  );
}
