import { EditFilled, LayoutFilled, PlusSquareFilled } from "@ant-design/icons";
import { ConfigProvider, Segmented, Tooltip, theme } from "antd";
import * as React from "react";
import type { IObject } from "../../../../interfaces/object";
import { type IDataset } from "../../../../interfaces/sources";
import { grey } from "../../../../utils/colorPalette";
import type {
  ILayout,
  IRecordAction,
} from "../../../v2-demo/container/record/component/domain";
import type { IRecord } from "../../../v2-demo/container/record/domain";
import type { IBehaviorState } from "../../domain";
import { ObjectLayoutEditorAdd } from "./add/ObjectLayoutEditorAdd";
import { ObjectLayoutEditorEdit } from "./edit/ObjectLayoutEditorEdit";
import { ObjectLayoutEditorLayer } from "./layer/ObjectLayoutEditorLayer";

interface IObjectLayoutEditorSiderProps {
  object: IObject;
  layout: ILayout;
  behavior: IBehaviorState;
  onLayoutChange: (actions: IRecordAction[]) => void;
  record: IRecord;
  allDatasets: IDataset[];
}

export function ObjectLayoutEditorSider(props: IObjectLayoutEditorSiderProps) {
  const { object, layout, behavior, onLayoutChange, record, allDatasets } =
    props;

  const [segmentSelected, setSegmentSelected] = React.useState<string>("edit");

  React.useEffect(() => {
    if (behavior.selected.component && segmentSelected !== "edit") {
      setSegmentSelected("edit");
    }
  }, [behavior.selected.component]);

  const renderSegment = () => {
    if (segmentSelected === "edit") {
      return (
        <ConfigProvider theme={{ algorithm: theme.compactAlgorithm }}>
          <ObjectLayoutEditorEdit
            object={object}
            layout={layout}
            selected={behavior.selected.component}
            allDatasets={allDatasets}
            onLayoutChange={onLayoutChange}
            record={record}
          />
        </ConfigProvider>
      );
    } else if (segmentSelected === "layer") {
      return (
        <ConfigProvider theme={{ algorithm: theme.compactAlgorithm }}>
          <ObjectLayoutEditorLayer
            object={object}
            layout={layout}
            behavior={behavior}
          />
        </ConfigProvider>
      );
    } else if (segmentSelected === "add") {
      return <ObjectLayoutEditorAdd type={layout.type} />;
    } else {
      return <div>not supported</div>;
    }
  };

  return (
    <div
      style={{ display: "flex", maxHeight: "100%", flexDirection: "column" }}
    >
      <div style={{ flex: 0, marginBottom: 16 }}>
        <Segmented
          block
          onChange={(e) => setSegmentSelected(e.toLocaleString())}
          value={segmentSelected}
          options={[
            {
              icon: (
                <Tooltip title="Edit" placement="top">
                  <EditFilled />
                </Tooltip>
              ),
              value: "edit",
            },
            {
              icon: (
                <Tooltip title="Select layer" placement="top">
                  <LayoutFilled />
                </Tooltip>
              ),
              value: "layer",
            },
            {
              icon: (
                <Tooltip title="Add component" placement="top">
                  <PlusSquareFilled />
                </Tooltip>
              ),
              value: "add",
            },
          ]}
          style={{ background: grey[3] }}
        />
      </div>
      <div
        style={{
          margin: -8,
          padding: 8,
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "Calc(100% - 72px)",
        }}
      >
        {renderSegment()}
      </div>
    </div>
  );
}
