import { DocumentsCellRenderer } from "../../../../../../../../../../components/ag-grid/object-table/cells/Documents/DocumentsCellRenderer";

export interface IDocumentsProps {
  property: string;
  size: "small" | "large" | "middle";
}

export function Documents(props: IDocumentsProps) {
  const { property, size } = props;
  return (
    <DocumentsCellRenderer property={property} size={size ? size : "small"} />
  );
}
