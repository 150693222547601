import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../components/compose/WlyCompose";
import Feednack from "../../components/layout/feedback/feedback";
import Loading from "../../components/layout/feedback/loading";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import { match } from "../../helpers/typescriptHelpers";
import type { RefreshTokenResource } from "../../interfaces/auth";
import * as authService from "../../services/authService";
import type { AuthStoreProps } from "../../store/authStore";
import type { UserStoreProps } from "../../store/userStore";

interface ISharedLoginProps {
  children?: React.ReactNode;
}

type ISharedLoginState = AsyncData<undefined>;

type Props = ISharedLoginProps &
  RouteComponentProps<{ publicToken?: string; privateToken?: string }> &
  UserStoreProps &
  AuthStoreProps;

class SharedLogin extends React.Component<Props, ISharedLoginState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: "initial",
    };
  }

  async getToken(): Promise<RefreshTokenResource> {
    const {
      match: {
        params: { publicToken, privateToken },
      },
    } = this.props;

    const token = await authService.getAccessToken();

    const baseToken: RefreshTokenResource = {
      expires_at: Date.now() + 10000 * 365 * 24 * 3600 * 1000,
      expires_in: 10000 * 365 * 24 * 3600 * 1000,
      refresh_token: "",
      scope: "",
      token_type: "bearer",
      access_token: token!,
    };

    if (publicToken) {
      return {
        ...baseToken,
        access_token: `public:report:${publicToken}`,
      };
    } else if (privateToken) {
      return {
        ...baseToken,
        access_token: `private:report:${privateToken}`,
      };
    } else if (!baseToken.access_token) {
      throw new Error("Unknown auth token.");
    }

    return baseToken;
  }

  public componentDidMount() {
    this.setState({ status: "loading" });

    this.getToken()
      .then((token) =>
        this.props.authStore.saveToken(token, { isPublicToken: true })
      )
      .then(() => this.props.userStore.getUser())
      .then(() => this.setState({ status: "success" }))
      .catch(() =>
        this.setState({
          status: "error",
          error: new Error(
            "Error while fetching... are you sure this report is still public ?"
          ),
        })
      );
  }

  public renderFromState = (state: ISharedLoginState) => {
    return match<undefined, React.ReactNode>(
      {
        initial: () => (
          <Feednack>
            <Loading />
          </Feednack>
        ),
        loading: () => (
          <Feednack>
            <Loading />
          </Feednack>
        ),
        success: (data) => this.props.children,
        error: (error) => (
          <Feednack>
            <div>Error: {error.message}</div>
          </Feednack>
        ),
      },
      state
    );
  };

  public render() {
    return this.renderFromState(this.state);
  }
}

export default compose<Props, ISharedLoginProps>(withRouter)(
  inject("userStore", "authStore")(observer(SharedLogin))
);
