import graphQlService from "../../../../../../../services/graphql/GraphQLService";
import { makeRecordIterator } from "../../../../../../../services/graphql/PaginatedGQLService";

const executeQueryArray = <T = any>(
  records: Array<T>,
  query: string,
  variableGenerator: (d: T) => object
) => {
  return records.reduce(async (acc, v) => {
    await acc;
    return graphQlService(query, variableGenerator(v));
  }, Promise.resolve());
};

const deleteModelFolder = async (orgId: string, warehouseId: string) => {
  const foldersIterator = await makeRecordIterator<{ id: string }>(
    `
  query getModelFolders($orgId: ID!, $warehouseId: ID!, $pageSize: Int, $offset: Int) {
    allModelFolders(where: { org: { id: $orgId }, isDeleted_not: true, warehouse: { id: $warehouseId } }, first: $pageSize, skip: $offset) {
      id
    }
  }
    `,
    {
      orgId: orgId,
      warehouseId: warehouseId,
    },
    "allModelFolders"
  );

  const modelFolders: Array<{ id: string }> = [];
  for await (const r of foldersIterator) {
    modelFolders.push(r);
  }

  await executeQueryArray(
    modelFolders,
    `
    mutation deleteModelFolder($id: ID!, $data: ModelFolderUpdateInput!) {
      updateModelFolder(id: $id, data: $data) {
        id
      }
    }
    `,
    (v) => ({
      id: v.id,
      data: {
        isDeleted: true,
      },
    })
  );
};

const deleteDatasets = async (orgId: string, warehouseId: string) => {
  const foldersIterator = await makeRecordIterator<{ id: string }>(
    `
  query getDatasets($orgId: ID!, $warehouseId: ID!, $pageSize: Int, $offset: Int) {
    allDatasets(where: { org: { id: $orgId }, deleted_not: true, warehouse: { id: $warehouseId } }, first: $pageSize, skip: $offset) {
      id
    }
  }
    `,
    {
      orgId: orgId,
      warehouseId: warehouseId,
    },
    "allDatasets"
  );

  const datasets: Array<{ id: string }> = [];
  for await (const r of foldersIterator) {
    datasets.push(r);
  }

  await executeQueryArray(
    datasets,
    `
    mutation deleteDataset($id: ID!, $data: DatasetUpdateInput!) {
      updateDataset(id: $id, data: $data) {
        id
      }
    }
    `,
    (v) => ({
      id: v.id,
      data: {
        deleted: true,
      },
    })
  );
};

const deleteObjects = async (orgId: string, warehouseId: string) => {
  const foldersIterator = await makeRecordIterator<{ id: string }>(
    `
  query getObjects($orgId: ID!, $pageSize: Int, $offset: Int) {
        allObjects(
          where: { org: { id: $orgId } }
          first: $pageSize
          skip: $offset
        ) {
          id
        }
      }
    `,
    {
      orgId: orgId,
      warehouseId: warehouseId,
    },
    "allObjects"
  );

  const objects: Array<{ id: string }> = [];
  for await (const r of foldersIterator) {
    objects.push(r);
  }

  await executeQueryArray(
    objects,
    `
    mutation deleteObject($id: ID!, $data: ObjectUpdateInput!) {
      updateObject(id: $id, data: $data) {
        id
      }
    }
    `,
    (v) => ({
      id: v.id,
      data: {
        isDeleted: true,
      },
    })
  );
};

export const deleteWorkbench = async (orgId: string, warehouseId: string) => {
  await deleteModelFolder(orgId, warehouseId);
  await deleteDatasets(orgId, warehouseId);
  await deleteObjects(orgId, warehouseId);
};
