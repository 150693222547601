import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import type { QueryOrder } from "@cubejs-client/core";
import type { IHeaderParams } from "ag-grid-community";
import { Popover, Space } from "antd";
import { useRef } from "react";
import type { AvailableColumn } from "../../../../containers/v2-demo/container/object/domain";
import type { AvailableProperty } from "../../../../containers/v2-demo/container/object/viewer/domain";
import type { IObjectTableContext } from "../ObjectTable";
import { ActivityChartSummaryCellRenderer } from "../cells/ActivityChartSummaryCellRenderer";
import { ObjectTableHeaderActivitiesPopover } from "./popover/ObjectTableHeaderActivitiesPopover";
import { ObjectTableHeaderStandardPopover } from "./popover/ObjectTableHeaderStandardPopover";

export interface ObjectTableHeaderProps extends IHeaderParams {
  property?: AvailableColumn;
  onToggleSort?: (options: [string, QueryOrder | undefined]) => void;
}

const HC_LEGEND_HEIGHT = 30;

const ObjectTableHeader = (props: ObjectTableHeaderProps) => {
  const {
    column,
    displayName,
    enableMenu,
    onToggleSort,
    showColumnMenu,
    property,
  } = props;
  const context = props.context as IObjectTableContext;
  const refButton = useRef<HTMLDivElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);

  const onMenuClicked = (element: HTMLDivElement | null) => {
    if (element) {
      showColumnMenu(element);
    }
  };

  const objectColumn = context.availableColumns.find(
    (ap) => ap.data.key === column.getColDef().colId
  );

  let sortKey: string | undefined = undefined;

  if (objectColumn) {
    if (objectColumn.type === "metric") sortKey = objectColumn.data.key;
    if (objectColumn.type === "property")
      sortKey = objectColumn.data.sortAndFilterKey;
  }

  const sortIndex =
    objectColumn && sortKey
      ? context.tableQuery.order.findIndex((o) => {
          if (objectColumn.type === "property") {
            return o[0] === sortKey;
          } else {
            return o[0] === sortKey;
          }
        })
      : -1;

  const sortedColumn = context.tableQuery.order[sortIndex];

  let menu: React.ReactNode[] = [];

  const description = property?.data?.description;
  if (description) {
    menu.push(
      <span key={"object-standard-popover"}>
        <ObjectTableHeaderStandardPopover
          label={property?.data?.label}
          description={description}
        />
      </span>
    );
  }

  if (enableMenu) {
    menu.push(
      <div
        key={"object-menu-outlined"}
        ref={refButton}
        onClick={() => onMenuClicked(refButton.current)}
        style={{ cursor: "pointer" }}
      >
        <MenuOutlined />
      </div>
    );
  }

  let sort: React.ReactNode = null;
  if (sortIndex > -1) {
    sort = (
      <div key={"object-sorter"}>
        <div>
          {sortIndex + 1}{" "}
          {sortedColumn[1] === "asc" ? (
            <ArrowUpOutlined />
          ) : (
            <ArrowDownOutlined />
          )}
        </div>
      </div>
    );
  }

  const showActivitiesProfile = property?.data?.formatter === "activities";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        maxWidth: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        className="ag-cell-label-container"
        style={{
          height: showActivitiesProfile
            ? `calc(100% - ${HC_LEGEND_HEIGHT}px)`
            : "100%",
        }}
      >
        {!showActivitiesProfile && (
          <span className="ag-header-icon ag-header-cell-menu-button">
            <Space size="small">{menu}</Space>
          </span>
        )}
        {showActivitiesProfile && (
          <div
            style={{
              display: "flex",
              alignItems: "end",
              cursor: "pointer",
            }}
          >
            <Popover
              title={property?.data.label}
              overlayStyle={{ width: 300, maxWidth: 300 }}
              trigger={["hover"]}
              placement="bottom"
              content={
                <ObjectTableHeaderActivitiesPopover
                  description={property?.data.description}
                  conf={
                    (property?.data as AvailableProperty)?.formatterConfig || ""
                  }
                />
              }
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
        )}
        <div
          className="ag-header-cell-label"
          ref={refWrapper}
          style={{
            cursor:
              column.getColId() !== "ag-Grid-AutoColumn" &&
              typeof onToggleSort === "function"
                ? "pointer"
                : "default",
          }}
          onContextMenu={(event) => {
            event.preventDefault();
            onMenuClicked(refWrapper.current);
          }}
          onClick={() => {
            if (onToggleSort && objectColumn && sortKey) {
              let nextDirection: QueryOrder | undefined = "asc";
              if (sortedColumn?.[1] === "asc") nextDirection = "desc";
              if (sortedColumn?.[1] === "desc") nextDirection = undefined;
              onToggleSort([sortKey, nextDirection]);
            }
          }}
        >
          <span className="ag-header-cell-text">{displayName}</span>
          <span className="ag-header-icon ag-header-label-icon">{sort}</span>
        </div>
      </div>
      {showActivitiesProfile && (
        <div style={{ height: HC_LEGEND_HEIGHT }}>
          <div
            className="wly-ag-legend-container"
            style={{
              position: "absolute",
              bottom: 0,
              left: -18,
              right: -18,
              fontWeight: "normal",
            }}
          >
            <div style={{ marginLeft: 1, opacity: 0.8 }}>
              <ActivityChartSummaryCellRenderer
                height={HC_LEGEND_HEIGHT}
                conf={
                  (property.data as AvailableProperty).formatterConfig || ""
                }
                reversed
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ObjectTableHeader;
