import authStore from "../store/authStore";
import { IDBService } from "./idbService";
import { SessionStorageService } from "./SessionStorageService";

export const encodePageLocation = (page: string): string => {
  return `whaly-request-${btoa(page)}`;
};

export const decodePageLocation = (hash: string): string => {
  return atob(hash.replace("whaly-request-", ""));
};

export const hasNeverBeenAuthenticated = async () => {
  const authResource = await IDBService.getAuthResource();
  return !authResource;
};

const isAuthenticated = async () => {
  const overrideToken = SessionStorageService.getOverrideToken();
  const authResource = await IDBService.getAuthResource();
  const token = overrideToken ?? authResource;

  return token && token.expires_at > Date.now();
};

export const isPartnerPortal = () => {
  const domain = window.location.hostname;
  return (
    domain.includes(".portals") || !!window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
  );
};

export const getAccessToken = async () => {
  const overrideToken = SessionStorageService.getOverrideToken();
  const authResource = await IDBService.getAuthResource();

  return overrideToken?.access_token ?? authResource?.access_token;
};

export const getAuthorizationType = async () => {
  const overrideToken = SessionStorageService.getOverrideToken();
  const authResource = await IDBService.getAuthResource();

  if (overrideToken) {
    return "Bearer";
  }
  if (authResource?.token_type === "bearer") {
    return "Bearer";
  }
  if (authResource?.token_type === "portal-jwt") {
    return "PortalJwt";
  }
};

export const authenticatedRequest = async () => {
  return (await isAuthenticated())
    ? Promise.resolve()
    : authStore.refreshSession();
};
