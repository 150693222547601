import type { Query } from "@cubejs-client/core";
import _ from "lodash";
import type { MeasureItemSortValue } from "../../../../../../../components/measures/measure-item/MeasureItem";
import type { IObject } from "../../../../../../../interfaces/object";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
  isAvailableMetric,
} from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import {
  convertKeyToMarkdocVariable,
  extractVariableFromMarkdocAst,
} from "../../common/markdoc/domain";
import { type BaseConfig } from "../domain";
import type { IFilterEditorValue } from "../related-lists/domain";
import { buildQueryFromDimensionAndMetrics } from "../related-lists/domain";

export interface IWidgetKPIConfig extends BaseConfig {
  columns: Array<string>;
  label?: string;
  secondary?: string;
  theme?: string;
  type?: "table" | "bar" | "progress" | "progressTable" | "none";
  barConfig?: {
    foreignObjectPropertyId: string;
    metric: string[];
    groupBy: string[];
    filters?: IFilterEditorValue;
    tooltip?: string;
    sortBy?: Array<[string, MeasureItemSortValue]>;
    highlightLabel?: string;
    limit?: number;
  };
  tableConfig?: {
    foreignObjectPropertyId: string;
    metric: string[];
    groupBy: string[];
    filters?: IFilterEditorValue;
    sortBy?: Array<[string, MeasureItemSortValue]>;
    highlightLabel?: string;
    limit?: number;
  };
  progressTableConfig?: {
    foreignObjectPropertyId: string;
    metric: string[];
    secondMetric: string[];
    groupBy: string[];
    filters?: IFilterEditorValue;
    sortBy?: Array<[string, MeasureItemSortValue]>;
    highlightLabel?: string;
    limit?: number;
  };
}
type theme = "gray" | "blue" | "orange" | "yellow" | "green";

export type kpiTheme = {
  background: string;
  primary: string;
  secondary: string;
};

export const kpiThemes: {
  [key in theme]: kpiTheme;
} = {
  gray: {
    background: "#F7F7F8",
    primary: "#4C4C4C",
    secondary: "#AAAAAA",
  },
  blue: {
    background: "#E7F0FF",
    primary: "#184D80",
    secondary: "#85A1C1",
  },
  orange: {
    background: "#FFEFE6",
    primary: "#8D3300",
    secondary: "#BA988E",
  },
  yellow: {
    background: "#FFF5DA",
    primary: "#785800",
    secondary: "#B6A685",
  },
  green: {
    background: "#E6FAE8",
    primary: "#00640A",
    secondary: "#79ad75",
  },
};

const buildSparklineQuery = (options: {
  metrics: string[];
  dimensions: string[];
  recordFilter?: {
    recordForeignKey: string;
    recordId: string;
  };
  additionalFilters?: IFilterEditorValue;
  sortBy?: Array<[string, MeasureItemSortValue]>;
  limit?: number;
}): Query => {
  return buildQueryFromDimensionAndMetrics(
    options.dimensions,
    options.metrics,
    options.recordFilter,
    options.additionalFilters,
    options.sortBy,
    options.limit
  );
};

const getSparklineConfig = (
  conf: IWidgetKPIConfig
):
  | IWidgetKPIConfig["barConfig"]
  | IWidgetKPIConfig["tableConfig"]
  | IWidgetKPIConfig["progressTableConfig"]
  | undefined => {
  switch (conf.type) {
    case "bar":
      return conf.barConfig;
    case "table":
      return conf.tableConfig;
    case "progressTable":
      return conf.progressTableConfig;
    default:
      return undefined;
  }
};

export const getForeignObject = (
  object: IObject,
  foreignObjectPropertyId: string
) => {
  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === foreignObjectPropertyId
  );
  if (!foreignProperty) return undefined;
  return foreignProperty.object;
};

export const getSparklineQuery = (options: {
  object: IObject;
  record: IRecord;
  conf: IWidgetKPIConfig;
}) => {
  const { object, record, conf } = options;

  const sparklineConfig = getSparklineConfig(conf);
  if (!sparklineConfig) {
    return undefined;
  }

  const foreignObject = getForeignObject(
    object,
    sparklineConfig.foreignObjectPropertyId
  );
  if (!foreignObject) {
    return undefined;
  }

  const property = foreignObject.properties.find(
    (p) => p.id === sparklineConfig?.foreignObjectPropertyId
  );
  if (!property) {
    return undefined;
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );

  const firstMetric = sparklineConfig?.metric ?? [];
  const secondMetric =
    "secondMetric" in sparklineConfig ? sparklineConfig.secondMetric : [];

  const metrics = firstMetric ? [...firstMetric, ...secondMetric] : undefined;
  const dimensions = sparklineConfig?.groupBy;
  const recordFilter = {
    recordForeignKey: `${foreignAvailable.key}_raw`,
    recordId: record[`${object.table.cubeName}.id`] as string,
  };

  if (!metrics || !dimensions) {
    return;
  }

  const columns = getObjectColumns(foreignObject);
  const metric = columns
    .filter(isAvailableMetric)
    .find((m) => m.data.key === metrics[0]);
  if (!metric) {
    return undefined;
  }

  const getTooltipMetrics = (): string[] => {
    if (conf.barConfig?.tooltip) {
      const substitutionMetrics = columns.reduce<{ [key: string]: string }>(
        (acc, v) => {
          return {
            ...acc,
            [convertKeyToMarkdocVariable(v.data.key)]: v.data.key,
          };
        },
        {}
      );
      const variables = extractVariableFromMarkdocAst(conf.barConfig.tooltip);
      return variables.map((v) => substitutionMetrics[v]).filter((v) => !!v);
    }

    return [];
  };

  const getSortBy = (): Array<[string, MeasureItemSortValue]> => {
    if (conf.tableConfig?.sortBy) {
      return conf.tableConfig?.sortBy;
    } else if (conf.barConfig?.sortBy) {
      return conf.barConfig?.sortBy;
    } else if (conf.progressTableConfig?.sortBy) {
      return conf.progressTableConfig?.sortBy;
    }
    return metrics.map((m) => [m, "desc"]);
  };

  const getLimit = (): number => {
    if (conf.type === "bar") {
      return conf.barConfig?.limit ?? 4;
    } else if (conf.type === "table") {
      return conf.tableConfig?.limit ?? 10;
    } else {
      return 10;
    }
  };

  const sortBy = getSortBy();

  const finalMetrics = _.uniq([...metrics, ...getTooltipMetrics()]);

  const getMetricWithSorting = () => {
    const metricsToAdd = [...(sortBy instanceof Array ? sortBy : [])]
      .map((d) => d[0])
      .filter((d) => d.includes(".met") && !finalMetrics?.includes(d));

    return [...(finalMetrics ?? []), ...metricsToAdd];
  };

  const mets = getMetricWithSorting();

  return buildSparklineQuery({
    metrics: mets,
    dimensions: dimensions,
    additionalFilters: sparklineConfig.filters,
    recordFilter: recordFilter,
    sortBy: sortBy,
    limit: getLimit(),
  });
};
