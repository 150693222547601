import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter, type RouteComponentProps } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { AuthStoreProps } from "../../../../store/authStore";
import type { UserStoreProps } from "../../../../store/userStore";
import RecordView from "./RecordView";

type RecordViewPublicEmbedProps = {
  shared?: boolean;
};

type Props = RecordViewPublicEmbedProps &
  UserStoreProps &
  AuthStoreProps &
  RouteComponentProps<{ organizationSlug: string }>;

function RecordViewPublicEmbed(props: Props) {
  const {
    authStore,
    userStore,
    match: {
      params: { organizationSlug },
    },
    shared,
  } = props;

  const [access, setAccess] = React.useState<AsyncData<boolean>>({
    status: "initial",
  });

  const fetchPageStatus = async () => {
    // temporary hack until we find a viable shared solution
    // we do this to embed publicly the object of fruity juicy in any app
    if (organizationSlug !== "fruity-juicy" && shared) {
      setAccess({ status: "success", data: true });
      return;
    }
    setAccess({ status: "loading" });
    // read if the password is stored in the local storage
    try {
      await Promise.resolve(
        authStore.saveToken(
          {
            access_token: `shared:report:1c5aa5d0-8427-4069-a1c6-8b619149c606:9cccf3c2-b752-4c01-9e31-785c04380a70`,
            expires_at: Date.now() + 10000 * 365 * 24 * 3600 * 1000,
            expires_in: 10000 * 365 * 24 * 3600 * 1000,
            refresh_token: "",
            scope: "",
            token_type: "bearer",
          },
          { isPublicToken: true }
        )
      );
      await userStore.getUser();
      setAccess({ status: "success", data: true });
    } catch (err) {
      setAccess({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    fetchPageStatus();
  }, []);

  if (access.status === "initial" || access.status === "loading") {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (access.status === "error") {
    return <Feednack>error: {access.error.message}</Feednack>;
  }

  return (
    <div style={{ overflowX: "auto", background: "white", height: "100%" }}>
      <RecordView />
    </div>
  );
}

export default compose<Props, RecordViewPublicEmbedProps>(
  inject("userStore", "authStore"),
  observer,
  withRouter
)(RecordViewPublicEmbed);
