import { Card } from "antd";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import type { IObject } from "../../../../../../../../interfaces/object";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
} from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import { buildQueryFromColumn } from "../../related-lists/domain";
import type { IWidgetRepeatableConfig } from "../domain";
import RepeatableWidgetQuery from "./RepeatableWidgetQuery";

import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import "./RepeatableWidget.scss";

interface IRepeatableWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IWidgetRepeatableConfig;
  edit?: boolean;
}

export function RepeatableWidget(props: IRepeatableWidgetProps) {
  const { widget, conf, object, record, layoutId, recordId } = props;
  const widgetCacheProps = {
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  };
  const { setAsFinished } = useWidgetCacheActions(widgetCacheProps);

  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === conf.foreignObjectPropertyId
  );

  if (!foreignProperty) {
    setAsFinished(true);
    return (
      <Card size="small" bodyStyle={{ height: 250 }}>
        <Feednack>Can't find foreign property</Feednack>
      </Card>
    );
  }

  const foreignObject = foreignProperty.object;
  const filters = conf.options?.filters;
  const cubeName = object.table.cubeName;
  const sortBy = conf.options?.sortBy;
  const availableColumns = getObjectColumns(foreignObject);

  const property = foreignObject.properties.find(
    (p) => p.id === conf.foreignObjectPropertyId
  );

  if (!property) {
    setAsFinished(true);
    return (
      <Card size="small" bodyStyle={{ height: 250 }}>
        <Feednack>Can't find foreign property</Feednack>
      </Card>
    );
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );

  const query = buildQueryFromColumn(
    [`${foreignObject.table.cubeName}.label`],
    availableColumns,
    {
      recordForeignKey: `${foreignAvailable.key}_raw`,
      recordId: record[`${cubeName}.id`] as string,
    },
    filters,
    sortBy,
    conf?.options?.limit || 10
  );

  return (
    <RepeatableWidgetQuery
      conf={conf}
      object={foreignObject}
      query={query}
      widgetCacheProps={widgetCacheProps}
    />
  );
}
