import { Form } from "antd";
import type { AvailableDimension } from "../../../../../../../components/measures/filter-item/FilterItem";
import ChartOptionLine from "../../../../../../chart-options/components/ChartOptionLine";
import PropertyGroupsSelector from "../../../../object/viewer/toolbar/PropertiesSelector/PropertyGroupsSelector";

type GroupSelectorProps = {
  availableDimensions: AvailableDimension[];
  label: string;
  name: Array<string | number>;
};

export const GroupSelector = ({
  availableDimensions,
  label,
  name,
}: GroupSelectorProps) => {
  return (
    <ChartOptionLine
      items={[
        {
          content: (
            <Form.Item style={{ marginBottom: 4 }} label={label} name={name}>
              <PropertyGroupsSelector
                measures={availableDimensions}
                zone="dimensions"
                accept={["property"]}
                showTimeAgg
              />
            </Form.Item>
          ),
          flex: 1,
        },
      ]}
    />
  );
};
