import { Col, Flex, Row, Space, Typography } from "antd";
import _ from "lodash";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type {
  IColumn,
  ILayout,
  IRecordAction,
  IRecordWidgetData,
  IRow,
  IRowSpacing,
  IWidget,
} from "../domain";
import { layoutObjectData, widgetObjectData } from "../domain";
import RecordHeader from "../header/RecordHeader";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyFilled,
  DeleteFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useMemo, useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { useIsOnline } from "../../../../../../components/hooks/useIsOnline";
import Loading from "../../../../../../components/layout/feedback/loading";
import { IDBService } from "../../../../../../services/idbService";
import {
  useWidgetCache,
  useWidgetCacheInitAction,
  useWidgetCacheIsInit,
  useWidgetCacheList,
} from "../../../../../../store/widgetCacheStore";
import { IObjectLayoutItemType } from "../../../../../custom-layout-editor/main/sider/add/domain";
import { generateFakeId } from "../../../../../workbench/workbench/exploration/domain";
import { EmbedType } from "../../domain";
import { shouldDisplayWidget } from "../common/visibility-filters/domain";
import { RecordHomePageHeader } from "../header/RecordHomePageHeader";
import RecordDocumentRenderer from "../panels/RecordDocumentRenderer";
import { WidgetRenderer } from "../widgets/WidgetRenderer";
import { EmptyColumnDropZone } from "./EmptyColumnDropZone";
import { HoverWrapper } from "./HoverWrapper";
import "./RecordLayout.scss";
import { WidgetDrag } from "./WidgetDrag";

interface IRecordLayoutBaseProps {
  layout: ILayout;
  object: IObject;
  recordId: string;
  data: IRecordWidgetData;
  preview: boolean;
  boxed?: boolean;
  hideHeader?: boolean;
  embedType?: EmbedType;
  isSubLayout?: boolean;
}

interface IRecordLayoutDisplayProps extends IRecordLayoutBaseProps {
  edit: false;
}

interface IRecordLayoutEditionProps extends IRecordLayoutBaseProps {
  edit: true;
  hovered?: string;
  selected?: string;
  setAction: (action: "hover" | "click", key: string) => void;
  onLayoutChange: (actions: IRecordAction[]) => void;
}

type IRecordLayoutProps = IRecordLayoutEditionProps | IRecordLayoutDisplayProps;

type Props = IRecordLayoutProps &
  InjectedOrgProps &
  RouteComponentProps<{ documentId?: string }>;

interface IInner<T, S extends string> {
  id: string;
  position: number;
  type: S;
  data: T;
}

type IInnerColumn =
  | IInner<IRow, "row">
  | IInner<IWidget, "widget">
  | IInner<{}, "space">;

const Comp = (props: Props) => {
  const {
    layout,
    object,
    recordId,
    data,
    edit,
    preview,
    boxed,
    hideHeader,
    embedType,
    isSubLayout,
    match: { params },
  } = props;

  const { hovered, selected, setAction, onLayoutChange } =
    props.edit === true
      ? props
      : {
          hovered: undefined,
          selected: undefined,
          setAction: undefined,
          onLayoutChange: undefined,
        };

  const isOnline = useIsOnline();
  const [hasSetCachedRecord, setHasSetCachedRecord] = useState(false);
  const hasInitWidgetCache = useWidgetCacheIsInit();
  const initWidgetCache = useWidgetCacheInitAction();
  const widgetCacheList = useWidgetCacheList(recordId, layout.id);

  const [panel, setPanel] = useState<"FOLDER" | null>(null);
  const [widgetState, setWidgetState] = useState<Record<PropertyKey, string>>(
    {}
  );

  useEffect(() => {
    if (!hasInitWidgetCache) {
      initWidgetCache();
    }
  });

  useEffect(() => {
    if (
      isOnline &&
      !hasSetCachedRecord &&
      widgetCacheList.length > 0 &&
      recordId &&
      object.id &&
      object.slug &&
      layout.id
    ) {
      const allWidgetCacheCount = widgetCacheList.length;
      const finishedWidgetCacheCount = widgetCacheList.filter(
        (w) => w.hasFinished
      ).length;

      if (finishedWidgetCacheCount / allWidgetCacheCount > 0.95) {
        IDBService.setCachedRecord(
          `recordId:${recordId}|objectId:${object.id}|objectSlug:${object.slug}|layoutId:${layout.id}`
        ).then(() => setHasSetCachedRecord(true));
      }
    }
  }, [
    isOnline,
    hasSetCachedRecord,
    layout.id,
    object.id,
    object.slug,
    recordId,
    widgetCacheList,
  ]);

  const isBoxed = useMemo(
    () => boxed ?? layout.config?.boxed,
    [boxed, layout.config?.boxed]
  );

  if (!hasInitWidgetCache || data.status !== "success") {
    return (
      <Flex
        align="center"
        justify="center"
        style={{ width: "100%", height: "100%" }}
      >
        <Loading />
      </Flex>
    );
  }

  return (
    <div className={`record-view record-layout ${edit ? "edition" : ""}`}>
      {!hideHeader && (
        <HoverWrapper
          componentKey="header"
          componentLabel={"Header"}
          edit={edit}
          hovered={hovered}
          selected={selected}
          setAction={(action, key: string) => setAction?.(action, key)}
        >
          <RecordHeader
            object={object}
            edit={edit}
            config={layout.header}
            data={data}
            preview={preview}
            embedType={embedType}
            disableEdit={hideHeader}
            action={() => setPanel(panel === null ? "FOLDER" : null)}
          />
        </HoverWrapper>
      )}

      {!isSubLayout && embedType === EmbedType.home && (
        <RecordHomePageHeader data={data} object={object} />
      )}

      <div className="record-view-body">
        <div
          className={`record-view-body-content ${
            isSubLayout ? "is-sub-layout" : ""
          } ${isBoxed ? "boxed" : ""}`}
        >
          {params.documentId &&
          embedType !== EmbedType.home &&
          data.status === "success" ? (
            <RecordDocumentRenderer
              documentId={params.documentId}
              object={object}
              data={data.data}
              embedType={embedType}
            />
          ) : (
            <Rows
              rows={layout.rows}
              data={data}
              object={object}
              edit={edit}
              layout={layout}
              recordId={recordId}
              preview={preview}
              widgetState={widgetState}
              hovered={hovered}
              selected={selected}
              setAction={(action, key) => setAction?.(action, key)}
              onLayoutChange={(actions) => onLayoutChange?.(actions)}
              setWidgetState={setWidgetState}
            />
          )}
        </div>
      </div>
    </div>
  );
};

type RowsProps = IRecordLayoutProps & {
  rows: IRow[];
  widgetState: Record<PropertyKey, string>;
  setWidgetState: Dispatch<SetStateAction<Record<PropertyKey, string>>>;
};
const Rows = (props: RowsProps) => {
  const {
    rows,
    data,
    object,
    layout,
    recordId,
    preview,
    edit,
    widgetState,
    setWidgetState,
  } = props;
  const { hovered, selected, setAction, onLayoutChange } =
    props.edit === true
      ? props
      : {
          hovered: undefined,
          selected: undefined,
          setAction: undefined,
          onLayoutChange: undefined,
        };

  const renderSpacing = (spacing?: IRowSpacing) => {
    if (!spacing) {
      return "8px";
    }

    switch (spacing) {
      case "none":
        return 0;
      case "small":
        return "8px";
      case "medium":
        return "32px";
      case "large":
        return "64px";
    }
  };

  const sortedRows = useMemo(
    () =>
      _.sortBy(rows, ["position"]).filter(({ displayFilters }) => {
        if (edit || !displayFilters) {
          return true;
        }
        return shouldDisplayWidget(
          displayFilters,
          data.status === "success" ? data.data : {},
          object,
          widgetState
        );
      }),
    [data, data.status, edit, object, rows, widgetState]
  );

  if (sortedRows.length === 0 && edit) {
    return (
      <EmptyColumnDropZone
        colId={"initial"}
        drop={{
          accept: IObjectLayoutItemType.LAYOUT,
          onDrop: (i) => {
            const layoutItem = layoutObjectData[i.identifier];
            if (!layoutItem) {
              return alert("item does not exists");
            }

            const newItemPosition = 0;

            const gen = layoutItem.generate(newItemPosition);
            onLayoutChange?.([{ type: "layout::add", data: gen }]);
          },
        }}
      >
        Drop something here
      </EmptyColumnDropZone>
    );
  }

  return (
    <>
      {sortedRows.map((row, index) => {
        return (
          <HoverWrapper
            componentKey={`row::${row.id}`}
            componentLabel={"Row"}
            edit={edit}
            hovered={hovered}
            selected={selected}
            setAction={(action, key: string) => setAction?.(action, key)}
            key={row.id}
            toolbox={[
              ...(index !== 0
                ? [
                    {
                      key: "move-up",
                      name: "Move up",
                      icon: <ArrowUpOutlined />,
                      onClick: () => {
                        onLayoutChange?.([
                          {
                            type: "layout::move",
                            data: { ...row, position: row.position - 1 },
                            position: "top",
                          },
                        ]);
                      },
                    },
                  ]
                : []),
              ...(index !== sortedRows.length - 1
                ? [
                    {
                      key: "move-down",
                      name: "Move down",
                      icon: <ArrowDownOutlined />,
                      onClick: () => {
                        onLayoutChange?.([
                          {
                            type: "layout::move",
                            data: { ...row, position: row.position + 1 },
                            position: "top",
                          },
                        ]);
                      },
                    },
                  ]
                : []),
              {
                key: "delete",
                name: "Delete",
                icon: <DeleteFilled />,
                onClick: () => {
                  setAction?.("click", "body");
                  onLayoutChange?.([{ type: "layout::remove", data: row }]);
                },
              },
            ]}
            drop={
              edit
                ? {
                    accept: IObjectLayoutItemType.LAYOUT,
                    onDrop: (i, position) => {
                      const layoutItem = layoutObjectData[i.identifier];
                      if (!layoutItem) {
                        return alert("item does not exists");
                      }

                      const newPosition =
                        position === "top" ? row.position : row.position + 1;
                      const gen = layoutItem.generate(newPosition);

                      onLayoutChange?.([
                        {
                          type: "layout::add",
                          data: gen,
                        },
                        {
                          type: "layout::move",
                          data: gen,
                          position: "top",
                        },
                      ]);
                    },
                  }
                : undefined
            }
          >
            <Row
              style={{
                marginTop: 8,
                paddingTop: renderSpacing(row.config?.topSpacing),
                paddingBottom: renderSpacing(row.config?.bottomSpacing),
              }}
              gutter={[16, 16]}
            >
              {(row.name || row.description) && (
                <Col xs={24}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {row.name && (
                      <Typography.Title level={4} style={{ marginBottom: 0 }}>
                        {row.name}
                      </Typography.Title>
                    )}
                    {row.description && (
                      <Typography.Text type="secondary">
                        {row.description}
                      </Typography.Text>
                    )}
                  </Space>
                </Col>
              )}
              <Columns
                columns={row.columns}
                data={data}
                object={object}
                edit={edit}
                layout={layout}
                recordId={recordId}
                preview={preview}
                widgetState={widgetState}
                hovered={hovered}
                selected={selected}
                setAction={(action, key) => setAction?.(action, key)}
                onLayoutChange={(actions) => onLayoutChange?.(actions)}
                setWidgetState={setWidgetState}
              />
            </Row>
          </HoverWrapper>
        );
      })}
    </>
  );
};

type ColumnsProps = IRecordLayoutProps & {
  columns: IColumn[];
  widgetState: Record<PropertyKey, string>;
  setWidgetState: Dispatch<SetStateAction<Record<PropertyKey, string>>>;
};
const Columns = (props: ColumnsProps) => {
  const {
    columns,
    data,
    object,
    layout,
    recordId,
    preview,
    widgetState,
    edit,
    setWidgetState,
  } = props;
  const { hovered, selected, setAction, onLayoutChange } =
    props.edit === true
      ? props
      : {
          hovered: undefined,
          selected: undefined,
          setAction: undefined,
          onLayoutChange: undefined,
        };

  const sortedColumns = useMemo(
    () => _.sortBy(columns, ["position"]),
    [columns]
  );

  return (
    <>
      {sortedColumns.map((c) => {
        const innerRows: Array<IInner<IRow, "row">> = layout.rows
          .filter((f) => f.parentColumn?.id === c.id)
          .map((row) => ({
            data: row,
            type: "row",
            position: row.position,
            id: row.id,
          }));

        const innerWidgets: Array<IInner<IWidget, "widget">> = layout.widgets
          .filter((f) => f.parentColumn?.id === c.id)
          .map((widget) => ({
            data: widget,
            type: "widget",
            position: widget.position,
            id: widget.id,
          }));

        const inner: IInnerColumn[] = _.sortBy(
          [...innerRows, ...innerWidgets],
          ["position"]
        );

        const renderInnerColumn = (innerColumn: IInnerColumn) => {
          if (innerColumn.type === "row") {
            return (
              <Rows
                rows={[innerColumn.data]}
                data={data}
                object={object}
                edit={edit}
                layout={layout}
                recordId={recordId}
                preview={preview}
                widgetState={widgetState}
                hovered={hovered}
                selected={selected}
                setAction={(action, key) => setAction?.(action, key)}
                onLayoutChange={(actions) => onLayoutChange?.(actions)}
                setWidgetState={setWidgetState}
              />
            );
          } else if (innerColumn.type === "widget") {
            return (
              <Widget
                widget={innerColumn.data}
                colId={c.id}
                p={innerColumn.data.position}
                data={data}
                object={object}
                edit={edit}
                layout={layout}
                recordId={recordId}
                preview={preview}
                widgetState={widgetState}
                hovered={hovered}
                selected={selected}
                setAction={(action, key) => setAction?.(action, key)}
                onLayoutChange={(actions) => onLayoutChange?.(actions)}
                setWidgetState={setWidgetState}
              />
            );
          } else if (innerColumn.type === "space") {
            return (
              <EmptyColumnDropZone
                colId={c.id}
                drop={{
                  accept: IObjectLayoutItemType.WIDGET,
                  onDrop: (d) => {
                    if (d.operation === "add") {
                      const widgetItem = widgetObjectData[d.identifier];
                      if (!widgetItem) {
                        return alert("item does not exists");
                      }
                      const gen = widgetItem.generate(0, c.id);
                      onLayoutChange?.([{ type: "widget::add", data: gen }]);
                    } else if (d.operation === "move") {
                      const parentId = c.id;
                      const prevParentId = d.data.parentColumn?.id;

                      const ndata = {
                        ...d.data,
                        position: 0,
                        parentColumn: {
                          id: parentId,
                        },
                      };

                      onLayoutChange?.([
                        {
                          type: "widget::move",
                          data: ndata,
                          position: "top",
                          colIdToReorder:
                            parentId !== prevParentId
                              ? prevParentId
                              : undefined,
                        },
                      ]);
                    }
                  },
                }}
              >
                Drop something here
              </EmptyColumnDropZone>
            );
          }
        };

        if (edit && inner.length === 0) {
          inner.unshift({
            id: generateFakeId(),
            position: 0,
            type: "space",
            data: {},
          });
        }

        return (
          <Col key={c.id} xs={24} md={c.size}>
            <HoverWrapper
              componentKey={`col::${c.id}`}
              componentLabel={`Col`}
              edit={edit}
              hovered={hovered}
              selected={selected}
              setAction={(action, key: string) => setAction?.(action, key)}
            >
              <Space
                style={{ width: "100%", display: "flex" }}
                size={"middle"}
                direction="vertical"
              >
                {inner
                  .filter((i) => {
                    if (edit || i.type !== "widget" || !i.data.displayFilters) {
                      return true;
                    }

                    return shouldDisplayWidget(
                      i.data.displayFilters,
                      data.status === "success" ? data.data : {},
                      object,
                      widgetState
                    );
                  })
                  .map((i) => {
                    return <div key={i.id}>{renderInnerColumn(i)}</div>;
                  })}
              </Space>
            </HoverWrapper>
          </Col>
        );
      })}
    </>
  );
};

type WidgetProps = IRecordLayoutProps & {
  widget: IWidget;
  colId: string;
  p: number;
  widgetState: Record<PropertyKey, string>;
  setWidgetState: Dispatch<SetStateAction<Record<PropertyKey, string>>>;
};
const Widget = (props: WidgetProps) => {
  const {
    widget,
    colId,
    p,
    data,
    object,
    layout,
    recordId,
    edit,
    widgetState,
    setWidgetState,
  } = props;

  const widgetCacheProps = {
    widgetId: widget.id,
    objectId: object.id,
    layoutId: layout.id,
    recordId,
  };
  const cachedWidget = useWidgetCache(widgetCacheProps);

  const { hovered, selected, setAction, onLayoutChange } =
    props.edit === true
      ? props
      : {
          hovered: undefined,
          selected: undefined,
          setAction: undefined,
          onLayoutChange: undefined,
        };

  if (!cachedWidget) {
    return <LoadingOutlined />;
  }

  return (
    <HoverWrapper
      componentKey={`widget::${widget.id}`}
      componentLabel={"Widget"}
      edit={edit}
      hovered={hovered}
      selected={selected}
      setAction={(action, key: string) => setAction?.(action, key)}
      drop={{
        accept: IObjectLayoutItemType.WIDGET,
        onDrop: (i, position) => {
          if (i.operation === "add") {
            const widgetItem = widgetObjectData[i.identifier];
            if (!widgetItem) {
              return alert("item does not exists");
            }

            const gen = widgetItem.generate(
              widget.position,
              widget.parentColumn?.id
            );
            onLayoutChange?.([
              { type: "widget::add", data: gen },
              {
                type: "widget::move",
                data: gen,
                position: position === "top" ? "top" : "bottom",
              },
            ]);
          } else {
            const parentId = colId;
            const prevParentId = i.data.parentColumn?.id;
            const data = {
              ...i.data,
              position: p,
              parentColumn: {
                id: parentId,
              },
            };

            onLayoutChange?.([
              {
                type: "widget::move",
                data: Object.assign({}, data),
                colIdToReorder:
                  parentId !== prevParentId ? prevParentId : undefined,
                position: position === "top" ? "top" : "bottom",
              },
            ]);
          }
        },
      }}
      toolbox={[
        {
          key: "duplicate",
          name: "Duplicate",
          icon: <CopyFilled />,
          onClick: () => {
            const id = generateFakeId();
            const newWidget = {
              ...widget,
              id,
            };
            onLayoutChange?.([
              {
                type: "widget::add",
                data: newWidget,
              },
              {
                type: "widget::move",
                position: "bottom",
                data: newWidget,
              },
            ]);
            setAction?.("click", `widget::${id}`);
          },
        },
        {
          key: "delete",
          name: "Delete",
          icon: <DeleteFilled />,
          onClick: () => {
            setAction?.("click", "body");
            onLayoutChange?.([{ type: "widget::remove", data: widget }]);
          },
        },
      ]}
    >
      <WidgetDrag edit={edit} widget={widget}>
        <WidgetRenderer
          object={object}
          widget={widget}
          record={data}
          layoutId={layout.id}
          recordId={recordId}
          edit={edit}
          state={widgetState[`widgetState::${widget.id}`]}
          setState={(value: string) =>
            setWidgetState((current) => ({
              ...current,
              [`widgetState::${widget.id}`]: value,
            }))
          }
        />
      </WidgetDrag>
    </HoverWrapper>
  );
};

export default compose<Props, IRecordLayoutProps>(WithOrg, withRouter)(Comp);
