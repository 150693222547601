export interface IJSONViewerProps {
  value?: object;
}

export function JSONViewer(props: IJSONViewerProps) {
  const { value } = props;
  return (
    <pre style={{ marginBottom: 0 }}>{JSON.stringify(value, undefined, 2)}</pre>
  );
}
