import { MoreOutlined } from "@ant-design/icons";
import { Avatar, Button, Skeleton, Space, Typography } from "antd";
import Dropdown from "antd/es/dropdown/dropdown";
import cuid from "cuid";
import React, { useContext, useEffect } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../interfaces/object";
import {
  IUserFeatureType,
  IUserRoleType,
} from "../../../../../../interfaces/user";
import { routeDescriptor } from "../../../../../../routes/routes";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../../../user-settings/HasRoleAccess";
import { RecordModalContext } from "../../../modals/records/RecordModalContext";
import { EmbedType } from "../../domain";
import type { IHeaderProperties, IRecordWidgetData } from "../domain";
import AnalysisCard from "../recommendations/recommendation-card/AnalysisCard";
import { RecommendationCard } from "../recommendations/recommendation-card/RecommendationCard";
import { WarningCard } from "../recommendations/warnings/WarningCard";
import { ActionButtonRenderer } from "../widgets/actions/ActionButtonRenderer";
import { stores } from "../widgets/suggested-products/data";
import { extractRecordLabel } from "./domain";

interface IRecordHeaderProps {
  data: IRecordWidgetData;
  object: IObject;
  config: IHeaderProperties;
  edit: boolean;
  preview: boolean;
  disableEdit?: boolean;
  embedType?: EmbedType;
  action?: (a: "FOLDER") => void;
}

type Props = IRecordHeaderProps &
  RouteComponentProps<{ recordId: string }> &
  InjectedOrgProps;

function RecordHeader(props: Props) {
  const {
    data,
    config,
    edit,
    object,
    embedType,
    action,
    user,
    userFeatures,
    history,
    org,
    match: { params },
  } = props;

  const [id] = React.useState(cuid());
  const { setValue, value } = useContext(RecordModalContext);

  const getRecommendations = () => {
    if (
      userFeatures.includes(IUserFeatureType.TMP_OBJECT_DOCUMENTS) &&
      object.slug === "magasins-4jz00xv"
    ) {
      return [
        <WarningCard key="warnings" />,
        <RecommendationCard
          key="recommended_visits"
          title="Visites à proximité"
          items={stores.map((s) => {
            return {
              title: s.name,
              subtitle: `${s.distance}m`,
              logo: s.image,
              score: s.score,
              link: routeDescriptor["object-record-centered"].renderRoute({
                ...params,
                objectSlug: "magasins-4jz00xv",
                recordId: s.id,
              }),
            };
          })}
        />,
        // <RecommendationCard
        //   key="lookalike_store"
        //   title="Magasin similaires"
        //   items={[...recommendedStores].reverse().map((s) => {
        //     return {
        //       title: s.name,
        //       subtitle: `${s.distance}m`,
        //       logo: s.image,
        //       link: routeDescriptor["object-record-centered"].renderRoute({
        //         ...params,
        //         objectSlug: "magasins-4jz00xv",
        //         recordId: s.id,
        //       }),
        //     };
        //   })}
        // />,
        // <RecommendationCard
        //   key="recommended_sales"
        //   title="Recommendation de vente"
        //   items={[
        //     {
        //       title: "Orange sans Pulpe 1L",
        //       subtitle: "4 facings - 61.3€ vmh",
        //       badge: "",
        //     },
        //     {
        //       title: "Réveil Fruité Ambiant 1,5L",
        //       subtitle: "2 facings - 55.48€ vmh",
        //       badge: "",
        //     },
        //   ]}
        // />,
      ];
    }

    if (
      userFeatures.includes(IUserFeatureType.TMP_OBJECT_DOCUMENTS) &&
      object.slug === "secteurs-nt2f044h"
    ) {
      return [
        <AnalysisCard
          object={object}
          data={data}
          embedType={embedType}
          key="analyses"
        />,
      ];
    }
    return;
  };

  const getAdditionalButtons = () => {
    let additionalButtons: React.ReactNode[] = [];

    const layouts = object.layouts || [];

    if (data.status === "success") {
      const availableActions = object.registeredActions;
      const featuredActions = (config.featuredActions ?? [])
        .map((a) => availableActions.find((r) => r.id === a))
        .filter((a) => !!a)
        .map((r) => {
          return (
            <ActionButtonRenderer
              key={r!.id}
              display={"TEXT"}
              registeredAction={r!}
              record={data.data}
              object={object}
              edit={edit}
              contextKey={r!.id}
              size="small"
              type="text"
            />
          );
        });
      additionalButtons = [...featuredActions, ...additionalButtons];
    }

    if (
      (layouts.length > 1 &&
        hasRoleAccessBoolean(IUserRoleType.ADMIN_BUILDER, user, org.id)) ||
      user.roles.find(
        (role) => role?.org?.id === "22675" || role?.org?.id === "22694"
      ) // To give access to GMP&Danone to the other layouts
    ) {
      additionalButtons.push(
        <Dropdown
          trigger={["click"]}
          placement="bottom"
          arrow
          menu={{
            items: [
              {
                key: "change-layout",
                type: "group",
                label: "Change layout",
                children: layouts.map((l) => {
                  return {
                    key: l.id,
                    label: l.name,
                    onClick: () => {
                      if (!embedType) {
                        return history.push(
                          routeDescriptor["object-record"].renderRoute({
                            ...params,
                            layoutId: l.id,
                          })
                        );
                      }
                      return history.push(
                        routeDescriptor["object-record-centered"].renderRoute({
                          ...params,
                          layoutId: l.id,
                        })
                      );
                    },
                  };
                }),
              },
            ],
          }}
        >
          <Button
            icon={<MoreOutlined style={{ color: "gray" }} />}
            size="small"
            type="text"
            shape="circle"
          />
        </Dropdown>
      );
    }

    return additionalButtons;
  };

  useEffect(() => {
    if (embedType === EmbedType.recordModal && setValue) {
      if (data.status === "loading" && value?.status !== "loading") {
        setValue({ status: "loading" });
      } else if (data.status === "success") {
        const d = extractRecordLabel(data, object);
        const recommendations = getRecommendations();
        if (value?.status !== "success") {
          setValue({
            status: "success",
            data: {
              id: d.id,
              name: d.name,
              image: d.image,
              additionalButtons: getAdditionalButtons(),
              showPanel:
                recommendations && recommendations.length
                  ? { type: "JSX", elements: recommendations }
                  : undefined,
            },
          });
        } else if (value.data.id !== d.id) {
          setValue({
            status: "success",
            data: {
              id: d.id,
              name: d.name,
              image: d.image,
              additionalButtons: getAdditionalButtons(),
              showPanel:
                recommendations && recommendations.length
                  ? { type: "JSX", elements: recommendations }
                  : undefined,
            },
          });
        }
      }
    }
    return () => setValue?.({ status: "initial" });
  }, [data, object, embedType]);

  if (embedType === EmbedType.recordModal) {
    return null;
  }

  if (!edit && !config.visible) {
    return null;
  }

  if (data.status === "loading") {
    return (
      <div className="record-view-header">
        <div className="record-view-header-logo">
          <Skeleton.Avatar size={32} active />
        </div>
        <div className="record-view-header-label">
          <Skeleton.Button size="small" active block />
        </div>
        <div className="record-view-header-edit">
          <Skeleton.Button size="small" active block />
        </div>
      </div>
    );
  }

  const d = extractRecordLabel(data, object);
  const invisible = !config.visible && edit;

  const additionalButtons = getAdditionalButtons();

  return (
    <div
      id={id}
      className={`record-view-header ${invisible ? "invisible" : ""}`}
    >
      {d.image ? (
        <div className="record-view-header-logo">
          <Avatar size={22} src={d.image} />
        </div>
      ) : null}
      <div className="record-view-header-label">
        <Typography.Text ellipsis style={{ marginBottom: 0 }} strong>
          {d.name ? d.name : d.id}
        </Typography.Text>
      </div>
      {additionalButtons.length > 0 && (
        <div className="record-view-header-edit">
          <Space>{additionalButtons}</Space>
        </div>
      )}
    </div>
  );
}

export default compose<Props, IRecordHeaderProps>(
  WithOrg,
  withRouter
)(RecordHeader);
