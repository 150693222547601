import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useMemo } from "react";
import {
  type InjectedAntUtilsProps,
  withAntUtils,
} from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import { JSONViewer } from "../../../components/form/json/JSONViewer";
import graphQlService from "../../../services/graphql/GraphQLService";
import WithOrg, { type InjectedOrgProps } from "../../orgs/WithOrg";
import { BORDER_COLOR } from "../../v2-demo/container/layout/domain";
import type { IClassifierJob, IIndexedFile } from "../domain";
import FileIndexerDocViewer from "./FileIndexerDocViewer";

export interface IFileIndexerFileCOntentInnerProps {
  indexedFile: IIndexedFile;
  classifierJobs: IClassifierJob[];
  onSave: () => Promise<void>;
}

type Props = IFileIndexerFileCOntentInnerProps &
  InjectedAntUtilsProps &
  InjectedOrgProps;

function FileIndexerFileContentInner(props: Props) {
  const { indexedFile, antUtils, org, classifierJobs, onSave } = props;

  const [form] = useForm<IIndexedFile>();
  const [stale, setIsStale] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsStale(false);
    form.resetFields();
  }, [indexedFile.id]);

  const initialValues = useMemo(() => {
    const { mappings, ...idxFl } = indexedFile;
    return classifierJobs.reduce((acc, m) => {
      const foundMapping = mappings.find(
        (a) => a.classifier?.id === m.classifier.id
      );
      return {
        ...acc,
        [m.classifier.id]: {
          status: foundMapping?.status,
          error: foundMapping?.error,
          mappings: JSON.parse(foundMapping?.mappings || "{}"),
          mappingsOverride: foundMapping?.mappingsOverride || "{}",
        },
      };
    }, idxFl);
  }, [indexedFile, classifierJobs]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{
          flex: `0 47px`,
          borderBottom: `1px solid ${BORDER_COLOR}`,
          display: "flex",
          alignItems: "center",
          padding: 8,
          gap: 16,
        }}
      >
        <div style={{ flex: 1 }}>
          <Typography.Text strong>{indexedFile.fileName}</Typography.Text>
        </div>
        <div style={{ flex: `0 auto` }}>
          <Button
            disabled={!stale}
            onClick={() => form.submit()}
            type="primary"
          >
            Save
          </Button>
        </div>
      </div>
      <div style={{ flex: 1, display: "flex", overflow: "hidden" }}>
        <div style={{ flex: 1, overflow: "auto" }}>
          <FileIndexerDocViewer
            filePath={indexedFile.filePath}
            extension={indexedFile.extension}
            objectStorageId={indexedFile.storage.id}
            orgId={org.id}
          />
        </div>
        <div
          style={{
            flex: `0 250px`,
            borderLeft: `1px solid ${BORDER_COLOR}`,
            overflow: "auto",
          }}
        >
          <Form<IIndexedFile>
            form={form}
            initialValues={{
              ...initialValues,
              validFrom: indexedFile.validFrom
                ? dayjs(indexedFile.validFrom)
                : null,
              validUntil: indexedFile.validFrom
                ? dayjs(indexedFile.validUntil)
                : null,
            }}
            onValuesChange={() => {
              if (!stale) {
                setIsStale(true);
              }
            }}
            onFinish={async () => {
              const loading = antUtils.message.loading(
                "Saving your changes...",
                0
              );
              try {
                const values = await form.validateFields();
                await graphQlService(
                  `mutation updateDoc($id: ID!, $data: DocumentUpdateInput!) {
                      updateDocument(id: $id, data: $data) {
                        id
                      }
                    }`,
                  {
                    id: indexedFile.id,
                    data: {
                      validFrom: values.validFrom,
                      validUntil: values.validUntil,
                    },
                  }
                );
                const { mappings, ...idxFl } = indexedFile;
                await classifierJobs.reduce(async (acc, v) => {
                  const foundMapping = mappings.find(
                    (a) => a.classifier?.id === v.classifier.id
                  );
                  await acc;
                  if (foundMapping) {
                    return graphQlService(
                      `mutation updateDocMapping($id: ID!, $data: DocumentMappingUpdateInput!) {
  updateDocumentMapping(id: $id, data: $data) {
    id
  }
}`,
                      {
                        id: foundMapping.id,
                        data: {
                          mappingsOverride:
                            values[v.classifier.id].mappingsOverride,
                        },
                      }
                    );
                  } else {
                    return graphQlService(
                      `
mutation createDocMapping($data: DocumentMappingCreateInput!) {
  createDocumentMapping(data: $data) {
    id
  }
}`,
                      {
                        data: {
                          classifier: {
                            connect: {
                              id: v.classifier.id,
                            },
                          },
                          org: {
                            connect: {
                              id: org.id,
                            },
                          },
                          document: {
                            connect: {
                              id: indexedFile.id,
                            },
                          },
                          status: undefined,
                          mappingsOverride:
                            values[v.classifier.id].mappingsOverride,
                        },
                      }
                    );
                  }
                }, Promise.resolve());
                await onSave();
                loading();
                antUtils.message.success("Changes successfully changed");
              } catch (err) {
                console.error(err);
                loading();
                antUtils.message.error(
                  "There was an error saving your changes"
                );
              }
            }}
          >
            <Space style={{ width: "100%", padding: 16 }} direction="vertical">
              <div>
                <div>
                  <Typography.Text strong>Extension</Typography.Text>
                </div>
                <div>
                  <Form.Item noStyle name={["extension"]}>
                    <Input disabled />
                  </Form.Item>
                </div>
              </div>
              <div>
                <div>
                  <Typography.Text strong>Valid from</Typography.Text>
                </div>
                <div>
                  <Form.Item noStyle name={["validFrom"]}>
                    <DatePicker allowClear={false} style={{ width: "100%" }} />
                  </Form.Item>
                </div>
              </div>
              <div>
                <div>
                  <Typography.Text strong>Valid until</Typography.Text>
                </div>
                <div>
                  <Form.Item noStyle name={["validUntil"]}>
                    <DatePicker allowClear={false} style={{ width: "100%" }} />
                  </Form.Item>
                </div>
              </div>
            </Space>

            {classifierJobs.map((c) => {
              return (
                <React.Fragment key={c.id}>
                  <div
                    style={{
                      width: "100%",
                      borderBottom: `1px solid ${BORDER_COLOR}`,
                    }}
                  />
                  <div style={{ padding: 16 }}>
                    <Typography.Text strong>
                      {_.capitalize(c.classifier.name)}
                    </Typography.Text>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      margin: `0 -16px`,
                      borderBottom: `1px solid ${BORDER_COLOR}`,
                    }}
                  />
                  <Space
                    style={{ width: "100%", padding: 16 }}
                    direction="vertical"
                  >
                    <div>
                      <div>
                        <Typography.Text strong>Status</Typography.Text>
                      </div>
                      <div>
                        <Form.Item noStyle name={[c.id, "status"]}>
                          <Select
                            placeholder="Not processed yet"
                            style={{ width: "100%" }}
                            disabled
                          >
                            <Select.Option value="PROCESSED">
                              Processed
                            </Select.Option>
                            <Select.Option value="FAILED">Failed</Select.Option>
                            <Select.Option value="SKIPPED">
                              Skipped
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div>
                      <div>
                        <Typography.Text strong>
                          Current Mappings
                        </Typography.Text>
                      </div>
                      <div>
                        <Form.Item noStyle name={[c.id, "mappings"]}>
                          <JSONViewer />
                        </Form.Item>
                      </div>
                    </div>
                    <div>
                      <div>
                        <Typography.Text strong>
                          Override Mappings
                        </Typography.Text>
                      </div>
                      <div>
                        <Form.Item noStyle name={[c.id, "mappingsOverride"]}>
                          <TextArea />
                        </Form.Item>
                      </div>
                    </div>
                  </Space>
                </React.Fragment>
              );
            })}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default compose<Props, IFileIndexerFileCOntentInnerProps>(
  withAntUtils,
  WithOrg
)(FileIndexerFileContentInner);
