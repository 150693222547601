import { Empty } from "antd";
import { useEffect } from "react";
import { useLagoonQuery } from "../../../../../../../../components/hooks/query/useLagoonQuery";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import { type IObject } from "../../../../../../../../interfaces/object";
import { type IOrg } from "../../../../../../../../interfaces/org";
import { type IUser } from "../../../../../../../../interfaces/user";
import { IDB_GET_ERROR } from "../../../../../../../../services/idbService";
import type { WidgetCacheProps } from "../../../../../../../../store/widgetCacheStore";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import { type IRecord } from "../../../../domain";
import { type IWidget } from "../../../domain";
import { getTempRecosToAvoid } from "../../actions/feedback/domain";
import {
  getSuggestedProductsQuery,
  type IWidgetParsedConfig,
  type IWidgetSuggestedProductsConfig,
} from "../domain";
import SuggestedProductsRenderer from "./SuggestedProductsRenderer";

export interface ISuggestedProductsLoaderProps {
  widget: IWidget;
  object: IObject;
  foreignObject: IObject;
  record: IRecord;
  conf: IWidgetSuggestedProductsConfig;
  parsedConf: IWidgetParsedConfig;
  org: IOrg;
  user: IUser;
  widgetCacheProps: WidgetCacheProps;
}

export function SuggestedProductsLoader(props: ISuggestedProductsLoaderProps) {
  const {
    conf,
    parsedConf,
    record,
    widget,
    object,
    foreignObject,
    org,
    user,
    widgetCacheProps,
  } = props;

  const { setAsFinished } = useWidgetCacheActions(widgetCacheProps);

  const id = record[`${object.table.cubeName}.id`] as string;
  const parentContext = `${object.id}.${id}.${foreignObject.id}`;

  const recosToAvoid = getTempRecosToAvoid(parentContext);

  const query = getSuggestedProductsQuery({
    object,
    record,
    conf,
    recosToAvoid,
    foreignObject,
  });

  const { data, loading, error, refetch } = useLagoonQuery(query, {
    objectType: "OBJECT",
    objectId: object.id,
    org: org,
    enabled: !!query,
  });

  useEffect(() => {
    if (data || error) {
      setAsFinished(!!error);
    }
  }, [data, error, setAsFinished]);

  if (loading) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (error) {
    if (error.cause === IDB_GET_ERROR) {
      return <Feednack>You need to be online to view this content</Feednack>;
    }

    return (
      <Feednack>
        <>{error.message}</>
      </Feednack>
    );
  }

  if (!data) {
    return (
      <Feednack>
        <Empty />
      </Feednack>
    );
  }

  return (
    <SuggestedProductsRenderer
      data={data}
      widget={widget}
      object={foreignObject}
      record={record}
      parentContext={parentContext}
      conf={parsedConf}
      user={user}
      reload={() => {
        const recosToAvoid = getTempRecosToAvoid(parentContext);
        const q = getSuggestedProductsQuery({
          object,
          record,
          conf,
          recosToAvoid,
          foreignObject,
        });
        if (q) {
          refetch(q);
        }
      }}
    />
  );
}
