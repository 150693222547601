import { Input, List, Typography } from "antd";
import { useFuseSearch } from "../../../components/hooks/useFuseSearch";
import { yellow } from "../../../utils/colorPalette";
import { BORDER_COLOR } from "../../v2-demo/container/layout/domain";
import type { IClassifierJob, IIndexedFile } from "../domain";

export interface IFileIndexerLoadedListProps {
  data: { files: IIndexedFile[]; classifierJobs: IClassifierJob[] };
  onSelect: (id: string) => void;
  selectedId?: string;
}

export function FileIndexerLoadedList(props: IFileIndexerLoadedListProps) {
  const { data, onSelect, selectedId } = props;

  const { search, setSearch, results } = useFuseSearch({
    items: data.files,
    fuseOptions: {
      keys: ["fileName"],
      includeMatches: false,
      shouldSort: false,
    },
  });

  return (
    <List
      style={{ position: "relative" }}
      header={
        <div
          style={{
            paddingLeft: `8px`,
            paddingRight: `8px`,
          }}
        >
          <Input
            value={search}
            placeholder="search"
            onChange={(v) => setSearch(v.target.value)}
            size="small"
          />
        </div>
      }
      dataSource={search ? results : data.files}
      renderItem={(item, index) => (
        <List.Item
          onClick={() => onSelect(item.id)}
          style={{
            backgroundColor: selectedId === item.id ? `#B9CEE5` : undefined,
            borderBottom: `1px solid ${BORDER_COLOR}`,
            paddingLeft: `16px`,
            paddingRight: `16px`,
            borderRight: item.mappings.find((m) => !!m.mappingsOverride)
              ? `4px solid ${yellow[5]}`
              : undefined,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }}
        >
          <Typography.Text ellipsis>{item.fileName}</Typography.Text>
        </List.Item>
      )}
    />
  );
}
