import { Pagination } from "antd";
import _ from "lodash";
import * as React from "react";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import type { IObject } from "../../../../../../../../interfaces/object";
import { IDB_GET_ERROR } from "../../../../../../../../services/idbService";
import type { WlyResultSet } from "../../../../../../../../services/LagoonService";
import { BORDER_COLOR } from "../../../../../layout/domain";
import type { AvailableColumn } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import RelatedCOllapseSingleItem from "./RelatedCollapseSingleItem";

export interface IRelatedCollapseContentProps {
  data?: IRecord[];
  resultSet?: WlyResultSet<any>;
  loading: boolean;
  error: Error | undefined;
  pageSize: number;
  labelColumn: string;
  rightColumn?: string;
  secondLineColumns?: string[];
  innerContent?: string;
  availableColumns: AvailableColumn[];
  object: IObject;
}

export function RelatedCollapseContent(props: IRelatedCollapseContentProps) {
  const {
    data,
    loading,
    error,
    pageSize,
    labelColumn,
    rightColumn,
    availableColumns,
    object,
    innerContent,
    secondLineColumns,
  } = props;
  const [page, setPage] = React.useState<number>(0);
  const [isOpen, setIsOpen] = React.useState<number>(-1);

  React.useEffect(() => {
    if (isOpen > -1) {
      setIsOpen(-1);
    }
  }, [page]);

  const renderInner = () => {
    if (loading) {
      return (
        <div style={{ height: 150 }}>
          <Feednack>
            <Loading />
          </Feednack>
        </div>
      );
    } else if (error) {
      return (
        <div style={{ height: 150 }}>
          {error.cause === IDB_GET_ERROR ? (
            <Feednack>You need to be online to view this content</Feednack>
          ) : (
            <Feednack>
              <div>Error: There was an error fetching your content</div>
            </Feednack>
          )}
        </div>
      );
    }
    const ps = pageSize ?? 10;
    const d = data || [];
    const pagedData = _.chunk(d, ps);
    const hasData = pagedData[page] && pagedData[page].length;

    const renderItems = () => {
      if (pagedData[page] && pagedData[page].length) {
        return (pagedData[page] ?? []).map((r, i) => {
          return (
            <div key={i}>
              <RelatedCOllapseSingleItem
                labelColumn={labelColumn}
                rightColumn={rightColumn}
                data={r}
                availableColumns={availableColumns}
                object={object}
                innerContent={innerContent}
                secondLineColumns={secondLineColumns}
                isOpen={isOpen === i}
                setIsOpen={() => (isOpen === i ? setIsOpen(-1) : setIsOpen(i))}
              />
            </div>
          );
        });
      }
      return (
        <div style={{ height: 150 }}>
          <Feednack>No data</Feednack>
        </div>
      );
    };

    return (
      <div>
        {renderItems()}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            paddingRight: 18,
            paddingBottom: 6,
            paddingTop: 6,
            borderTop: !hasData ? `1px solid ${BORDER_COLOR}` : undefined,
          }}
        >
          <div>
            <Pagination
              size="small"
              current={page + 1}
              onChange={(e) => setPage(e - 1)}
              total={d.length}
              pageSize={ps}
              showLessItems={true}
              showSizeChanger={false}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
            />
          </div>
        </div>
      </div>
    );
  };

  return <div>{renderInner()}</div>;
}
