import { Drawer, Space, theme, Tooltip, Typography } from "antd";
import cuid from "cuid";
import React, { useState } from "react";
import { type AvailableProperty } from "../../../../../containers/v2-demo/container/object/viewer/domain";
import type { IObjectPropertyScoreFormatterConfig } from "../../../../../interfaces/object";
import { usePropertyContext } from "../domain";
import { ScoreDrawerContentRenderer } from "./ScoreDrawerContentRenderer";
import { renderColor, renderIcon, type IScoreConfigValue } from "./domain";

interface IScoreCellRendererProps {
  property: AvailableProperty;
  config: IObjectPropertyScoreFormatterConfig;
  value?: IScoreConfigValue;
}

const { useToken } = theme;

const ScoreCellRenderer = (props: IScoreCellRendererProps) => {
  const { config, value, property } = props;

  const { token } = useToken();
  const [context, setContextValue, contextValue] = usePropertyContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  if (!value) {
    return null;
  }

  const color = renderColor(token, value);
  const icon = renderIcon(value);

  const onDrawerOpen = () => {
    setIsDrawerOpen((v) => !v);
    if (contextValue && contextValue.status === "success") {
      setContextValue?.({
        ...contextValue,
        data: {
          ...contextValue.data,
          key: cuid(),
          showPanel: {
            type: "JSX",
            elements: [renderInnerDrawer()],
          },
        },
      });
    }
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    if (contextValue && contextValue.status === "success") {
      setContextValue?.({
        ...contextValue,
        data: {
          ...contextValue.data,
          showPanel: null as any,
          key: cuid(),
        },
      });
    }
  };

  const getDisplayMode = () => {
    if (value.reasons && Array.isArray(value.reasons) && value.reasons.length) {
      return "tooltip";
    } else if (
      (value?.subScores && value?.subScores.length) ||
      value?.description
    ) {
      return "drawer";
    }
    return null;
  };

  const displayMode = getDisplayMode();

  const wrapInTooltip = (n: React.ReactElement): React.ReactElement => {
    if (
      displayMode === "tooltip" &&
      value.reasons &&
      Array.isArray(value.reasons)
    ) {
      return (
        <Tooltip
          title={
            <div style={{ maxWidth: 300 }}>
              <Space direction="vertical">
                {value.reasons.map((r) => {
                  return <div key={r}>• {r}</div>;
                })}
              </Space>
            </div>
          }
        >
          {n}
        </Tooltip>
      );
    }
    return n;
  };

  const renderInnerDrawer = () => {
    return (
      <ScoreDrawerContentRenderer
        title={property?.label}
        value={value}
        onClose={onDrawerClose}
        context={context}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        height: "100%",
      }}
    >
      {wrapInTooltip(
        <div
          style={{
            height: 26,
            backgroundColor: color.background,
            display: "flex",
            gap: 8,
            padding: `0 12px`,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 16,
            cursor: displayMode === "drawer" ? "pointer" : undefined,
            // border: `1px solid ${color.border}`,
          }}
          onClick={displayMode === "drawer" ? onDrawerOpen : undefined}
        >
          {icon && (
            <div style={{ fontSize: `1.1em`, color: color.color }}>{icon}</div>
          )}

          <div>
            <Typography.Text>{value?.score}</Typography.Text>
          </div>
        </div>
      )}
      {context === "DRAWER" && displayMode === "drawer" && (
        <Drawer
          styles={{ body: { padding: 16 }, header: { padding: 16 } }}
          open={isDrawerOpen}
          title={property?.label}
          onClose={onDrawerClose}
        >
          {renderInnerDrawer()}
        </Drawer>
      )}
    </div>
  );
};

export default ScoreCellRenderer;
