import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import WithOrg, { type InjectedOrgProps } from "../../../../../orgs/WithOrg";
import type { IClonedWorkbench } from "./services/domain";
import { uploadWorkbench } from "./services/uploadWorkbenchService";

interface IImportWorkbenchProps {
  currentWarehouse: IDestination;
}

const { Dragger } = Upload;

type Props = IImportWorkbenchProps & InjectedAntUtilsProps & InjectedOrgProps;

function ImportWorkbench(props: Props) {
  const {
    antUtils: { message, modal },
    org,
    currentWarehouse,
  } = props;

  const [uploading, setUploading] = React.useState<
    AsyncData<{ filePath: string }>
  >({
    status: "initial",
  });

  React.useEffect(() => {
    if (uploading.status === "success") {
      setTimeout(() => {
        setUploading({ status: "initial" });
      }, 3000);
    }
  }, [uploading.status]);

  const onUpload = async (file: File): Promise<void> =>
    new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = function (evt) {
        const results = evt.target?.result;
        if (!results) {
          reject("Document is empty");
        }
        if (typeof results !== "string") {
          reject("Invalid document");
        }
        const safeParse = (): IClonedWorkbench => {
          try {
            return JSON.parse(results as string);
          } catch (err) {
            return reject("Couldn't parse document") as any;
          }
        };
        const json = safeParse();
        uploadWorkbench(org.id, currentWarehouse.id, json)
          .then(resolve)
          .catch(reject);
      };
      reader.onerror = function (evt) {
        reject("Error while reading document");
      };
    });

  const uploadProps: UploadProps = {
    name: "file",
    disabled: uploading.status === "loading",
    showUploadList: false,
    accept: "*/*",
    customRequest: (options) => {
      onUpload(options.file as File)
        .then((r) => {
          options?.onSuccess?.({ response: "ok" });
        })
        .catch((err) => {
          console.error(err);
          options?.onError?.(err);
        });
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        setUploading({ status: "loading" });
      }
      if (status === "done" && info.file?.response) {
        setUploading({
          status: "success",
          data: { filePath: info.file?.response },
        });
      } else if (status === "error") {
        setUploading({ status: "initial" });
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: async (file) => {
      await new Promise((resolve, reject) => {
        modal.warning({
          content: `You are about to import folders, models and objects into ${org.name}. This cannot be undone, are you sure you want to do that ?`,
          onOk: () => {
            setUploading({ status: "loading" });
            resolve({});
          },
          onCancel: () => {
            reject();
          },
        });
      });
    },
  };

  const renderInnerModal = () => {
    if (uploading.status === "success") {
      return (
        <div
          style={{
            height: 200,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Success
        </div>
      );
    }
    if (uploading.status === "loading") {
      return (
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <LoadingOutlined />
          </p>
          <p className="ant-upload-text">Uploading...</p>
        </Dragger>
      );
    }

    return (
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Drag your file here</p>
        {/* <p className="ant-upload-hint">
          Accepts XXXX files. In case of doubts you can download a template
          there.
        </p> */}
      </Dragger>
    );
  };

  return <>{renderInnerModal()}</>;
}

export default compose<Props, IImportWorkbenchProps>(
  withAntUtils,
  WithOrg
)(ImportWorkbench);
