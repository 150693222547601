import data from "@emoji-mart/data";
import { LicenseManager } from "ag-grid-enterprise";
import { ConfigProvider } from "antd";
import relativeTime from "dayjs/plugin/relativeTime";
import { init } from "emoji-mart";
import { createBrowserHistory } from "history";
import { Provider } from "mobx-react";
import { syncHistoryWithStore } from "mobx-react-router";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { AntUtilsProvider } from "./components/ant-utils/antUtilsContextProvider";
import App from "./containers/app/App";
import OnlineStatusProvider from "./providers/OnlineStatusProvider";
import { getClient } from "./services/graphql/GraphQLService";
import authStore from "./store/authStore";
import breadcrumbStore from "./store/breadCrumbStore";
import workspaceDatastore from "./store/dataStores/workspace/workspaceDatastore";
import demoStore from "./store/demoStore";
import objectUIStore from "./store/objectUIStore";
import onboardingStore from "./store/onboardingStore";
import routerStore from "./store/routerStore";
import userStore from "./store/userStore";
import workbenchUIStore from "./store/workbenchUIStore";
import workspaceUIStore from "./store/workspaceUIStore";

import { ApolloProvider as RawApolloProvider } from "@apollo/client";
import "antd/dist/reset.css";
import dayjs from "dayjs";
import { type ReactNode } from "react";
import { useAsync } from "react-use";
import { SlowNetworkProvider } from "./components/contexts/SlowNetworkProvider";
import "./index.css";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-055253}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Whaly_SAS}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Whaly}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Whaly}_need_to_be_licensed___{Whaly}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{18_February_2025}____[v3]_[01]_MTczOTgzNjgwMDAwMA==6603bf1db0fdbce7e488b82c8006f2c7"
);

dayjs.extend(relativeTime);

init({ data });

const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, routerStore);

const stores = {
  userStore,
  authStore,
  breadcrumbStore,
  onboardingStore,
  workbenchUIStore,
  objectUIStore,
  routerStore,
  workspaceUIStore,
  workspaceDatastore,
  demoStore,
};

(window as any)._____APP_STATE_____ = stores;

const container = document.getElementById("root");
const root = createRoot(container!);

const ApolloProvider = ({ children }: { children: ReactNode }) => {
  const { value: client, loading, error } = useAsync(() => getClient(), []);

  if (!error && !loading && client) {
    return <RawApolloProvider client={client}>{children}</RawApolloProvider>;
  } else {
    return <></>;
  }
};

root.render(
  <Provider {...stores}>
    <ConfigProvider
      theme={{
        components: {
          Button: {
            primaryShadow: "none",
            defaultShadow: "none",
            dangerShadow: "none",
          },
        },
        token: {
          //boxShadow: "none",
          colorPrimary: "#3a5c83",
          colorInfo: "#69a2b0",
          colorLink: "#3a5c83",
          colorBgContainerDisabled: "#f5f5f5",
          colorBgLayout: "#f3f5f7",
          fontFamily: "'Lato', sans-serif",
          colorLinkHover: "#4d7aae",
          // borderRadius: 4,
          // borderRadiusSM: 2,
          // "@processing-color": "#5D6671",
          // "@normal-color": "#E7ECEF",
          // "@layout-sider-background": "#3A5C83",
        },
      }}
    >
      <SlowNetworkProvider>
        <AntUtilsProvider>
          <OnlineStatusProvider>
            <DndProvider backend={HTML5Backend}>
              <ApolloProvider>
                <App history={history} />
              </ApolloProvider>
            </DndProvider>
          </OnlineStatusProvider>
        </AntUtilsProvider>
      </SlowNetworkProvider>
    </ConfigProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
