import { gql } from "@apollo/client";
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Skeleton,
  Space,
  Switch,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import _ from "lodash";
import React, { useState } from "react";
import {
  withAntUtils,
  type InjectedAntUtilsProps,
} from "../../../../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { useGQLQuery } from "../../../../../../../components/hooks/query/useGQLQuery";
import { SourceImageRenderer } from "../../../../../../../components/sources/SourceImageRenderer";
import type { IFileUpload } from "../../../../../../../interfaces/fileUpload";
import type { IObjectStorage } from "../../../../../../../interfaces/objectStorage";
import type { IUser } from "../../../../../../../interfaces/user";
import workbenchUIStore from "../../../../../../../store/workbenchUIStore";
import WithOrg, { type InjectedOrgProps } from "../../../../../../orgs/WithOrg";
import type { IActiveObject } from "../../../../domain";
import type { UpdateFileUploaderJobFunction } from "../../../../selector/file-uploader/domain";

export interface IFileUploaderSettingsViewerProps {
  activeObject: IActiveObject;
  fileUploadJob: IFileUpload;
  onUpdateFileUploader: UpdateFileUploaderJobFunction;
  afterSave: () => void;
}

type Props = IFileUploaderSettingsViewerProps &
  InjectedAntUtilsProps &
  InjectedOrgProps;

export enum ZoneName {
  US_CENTRAL1_A = "us-central1-a",
  US_CENTRAL1_B = "us-central1-b",
  US_EAST1_B = "us-east1-b",
  EUROPE_WEST1_B = "europe-west1-b",
  EUROPE_WEST1_D = "europe-west1-d",
  ASIA_EAST1_A = "asia-east1-a",
  // Add more zones as needed
}

export enum MachineType {
  E2_MICRO = "e2-micro",
  E2_SMALL = "e2-small",
  E2_MEDIUM = "e2-medium",
  N1_STANDARD_1 = "n1-standard-1",
  N1_STANDARD_2 = "n1-standard-2",
  N1_HIGHMEM_2 = "n1-highmem-2",
  N1_HIGHCPU_4 = "n1-highcpu-4",
  // Add more machine types as needed
}

function FileUploaderSettingsViewer(props: Props) {
  const {
    fileUploadJob,
    antUtils,
    org,
    onUpdateFileUploader,
    afterSave,
    activeObject,
  } = props;

  const [saving, setSaving] = React.useState<boolean>(false);
  const [form] = useForm<IFileUpload>();
  const [canSave, setCanSave] = useState(false);
  const { data, pending, loading, error } = useGQLQuery<{
    allObjectStorages: IObjectStorage[];
    allUsers: IUser[];
  }>(
    gql`
      query getOrgObjectStorages($orgId: ID!) {
        allObjectStorages(where: { isDeleted_not: true, org: { id: $orgId } }) {
          id
          name
          objectStorageMeta {
            publicInfo {
              logo
            }
          }
        }
        allUsers(
          where: {
            isDeleted_not: true
            type: SERVICE_ACCOUNT
            roles_some: { org: { id: $orgId }, isDeleted_not: true }
          }
        ) {
          id
          firstName
          lastName
        }
      }
    `,
    { variables: { orgId: org.id } }
  );

  const initialValues =
    activeObject.stale && activeObject.staleState
      ? activeObject.staleState
      : {
          ...fileUploadJob,
          computeConfig: JSON.parse(fileUploadJob.computeConfig ?? "{}"),
        };

  const renderInnerObjectStorages = () => {
    if (pending || loading) {
      return <Skeleton.Button block active shape="round" />;
    } else if (error) {
      return (
        <Typography.Text type="danger">
          There was an error fetching object storages
        </Typography.Text>
      );
    } else {
      return (
        <Select style={{ width: "100%" }} showSearch optionFilterProp="label">
          {data?.allObjectStorages
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .map((v) => {
              return (
                <Select.Option key={v.id} value={v.id} label={v.name}>
                  <Space>
                    <SourceImageRenderer
                      alt={"sourceName"}
                      className="source-table-selection-logo"
                      img={v.objectStorageMeta.publicInfo.logo}
                      size={16}
                    />
                    {v.name}
                    <Typography.Text type="secondary">{v.name}</Typography.Text>
                  </Space>
                </Select.Option>
              );
            })}
        </Select>
      );
    }
  };

  const renderInnerServiceAccount = () => {
    if (pending || loading) {
      return <Skeleton.Button block active shape="round" />;
    } else if (error) {
      return (
        <Typography.Text type="danger">
          There was an error fetching object storages
        </Typography.Text>
      );
    } else {
      return (
        <Select style={{ width: "100%" }} showSearch optionFilterProp="label">
          {data?.allUsers.map((v) => {
            const name = `${v.firstName} ${v.lastName}`;
            return (
              <Select.Option key={v.id} value={v.id} label={name}>
                <Space>{name}</Space>
              </Select.Option>
            );
          })}
        </Select>
      );
    }
  };

  return (
    <div style={{ maxWidth: 800, width: "100%", margin: "24px auto" }}>
      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <WlyCard
          title={"Edit your file upload job"}
          extra={
            <Button
              loading={saving}
              onClick={form.submit}
              disabled={!canSave}
              type="primary"
            >
              Save
            </Button>
          }
        >
          <Form
            form={form}
            disabled={saving}
            initialValues={initialValues}
            layout="vertical"
            onValuesChange={() => {
              if (!canSave) {
                setCanSave(true);
              }
              const values = form.getFieldsValue();
              if (!activeObject.stale && !_.isEqual(values, initialValues)) {
                workbenchUIStore.setActiveObjectStale(
                  activeObject,
                  form.getFieldsValue()
                );
              }
            }}
            onFinish={async () => {
              try {
                setSaving(true);
                const f = await form.validateFields();
                const payload = {
                  ...f,
                  computeConfig: JSON.stringify(f.computeConfig),
                };
                await onUpdateFileUploader(fileUploadJob.id, payload);
                await antUtils.message.success(
                  "Successfully saved your file upload job."
                );
                await workbenchUIStore.setActiveObjectStale(
                  activeObject,
                  null,
                  false
                );
                await afterSave();
              } catch (err) {
                antUtils.message.error(
                  "There was an error saving your file upload job. Please try again."
                );
              } finally {
                setSaving(false);
              }
            }}
          >
            <Divider orientation="left" orientationMargin={0}>
              <Typography.Text strong>Details</Typography.Text>
            </Divider>
            <Form.Item rules={[{ required: true }]} label="Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <TextArea />
            </Form.Item>
            <Form.Item valuePropName="checked" label="Enabled" name="isEnabled">
              <Switch />
            </Form.Item>
            <Form.Item
              label={"Service account"}
              name={["serviceAccount", "id"]}
              rules={[{ required: true }]}
            >
              {renderInnerServiceAccount()}
            </Form.Item>
            <Form.Item
              label={"Storage"}
              name={["storage", "id"]}
              rules={[{ required: true }]}
            >
              {renderInnerObjectStorages()}
            </Form.Item>
            <Divider orientation="left" orientationMargin={0}>
              <Typography.Text strong>Compute</Typography.Text>
            </Divider>
            <Form.Item
              rules={[{ required: true }]}
              label="Compute platform"
              name="computeType"
            >
              <Select>
                <Select.Option value="GOOGLE">Google Cloud</Select.Option>
                <Select.Option disabled value="AZURE">
                  Azure
                </Select.Option>
                <Select.Option disabled value="AWS">
                  AWS
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {() => {
                if (form.getFieldValue("computeType") === "GOOGLE") {
                  return (
                    <>
                      <Form.Item
                        name={["computeConfig", "PROJECT_ID"]}
                        label="Project Id"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={["computeConfig", "ZONE_NAME"]}
                        label="Zone"
                        rules={[{ required: true }]}
                      >
                        <Select>
                          {Object.keys(ZoneName).map((v) => {
                            return (
                              <Select.Option key={v} value={ZoneName[v]}>
                                {ZoneName[v]}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={["computeConfig", "SERVICE_ACCOUNT_EMAIL"]}
                        label="Service account email"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={[
                          "computeConfig",
                          "GOOGLE_APPLICATION_JSON_CREDENTIALS",
                        ]}
                        label="Service account key"
                        rules={[{ required: true }]}
                      >
                        <Input type="password" />
                      </Form.Item>
                    </>
                  );
                }
                return null;
              }}
            </Form.Item>
            <Divider orientation="left" orientationMargin={0}>
              <Typography.Text strong>
                Transformer configuration
              </Typography.Text>
            </Divider>
            <Form.Item
              rules={[{ required: true }]}
              label="Docker url"
              name="transformerDockerUrl"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              label="Docker variables"
              name="transformerDockerVars"
            >
              <TextArea />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {() => {
                if (form.getFieldValue("computeType") === "GOOGLE") {
                  return (
                    <>
                      <Form.Item
                        name={["computeConfig", "TRANSFORMER_MACHINE_TYPE"]}
                        label="Machine type"
                        rules={[{ required: true }]}
                      >
                        <Select>
                          {Object.keys(MachineType).map((v) => {
                            return (
                              <Select.Option key={v} value={MachineType[v]}>
                                {MachineType[v]}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={["computeConfig", "TRANSFORMER_DISK_SIZE_GB"]}
                        label="Disk size"
                        rules={[{ required: true }]}
                      >
                        <Select>
                          <Select.Option value={10}>10gb</Select.Option>
                          <Select.Option value={20}>20gb</Select.Option>
                          <Select.Option value={30}>30gb</Select.Option>
                        </Select>
                      </Form.Item>
                    </>
                  );
                }
                return null;
              }}
            </Form.Item>
            <Divider orientation="left" orientationMargin={0}>
              <Typography.Text strong>Uploader configuration</Typography.Text>
            </Divider>
            <Form.Item
              rules={[{ required: true }]}
              label="Docker url"
              name="uploaderDockerUrl"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              label="Docker variables"
              name="uploaderDockerVars"
            >
              <TextArea />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {() => {
                if (form.getFieldValue("computeType") === "GOOGLE") {
                  return (
                    <>
                      <Form.Item
                        name={["computeConfig", "UPLOADER_MACHINE_TYPE"]}
                        label="Machine type"
                        rules={[{ required: true }]}
                      >
                        <Select>
                          {Object.keys(MachineType).map((v) => {
                            return (
                              <Select.Option key={v} value={MachineType[v]}>
                                {MachineType[v]}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={["computeConfig", "UPLOADER_DISK_SIZE_GB"]}
                        label="Disk size"
                        rules={[{ required: true }]}
                      >
                        <Select>
                          <Select.Option value={10}>10gb</Select.Option>
                          <Select.Option value={20}>20gb</Select.Option>
                          <Select.Option value={30}>30gb</Select.Option>
                        </Select>
                      </Form.Item>
                    </>
                  );
                }
                return null;
              }}
            </Form.Item>
          </Form>
        </WlyCard>
      </Space>
    </div>
  );
}

export default compose<Props, IFileUploaderSettingsViewerProps>(
  withAntUtils,
  WithOrg
)(FileUploaderSettingsViewer);
