import * as React from "react";
import { Link } from "react-router-dom";
import type { IOrg } from "../../../interfaces/org";
import "./OrgListItem.scss";

interface IOrgListItemProps {
  org: IOrg;
  render: (org: IOrg) => React.ReactNode;
  to: string;
  onClick?: (url: string) => void;
}

export default class OrgListItem extends React.Component<IOrgListItemProps> {
  public render() {
    const { org, render, to, onClick } = this.props;
    return (
      <Link
        to={to}
        onClick={(e) => {
          onClick && e.preventDefault();
          onClick?.(to);
        }}
      >
        <div className="org-list-item">{render(org)}</div>
      </Link>
    );
  }
}
