import {
  BookOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps, PopoverProps } from "antd";
import { Menu, Popover } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { compose } from "../../components/compose/WlyCompose";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import "./HelpDropdown.scss";

interface IHelpDropDownProps extends PopoverProps {}

interface IState {
  open: boolean;
}

type Props = IHelpDropDownProps & RouteComponentProps & InjectedOrgProps;

class HelpDropDown extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public render() {
    const close = () => {
      this.setState({ open: false });
    };

    const handleOpenChange = (open: boolean) => {
      this.setState({ open: open });
    };

    const items: MenuProps["items"] = [
      {
        key: "1",
        onClick: () => close(),
        label: (
          <Link to={{ pathname: "https://docs.whaly.io" }} target="whaly_doc">
            <QuestionCircleOutlined /> Product documentation
          </Link>
        ),
      },
      {
        key: "2",
        onClick: () => close(),
        label: (
          <Link to={{ pathname: "https://help.whaly.io" }} target="whaly_help">
            <BookOutlined /> Implementation guides
          </Link>
        ),
      },
      {
        key: "2-1",
        type: "divider",
      },
      {
        key: "3",
        label: (
          <Link
            to="/"
            id="help-button"
            onClick={(e) => {
              e.preventDefault();
              (window as any).Intercom?.("show");
              close();
            }}
          >
            <MessageOutlined /> Send us a message
          </Link>
        ),
      },
      {
        key: "4",
        onClick: () => close(),
        label: (
          <Link
            to={{
              pathname:
                "https://meetings.hubspot.com/florianbuguet/30-minutes-call",
            }}
            target="whaly_sales"
          >
            <UserOutlined /> Chat with sales
          </Link>
        ),
      },
      {
        key: "4-1",
        type: "divider",
      },
      {
        key: "5",
        onClick: () => close(),
        label: (
          <Link
            to={{ pathname: "https://whaly.io/posts/tags/change-log" }}
            target="whaly_blog"
          >
            What's new
          </Link>
        ),
      },
    ];

    return (
      <Popover
        overlayClassName="help-dropdown"
        content={
          <Menu
            style={{
              borderRadius: "0 0 6px 6px",
            }}
            items={items}
          />
        }
        open={this.state.open}
        onOpenChange={handleOpenChange}
        destroyTooltipOnHide={true}
        title={
          <div className="info-wrapper">
            <div className="title">Help</div>
            <div>Whaly's resources</div>
          </div>
        }
        overlayInnerStyle={{
          padding: 0,
        }}
        {...this.props}
      >
        <div style={{ cursor: "pointer", display: "inline-block" }}>
          {this.props.children}
        </div>
      </Popover>
    );
  }
}

export default compose<Props, IHelpDropDownProps>(
  withRouter,
  WithOrg
)(observer(HelpDropDown));
