import { Modal, Typography } from "antd";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useAsync } from "react-use";
import { login, portalLogin } from "../../auth/auth";
import { compose } from "../../components/compose/WlyCompose";
import ErrorFeedback from "../../components/layout/feedback/error";
import Feednack from "../../components/layout/feedback/feedback";
import type { RouteDefinition } from "../../routes/domain";
import { routesComponents } from "../../routes/routesComponents";
import { track } from "../../services/AnalyticsService";
import * as authService from "../../services/authService";
import { useLogoutEvent } from "../../services/broadcastService";
import type { UserStoreProps } from "../../store/userStore";
import userStore from "../../store/userStore";
import LayoutManager from "../layout/LayoutManager";

type AuthenticatedRouteRendererProps = {
  route: RouteDefinition;
  error: Error;
};

type Props = AuthenticatedRouteRendererProps & UserStoreProps;

const Comp = ({ route, error }: AuthenticatedRouteRendererProps) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [hasFetchUser, setHasFetchUser] = useState(false);
  const {
    value: hasNeverBeenAuthenticated,
    loading,
    error: authServiceError,
  } = useAsync(authService.hasNeverBeenAuthenticated, []);

  useEffect(() => {
    if (hasNeverBeenAuthenticated) {
      const { pathname, search, hash } = window.location;

      authService.isPartnerPortal()
        ? window.location.replace(portalLogin(`${pathname}${search}${hash}`))
        : window.location.replace(login(`${pathname}${search}${hash}`));
    } else {
      if (userStore.user) {
        setHasFetchUser(true);
      } else if (!loading) {
        userStore.getUser().finally(() => setHasFetchUser(true));
      }
    }
  }, [hasNeverBeenAuthenticated, loading]);

  useLogoutEvent(() => {
    if (!document.hasFocus()) {
      setShowLogoutModal(true);
    }
  });

  if (error || authServiceError) {
    track("Error", {
      title: route.title,
      path: window.location.pathname,
    });

    return <ErrorFeedback err={error ?? authServiceError} />;
  }

  if (
    loading ||
    hasFetchUser === false ||
    hasNeverBeenAuthenticated === undefined ||
    hasNeverBeenAuthenticated
  ) {
    return (
      <Feednack>
        <div>Login you in...</div>
      </Feednack>
    );
  }

  return (
    <>
      <Modal
        open={showLogoutModal}
        okText="Reload"
        onOk={() => window.location.reload()}
        closable={false}
        maskClosable={false}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Typography.Text>You have been disconnected.</Typography.Text>
      </Modal>
      <LayoutManager
        component={routesComponents[route.component]}
        layout={route.layout}
        additionalProps={route.props}
        restrictAccess={route.restrictAccess}
      />
    </>
  );
};

export const AuthenticatedRouteRenderer = compose<
  Props,
  AuthenticatedRouteRendererProps
>(
  inject("userStore"),
  observer
)(Comp);
