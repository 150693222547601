import { useDrag } from "react-dnd";
import type { IReport } from "../../../interfaces/reports";
import ReportCard from "./ReportCard";

interface IReportDraggableCardProps {
  report: IReport;
  isOnline: boolean;
  onUpdate: () => void;
}

export function ReportDraggableCard(props: IReportDraggableCardProps) {
  const { report, isOnline, onUpdate } = props;

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: "REPORT",
      item: report,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.3 : 1,
      }),
      options: {
        dropEffect: "move",
      },
    }),
    []
  );

  return (
    <ReportCard
      forwardRef={isOnline ? dragRef : undefined}
      style={{ opacity }}
      report={report}
      onUpdate={onUpdate}
      isOnline={isOnline}
    />
  );
}
