import {
  CloseCircleTwoTone,
  CodeOutlined,
  DownloadOutlined,
  LineChartOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Flex, Skeleton, Typography } from "antd";
import { ExportToCsv } from "export-to-csv";
import * as React from "react";
import type { GridChildComponentProps } from "react-window";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { InjectedHeight } from "../../../../../../components/height-sizer/withHeightSizer";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import { TabMenu } from "../../../../../../components/menu/TabMenu";
import type {
  SpreadsheetBodyCellRenderer,
  SpreadsheetHeaderCellRenderer,
} from "../../../../../../components/spreadsheet/SpreadsheetScrollSync";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type {
  BooleanSchemaItem,
  CellData,
  DataType,
  SchemaResult,
  TableResult,
} from "../../../../../../interfaces/transformations";
import { grey, red } from "../../../../../../utils/colorPalette";
import { generateUniqueId } from "../../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import { SpreadsheetErrorRenderer } from "../../../../../spreadsheet/error/SpreadsheetErrorRenderer";
import FieldRenderer from "../../../../../spreadsheet/renderer/FieldRenderer";
import TypeRenderer from "../../../../../spreadsheet/renderer/TypeRenderer";
import * as Toolbar from "../../../../../spreadsheet/toolbar/Toolbar";
import { DatasetViewer } from "../../../dataset-viewer/DatasetViewer";
import EmbeddedCharts from "./embeded-charts/EmbeddedChart";
import { SQLExport } from "./export/SQLExport";
import { SQLExecutedQuery } from "./SQLExecutedQuery";

interface ISQLResultsProps {
  status: "initial" | "error" | "loading" | "success";
  count?: number;
  records?: TableResult;
  schema?: SchemaResult;
  sql: AsyncData<string>;
  error?: string;
  loading: {
    schema: boolean;
    count: boolean;
    records: boolean;
  };
  initialScrollLeft?: number;
  initialScrollTop?: number;
  height?: number;
  onScroll?: (scrollLeft: number, scrollTop: number) => void;
  currentWarehouse: IDestination;
}

interface IState {
  preSaveModalVisible: boolean;
  primaryKeysModalVisible: boolean;
  primaryKeysModalSaving: boolean;
  tab: "results" | "chart" | "sql" | "export";
}

type Props = ISQLResultsProps &
  InjectedAntUtilsProps &
  InjectedOrgProps &
  InjectedHeight;

class SQLResults extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      preSaveModalVisible: false,
      primaryKeysModalVisible: false,
      primaryKeysModalSaving: false,
      tab: "results",
    };
  }

  public spreadsheetBodyCellRenderer =
    (schemaDef: SchemaResult): SpreadsheetBodyCellRenderer =>
    (props: GridChildComponentProps<SchemaResult>) => {
      const { loading, schema, records } = this.props;

      const dataKey = Object.keys(schemaDef)[props.columnIndex];
      const cellData =
        records && records[props.rowIndex]
          ? records[props.rowIndex][dataKey]
          : null;

      const key = props.rowIndex + "-" + props.columnIndex;

      return loading.records || (schema && schema[dataKey].loading) ? (
        <div
          key={`loading-${key}`}
          className="cell-content"
          style={{ ...props.style }}
        >
          <Skeleton.Input
            style={{ width: 174, borderRadius: 12, height: 16, marginTop: 7 }}
            active={true}
            size={"small"}
          />
        </div>
      ) : (
        <div
          key={key}
          style={{ ...props.style }}
          className={`cell-content`}
          onDoubleClick={(e) => {
            try {
              if (records?.[props.rowIndex]?.[dataKey]) {
                e.preventDefault();
                navigator.clipboard.writeText(
                  records[props.rowIndex]?.[dataKey]?.toString?.()
                );
                this.props.antUtils.message.success(
                  "Value copied to clipboard"
                );
              }
            } catch (error) {
              console.warn(error);
            }
          }}
        >
          {this.renderContent(
            cellData,
            schema ? schema[dataKey].domain : undefined
          )}
        </div>
      );
    };

  public renderContent = (cellData: CellData, type?: DataType) => {
    let content = cellData;

    return <FieldRenderer content={content} type={type} />;
  };

  public spreadsheetHeaderCellRenderer =
    (schemaDef: SchemaResult): SpreadsheetHeaderCellRenderer =>
    (listProps) => {
      const { loading, schema } = this.props;
      const dataKey = Object.keys(schemaDef)[listProps.index];

      const renderDataType = () => {
        if (schema) {
          const schemaItem = schema[dataKey];
          return (
            <TypeRenderer
              domain={schemaItem.domain}
              formula={schemaItem.operation === "Table.AddColumn"}
            />
          );
        }
        return <span />;
      };

      return loading.schema ? (
        <div
          className="ReactVirtualized__Table__headerColumn"
          style={listProps.style}
        >
          <Skeleton.Input
            style={{ height: 16, borderRadius: 12, width: 174, marginTop: 7 }}
            active={true}
            size={"small"}
          />
        </div>
      ) : (
        <div
          className={`ReactVirtualized__Table__headerColumn`}
          style={listProps.style}
        >
          {
            <div className="header-inner">
              <div className="header-type">{renderDataType()}</div>
              <div className="header-name">
                {schema && schema[dataKey] && schema[dataKey].label
                  ? schema[dataKey].label
                  : dataKey}
              </div>
            </div>
          }
        </div>
      );
    };

  public render() {
    const {
      loading,
      schema,
      records,
      count,
      error,
      status,
      sql,
      height,
      currentWarehouse,
    } = this.props;

    let schemaDef = schema ? schema : {};
    if (loading.schema) {
      schemaDef = Array.from({ length: 10 }, (_, a) => ({
        key: generateUniqueId(),
      }))
        .map((_) => {
          return {
            [_.key]: {
              domain: "BOOLEAN",
              type: "BOOLEAN",
              distinct: 0,
              null: 0,
              count: 0,
            } as BooleanSchemaItem,
          };
        })
        .reduce((p, v, i) => {
          return {
            ...p,
            ...v,
          };
        }, {} as SchemaResult);
    }

    let recordsDef = records ? records : [];
    if (loading.records) {
      const record = Object.keys(schemaDef).reduce((p, v, i) => {
        return {
          ...p,
          [v]: null,
        };
      }, {});
      recordsDef = Array.from({ length: 100 }, (_, a) => record);
    }

    const renderInner = () => {
      if (status === "initial") {
        return <Feednack>Please run a query to get started</Feednack>;
      }
      if (this.state.tab === "results") {
        return (
          <>
            <div className="spreadsheet-table">
              {error ? (
                <SpreadsheetErrorRenderer>{error}</SpreadsheetErrorRenderer>
              ) : (
                <DatasetViewer
                  loading={{ ...loading, tables: false }}
                  tableData={{ schema, list: records, rowCount: count }}
                  onExportCsv={() => {
                    const csvExporter = new ExportToCsv({
                      useKeysAsHeaders: true,
                      fieldSeparator: ",",
                      quoteStrings: '"',
                      decimalSeparator: ".",
                      showLabels: true,
                      showTitle: false,
                      useTextFile: false,
                      useBom: true,
                    });
                    return csvExporter.generateCsv(recordsDef);
                  }}
                />
              )}
            </div>
          </>
        );
      } else if (
        this.state.tab === "export" &&
        sql.status === "success" &&
        currentWarehouse.isDataLoadingEnabled &&
        currentWarehouse.destinationMeta.warehouseType === "bigquery"
      ) {
        return <SQLExport sql={sql.data} currentWarehouse={currentWarehouse} />;
      } else if (this.state.tab === "sql" && sql) {
        return <SQLExecutedQuery height={height} sql={sql} />;
      } else {
        return (
          <div className="spreadsheet-table">
            {" "}
            {error ? (
              <SpreadsheetErrorRenderer>{error}</SpreadsheetErrorRenderer>
            ) : (
              <EmbeddedCharts
                status={status}
                data={loading.records ? [] : recordsDef}
                schema={loading.schema ? {} : schemaDef}
              />
            )}
          </div>
        );
      }
    };

    return (
      <div className="spreadsheet-inner">
        {status !== "initial" ? (
          <Toolbar.Toolbar>
            <Flex align="center" gap="small" style={{ paddingInline: 12 }}>
              <div style={{ flexGrow: 1 }}>
                <TabMenu
                  items={[
                    {
                      key: "results",
                      icon: <TableOutlined />,
                      content: "Results",
                    },
                    ...(sql
                      ? [
                          {
                            key: "sql",
                            icon: <CodeOutlined />,
                            content: "Executed SQL",
                          },
                        ]
                      : []),
                    {
                      key: "chart",
                      icon: <LineChartOutlined />,
                      content: "Chart",
                    },
                    ...(sql &&
                    currentWarehouse.destinationMeta.warehouseType ===
                      "bigquery" &&
                    currentWarehouse.isDataLoadingEnabled &&
                    status !== "error"
                      ? [
                          {
                            key: "export",
                            icon: <DownloadOutlined />,
                            content: "Export",
                          },
                        ]
                      : []),
                  ]}
                  activeKey={this.state.tab}
                  onChange={(tab: any) => this.setState({ tab })}
                />
              </div>

              {status === "error" && (
                <div>
                  <Typography.Text type="danger">
                    <CloseCircleTwoTone twoToneColor={red[6]} /> Error
                  </Typography.Text>
                </div>
              )}
            </Flex>
          </Toolbar.Toolbar>
        ) : (
          <span style={{ width: "100%", height: "1px", background: grey[5] }} />
        )}

        {renderInner()}
      </div>
    );
  }
}

export default compose<Props, ISQLResultsProps>(
  withAntUtils,
  WithOrg
)(SQLResults);
