import { Card, Spin } from "antd";
import * as React from "react";
import {
  type InjectedAntUtilsProps,
  withAntUtils,
} from "../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import type { AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import {
  generateDownloadlink,
  getWarehouseDownloadLink,
} from "../../../../../../../services/FileService";
import { LoadingOutlined } from "@ant-design/icons";
import * as path from "path";

export interface ISQLExportItemProps {
  filePath: string;
  warehouseId: string;
  index: number;
}

type Props = ISQLExportItemProps & InjectedAntUtilsProps;

function SQLExportItem(props: Props) {
  const { filePath, warehouseId, index, antUtils } = props;
  const [downloadLink, setDownloadLink] = React.useState<AsyncData<string>>({
    status: "initial",
  });

  const fetch = async () => {
    try {
      setDownloadLink({ status: "loading" });
      const d = await getWarehouseDownloadLink(warehouseId, filePath);
      const url = generateDownloadlink("warehouses", warehouseId, d.data.token);
      setDownloadLink({ status: "initial" });
      window.open(url, "_blank")?.focus();
    } catch (err) {
      console.error(err);
      antUtils.message.error(
        "There was an error generating your download link"
      );
      setDownloadLink({ status: "initial" });
    }
  };

  const f = filePath.split("/");
  const base = f[f.length - 1];

  return (
    <Spin
      spinning={downloadLink.status !== "initial"}
      indicator={<LoadingOutlined />}
      delay={100}
    >
      <Card onClick={() => fetch()} className="worksheet-export-item">
        <div>Download: {base}</div>
      </Card>
    </Spin>
  );
}

export default compose<Props, ISQLExportItemProps>(withAntUtils)(SQLExportItem);
