import { Promise as BPromise } from "bluebird";
import _ from "lodash";
import type {
  Transformation,
  TransformationResult,
} from "../interfaces/transformations";
import ApiService from "./ApiService";
import { authenticatedRequest } from "./authService";

export interface DatabaseInfo {
  databaseName: string;
  shouldQuote: boolean;
}

export interface SchemaInfo {
  schemaName: string;
  shouldQuote: boolean;
}
interface TableInfo {
  tableName: string;
  shouldQuote: boolean;
}

export interface TableFields {
  fields?: Field[];
}

interface Field {
  domain: string;
  type: string;
  name: string;
  description?: string;
}

export interface CompletionRequest {
  prompt: string;
  model: string;
}

export enum WarehouseUserType {
  BI = "bi",
  DATA_LOADING = "data_loading",
}

export const getDatabases = (
  warehouseId: string,
  userType: WarehouseUserType
): Promise<{ data: DatabaseInfo[] }> => {
  return authenticatedRequest().then(() => {
    return ApiService.getRequest(
      `brizo/warehouse/${warehouseId}/warehouse/databases`,
      {
        userType,
      }
    );
  });
};

export const getSchemas = (
  warehouseId: string,
  databaseId: string,
  userType: WarehouseUserType
): Promise<{ data: SchemaInfo[] }> => {
  return authenticatedRequest().then(() => {
    return ApiService.getRequest(
      `brizo/warehouse/${warehouseId}/warehouse/databases/${databaseId}/schemas`,
      {
        userType,
      }
    );
  });
};

export const getTables = (
  warehouseId: string,
  databaseId: string,
  schemaId: string,
  userType: WarehouseUserType
): Promise<{ data: TableInfo[] }> => {
  return authenticatedRequest().then(() => {
    return ApiService.getRequest(
      `brizo/warehouse/${warehouseId}/warehouse/databases/${databaseId}/schemas/${schemaId}/tables`,
      {
        userType,
      }
    );
  });
};

export const getWarehouseTableColumns = (
  warehouseId: string,
  databaseId: string,
  schemaId: string,
  tableId: string
): Promise<{ data: TableFields }> => {
  return authenticatedRequest().then(() => {
    return ApiService.getRequest(
      `brizo/warehouse/${warehouseId}/warehouse/databases/${databaseId}/schemas/${schemaId}/tables/${tableId}/fields`
    );
  });
};

interface CheckCredentialResult {
  status: "ok" | "error";
  error?: string;
  data: {
    canRunQuery: boolean;
    canWriteStorage: boolean;
    createTable: boolean;
  };
}

export const checkWarehouseCredentials = async (
  orgId: string,
  credentials: {
    warehouse_type: string;
    [key: string]: string;
  }
): Promise<CheckCredentialResult> => {
  await authenticatedRequest();
  const result = await ApiService.postRequest<CheckCredentialResult>(
    `brizo/org/${orgId}/warehouse/test_query`,
    credentials
  );
  return result;
};

// Transformation

interface MashupFunctionMap {
  [a: string]: Transformation[];
}
export interface WrappedTransformationResult {
  data: TransformationResult;
  errors?: {
    [key: string]: string;
  };
}
export const computeTransformations = async (
  warehouseId: string,
  functions: MashupFunctionMap,
  expandQuery = false,
  throwError = true
): Promise<WrappedTransformationResult> => {
  await authenticatedRequest();

  // In order to avoid sending payload that are too large to the mashup endpoint, we chunk the requests to make N calls
  const functionKeyChunks = _.chunk(Object.keys(functions), 20);
  const resultChunks = await BPromise.map<
    string[],
    WrappedTransformationResult
  >(
    functionKeyChunks,
    async (chunk) => {
      const chunkFunctions = chunk.reduce<MashupFunctionMap>((acc, key) => {
        return {
          ...acc,
          [key]: functions[key],
        };
      }, {});
      return ApiService.postRequest(
        `brizo/warehouse/${warehouseId}/mashup`,
        chunkFunctions,
        expandQuery
          ? {
              expand: true,
            }
          : undefined
      );
    },
    { concurrency: 4 }
  );

  const transformationResult = resultChunks.reduce<TransformationResult>(
    (acc, resultChunk) => {
      return {
        ...acc,
        ...resultChunk.data,
      };
    },
    {}
  );

  const transformationError = resultChunks.reduce<{ [key: string]: string }>(
    (acc, resultChunk) => {
      return {
        ...acc,
        ...(resultChunk.errors ? resultChunk.errors : {}),
      };
    },
    {}
  );
  const hasErrors = Object.keys(transformationError).length > 0;
  if (hasErrors && throwError) {
    throw new Error(transformationError[Object.keys(transformationError)[0]]);
  }

  return {
    data: transformationResult,
    errors: hasErrors ? transformationError : undefined,
  };
};

export const getSqlFromTransformations = async (
  warehouseId: string,
  transformation: Transformation[]
): Promise<{ data: string }> => {
  await authenticatedRequest();

  const sql = await ApiService.postRequest(
    `brizo/warehouse/${warehouseId}/sql`,
    transformation
  );
  // In order to avoid sending payload that are too large to the mashup endpoint, we chunk the requests to make N calls

  return sql as {
    status: "ok";
    data: string;
  };
};

export const validateResult = (
  warehouseId: string,
  transformations: Transformation[]
) => {
  return authenticatedRequest().then(() => {
    return ApiService.postRequest<{
      status: "success" | "error";
      error_message: string;
    }>(`brizo/warehouse/${warehouseId}/mashup/validate`, {
      stack: transformations,
    });
  });
};

export const getUserCreatedColumn = (t: Transformation[]) => {
  return t
    .map((a) => {
      if (
        a.operation.type === "Table.AddColumn" ||
        a.operation.type === "WhalyExt.Table.AddLookupColumn" ||
        a.operation.type === "WhalyExt.Table.AddRollupColumn"
      ) {
        return a.operation.args.newColumnName;
      }
      return null;
    })
    .filter((a) => !!a);
};

export const getCompletions = async (
  orgId: string,
  body: CompletionRequest
): Promise<{ data: string }> => {
  await authenticatedRequest();

  const sql = await ApiService.postRequest(
    `brizo/org/${orgId}/completions`,
    body
  );
  // In order to avoid sending payload that are too large to the mashup endpoint, we chunk the requests to make N calls

  return sql as {
    status: "ok";
    data: string;
  };
};

export const getExport = async (
  warehouseId: string,
  sql: string
): Promise<{ filePath: string; results: Array<string> }> => {
  await authenticatedRequest();

  const exp = await ApiService.postRequest<{
    status: "success";
    data: {
      filePath: string;
      results: Array<string>;
    };
    error?: string;
  }>(`brizo/warehouse/${warehouseId}/export`, {
    sql,
  });

  if (exp.status !== "success") {
    throw new Error(exp?.error);
  }
  // In order to avoid sending payload that are too large to the mashup endpoint, we chunk the requests to make N calls

  return exp.data;
};
